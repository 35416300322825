import React, { Component } from 'react'

import './TrainText.scss'

export default class TrainText extends Component {
  render() {
    return (
      <div className='TrainTextPage'>
            <h1>关于举办第二期报废机动车回收拆解行业及二手车评估线上培训班的通知</h1>
            <p>各有关单位：</p>
            <p className='ti-w'>根据国务院《报废机动车回收管理办法》（国务院令第715号），商务部制定了《报废机动车回收管理办法实施细则》（商务部令2020年第2号），提出规范报废机动车回收拆解活动，加强报废机动车回收拆解行业管理。为规范行业管理，促进从业人员对行业法律法规、环保要求、规范化操作内容的理解掌握，我院将于2024年1月15-18日举办第二期报废机动车回收拆解行业及二手车评估线上培训班。欢迎各有关单位本着自愿原则报名参加。现将有关事项通知如下：</p>
            <p className='ti-w fwb'>一、培训内容</p>
            <p className='ti-3 fwb'>（一）学习《报废机动车回收管理办法》（国务院令第715号）</p>
            <p className='ti-4'>1.报废机动车回收拆解企业准入退出机制</p>
            <p className='ti-4'>2.拆解企业“五大总成”再制造要求</p>
            <p className='ti-4'>3.主管部门对拆解企业的日常监管要求</p>
            <p className='ti-3 fwb'>（二）学习《报废机动车回收拆解企业技术规范》（GB22128-2019）</p>
            <p className='ti-4'>1.拆解企业建设项目的选址及产能要求</p>
            <p className='ti-4'>2.拆解企业需具备的设施设备要求</p>
            <p className='ti-4'>3.拆解企业对电动汽车回收拆解要求</p>
            <p className='ti-4'>4.拆解企业场地建设和设施设备环保要求</p>

            <p className='ti-3 fwb'>（三）学习《报废机动车回收管理办法实施细则》（商务部令2020年第2号）</p>
            <p className='ti-4'>1.报废机动车回收拆解企业活动的监督管理要求</p>
            <p className='ti-4'>2.申请报废机动车回收拆解资质认定和管理要求</p>
            <p className='ti-4'>3.报废机动车回收拆解企业的运营规范要求</p>
            <p className='ti-4'>4.报废机动车回收拆解企业的违规行为及法律后果</p>
            <p className='ti-4'>15.报废机动车回收拆解企业的信息追溯要求</p>
            <p className='ti-3 fwb'>（四）学习《报废机动车拆解企业污染控制技术规范》（HJ348-2022）</p>
            <p className='ti-4'>1. 报废机动车拆解企业基础设施和拆解过程污染控制要求及污染物排放要求</p>
            <p className='ti-4'>2. 报废机动车拆解企业管理、企业环境监测的要求</p>
            <p className='ti-4'>3. 报废电动汽车拆解全过程污染控制要求</p>
            <p className='ti-4'>4. 报废机动车拆解后的固废、危废的特性及去向要求</p>
            <p className='ti-3 fwb'>（五）学习《报废电动汽车回收拆解技术要求》（征求意见稿）</p>
            <p className='ti-4'>1. 报废电动汽车回收拆解的安全防护要求</p>
            <p className='ti-4'>2. 报废电动汽车回收拆解的进厂检测和分类</p>
            <p className='ti-4'>3. 报废电动汽车贮存要求和场内转移要求</p>
            <p className='ti-4'>4. 报废电动汽车的拆解技术要求</p>
            <p className='ti-4'>5. 拆卸后动力电池安全贮存要求</p>
            <p className='ti-3 fwb'>（六）学习《汽车零部件再制造规范管理暂行办法》（发改环资规[2021]528号）</p>
            <p className='ti-4'>1. 汽车零部件再制造企业的规范条件</p>
            <p className='ti-4'>2. 汽车零部件再制造企业旧件回收管理要求</p>
            <p className='ti-4'>3. 汽车零部件再制造生产的管理要求</p>
            <p className='ti-4'>4. 汽车零部件再制造产品的管理要求</p>
            <p className='ti-4'>5. 汽车零部件再制造市场的管理要求</p>
            <p className='ti-4'>6. 监管部门对再制造行业的监督管理要求</p>
            <p className='ti-3 fwb'>（七）报废机动车回收拆解企业实际运营管理实操分享</p>
            <p className='ti-4'>1.报废机动车辆的唯一性确认及监销要求</p>
            <p className='ti-4'>2.报废机动车企业的回收业务特点及管理要求</p>
            <p className='ti-4'>3.报废车企业安全和消防管理方式</p>
            <p className='ti-4'>4.报废车企业全流程信息智能管理模式</p>
            <p className='ti-3 fwb'>（八）二手车评估</p>
            <p className='ti-4'>1.二手车鉴定行业发展重要性</p>
            <p className='ti-4'>2.二手车鉴定评估流程及注意事项</p>
            <p className='ti-4'>3.学习《二手车鉴定评估技术规范》GB/T30323-2013</p>
            <p className='ti-4'>4.燃油汽车构造及重要零部件识读</p>
            <p className='ti-4'>5.车身外观、驾驶舱、发动机底盘、启动、路试检查要点</p>
            <p className='ti-4'>6.新能源汽车构造及重要部件识读</p>
            <p className='ti-4'>7.动力电池评估技术</p>
            <p className='ti-4'>8.事故车辆判定方法、检查要点和难点</p>
            <p className='ti-4'>9.泡水车、火烧车的判定方法及动静态车辆技术检查</p>
            <p className='ti-4'>10.撰写评估报告</p>
            <p className='ti-4'>11.二手车市场交易注意事项</p>
            <p className='ti-w fwb'>二、培训形式</p>
            <p className='ti-4'>本期培训班采用线上直播的形式举办，培训课程拟邀请行业主管部门负责人、相关单位专家学者、行业领先单位高级管理人员授课。</p>
            <p className='ti-w fwb'>三、招生对象</p>
            <p className='ti-4'>各级政府相关部门工作人员、报废机动车回收拆解企业拆解技术人员、报废电动汽车拆解技术人员、报废机动车回收人员、报废机动车回收拆解企业污染控制管理人员、报废机动车回收拆解企业运营管理人员、报废机动车项目运营负责人；申报报废机动车回收拆解资质的技术人员及管理人员、再生资源回收加工企业从业人员、报废机动车拆解设备企业从业人员；动力蓄电池回收拆解处置企业人员; 二手车评估人员。各地可组织相关单位工作人员参加。</p>
            <p className='ti-w fwb'>四、费用及其他事项</p>
            <p className='ti-4'>本次培训班共涉及报废汽车拆解专业技术人员、报废电动汽车拆解专业技术人员、报废机动车回收拆解企业污染控制高级管理人员、报废机动车回收拆解企业高级管理人员、二手车评估人员五个方向。其中报废汽车拆解专业技术人员培训费2800元/人、报废电动汽车拆解专业技术人员、报废机动车回收拆解企业污染控制高级管理人员、报废机动车回收拆解企业高级管理人员培训费均为3200元/人，二手车评估人员培训费2200元/人（包括教学、教材等费用）。</p>
            <p className='ti-4'>本次培训班由商务部国际贸易经济合作研究院主办并出具发票，北京车兄弟科技有限公司承办。</p>
            <p className='ti-4'>即日起报名，请参训人员将报名回执表发至会务组。会务组将于开班前5日内向参训学员详告具体报到事项。请参训人员直接汇款至以下账户,汇款时请备注“培训202355”,并请把汇款凭证及电子发票接收手机和邮箱号发至会务组。</p>
            <p className='ti-4'>户名:商务部国际贸易经济合作研究院</p>
            <p className='ti-4'>账号：0200003409089121739 </p>
            <p className='ti-4'>开户行：中国工商银行股份有限公司北京朝阳支行</p>
            <p className='ti-w fwb'>五、联系方式</p>
            <p className='ti-3 fwb'>（一）会务组</p>
            <p className='ti-4'>联系人：由红霞</p>
            <p className='ti-4'>手机：13021169078</p>
            <p className='ti-4'>邮箱：CDRIA999@163.com</p>
            <p className='ti-3 fwb'>（二）商务部研究院培训中心</p>
            <p className='ti-4'>联系人：章旭</p>
            <p className='ti-4'>电话：010-64515189</p>
            <p className='ti-4'>网址：www.caitec.org.cn</p>
            <p className='ti-4'>地址：北京东城区安外东后巷28号院主楼118室</p>
            <p className='ti-4'>地更多信息,请浏览官网https://www.caitec.org.cn/。</p>
            <p className='ti-4 ' ><a className='colorBlue' href="https://csdfz-collection.oss-cn-beijing.aliyuncs.com/collection/20231225/efad6c9704778d48e0c7511f7152627d.docx">附件：报名回执表</a></p>
            <div className='rightSign'>
                <p>商务部国际贸易经济合作研究院</p>
                <p>2023年12月27日</p>
            </div>
      </div>
    )
  }
}
