import React, { Component } from 'react'
import {
	Form,
	Input,
	Tooltip,
	Icon,
	Cascader,
	Upload,
	Select,
	Row,
	Col,
	Checkbox,
	message,
	Button,
	AutoComplete,
} from 'antd';
import {
	encryptByDES,
	encryptPassword,
	encryptEDSPwds
} from "../../utils/encrypt";
import BackEnd from '../../services/api';
import areaJson from './area.json'
const { Option } = Select;
function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('只能上传类型是JPG/PNG文件');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('上传图片不能超过2MB!');
	}
	return isJpgOrPng && isLt2M;
}
class CompanyRegister extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isActive: false, // tab切换
			isClick: false, // 验证码按钮禁用
			codeContent: '获取验证码', // 验证码按钮文案
			loading: false, // 上传图片的loading图
			imageUrl: '', // 上传图片的url
			getFieldsValue: {},// 表单的值
			captchaChange: '',// 监听的验证码
			companyPhoneChange: '',//监听手机号
			recommendPhone: '',//监听手机号
			companyRegister: {
				Code: '21103',
				Mobile: '',
				LoginPwd: "",
				Name: "",
				LicenceImgUrl: "",
				EnterpriseCategory: 0,
				MainBusiness: '',
				Province: "",
				City: "",
				County: "",
				OriMobile:''
			},
			optionss: [
				{
					value: '1',
					label: '汽配商',
				},
				{
					value: '2',
					label: '汽修厂',
					children: [
						{
							value: '一类',
							label: '一类',
						},
						{
							value: '二类',
							label: '二类',
						},
						{
							value: '快修',
							label: '快修',
						},
						{
							value: '专修',
							label: '专修',
						},
						{
							value: '汽车美容',
							label: '汽车美容',
						},
						{
							value: '改装',
							label: '改装',
						},
					],
				},
				{
					value: '3',
					label: '厂家',
				},
				{
					value: '5',
					label: '报废车企业',
				}
			]
		}
	}
	// 返回登录
	backLogin = () => {
		window.history.back();
	}
	// 监听验证码
	captchaChange = (e) => {
		this.setState({
			captchaChange: e.target.value
		})
	}
	// 监听手机号
	companyPhoneChange = (e) => {
		this.setState({
			companyPhoneChange: e.target.value
		})
	}
	recommendPhone = (e) => {
		this.setState({
			recommendPhone: e.target.value
		})
	}
	// 获取验证码
	getCode = () => {
		// 重新发送动画
		if (this.state.companyPhoneChange === "") {
			message.error('手机号不能为空')
			return;
		}
		let time = 60;
		this.state.isClick = !this.state.isClick;
		var timer = setInterval(() => {
			time--;
			this.setState({
				codeContent: `重新发送(${time})`
			})
			if (time <= 0) {
				clearInterval(timer);
				time = 5;
				this.setState({
					codeContent: `获取验证码`,
					isClick: !this.state.isClick
				})
			}
		}, 1000);
		let encrypt = {
			Code: "00021003",
			Mobile: this.state.companyPhoneChange,
			TimeSptams: String(Date.parse(new Date())),
			SendMsgType: "5",
			Tokens: ""
		};
		let msg = `${encrypt.Mobile}${encrypt.SendMsgType}${encrypt.TimeSptams}`;

		encrypt.Tokens = encryptByDES(msg, 'jinmusen7931BEIJING');

		BackEnd.getData(encrypt)
			.then(res => {
				if (res.data.Message.ErrorMessage) {
					message.error(res.data.Message.ErrorMessage.ErrMsg)
					return;
				}
				window.sessionStorage.setItem('TimeSptams', encrypt.TimeSptams)
				message.success('短信发送成功!')
			})
			.catch(err => {
				message.error('短信发送失败!')
			});
	};
	// 格式数据
	initData() {
		let initData = {
			Code: 21005,
			Mobile: this.state.companyPhoneChange,
			MsgCodes: this.state.captchaChange,
			TimeSptams: window.sessionStorage.getItem('TimeSptams'),
			SendMsgType: 5,
		}

		let key = `${initData.Mobile}${initData.MsgCodes}${initData.TimeSptams}`;

		initData = Object.assign(
			{},
			initData,
			{
				Tokens: encryptByDES(key, 'jinmusen7931BEIJING')
			}
		)
		return initData
	}
	// 企业用户注册
	handleCompanySubmit = e => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				BackEnd.getData(this.initData()).then(res => {
					if (res.data.Message.ErrorMessage) {
						message.error('验证码不正确!');
						return;
					}
					// 账户密码
					let newInitData =
						values.companyPhone.substring(values.companyPhone.length - 4) + "00kt";
					let newLoginPwd = encryptPassword(values.companyPassword);
					let submitCompanyRegister = Object.assign({}, this.state.companyRegister, {
						Mobile: values.companyPhone,
						LoginPwd: encryptEDSPwds(newLoginPwd, newInitData),
						Name: values.companyName,
						LicenceImgUrl: this.state.imageUrl,
						EnterpriseCategory: values.companyType[0],
						Province: values.companyArea[0],
						City: values.companyArea[1],
						County: values.companyArea[2],
						MainBusiness: values.companyType[1],
						OriMobile:this.state.recommendPhone
					})
					this.setState({
						companyRegister: submitCompanyRegister
					}, () => {
						BackEnd
							.getData(this.state.companyRegister)
							.then(res => {
								if (res.data.Message.ErrorMessage) {
									message.error(res.data.Message.ErrorMessage.ErrMsg);
									return;
								}
								message.success('企业用户注册成功!');
								window.history.back()
							})
					})
				})
			}
		});
	};

	handleChange = (info, fileList, event) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageUrl =>
				this.setState({
					imageUrl: info.file.response.datas.baseUrl + info.file.response.datas.filePath,
					loading: false,
				}),
			);
		}
	};
	componentWillUnmount() {
		clearInterval(this.timer);
	}

	onChangeq(value) {
		console.log(value);
	}


	render() {
		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 24,
					offset: 0,
				},
			},
		};


		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">上传</div>
			</div>
		);
		return (

			<div className='registerContentCompany' style={this.props.isActive ? { display: 'none' } : { display: 'block' }}>
				<Form {...formItemLayout} onSubmit={this.handleCompanySubmit}>
					<Form.Item label="企业名称">
						{getFieldDecorator('companyName', {
							rules: [
								{
									required: true,
									message: '请输入企业名称',
								},
							],
						})(<Input placeholder='请输入公司名称' />)}
					</Form.Item>
					<Form.Item label="所属地区">
						{getFieldDecorator('companyArea', {
							// initialValue: ['zhejiang', 'hangzhou', 'xihu'],
							rules: [
								{ type: 'array', required: true, message: '请输入地区' },
							],
						})(<Cascader options={areaJson} placeholder='请输入地区' onChange={this.companyArea} />)}
					</Form.Item>
					<Form.Item label="企业类别">
						{getFieldDecorator('companyType', {
							rules: [{ required: true, message: '请选择企业类别' }],
						})(
							<Cascader options={this.state.optionss} placeholder="请选择企业类型" onChange={this.onChangeq()} />
						)}
					</Form.Item>
					<Form.Item label="营业执照">
						{getFieldDecorator('upload', {
							rules: [
								{
									required: true,
									message: '请上传营业执照'
								}
							]
						})(
							<Upload
								name="file"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								action="https://shop.chexd.com/apis/upload/uploadFile"
								beforeUpload={beforeUpload}
								onChange={this.handleChange}
							>
								{this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
							</Upload>
						)}
					</Form.Item>
					<Form.Item label="联系人姓名">
						{/* 
						  name:wangji
						  date:2020.5.14
						  content:企业注册添加联系人姓名
						*/}
						{getFieldDecorator('nickname', {
							rules: [
								{
									required: true,
									message: '请输入联系人姓名',
								},
								{
									pattern: "^[A-z0-9\\u4e00-\\u9fa5]*$",
									message: '禁止输入特殊字符'
								}
							],
						})(<Input placeholder='请输入联系人姓名' />)}
					</Form.Item>
					<Form.Item label="手机号">
						{getFieldDecorator('companyPhone', {
							rules: [
								{
									required: true,
									message: '请输入手机号',
								},
								{
									pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/,
									message: '手机号输入不正确'
								}
							]
						})(<Input placeholder='请输入手机号' maxLength={11} onChange={this.companyPhoneChange} autoComplete='new-password' />)}
					</Form.Item>
					<Form.Item label="验证码">
						<Row gutter={8}>
							<Col span={12}>
								{getFieldDecorator('captcha', {
									rules: [{ required: true, message: '请输入验证码' }],
								})(<Input maxLength={6} placeholder='请输入验证码' onChange={this.captchaChange} />)}
							</Col>
							<Col span={12}>
								<Button onClick={this.getCode} disabled={this.state.isClick}>{this.state.codeContent}</Button>
							</Col>
						</Row>
					</Form.Item>
					<Form.Item label="登录密码">
						{getFieldDecorator('companyPassword', {
							rules: [
								{
									required: true,
									message: '请输入密码',
								}
							],
						})(<Input.Password maxLength={20} placeholder='请输入密码' autoComplete='new-password' />)}
					</Form.Item>
					<Form.Item label="邀请人手机号">
						{getFieldDecorator('OriMobile', {
							
						})(<Input placeholder='邀请人手机号' maxLength={11} onChange={this.recommendPhone} autoComplete='new-password' />)}
					</Form.Item>
					<Form.Item {...tailFormItemLayout} className='registerArea'>
						<Button type="primary" htmlType="submit" className='registerButton' onClick={this.CompanyRegister}>注册</Button>
					</Form.Item>
				</Form>
				<p className='ownNumber' onClick={this.backLogin}>已注册,去登陆</p>
			</div>
		)
	}
}
export default Form.create({})(CompanyRegister)

