import React, { PureComponent } from 'react'
import UploadImg from './UploadImg'
import { message,Input ,} from 'antd'
import BackEnd from "../../../../services/api";

const { TextArea } = Input;
class ProofOfPayment extends PureComponent {
    constructor(props) {
        super(props)
        this.state={
            payment_photo_remark:''
        }
      
        this.uploadRef1 = React.createRef()
      
    }
    onSubimt = (id)=>{
        console.log(this.state.payment_photo_remark.length)
       
        if(this.state.payment_photo_remark !=''){
            if(this.state.payment_photo_remark.length < 5 ) {
                message.error('备注最少5个字！')
            }else{
                let obj = {
                    payment_photo:this.uploadRef1.current.state.imageUrl,
                    train_id:id,
                    userId:window.localStorage.getItem('userId'),
                    CompanyName:window.localStorage.getItem('CompanyName'),
                    payment_photo_remark:this.state.payment_photo_remark
                }
                BackEnd.paymentPhoto(obj).then(res=>{
                    if(res.data.code == 0){
                        this.props.Payclose()
                    }else{
                        message.error(res.data.Message)
                    }
                })
            }
            
        }else{
            message.error('请填写付款凭证备注！')
        }
        
    }
    kongImg = ()=>{
        this.uploadRef1.current.kong()
    }
    render() {
        return (
            <div>
                <p>上传付款凭证照片</p>
                <UploadImg ref={this.uploadRef1}  name={'付款凭证'}></UploadImg>
                {/* <Button onClick={this.onSubimt} >提交</Button> */}
                <p>
                    <span className='redspan'>*</span> 付款凭证备注：
                    <TextArea placeholder='请输入付款凭证备注最少五个字' rows={4} value={this.state.payment_photo_remark} onChange={(e)=>{
                        this.setState({
                            payment_photo_remark:e.target.value
                        })
                    }} />
                </p>
            </div>
        )
    }
}

export default ProofOfPayment