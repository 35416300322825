import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty, InputNumber,Button,Input } from 'antd'
import './Qualityassurancecommitment.scss'
import BackEnd from '../../../services/api'
import { v4 as uuidv4 } from 'uuid';
const { TabPane } = Tabs
const { confirm } = Modal




class Qualityassurancecommitment extends Component {
  constructor() {
    super()
    this.state = {
      userid:window.localStorage.getItem('userId'),
      ResponseObj:{},
      warrantyCommitmentGuid:uuidv4(),
      AuthCode:window.localStorage.getItem('AuthCode')
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    
  }
  componentDidMount() {
    this.getQualityassurance()
  }
  onChange=(e,m,n)=> {
      this.state.ResponseObj[m]=n
     this.setState({
        ResponseObj:this.state.ResponseObj
     })
  }
  Change=(s,j,m,n)=> {
    this.state.ResponseObj.BrandPromise[s][j]=n
   this.setState({
      ResponseObj:this.state.ResponseObj
   })
}
Changetext=(e,s,d)=> {
    this.state.ResponseObj.BrandPromise[s][d]=e.target.value 
   this.setState({
      ResponseObj:this.state.ResponseObj
   })
}
onChangestatu=(e,m)=>{
    this.state.ResponseObj[e]=m.target.checked?1:0
   this.setState({
      ResponseObj:this.state.ResponseObj
   })
}
onChangestatus=(e,s,m)=>{
  this.state.ResponseObj.BrandPromise[e][s]=m.target.checked?1:0 
  this.setState({
    ResponseObj:this.state.ResponseObj
 })
}
  // 获取质保承诺
  getQualityassurance(){
    BackEnd.orderDetails("/api/EnterpriseSquareInfo/GetWarrantyCommitmentDataList", {
      storeId:this.state.AuthCode
    }).then(res => {
      if (res.data.Message == "Success") {
      this.setState({
        ResponseObj:res.data.ResponseObj[0]
      })
    }else{
      var obj= {
        "WarrantyCommitmentGuid": this.state.warrantyCommitmentGuid,
      "StoreId": this.state.AuthCode,
      "WhetherNoReason7days": 1,
      "WhetherOriginalParts": 0,
      "OriginalPartsMonth": 0,
      "OriginalPartsK": 0,
      "WhetherOE": 0,
      "OEMonth": 0,
      "OEK": 0,
      "WhetherDisassemble": 0,
      "DisassembleMonth": 0,
      "DisassembleK": 0,
      "OccurTime": "",
      "Userid": this.state.userid,
      "BrandPromise": [
        {
          "WarrantyCommitmentGuId":this.state.warrantyCommitmentGuid,
          "whether": 0,
          "BrandName": "",
          "WarrantyMonth": 0,
          "WarrantyK": 0
        },
        {
          "WarrantyCommitmentGuId":this.state.warrantyCommitmentGuid,
          "whether": 0,
          "BrandName": "",
          "WarrantyMonth": 0,
          "WarrantyK": 0
        }, {
          "WarrantyCommitmentGuId":this.state.warrantyCommitmentGuid,
          "whether": 0,
          "BrandName": "",
          "WarrantyMonth": 0,
          "WarrantyK": 0
        }, {
          "WarrantyCommitmentGuId":this.state.warrantyCommitmentGuid,
          "whether": 0,
          "BrandName": "",
          "WarrantyMonth": 0,
          "WarrantyK": 0
        },
      ]
    }
    this.setState({
      ResponseObj:obj
    })
    }
    })
  }
  addbrandlist=()=>{
    var arr=this.state.ResponseObj
    arr.BrandPromise.push({
        WarrantyCommitmentGuId:arr.WarrantyCommitmentGuid,
        Whether: 0,
        BrandName: "",
        WarrantyMonth: 0,
        WarrantyK: 0}
        )
        this.setState({
          ResponseObj:arr
        })
  }
  deleteList=(obj,index)=>{
    this.state.ResponseObj.BrandPromise.splice(obj,1)
    this.setState({
      ResponseObj:this.state.ResponseObj
    })
  }
  submitBut=()=>{
    BackEnd.orderDetails("/api/EnterpriseSquareInfo/InsertWarrantyCommitment",this.state.ResponseObj).then(res => {
       if(res.data.ResponseObj==true){
        message.success('保存成功')
         this.getQualityassurance()
       }
    })
  }
  render() {
    var arr=this.state.ResponseObj.BrandPromise
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="Qualityassurancecommitmentbox">
          <p className='title'>质保承诺</p>
          <div className="Qualityassurancecommitment">
            <div className="QcCenter">
              <p className='titles'>质保承诺</p>
              <p><Checkbox checked={this.state.ResponseObj.WhetherNoReason7days==1?true:false} onChange={this.onChangestatu.bind(this,"WhetherNoReason7days")}>支持七天无理由退货</Checkbox></p>
              <p><Checkbox checked={this.state.ResponseObj.WhetherOriginalParts==1?true:false} onChange={this.onChangestatu.bind(this,"WhetherOriginalParts")}>原厂件承诺：</Checkbox>
              <InputNumber style={{ width: "50px" }}  min={0} value={this.state.ResponseObj.OriginalPartsMonth} onChange={this.onChange.bind(this,this.state.ResponseObj.OriginalPartsMonth,"OriginalPartsMonth")} />&nbsp;&nbsp;&nbsp;个月或&nbsp;&nbsp;&nbsp;
              <InputNumber min={0} value={this.state.ResponseObj.OriginalPartsK} onChange={this.onChange.bind(this,this.state.ResponseObj.OriginalPartsK,"OriginalPartsK")} />&nbsp;&nbsp;&nbsp;公里质保</p>
              <p><Checkbox checked={this.state.ResponseObj.WhetherOE==1?true:false} onChange={this.onChangestatu.bind(this,"WhetherOE")}>oe件承诺：&nbsp;&nbsp;&nbsp;</Checkbox>
              <InputNumber  min={0} style={{ width: "50px" }}  value={this.state.ResponseObj.OEMonth} onChange={this.onChange.bind(this,this.state.ResponseObj.OEMonth,"OEMonth")} />&nbsp;&nbsp;&nbsp;个月或&nbsp;&nbsp;&nbsp;
              <InputNumber  min={0} value={this.state.ResponseObj.OEK} onChange={this.onChange.bind(this,this.state.ResponseObj.OEK,"OEK")} />&nbsp;&nbsp;&nbsp;公里质保</p>
              <p><Checkbox checked={this.state.ResponseObj.WhetherDisassemble==1?true:false} onChange={this.onChangestatu.bind(this,"WhetherDisassemble")}>拆车件承诺：</Checkbox>
              < InputNumber min={0} style={{ width: "50px" }}  value={this.state.ResponseObj.DisassembleMonth} onChange={this.onChange.bind(this,this.state.ResponseObj.DisassembleMonth,"DisassembleMonth")} />&nbsp;&nbsp;&nbsp;个月或&nbsp;&nbsp;&nbsp;
              <InputNumber  min={0} value={this.state.ResponseObj.DisassembleK} onChange={this.onChange.bind(this,this.state.ResponseObj.DisassembleK,"DisassembleK")} />&nbsp;&nbsp;&nbsp;公里质保</p>
              <br />
            </div>
            <br />
            <p>品牌件承诺：&nbsp;&nbsp;&nbsp;<Button onClick={this.addbrandlist}>新增</Button></p>
            <div className="brandlist">
              {
                arr==undefined?
                "":
                arr.map((item,index)=>{
                  return (<li className="rechargeamount" >
                    <p><Checkbox checked={item.Whether==1?true:false} onChange={this.onChangestatus.bind(this,index,"Whether")}></Checkbox>&nbsp;&nbsp;&nbsp; 
                      <Input style={{width:"150px"}} value={item.BrandName} onChange={e=>this.Changetext(e,index,"BrandName")} placeholder="请输入品牌名称" />&nbsp;&nbsp;
                      < InputNumber style={{ width: "50px" }} min={0} value={item.WarrantyMonth} onChange={this.Change.bind(this,index,"WarrantyMonth",item.WarrantyMonth)} />&nbsp;&nbsp;&nbsp;个月或&nbsp;&nbsp;&nbsp;
                      <InputNumber  style={{ width: "50px" }} min={0} value={item.WarrantyK} onChange={this.Change.bind(this,index,"WarrantyK",item.WarrantyK)} />&nbsp;&nbsp;&nbsp;公里质保&nbsp;&nbsp;&nbsp;
                      <span href="" onClick={this.deleteList.bind(this,index)} style={{color:"cornflowerblue"}}>删除</span></p></li>)
                })
                }
            </div>
              <Button onClick={this.submitBut}>提交</Button>
          </div>
        </div>

      </div>
    )
  }
}
export default withRouter(Qualityassurancecommitment)
