import React, { Component } from 'react'
import BackEnd from '../../../../services/api'
import {  Form, Input, InputNumber, Button } from 'antd';
// import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import './invoiceOrder.scss'


export default class InvoiceOrder extends Component {
    
    constructor (){
        super();
        this.state={
            CompanyName:'',
            identify:'',
            content:'',
            invoiceNumber:'',
        } 
    }
 
 
    render() {
      
        return (
            <div className="invoiceContent">
                        <p><span>发票抬头:</span>
                            <Input  mode="multiple"   placeholder="发票抬头"  onChange={e=>{
                                this.setState({
                                    CompanyName:e.target.value
                                })
                            }}  />
                        </p>
                   
                    <p><span>纳税人识别号:</span>
                        <Input type="text" placeholder="纳税人识别号"  onChange={e=>{
                            this.setState({
                                identify:e.target.value
                            })
                        }} />
                    </p>
                   
                    <p><span>发票内容:</span>
                        <Input type="text" placeholder="请输入发票内容"    onChange={e=>{
                            this.setState({
                                content:e.target.value
                            })
                        }} />
                    </p> 
                    
                    <p><span>发票人手机号:</span>
                        <Input type="text" placeholder="发票人手机号" onChange={e=>{
                            this.setState({
                                invoiceNumber:e.target.value
                            })
                        }} />
                    </p>
             


            </div>
        )
    }
}
// export default Form.create({name: 'myform'})(InvoiceOrder)