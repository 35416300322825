import React, { Component } from 'react'
import './EPC_query.scss'
import Header from '../../components/Header'
import { message , Modal } from 'antd'
import { setCookie, getCookie, deleteCookie } from '../../utils/env'
export default class EPC_query extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ZipCode:"",
            visible: false,
            id:null
        }
    }
    componentWillMount() {
    }
    componentDidMount() {
        
       console.log(getCookie('LoginCacheKey')==null,'1213212313')
        var this_ = this
        window.addEventListener('message', function(e) {
            console.log(e)
            var obj = JSON.parse(e.data)
            setCookie('isxinhao',obj.id,30)
            if(obj.enter==1){
                // message.success('请您先登陆！')
                this_.setState({
                    visible: true
                })
              
            }else if(obj.id == 1){
                deleteCookie('isxinhao')
            }else if(obj.id == 2){
                this_.setState({
                    id:2,
                    ZipCode:obj.OeCode,
               },()=>{

                this_.props.history.push({pathname:'/',query:{'ZipCode':obj.OeCode}})
                console.log('这里是什么',obj.OeCode,this.ZipCode)

            })
            }else if(obj.id == 3){
                this_.setState({
                    id:3,
                    ZipCode:obj.OeCode,
               },()=>{
                this_.props.history.push({pathname:'/',query:{'ZipCode':obj.OeCode}})
                console.log('这里是什么',obj.OeCode,this.ZipCode)

            })
            }
           console.log('data from domain2 ---> ' + e.data);
        }, false);
    }
    loginVisible = () => {
		this.setState({
			visible: false
		})
    }
    // 去登录
	goLogin = () => {
		this.props.history.push('/login')
	}
    render() {
        // let src = `http://localhost:8082`//本地
        // let src = `https://testepcweb.chexd.com`//测试
        // let src = `https://preepcweb.chexd.com`//准生产
        let src = `https://epcweb.chexd.com`//生产
       
        return (
            <div>
                <Header></Header>
                <div className="wire"> </div>
                <div className="warpper-epc">
                    <iframe id="sendMessage"
                     name="myFrame" onLoad={this.sendToken} src={src} width="100%" height="100%"  frameBorder="0"  onLoad={()=>{
                        const childFrameObj = document.getElementById('sendMessage');
                        if(getCookie('LoginCacheKey') == null ){
                            var data = {'LoginCacheKey': 0,'id': getCookie('isxinhao')}
                            childFrameObj.contentWindow.postMessage(JSON.stringify(data), src);
                        }else{
                            var data = {'LoginCacheKey': getCookie('LoginCacheKey'),'id': getCookie('isxinhao')}
                            childFrameObj.contentWindow.postMessage(JSON.stringify(data), src);
                        }
                     }} > </iframe>
                </div>
                <Modal
					title="提示"
					visible={this.state.visible}
					onCancel={this.loginVisible}
					footer={null}
					maskClosable={false}
					wrapClassName='isLoginModel'
				>
					<p>请您登录后再访问</p>
					<p className='loginFunction'><span className="spanmy" onClick={this.goLogin}>登录</span></p>
				</Modal>
               
            </div>
        )
    }
}
