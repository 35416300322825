import React, { Component } from 'react';
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import BackEnd from './../../../services/api'
import { Pagination, List, Spin } from 'antd'
import Base64 from 'base-64';
import {
    encryptByDES,
    encryptPassword,
    encryptEDSPwd
  } from '../../../utils/encrypt';
import "./Cheap.scss"
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <span>上一页</span>;
    } if (type === 'next') {
        return <span>下一页</span>;
    }
    return originalElement;
}
class Cheap extends Component {
    constructor(props) {
        super()
        this.state = {
            shopList: [], // 商品列表
            searchValue: '', // 搜索内容
            carSeriesName: '', //车系
            brandName: '', // 车型品牌
            searchFirm: {},
            TotalCount: 0, // 查询结果数量
            Page: 1,
            dataSort: 2,
            getNewListLoading: true,
            newSortOrderByDescending: false, // 默认新品排序
            priceSortOrderByDescending: false // 默认价格排序
        }
    }
    componentDidMount() {
        this.getNewList()
    }
    // 切换分页
    conversion = (page) => {
        window.scrollTo(0, 0);
        this.setState({
            Page: page
        }, () => {
            this.getNewList()
        })
    }
    firstPage = () => {
        window.scrollTo(0, 0);
        this.setState({
            Page: 1
        }, () => {
            this.getNewList()
        })
    }
    endPage = () => {
        // let changePage = Object.assign({}, this.state.searchFirm, { Page: Math.ceil(this.state.TotalCount / 5) || 1 })s
        window.scrollTo(0, 0);
        this.setState({
            Page: Math.ceil(this.state.TotalCount / 15) || 1
        }, () => {
            this.getNewList()
        })
    }
    // 查看配件详情
    goProductDetail = (item) => {
        // console.log('配件详情数据', item);
        this.props.history.push({ pathname: '/CheapDetail', search: `?id=${item.ComponentId}&prevAddr=3` })
        // this.props.history.push('/productDetail')
    }
    // 搜索获取数据
    // searchData(content) {
    //     this.setState({
    //         searchValue: content,
    //         Page: 1,
    //         dataSort: 2
    //     }, () => {
    //         this.getNewList()
    //     })
    // }
    // 展示内容
    showContent() {
        if (this.state.shopList.length > 0) {
            return this.state.shopList.map(item => <div className="productMain" key={'shopList_' + item.Id} >
                <div className="imgBoxs">
                    <img onClick={this.goProductDetail.bind(this, item)} src={item.PicturePathArray != null ? item.PicturePathArray[0] : require("./../imgage/prouctImg.png")} alt="" />
                </div>
                <p>{item.ComponentName}</p>
                <p onClick={this.goProductDetail.bind(this, item)} style={{width:'200px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{item.ComponentCode}</p>
                <p style={{ color: '#ff6b37' }}>￥{item.EnterpriseCategoryPrice}</p>
            </div>)
        } else {
            if (!this.state.getNewListLoading) {
                return <List className="goodsList-div" />
            }
        }
    }
    // 获取促销商品
    getNewList(clickType) {
        this.setState({
            getNewListLoading: true,
            shopList: [],
            newSortOrderByDescending: !this.state.newSortOrderByDescending,
            priceOrderByDescending: !this.state.priceOrderByDescending
        }, () => {
            // 设置排序开关
            let orderByDescending;
            if (clickType == 'newSort') {
                this.setState({
                    priceOrderByDescending: false
                })
                orderByDescending = this.state.newSortOrderByDescending
            } else if (clickType == 'priceSort') {
                this.setState({
                    newSortOrderByDescending: false
                })
                orderByDescending = this.state.priceOrderByDescending
            } else {
                orderByDescending = true
            }
            // console.log('最终值', orderByDescending)

            BackEnd
                .QueryShareAutoparts({ ciphertext: Base64.encode(encryptEDSPwd(JSON.stringify({
                    "carSeriesName": this.state.carSeriesName,
                    "brandName": "",
                    "authCode": 0,
                    "content": this.state.searchValue, // 搜索内容
                    "brand": this.state.brandName,
                    "orderByDescending": orderByDescending,
                    "sort": this.state.dataSort, // 1是按价格排序 2是按新品排序
                    "userId": localStorage.getItem('userId') || 0,
                    "promotionStatus": "1",
                    "currentPage": this.state.Page,
                    "pageSize": 15,
                    "isHomePage": false
                }))) })
                .then(res => {

                    if (res.data.Code == 0) {
                        console.log('促销商品获取成功', res)
                        this.setState({
                            shopList: res.data.ResponseObj.Items,
                            TotalCount: res.data.ResponseObj.TotalNum,
                            getNewListLoading: false
                        })
                    }

                })
        })

    }
    newSort() {
        this.setState({
            dataSort: 2
        }, () => {
                this.getNewList('newSort');
        })
    }
    priceSort() {
        this.setState({
            dataSort: 1
        }, () => {
                this.getNewList('priceSort');
        })
    }
    render() {
        return (
            <div>
                <Header />
                <div className="top_sort">
                    <div>
                        <span onClick={() => { this.props.history.push({ pathname: '/partsShopping', search: `` })}}>首页</span>
                        <span>促销商品</span>
                    </div>
                    <div>
                        <span onClick={this.newSort.bind(this)}> 上架时间 <img src={require("./../imgage/sort.png")} alt="" /></span>
                        <span onClick={this.priceSort.bind(this)}>价格排序<img src={require("./../imgage/sort.png")} alt="" /></span>
                    </div>
                </div>
                <div className="NewArrivals_list">

                    <Spin spinning={this.state.getNewListLoading} className="loading-spin" size="large" />

                    <div className="lists_box">
                        {this.showContent()}
                    </div>

                </div>
                {
                    this.state.shopList.length > 0 ? <div className="list_info_pagination">
                        <span className="first_button" onClick={this.firstPage}>首页</span>
                        <Pagination
                            total={this.state.TotalCount || 15}
                            current={this.state.Page}
                            itemRender={itemRender}
                            pageSize={15}
                            onChange={this.conversion}
                        />
                        <span className="last_button" onClick={this.endPage}>末页</span>
                    </div> : null
                }

                <Footer />
            </div>
        );
    }
}

export default Cheap;

