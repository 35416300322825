import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, Pagination, message, Spin, Checkbox, Empty, Space, Select, Input, Col, Row, InputNumber, DatePicker, AutoComplete, Cascader, Button } from 'antd'
import './Mycar.scss'
import QRCode from 'qrcode.react';
import BackEnd from '../../../services/api'
import Recharge from '../../PartsShopping/information/Recharge/Recharge'
const { TabPane } = Tabs
const { confirm } = Modal
const { Option } = Select;

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}


class Mycar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //列表传参
      informationList: {
        iMId: window.localStorage.getItem("IMId"),
        currentPage: 1,
        pageSize: 10,
      },
      //表头
      columns: [{
        title: "车牌号",
        dataIndex: 'plateNumber',
        key: 'plateNumber',
        width: 80,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '车架号',
        dataIndex: 'vinno',
        key: 'vinno',
        // width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '品牌',
        dataIndex: 'brandName',
        key: 'brandName',
        width: 80,
        ellipsis: true,
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '发动机号',
        dataIndex: 'engineSerialNumber',
        key: 'engineSerialNumber',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, , {
        title: '车型',
        dataIndex: 'vehicleMode',
        key: 'vehicleMode',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '年款',
        dataIndex: 'modelYear',
        key: 'modelYear',
        width: 60,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '型号',
        dataIndex: 'model',
        key: 'model',
        width: 230,
        ellipsis: true,
        className: 'model',
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '操作',
        width: 230,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div className="operation">
            <button onClick={this.modify.bind(this, record)}>修改</button>&nbsp;&nbsp;
            <button onClick={this.deletCar.bind(this, record)}>删除</button>&nbsp;&nbsp;
            <button onClick={this.toFind}>查看适用配件</button>
          </div>

        )
      }],
      visibleEnquiry: false,
      visibleEnquirys: false,
      carList: [],
      TotalCount: '',

      //修改数据暂存
      plateNumber: '',//车牌号
      vinno: '',//车架号
      engineSerialNumber: '',//发动机号
      carId: '',
      licensePlateList: '',
      licensePlateLists: '',
      carPlateCity: '京',
      carPlateCitys: '',
      carPlateNums: '',
      carPlateNum: '',
      FreeAdmission: '',
      judge: '2',//没有免费次数
      RechargePackagelist: [],
      cashbalanceText: 0, //
      PresentedbalanceText: 0,
      productId: "",
      tooltip: false,
      payShow: false,
      loadings: false,
      loadingss: false,
      loadingsss: false,
      price: ''

    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {

    this.myCarList()
    this.getlicensePlate()
    this.GetRechargePackage()
  }
  toFind = () => {
    this.props.history.push("/partsShopping")
  }
  //修改信息
  modify(record) {
    console.log(record)
    let city = record.plateNumber
    console.log(city.toString().slice(0, 1))
    console.log(city.substr(1, city.length))
    this.setState({
      carPlateCitys: city.toString().slice(0, 1),//地区
      carPlateNums: city.substr(1, city.length),//车牌
      vinno: record.vinno,//车架
      engineSerialNumber: record.engineSerialNumber,//发动机
      carId: record.id,//发动机
      visibleEnquirys: true

    }, () => {
      console.log(this.state.plateNumber)
      console.log(this.state.vinno)
      console.log(this.state.engineSerialNumber)
    })

  }
  //确认修改
  modifySure = () => {
    //车牌号校验
    var regExp = /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁秦蜀滇陇黔琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/
    //发动机号校验
    var r = /^[0-9A-Za-z\-\－\u4e00-\u9fa5]{1,20}$/;
    let infoList = {
      licensePlate: this.state.carPlateCitys + this.state.carPlateNums,
      imId: window.localStorage.getItem("IMId"),
      id: this.state.carId,
      vinNo: this.state.vinno,
      engineNo: this.state.engineSerialNumber
    }
    console.log(infoList)
    if (!regExp.test(infoList.licensePlate)) {
      message.error('请输入正确的车牌号')
    } else if (!this.onblurjs(infoList.vinNo)) {
      message.error('请输入正确的车架号')
    } else {
      BackEnd.modifySureCar(infoList).then(res => {
        if (res.data.responseObj == true) {

          message.success('车辆修改成功')

          this.setState({
            visibleEnquirys: false,
            carPlateCitys: '',//地区
            carPlateNums: '',//车牌
            vinno: '',//车架
            engineSerialNumber: '',
          })
          this.myCarList()

        }
      })
    }

  }
  //删除车辆
  deletCar(record) {
    console.log(record)
    // return false
    BackEnd.deleteCar(record.id).then(res => {
      console.log(res)
      if (res.data.message == "Success") {
        message.success('删除成功');
        this.myCarList()
      } else {
        message.error('删除失败');

      }
    })
  }
  //车辆列表
  myCarList() {
    this.setState({
      loadingsss: true
    })
    BackEnd.companySquare('/api/UserCenter/getVehicleModel', this.state.informationList).then(res => {
      if(res.data.code == 0) {
        this.setState({
          TotalCount: res.data.responseObj.totalNum,
  
          carList: res.data.responseObj.items,
          loadingsss: false
  
        })
      }else{
        this.setState({
          loadingsss: false
        })
      }
     

    })
  }
  //添加车辆
  openAddCar() {
    this.setState({
      visibleEnquiry: true,
    })

    BackEnd.getCarvinnoNum({
      userPhone: window.localStorage.getItem("userPhone")
    }).then(res => {
      console.log(res.data.message)
      if (res.data.message == "Success") {
        this.setState({
          FreeAdmission: res.data.responseObj.queriesNumber,
          price: res.data.responseObj.value
        }, () => {
          if (this.state.FreeAdmission >= 1) {
            this.setState({
              judge: '1'
            })
          } else {
            this.setState({
              judge: '2'
            })
          }
        })
      }

    })

  }
  //免费获取车
  FreeAccess = () => {
    this.setState({
      loadings: true
    }, () => {
      console.log(this.state.loadings)
    })


    console.log(this.state.loadings)
    let arr = {
      licensePlate: this.state.carPlateCity + this.state.carPlateNum,
      imId: window.localStorage.getItem("IMId")
    }
    var regExp = /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁秦蜀滇陇黔琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/
    if (!regExp.test(arr.licensePlate)) {
      message.error('请输入正确的车牌号')
      this.setState({
        loadings: false
      })
    } else {

      BackEnd.FreeAccessGetvin(arr).then(res => {
        // message.info(res.data.message.)

        if (res.data.code == "0") {
          console.log(res.data.responseObj.engineSerialNumber)
          message.info(res.data.message)
          this.setState({
            vinno: res.data.responseObj.vinNo,
            engineSerialNumber: res.data.responseObj.engineSerialNumber,
          })

          //查询免费次数判断
          BackEnd.getCarvinnoNum({
            userPhone: window.localStorage.getItem("userPhone")
          }).then(res => {

            console.log(res.data.message)
            // alert(res.data.message)
            if (res.data.message == "Success") {

              this.setState({
                FreeAdmission: res.data.responseObj.queriesNumber
              }, () => {

                if (this.state.FreeAdmission >= 1) {
                  this.setState({
                    judge: '1'
                  })
                } else {
                  this.setState({
                    judge: '2'
                  })
                }
              })
            }

          })

          this.setState({
            loadings: false
          })
        } else {

          Modal.info({
            title: '提示',
            content: (
              <div>
                <p>车架号查询失败，系统未扣减您的免费查询次数</p>

              </div>
            ),
            onOk: () => {
              this.setState({
                loadings: false
              })
            },
          });

        }
      }
      )
    }
  }
  showConfirm = () => {
    confirm({
      title: '提示',
      content: '查询成功后，系统将自动扣除1次免费查询次数，点击确定继续',
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        // console.log(this.FreeAccess())
        this.FreeAccess()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  //判断余额 花钱获取车架号
  PayMoney = () => {

    BackEnd.GetBalance().then(res => {

      // console.log(res.data.responseObj.balance)

      // return false
      // let balance = res.data.ResponseObj.balance
      if (res.data.responseObj.balance < 1) {

        confirm({
          title: '提示',
          content: '提示您当前余额不足，请充值',
          okText: "确定",
          cancelText: "取消",
          onOk: () => {
            console.log("1111")
            this.setState({
              payShow: true,
              loadings: false
            })
            BackEnd.orderDetails("/api/PayCenter/GetRechargePackage", {
            }).then(res => {
              console.log(res)
              if (res.data.message == "Success") {
                this.setState({
                  RechargePackagelist: res.data.ResponseObj,
                  loadingss: false
                })
              }

            })
          },
          onCancel() {
            console.log('Cancel');
          },
        });


        // this.GetRechargePackage()
      } else {
        confirm({
          title: '提示',
          content: '查询成功后，系统将从您的余额自动扣除' + this.state.price + '元，点击确定继续',
          okText: "确定",
          cancelText: "取消",
          onOk: () => {
            // console.log(this.FreeAccess())
            this.setState({
              loadings: true
            })
            let arr = {
              licensePlate: this.state.carPlateCity + this.state.carPlateNum,
              imId: window.localStorage.getItem("IMId")
            }
            var regExp = /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁秦蜀滇陇黔琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/
            if (!regExp.test(arr.licensePlate)) {
              message.error('请输入正确的车牌号')
            } else {
              BackEnd.haveMoeyGetvin(arr).then(res => {
                console.log(res)
                if (res.data.code == "0") {
                  message.info(res.data.message)

                  this.setState({
                    vinno: res.data.responseObj.vinNo,
                    engineSerialNumber: res.data.responseObj.engineSerialNumber,
                    loadings: false
                  })
                } else {
                  Modal.info({
                    title: '提示',
                    content: (
                      <div>

                        车架号查询失败，系统未扣减您余额
                      </div>
                    ),
                    onOk() { },
                  });
                }
              })

            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });


      }
    })
  }
  // 提交添加车辆
  addCar = () => {
    this.setState({
      loadings: true
    })
    let licensePlate = this.state.carPlateCity + this.state.carPlateNum
    //车牌号校验
    var regExp = /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁秦蜀滇陇黔琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/
    //发动机号校验
    var r = /^[0-9A-Za-z\-\－\u4e00-\u9fa5]{1,20}$/;
    let infoList = {
      licensePlate: licensePlate,
      imId: window.localStorage.getItem("IMId"),
      vinNo: this.state.vinno,
      engineNo: this.state.engineSerialNumber
    }
    console.log(infoList)
    if (!regExp.test(infoList.licensePlate)) {
      message.error('请输入正确的车牌号')
      this.setState({
        loadings: false
      })
    } else if (!this.onblurjs(infoList.vinNo)) {
      message.error('请输入正确的车架号')
      this.setState({
        loadings: false
      })
    }
    else {

      BackEnd.addCarList(infoList).then(res => {


        if (res.data.responseObj == true) {
          // this.setState({

          // })

          this.setState({
            carPlateCity: '京',//地区
            carPlateNum: '',//车牌
            vinno: '',//车架
            engineSerialNumber: '',
            loadings: false,
            visibleEnquiry: false,

          }, () => {
            console.log(this.state.carPlateNums)
          })
          message.success('车辆添加成功')
          this.myCarList()




        }
      })
    }
    // console.log(infoList)

  }
  //校验车架号
  onblurjs = (vin) => {

    if (vin.length > 0 && vin.length != 17) {
      return false;
    } else {
      var vinVal = vin.toUpperCase();
      //document.getElementById("vin_"+k).value = vinVal;
      var charToNum = { 'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8, 'J': 1, 'K': 2, 'L': 3, 'M': 4, 'N': 5, 'P': 7, 'R': 9, 'S': 2, 'T': 3, 'U': 4, 'V': 5, 'W': 6, 'X': 7, 'Y': 8, 'Z': 9 };
      var obj = 0;
      var arr = new Array();
      for (var i = 0; i < vinVal.length; i++) {
        var temp = vinVal.charAt(i);

        if (charToNum[temp]) {
          arr[i] = charToNum[temp];
        } else {
          arr[i] = Number(temp);
        }
        if (i == 8) {
          arr[i] = vinVal.charAt(i);
        }

      };
      var a1 = 8;
      for (var i = 0; i < 7; i++) {
        obj += Number(arr[i]) * a1;
        a1--;
      };

      obj += Number(arr[7]) * 10;

      var a2 = 9;
      for (var i = 9; i < 17; i++) {
        obj += Number(arr[i]) * a2;
        a2--;
      };

      var result = Number(obj) % 11;
      if (parseInt(result) === 10) {
        result = 'X';
      }
      if (result == arr[8]) {
        //成功
        return true;
      } else {
        //失败
        return false;
      }
    }
  };


  //获取车架号


  //获取车牌信息
  getlicensePlate() {
    BackEnd.getCityList().then(res => {
      this.setState({
        licensePlateList: res.data.responseObj,
        licensePlateLists: res.data.responseObj,
      })
      console.log(res.responseObj)
    })
  }
  //下拉车牌地区
  selectStreetIdChange = (obj) => {
    const children = [];
    for (let i = 0; i < obj.length; i++) {
      children.push(<Option key={obj[i]} value={obj[i]}  >{obj[i]}</Option>);
    }
    return children
    // this.setState({
    //   carPlateCity: children
    // },()=>{
    //   console.log(this.state.carPlateCity)
    // })
  }
  // 充值套餐查询
  GetRechargePackage() {
    BackEnd.orderDetails("/api/PayCenter/GetRechargePackage", {
    }).then(res => {
      this.setState({
        loadingss: false,
        RechargePackagelist: res.data.ResponseObj
      }, () => {
      })

    })
  }



  giveCarengine(e) {
    this.setState({
      vinno: e.t
    })
  }

  handleChange(value) {
    console.log(`selected ${value}`);
  }
  handleCancel = () => {
    this.setState({
      visibleEnquiry: false,
      carPlateCitys: '',//地区
      carPlateNums: '',//车牌
      vinno: '',//车架
      engineSerialNumber: '',

    });
  }
  handleCancels = () => {
    this.setState({
      visibleEnquirys: false,
      carPlateCitys: '',//地区
      carPlateNums: '',//车牌
      vinno: '',//车架
      engineSerialNumber: '',
    });
  }
  handleCasncelss = () => {
    this.setState({
      payShow: false,
    });
  }

  // 切换分页
  conversion = (page) => {
    let changePage = Object.assign({}, this.state.informationList, { currentPage: page })
    console.log(changePage)
    // window.scrollTo(0, 0);
    this.setState({
      informationList: changePage
    }, () => { this.myCarList() })
  }
  firstPage = () => {
    let changePage = Object.assign({}, this.state.informationList, { currentPage: 1 })
    // window.scrollTo(0, 0);
    this.setState({
      informationList: changePage
    }, () => { this.myCarList() })
  }
  endPage = () => {
    let changePage = Object.assign({}, this.state.informationList, { currentPage: Math.ceil(this.state.TotalCount / 10) || 1 })
    // window.scrollTo(0, 0);
    this.setState({
      informationList: changePage
    }, () => { this.myCarList() })
  }

  //点击套餐  566567
  setmoney = (cashbalanceText, PresentedbalanceText, productId) => {

    this.setState({
      cashbalanceText: cashbalanceText,
      PresentedbalanceText: PresentedbalanceText,
      productId: productId
    });
  }
  //赠送金额判断
  setGiveprice = (cashbalanceText) => {
    this.setState({
      cashbalanceText: cashbalanceText
    })
    var PresentedbalanceText = 0
    if (cashbalanceText < 300) {
      PresentedbalanceText = 0
    } else if (300 <= cashbalanceText && cashbalanceText < 500) {
      PresentedbalanceText = 20
    } else if (500 <= cashbalanceText && cashbalanceText < 1000) {
      PresentedbalanceText = 75
    } else if (1000 <= cashbalanceText && cashbalanceText < 3000) {
      PresentedbalanceText = 200
    } else if (3000 <= cashbalanceText && cashbalanceText < 5000) {
      PresentedbalanceText = 750
    } else if (5000 <= cashbalanceText && cashbalanceText < 10000) {
      PresentedbalanceText = 1750
    } else if (cashbalanceText > 10000) {
      PresentedbalanceText = 5000
    }
    this.setState({
      PresentedbalanceText: PresentedbalanceText,
    })
  }
  //立即充值
  goPay = () => {
    this.setState({
      loadingss: true
    })
    let params
    if (this.state.productId == "") {
      params = {
        productId: "",
        tatal_fee: this.state.cashbalanceText,
        givenPrice: this.state.PresentedbalanceText,
      }
    } else {
      params = {
        productId: this.state.productId + "",
        // tatal_fee: this.state.cashbalanceText,
        // givenPrice: this.state.PresentedbalanceText,
      }
    }
    BackEnd.orderDetails("/api/PayCenter/GetPayUrl", params).then(res => {
      this.setState({
        PayId: res.data.responseObj.PayId,
        CodeImg: res.data.responseObj.url,

        tooltip: true
      })
    })
  }
  tubeHandletooltip = () => {
    message.success("充值有轻微延迟，预计10s后到账，您可稍后刷新查看！");
    this.setState({
      tooltip: false,
      payShow: false
    })
    this.timerID = setInterval(
      () => {
        this.getOrderStatus()
      },
      3000
    );
  }
  // 获取订单状态
  getOrderStatus() {
    BackEnd.companySquare('/api/IndustryInformation/GetRepaidBalanceVoucherState', { Id: this.state.PayId }).then(res => {
      console.log(res)
      if (res.data.Code == '0' && res.data.ResponseObj == "1") {

        this.GetRepaidBalanceVoucher();
        this.getAccountBalance() // 获取充值记录
        clearInterval(this.timerID);



        // window.location.href="/"
      } else {
      }

    })
  }
  render() {
    return (
      <div className="MycarBox">
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="Mycar">
          <p className='title'>我的车辆</p>
          <Spin spinning={this.state.loadingsss}>
            <div className="mycartable">
              <button type="primary" className='addcar' onClick={this.openAddCar.bind(this)}>添加车辆</button>
              <Table bordered dataSource={this.state.carList} columns={this.state.columns} pagination={false} />

              <div className="list_info_pagination">
                {/* <span className="first_button" onClick={this.firstPage}>首页</span> */}
                <Pagination
                  total={this.state.TotalCount || 10}
                  //   current={this.state.searchFirm.currentPage}
                  itemRender={itemRender}
                  pageSize={10}
                  onChange={this.conversion}
                />
                {/* <span className="last_button" onClick={this.endPage}>末页</span> */}
              </div>

            </div>
          </Spin>
        </div>
        {/* 添加车辆弹框 */}

        <Modal
          title="添加车辆"
          visible={this.state.visibleEnquiry}
          onCancel={this.handleCancel}
          footer={null}
          centered
          style={{ margin: "auto" }}
          width={330}
          maskStyle={{ background: "rgba(129,148,148,.5)" }}
          className="addcar"
        >
          <Spin spinning={this.state.loadings}>
            <div><span className="carId">车牌号码：</span>
              <Input.Group compact>
                <Select defaultValue="京" style={{ width: '30%' }} onChange={(e) => {
                  this.setState({
                    carPlateCity: e
                  })
                }}
                >
                  {this.selectStreetIdChange(this.state.licensePlateList)}

                </Select>
                <AutoComplete
                  style={{ width: '70%' }}
                  placeholder="车牌号"
                  options={[]}
                  onChange={(e) => {
                    console.log(e)
                    this.setState({
                      carPlateNum: e
                    }, () => {
                      console.log(this.state.carPlateNum)
                    })
                  }}
                />
              </Input.Group>
            </div>
            {/* <div className="carnum"><span>车牌号码：</span><input type="text" value={this.state.plateNumber} onChange={(e) => {
            this.setState({
              plateNumber: e.target.value
            })
          }} /></div> */}
            {
              this.state.judge == 1 ? <div className="freegetcarnum"> <button onClick={this.showConfirm.bind()}>免费获取车架号（剩余{this.state.FreeAdmission}次）</button></div>
                :
                <div className="freegetcarnum"> <button onClick={this.PayMoney.bind()}>获取车架号（{this.state.price}元/次）</button></div>
            }


            <div className="carnum"><span>车架号码：</span><input type="text" value={this.state.vinno} onChange={(e) => {
              this.setState({
                vinno: e.target.value
              })
            }} /></div>
            <div className="enginenum"><span>发动机号：</span><input type="text" value={this.state.engineSerialNumber} onChange={(e) => {
              this.setState({
                engineSerialNumber: e.target.value
              })
            }} /></div>

            <div className="colose"><button onClick={this.handleCancel}>取消</button><button onClick={this.addCar.bind()}>添加车辆</button></div>
          </Spin>
        </Modal>

        {/* 修改车辆信息 */}
        <Modal
          title="修改车辆信息"
          visible={this.state.visibleEnquirys}
          onCancel={this.handleCancels}
          footer={null}
          centered
          style={{ margin: "auto" }}
          width={330}
          maskStyle={{ background: "rgba(129,148,148,.5)" }}
          className="addcar"
        >
          <div><span className="carId">车牌号码：</span>
            <Input.Group compact>
              <Select value={this.state.carPlateCitys} style={{ width: '30%' }} onChange={(e) => {
                this.setState({
                  carPlateCitys: e
                })
              }}
              >
                {this.selectStreetIdChange(this.state.licensePlateLists)}

              </Select>
              <AutoComplete
                style={{ width: '70%' }}
                placeholder="车牌号"
                value={this.state.carPlateNums}
                options={[]}
                onChange={(e) => {
                  console.log(e)
                  this.setState({
                    carPlateNums: e
                  }, () => {
                    console.log(this.state.carPlateNums)
                  })
                }}
              />
            </Input.Group>
          </div>

          <div className="carnum"><span>车架号码：</span><input type="text" value={this.state.vinno} onChange={(e) => {
            this.setState({
              vinno: e.target.value
            })
          }} /></div>
          <div className="enginenum"><span>发动机号：</span><input type="text" value={this.state.engineSerialNumber} onChange={(e) => {
            this.setState({
              engineSerialNumber: e.target.value
            })
          }} /></div>
          <div className="colose"><button onClick={this.handleCancels}>取消</button><button onClick={this.modifySure.bind(this)}>确认修改</button></div>
        </Modal>

        <Modal
          title="微信支付"
          closeIcon="关闭"
          visible={this.state.tooltip}
          onCancel={this.tubeHandletooltip}
          footer={null}
          centered
          style={{ margin: 'auto' }}
          wrapClassName="placeTubeModel"
          width={400}
        >
          <div className="tubePlacetoolti" style={{ height: '350px', width: "200px" }}>
            <p style={{ textAlign: 'center', marginTop: '20px', color: "#FF6B37" }}>付款金额{this.state.priceNnm}元</p>
            <QRCode className="QRCode-qrCode" value={this.state.CodeImg} size={200} />
            <p style={{ textAlign: 'center' }}>微信支付完成后，请关闭</p>
          </div>
        </Modal>
        {/* 充值 */}
        <Modal
          title="余额充值"
          visible={this.state.payShow}
          onCancel={this.handleCasncelss}
          footer={null}
          centered
          style={{ margin: "0 auto" }}
          width={330}
          maskStyle={{ background: "rgba(129,148,148,.5)" }}
          className="addcar"
        >
          <Spin spinning={this.state.loadingss}>
            <div className="moneyList">
              {
                this.state.RechargePackagelist.map(item => {
                  // return (<li className="rechargeamount" onClick={this.setmoney.bind(this, item.Price, item.GivenPrice, item.ID)}>{item.Name}</li>)
                  return (<div className="rechargeamount" onClick={this.setmoney.bind(this, item.Price, item.GivenPrice, item.ID)}>{item.Name}</div>)
                })
              }
            </div>
            <div>充值金额：<InputNumber style={{ width: "50%" }} placeholder="0" value={this.state.cashbalanceText} onChange={this, this.setGiveprice.bind(this.state.cashbalanceText)} />&nbsp;&nbsp;&nbsp;&nbsp;元</div>
            <div>赠送金额：<InputNumber style={{ width: "50%" }} placeholder="不可自己输入" disabled value={this.state.PresentedbalanceText} />&nbsp;&nbsp;&nbsp;&nbsp;元</div>
            <div className="colose"><button onClick={this.goPay} style={{ width: "100%" }}>立即充值</button></div>
          </Spin>
        </Modal>



      </div >
    )
  }
}
export default withRouter(Mycar)


