import React, { Component } from 'react'

import moment from 'moment';
import { formatDate, getBeforeDate } from '../../../utils/filter'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { Link, withRouter } from 'react-router-dom'
import { Row, Col, DatePicker, Select, Button,ConfigProvider,  Table,Pagination, Input } from 'antd'
import BackEnd from '../../../services/api'

import Notopened from './inner/Notopened'

import 'moment/locale/zh-cn'
import './management.scss'

const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const monthFormat = 'YYYY/MM';


const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];


 

  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <span>上一页</span>;
    } if (type === 'next') {
      return <span>下一页</span>;
    }
    return originalElement;
  } 

export default class management extends Component {
    search(){
        const inpVal = this.input.value;
        console.log(inpVal);
    }
    constructor() {
        super()
        this.state={
            
            orderStatus:'',
            Category:0,
            total:0,
            currentPage: 1,
            pageSize: 10,
            totalCount:'',
            managementList:[],
            columns: [{
              title: '订单号',
              dataIndex: 'OrderCode',
              key: 'OrderCode',
              width:150,
              height: 50,
          }, {
              title: '下单时间',
              dataIndex: 'OrderTime',
              key: 'OrderTime',
              ellipsis: true,
              width:145,
              className: 'resultColumns',
              render: (text, record) => (
                  <span>{moment(text,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</span>
                )
          }, {
              title: '下单客户',
              dataIndex: 'AuthCodeName',
              width:166,
              key: 'AuthCodeName',
          }, {
              title: '企业类型',
              dataIndex: 'EnterpriseCategoryName',
              key: 'EnterpriseCategoryName',
              ellipsis: true,
              className: 'resultColumns',
              
          }, {
              title: '收货人手机号',
              dataIndex: 'Mobile',
              key: 'Mobile',
            
              ellipsis: true,
              className: 'resultColumns',
             
          }, {
              title: '订单金额',
              dataIndex: 'OrderAmount',
              key: 'OrderAmount',
              ellipsis: true,
              className: 'resultColumns',
              render: (text, record) => (
                <div>{Number(text).toFixed(2)}</div>
              )
          },{
            title: '状态',
            dataIndex: 'OrderStatusName',
            key: 'OrderStatusName',
            
            ellipsis: true,
            className: 'resultColumns',
            
        }, {
              title: '操作',
              dataIndex: 'Origin',
              key: 'Origin',
              id:'Id',
              ellipsis: true,
              className: 'resultColumns',
              render: (text, record) => (
                <span >
                    <Link
                    className="link"
                    to={
                            {
                                pathname: "/ToBeSalesslip",
                                search: `?id=${record.Id}`
                            }
                        }
                    >详情 </Link>
                    </span>
                  
                  )
          }],
          BeginTime:'',
          EndTime:'',
          Sertime:'',
          Code:0
        }
    }
    componentWillMount() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        this.getShoptInquire()
    }
    getShoptInquire() {
        BackEnd.orderInquire('/api/ShoppingCart/QuerySeller', {
            "authCode":window.localStorage.getItem('AuthCode'),
            "authCodeName": this.refs.ordersName.value,
            "mobile": this.refs.mobile.value,
            "enterpriseCategory": this.state.Category,
            "orderCode": this.refs.orderNumber.value,
            "orderStatus": this.state.orderStatus,
            "province": "",
            "city": "",
            "county": "",
            "orderTimeStart": this.state.BeginTime,
            "orderTimeEnd":  this.state.EndTime,
            "currentPage": this.state.currentPage,
            "pageSize":10
              
        }).then(res => {
            console.log('asdasdasdas',res)
            if(res.data.Code == 2) {
                // console.log(this.props)
                // return false;
                // this.props.history.replace('/notopened')
                this.setState({
                    Code:res.data.Code
                })
            }else{
                this.setState({
                    managementList: res.data.ResponseObj.Items,
                    totalCount:res.data.ResponseObj.CustomTotalNum,
                  })
            }

        })
    }
 // 分页改变
 onChange = page => {
  console.log(page);
  this.setState({
    currentPage: page,
  },()=>{
    this.getShoptInquire()
  });
};
//获得时间
onStartChange=(date, dateString) => {
  this.setState({
      BeginTime:formatDate(dateString, 'yyyymmdd') + '000000'
    })
    console.log(formatDate(dateString, 'yyyymmdd' + '000000'));
}
onEndChange =(date, dateString) => {
  console.log(formatDate(dateString, 'yyyymmdd') + '000000');
  this.setState({
    EndTime:formatDate(dateString, 'yyyymmdd') + '235959'
  })
}
// SerializationTime=(text)=>{
//     console.log(text);
//     this.setState({
//         //Sertime:time.substr(0, 1)+time.substr(0, 1)
//     },()=>{
//         console.log("text".slice(0, 4)+"-"+"text".slice(4, 6)+"-"+"text".slice(6, 8)+" "+"text".slice(8, 10)+":"+"text".slice(10, 12)+":"+"text".slice(12, 14));
//       })
//     //   test = "20200319161055".slice(0, 4)+"-"+"20200319161055".slice(4, 6)+"-"+"20200319161055".slice(6, 8)+" "+"20200319161055".slice(8, 10)+":"+"20200319161055".slice(10, 12)+":"+"20200319161055".slice(12, 14)

// }

    render() {
       
        return (
            <div>
                {
                    this.state.Code == 2 ? <Notopened></Notopened> :
                    <div className="managementpage"> 
                        <div className="managementtitle">
                            <Row className="row-p">
                                <Col className="col" span={8}>
                                    <span className="col-span">订单号:</span><input className="col-input" ref='orderNumber'  />
                                </Col>
                                <Col className="col" span={8}>
                                    <span className="col-span">下单客户:</span><input className="col-input col-input1" ref='ordersName' />
                                </Col>
                                <Col className="col" span={8}>
                                    <span className="col-span">收货人手机号:</span><input className="col-input" ref='mobile' />
                                </Col>
                            </Row>
                            <Row className="row-p">
                                <Col className="col-1" span={6}>
                                    <span className="col-span">企业类型:&nbsp;&nbsp;</span>
                                        <Select
                                        className="col-input"
                                            style={{ width: 150 }}
                                            placeholder="全部"
                                            onChange={e=>{
                                            this.setState({
                                                Category: e
                                            })
                                        }}
                                        >
                                        <Option value="0">全部</Option>
                                            <Option value="1">汽配商</Option>
                                            <Option value="2">汽修厂</Option>
                                            <Option value="3">个人</Option>
                                            <Option value="4">厂家</Option>
                                            
                                        </Select>
                                
                                </Col>
                                <Col className="col-1" span={6}>
                                    <span className="col-span">订单状态:&nbsp;&nbsp;</span>
                                    <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="全部"
                                            className="col-input"
                                            onChange={e=>{
                                            this.setState({
                                                orderStatus:e
                                            })
                                        }}
                                        >
                                            <Option value="">全部</Option>
                                            <Option value="0">待确认</Option>
                                            <Option value="1">待支付</Option>
                                            <Option value="2">待发货</Option>
                                            <Option value="3">已发货</Option>
                                            <Option value="4">已取消</Option>
                                            <Option value="5">已收货</Option>
                                        </Select>
                            
                                </Col>
                                <Col className="col-1 col-2" span={11}>
                                <span className="col-span">下单时间:&nbsp;&nbsp;</span>
                                            <ConfigProvider locale={zh_CN}>
                                                <DatePicker
                                                className="big"
                                                // defaultValue={moment(new Date(), dateFormat)}
                                                onChange={this.onStartChange}
                                            
                                                /></ConfigProvider>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b className='separator'>至</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <ConfigProvider locale={zh_CN}>
                                                <DatePicker
                                                className="big"
                                                // defaultValue={moment(new Date(), dateFormat)}
                                                onChange={this.onEndChange}
                                                />
                                            </ConfigProvider>
                                </Col>
                            </Row>
                            <div className="searchButton">
                                <Button 
                                type="primary"
                                onClick={()=>{
                                    this.setState({
                                        currentPage:1
                                    })
                                    this.getShoptInquire()
                                    // this.SerializationTime();
                                }
                                }>查询</Button>
                                {/* <Button>重置</Button> */}
                            </div>
                            
                        </div>
                        <div> <p  className="management-p">数据列表</p></div>
                        <div className="managementcontent">
                            <Table
                                width="100%"
                                rowKey={(record, index) => index}
                                size="small"
                                columns={this.state.columns}
                                dataSource={this.state.managementList}
                                bordered={true}
                                pagination={false}
                                className="buyerOrderListTable1"
                            />
                            
                        </div>
                        <div className="totleP">
                        <Pagination 
                                defaultCurrent={1} 
                                current={this.state.currentPage}
                                onChange={this.onChange}
                                pageSize={10}
                                defaultCurrent={1}
                                total={this.state.totalCount} 
                                className="totleP"
                                />
                        </div>
                    </div>
               }
        </div>
        )
    }
}
