import React, { PureComponent } from 'react'
import {
    Form,Input,Select ,Button,Table,message,
    Popconfirm ,ColumnGroup
} from 'antd'

import UploadImg from './UploadImg'
import BackEnd from '../../../../services/api'

const { Option } = Select;

class ContentTheReport extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            name:'',//名字
            id_number:"",//身份证
            sex:"",//性别
            education:'0',//学历
            tel:'0',//联系方式
            columns:[
                {
                    title: "ID",
                    dataIndex: 'id',
                    key: 'id',
                    width: 80,
                },
                {
                    title: "照片",
                    dataIndex: 'userName',
                    key: '2',
                    width: 80,
                    render: (text, record) => (
						<div className="img_photo">
							<img className='img' src={record.id_photo} alt="" />
						</div>
					)
                },
                {
                    title: "姓名",
                    dataIndex: 'name',
                    key: 'name',
                    width: 80,
                },
                {
                    title: "性别",
                    dataIndex: 'sex',
                    key: 'sex',
                    width: 80,
                },
                {
                    title: "联系方式",
                    dataIndex: 'tel',
                    key: 'tel',
                    width: 80,
                },
                {
                    title: "身份证号",
                    dataIndex: 'id_number',
                    key: 'id_number',
                    width: 80,
                },
                {
                    title: "学历",
                    dataIndex: 'EducationText',
                    key: 'EducationText',
                    width: 80,
                },
                {
                    title: "学员编码",
                    dataIndex: 'id_code',
                    key: 'id_code',
                    width: 80,
                },
                {
					title: "操作",
					width: 120,
                    key:"create_time",
					render: (text, record) => (
						<div className="operation">
							<Button type="link" onClick={ ()=>{this.editHandle(record)} } >编辑</Button>
                            <Popconfirm title="确定要删除该学员吗？" okText="Yes" cancelText="No" onCancel={this.CancelHandle} onConfirm={ ()=>{this.deletHandle(record)}}>
                                <Button type="link">删除</Button>
                            </Popconfirm>
						</div>
					)
				},
            ],
            carList:[],
            SId:null,
            ids:null,
            editId:null,
            userId:window.localStorage.getItem('userId') || '',
			CompanyName:window.localStorage.getItem('CompanyName') ||'',
            certify:"",
            table:"",
        }
        this.uploadRef1 = React.createRef()
        this.uploadRef2 = React.createRef()
        this.uploadRef3 = React.createRef()
        this.uploadRef4 = React.createRef()
    }
    componentDidMount() {
        
    }
    //编辑
    editHandle = (e)=>{
        this.setState({
            name:e.name,
            tel:e.tel,
            sex:e.sex,
            id_number:e.id_number,//身份证
            education:`${e.education}` ,//学历
            ids:1,
            editId:e.id,
        },()=>{
            this.uploadRef1.current.setImg(e.id_photo)
            this.uploadRef2.current.setImg(e.application)
            this.uploadRef3.current.setImg(e.id_number_photo)
            this.uploadRef4.current.setImg(e.education_photo)
        })
        
    }
    //删除
    deletHandle = (e)=>{
        BackEnd.StudentDelete({id:e.id}).then(res=>{
            message.success(res.data.Message);
            this.setStudent()
        })
    }
    CancelHandle = ()=>{
        console.log(1)
    }
    kong=()=>{
        this.setState({
            name:'',
            id_number:"",
            tel:"",
            education:"0",
            sex:"",
            ids:0,
            userId:window.localStorage.getItem('userId') || '',
			CompanyName:window.localStorage.getItem('CompanyName') ||''
        },()=>{
            this.uploadRef1.current.kong()
            this.uploadRef2.current.kong()
            this.uploadRef3.current.kong()
            this.uploadRef4.current.kong()
            BackEnd.getUrl({id:this.state.SId}).then(res=>{
                console.log(res)
                if(res.data.code == 0){
                    this.setState({
                        certify:res.data.data.certify || '',
                        table:res.data.data.table || '',
                    })
                }
            })
        })
    }
    //下拉宽
    SelectHandle = (e)=>{
        this.setState({
            education:e
        })
    }
    //添加学员
    addStudent= ()=>{
        let obj = {
            train_id:this.state.SId,
            name:this.state.name,
            tel:this.state.tel,
            sex:this.state.sex,
            id_number:this.state.id_number,
            education:this.state.education,
            id_photo:this.uploadRef1.current.state.imageUrl,
            application:this.uploadRef2.current.state.imageUrl,
            id_number_photo:this.uploadRef3.current.state.imageUrl,
            education_photo:this.uploadRef4.current.state.imageUrl,
            userId:this.state.userId,
			CompanyName:this.state.CompanyName,
        }
        BackEnd.addStudent(obj).then(res=>{
            if(res.data.code == 0 ){
                message.success(res.data.Message);
                this.setStudent()
                this.kong()
            }else{
                message.error(res.data.Message);

            }
        })
    }
    //学员列表
    setStudent=()=>{
        let obj = {
            userId:this.state.userId,
			CompanyName:this.state.CompanyName,
            train_id:this.state.SId
        }
        BackEnd.StudentList(obj).then(res=>{
            console.log(res)
            this.setState({
                carList:res.data.data
            })
        })
    }
    //编辑学院
    editStudent=()=>{
        let obj = {
            name:this.state.name,
            id_number:this.state.id_number,
            education:this.state.education,
            tel:this.state.tel,
            sex:this.state.sex,
            id_photo:this.uploadRef1.current.state.imageUrl,
            application:this.uploadRef2.current.state.imageUrl,
            id_number_photo:this.uploadRef3.current.state.imageUrl,
            education_photo:this.uploadRef4.current.state.imageUrl,
            id:this.state.editId,
            
        }
        BackEnd.StudentSave(obj).then(res=>{
            if(res.data.code==0){
                message.success(res.data.Message);
                this.setStudent()
                this.kong()
            }else{
                message.error(res.data.Message);
            }
        })
    }
   
    
    render() {
        return (
            <div>
                <Form>
                    <Form.Item label="学员姓名" style={{marginBottom: '0'}}>
                        <Input value={this.state.name} onChange={e=>{
                            this.setState({name:e.target.value})
                        }}></Input>
                    </Form.Item>
                    <Form.Item label="性别" style={{marginBottom: '0'}}>
                        <Input value={this.state.sex} onChange={e=>{
                            this.setState({sex:e.target.value})
                        }}></Input>
                    </Form.Item>
                    <Form.Item label="联系方式" style={{marginBottom: '0'}}>
                        <Input  value={this.state.tel} onChange={e=>{
                            this.setState({tel:e.target.value})
                        }}></Input>
                    </Form.Item>
                    <Form.Item label="身份证号" style={{marginBottom: '0'}}>
                        <Input  value={this.state.id_number} onChange={e=>{
                            this.setState({id_number:e.target.value})
                        }}></Input>
                    </Form.Item>
                   
                    <Form.Item label="学历" style={{marginBottom: '0'}}>
                        <Select defaultValue="0" value={this.state.education} onChange={this.SelectHandle}>
                            <Option value="0">--请选择最高学历--</Option>
                            <Option value="1">初中</Option>
                            <Option value="2">高中</Option>
                            <Option value="3">职高</Option>
                            <Option value="4">中专</Option>
                            <Option value="5">大专</Option>
                            <Option value="6">本科</Option>
                            <Option value="7">研究生</Option>
                            <Option value="8">博士</Option>
                            <Option value="9">博士后</Option>
                        </Select>
                    </Form.Item>
                </Form>
                <div className='Img_up' >
                   <UploadImg ref={this.uploadRef1} name={`2寸蓝底照片点此上传`}></UploadImg>
                   <UploadImg ref={this.uploadRef2} name={'上传申请表盖章扫描件'}></UploadImg>
                   <UploadImg ref={this.uploadRef3} name={'上传盖章身份证复印件'}></UploadImg>
                   <UploadImg ref={this.uploadRef4} name={'上传学历证明或企业人才培养证明'}></UploadImg>
                </div>
                <br></br>

                <div className='rightAdd'>
                    {
                        this.state.table ? <a className='a_url' href={this.state.table}>下载申请表模板</a> : ''
                    }
                    {
                       this.state.certify ? <a className='a_url' href={this.state.certify}>下载企业人才培养证明模板</a>: ''
                    }
                   
                    {
                    this.state.ids == 1 
                    ? 
                    <Button type="primary" onClick={this.editStudent}>保存资料</Button>
                    :
                    <Button type="primary" onClick={this.addStudent}>提交信息</Button>
                    }
                    <Button className='ml-10' type="primary" onClick={this.props.onCls}>关闭窗口</Button>
                </div>
               
               
                <br></br>
                <br></br>
                {
                    this.state.carList.length != 0 
                    ?
                    <Table scroll={{ x: "100%" }} rowKey="id" bordered dataSource={this.state.carList} columns={this.state.columns} pagination={false} 
                   
                    ></Table>
                    :
                    ''
                }
               
            </div>
        )
    }
}

export default ContentTheReport