import React, { Component } from 'react'
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import {
  Table,
  Pagination,
  message,
  Spin,
  Icon
} from 'antd';
import './ProductList.scss'
import BackEnd from '../../../../services/api'
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}
class ProductList extends Component {
  constructor() {
    super()
    this.state = {
      CompanyName: '',
      wholeCommodityList: [], // 全部商品列表
      selectedRowKeys: [], // 选中项
      storeTitle: '', // 窗口标题
      storeId: '',
      totalCount: '', // 数据总条数
      IsRegState: '', // 是否开通扫码付 
      wholeCommoditySearch: {
        authCode: '',
        userId: Number(window.localStorage.getItem('userId')),
        promotionStatus: 0,  // 0是全部商品 1是促销商品
        isZero: true,// 是否显示有货 true显示有货 false显示无货
        content: '',
        currentPage: 1,
        pageSize: 10,
        Company: '',
      },
      noSweepCodecolumns: [{
        title: '配件编码',
        dataIndex: 'ComponentCode',
        key: 'ComponentCode',
        width: 180,
        render: (text, record) => (
          <a className="color" onClick={this.clickRow.bind(this, record)}>{text}</a>
        )
      }, {
        title: '配件名称',
        dataIndex: 'ComponentName',
        key: 'ComponentName',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '品牌',
        dataIndex: 'Brand',
        key: 'Brand',
        width: 80,
        ellipsis: true,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '产地',
        dataIndex: 'Origin',
        key: 'Origin',
        width: 80,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '车型',
        dataIndex: 'VehicleMode',
        key: 'VehicleMode',
        width: 80,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '地区',
        dataIndex: 'Region',
        key: 'Region',
        width: 110,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '库存',
        dataIndex: 'ShowQuantity',
        key: 'ShowQuantity',
        width: 60,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: 60,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '单价',
        dataIndex: 'EnterpriseCategoryPrice',
        key: 'EnterpriseCategoryPrice',
        width: 70,
        ellipsis: true,
        render: (text, record) => (
          window.localStorage.getItem('Tokens') == null ? <span className="addshopCart" onClick={() => { this.goLogin() }}>登陆可查看</span> : (this.state.IsRegState == false ? text : '')

        )
      }],
      haveSweepCodecolumns: [{
        title: '配件编码',
        dataIndex: 'ComponentCode',
        key: 'ComponentCode',
        width: 180,
        render: (text, record) => (
          <a className="color" onClick={this.clickRow.bind(this, record)}>{text}</a>
        )
      }, {
        title: '配件名称',
        dataIndex: 'ComponentName',
        key: 'ComponentName',
        width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '品牌',
        dataIndex: 'Brand',
        key: 'Brand',
        width: 80,
        ellipsis: true,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '产地',
        dataIndex: 'Origin',
        key: 'Origin',
        width: 80,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span >{text}</span>
        )
      }, {
        title: '车型',
        dataIndex: 'VehicleMode',
        key: 'VehicleMode',
        width: 80,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '地区',
        dataIndex: 'Region',
        key: 'Region',
        width: 110,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '库存',
        dataIndex: 'ShowQuantity',
        key: 'ShowQuantity',
        width: 60,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: 60,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '单价',
        dataIndex: 'EnterpriseCategoryPrice',
        key: 'EnterpriseCategoryPrice',
        width: 70,
        ellipsis: true,
        render: (text, record) => (
          window.localStorage.getItem('Tokens') == null ? '' : <span>{text}</span>
        )
      }, {
        title: '订购数量',
        dataIndex: 'Quantity',
        key: 'Quantity',
        width: 150,
        ellipsis: true,
        render: (text, record, index) => (
          this.state.IsRegState == false ? '' : (<div className="quantityContainer">
            <span onClick={this.removeEnquiryQuantity.bind(this, record, index)}>-</span>
            <input type="text" value={text} className="purchaseQuantity" maxLength="5" ref="quantity" readOnly />
            <span onClick={this.addEnquiryQuantity.bind(this, record, index)}>+</span>
          </div>)
        )
      }, {
        title: '操作',
        key: 'operation',
        width: 100,
        ellipsis: true,
        render: (text, record) => (
          this.state.IsRegState == false ? '' : (window.localStorage.getItem('Tokens') == null ? <span className="addshopCart" onClick={() => { this.goLogin() }}>登陆查看单价</span> : <span className="addshopCart" onClick={this.singleAddShopCart.bind(this, record)}>加入购物车</span>)
        )
      }]
    }
  }
  componentWillMount() { }

  componentDidMount() {
    this.totalCommodity() // 全部
  }

  // 全部商品
  totalCommodity() {
    // const { search } = this.props.location
    // const paramsString = search.substring(1)
    // const searchParams = new URLSearchParams(paramsString)
    // const id = searchParams.get('id')
    let newAuthcode = Object.assign({}, this.state.wholeCommoditySearch, {
      authCode: sessionStorage.getItem('StoreId')
    })
    console.log(newAuthcode)
    this.setState({
      wholeCommoditySearch: newAuthcode
    }, () => {
      BackEnd
        .companySquare('/api/ShoppingCart/QueryAutopartsGWList', this.state.wholeCommoditySearch).then(res => {
          console.log('参数', res)
          if (res.data.Message === 'Success' && res.data.ResponseObj.Items.length) {
            this.setState({
              storeTitle: res.data.ResponseObj.Items[0],
              storeId: res.data.ResponseObj.Items[0].AuthCode,
              wholeCommodityList: res.data.ResponseObj.Items,
              totalCount: res.data.ResponseObj.TotalNum,
              IsRegState: res.data.ResponseObj.Items[0].RegState,
              // Company:this.state.wholeCommodityList[1].CompanyName
            }, () => {
              window.sessionStorage.setItem('IsRegState', this.state.IsRegState)
              console.log('扫码付', this.state.IsRegState, this.state.storeTitle)
            })
          } else {
            this.setState({
              wholeCommodityList: []
            })
          }
        })
    })
  }
  // 搜索商品
  searchCommodity = () => {
    let newCommodity = Object.assign({}, this.state.wholeCommoditySearch, { content: this.refs.totalCommodity.value }, { currentPage: 1 })
    this.setState({
      wholeCommoditySearch: newCommodity
    }, () => {
      this.totalCommodity()
    })
  }
  // 减少询价配件数量
  removeEnquiryQuantity(record, index, e) {
    e.stopPropagation()
    let removeEnquiryQuantityList = [...this.state.wholeCommodityList]
    if (removeEnquiryQuantityList[index].Quantity <= 1) {
      return
    }
    removeEnquiryQuantityList[index].Quantity--;
    this.setState({
      wholeCommodityList: removeEnquiryQuantityList
    }, () => {
      console.log('减少数量', this.state.wholeCommodityList);
    })
  }
  // 增加询价配件数量
  addEnquiryQuantity(record, index, e) {
    e.stopPropagation()
    let addEnquiryQuantityList = [...this.state.wholeCommodityList]

    let addEnquiryQuantity = addEnquiryQuantityList[index]
    if (addEnquiryQuantity.Quantity + 1 > addEnquiryQuantity.ShowQuantity) {
      return message.success('库存不足', 1)
    }
    addEnquiryQuantityList[index].Quantity++;

    console.log('dadasda', addEnquiryQuantityList[index])

    this.setState({
      wholeCommodityList: addEnquiryQuantityList
    }, () => {

      console.log('添加', this.state.wholeCommodityList);
    })
  }

  goLogin = () => {
    this.props.history.push('/login')
  }
  // 单个加入购物车
  singleAddShopCart(record, e) {
    console.log('事件', e)
    e.stopPropagation()
    console.log('单个加入购物车', record);
    let singleShopcart = {
      userId: Number(window.localStorage.getItem('userId')),
      shoppingCartItem: [
        {
          authCode: record.AuthCode,
          id: record.Id,
          componentCode: record.ComponentCode,
          componentName: record.ComponentName,
          componentId: record.ComponentId,
          brand: record.Brand,
          vehicleMode: record.VehicleMode,
          origin: record.Origin,
          companyName: record.CompanyName,
          region: record.Region,
          unitPrice: record.EnterpriseCategoryPrice,
          unit: record.Unit,
          quantity: record.Quantity,
          showQuantity: record.ShowQuantity
        }
      ]
    }
    BackEnd
      .companySquare('/api/ShoppingCart/InSetShoppingCartCache', singleShopcart).then(res => {
        if (res.data.Code == 1) {
          message.success('不可超出库存数量', 1)
        }
        console.log("加入购物车", res.data)
        if (res.data.Message == "Success") {
          message.success('已加入购物车', 1)
        }
      })
  }

  // 选中项
  // onSelectChange = (selectedRowKeys, selectedRows) => {
  //   console.log('选中项', selectedRowKeys,selectedRows);
  //   // let inquiryList = []
  //   // for (let i = 0; i < selectedRows.length; i++) {
  //   //   selectedRows[i].Quantity = 1
  //   //   inquiryList.push(selectedRows[i])
  //   // }
  //   // this.setState({
  //   //   setInquiryCondition: inquiryList,
  //   //   selectedRowKeys
  //   // }, () => {
  //   //   console.log('参数', this.state.selectedRowKeys)
  //   // })
  // }
  // 批量加入购物车
  // batchAddCart = () => {
  //   console.log('批量加入购物车')
  // }
  // 分页改变
  changePage = (page) => {
    let changePage = Object.assign({}, this.state.wholeCommoditySearch,
      { currentPage: page }
    )
    this.setState({
      wholeCommoditySearch: changePage
    }, () => {
      this.totalCommodity()
    })
  }
  firstPage = () => {
    let changePage = Object.assign({}, this.state.wholeCommoditySearch, { currentPage: 1 })
    this.setState({
      wholeCommoditySearch: changePage
    }, () => { this.totalCommodity() })
  }
  endPage = () => {
    let changePage = Object.assign({}, this.state.wholeCommoditySearch, { currentPage: Math.ceil(this.state.totalCount / this.state.wholeCommoditySearch.pageSize) || this.state.wholeCommoditySearch.pageSize })
    this.setState({
      wholeCommoditySearch: changePage
    }, () => { this.totalCommodity() })
  }
  // 跳转商品详情
  clickRow = (record) => {
    this.props.history.push({ pathname: '/productDetail', search: `?id=${record.ComponentId}` })
  }




  render() {
    // const { selectedRowKeys } = this.state;
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange.bind(this)
    // };
    return (
      <div className="productListPage">
        <Header text={this.state.storeTitle} />
        <div className="productContainer">

          <div className="searchContainer">

            <div className="search">
              <input type="text" placeholder="请输入配件编码、配件名称" ref="totalCommodity" />
              <span onClick={this.searchCommodity}>搜本店</span>
              <p>为您查询到{this.state.totalCount || 0}件商品</p>
            </div>

            <div>
              {/* <img src="" alt=""/> */}
            </div>
          </div>
          <div className='productList'>
            {!this.state.IsRegState ? <Spin tip="数据正在加载中..." spinning={false}>
              <Table
                width='100%'
                rowKey={(record, index) => index}
                columns={this.state.noSweepCodecolumns}
                dataSource={this.state.wholeCommodityList}
                bordered={true}
                pagination={false}
                className='producTtable'
              />
              <div className='product_pagination'>
                <span className={`first_button`} onClick={this.firstPage}>首页</span>
                <Pagination
                  total={this.state.totalCount || 10}
                  current={this.state.wholeCommoditySearch.currentPage}
                  itemRender={itemRender}
                  pageSize={this.state.wholeCommoditySearch.pageSize}
                  onChange={this.changePage}
                />
                <span className={`last_button`} onClick={this.endPage}>末页</span>
              </div>
            </Spin> : <Spin tip="数据正在加载中..." spinning={false}>
                <Table
                  width='100%'
                  rowKey={(record, index) => index}
                  columns={this.state.haveSweepCodecolumns}
                  dataSource={this.state.wholeCommodityList}
                  bordered={true}
                  pagination={false}
                  className='producTtable'
                  onRow={(record) => {//表格行点击事件
                    return {
                      // onClick: this.clickRow.bind(this, record)
                    };
                  }}
                />
                <div className='product_pagination'>
                  {/* <span className="batchAddCart" onClick={this.batchAddCart}>批量加入购物车</span> */}
                  <span className={`first_button`} onClick={this.firstPage}>首页</span>
                  <Pagination
                    total={this.state.totalCount || 10}
                    current={this.state.wholeCommoditySearch.currentPage}
                    itemRender={itemRender}
                    pageSize={this.state.wholeCommoditySearch.pageSize}
                    onChange={this.changePage}
                  />
                  <span className={`last_button`} onClick={this.endPage}>末页</span>
                </div>
              </Spin>}
          </div>
        </div>
        <div className="botton">

          <Footer />
        </div>
      </div>
    )
  }
}
export default ProductList