import React, { PureComponent } from 'react'
import {
	Form,
	Input,
	Cascader,
	Row,
	Col,
	message,
	Button,
} from 'antd';
import {
	encryptByDES,
	encryptPassword,
	encryptEDSPwds
} from "../../utils/encrypt";
import BackEnd from '../../services/api';
import areaJson from './area.json'
class PersonageRegister extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isClick: false, // 验证码按钮禁用
			personageNameChange: '',// 个人注册昵称
			codeContent: '获取验证码', // 验证码按钮文案
			personagePhoneChange: '',// 个人注册手机号
			personageCaptchaChange: '', // 个人注册验证码
			personagePasswordChange: '' ,// 个人注册密码
			recommendPhoneChange: '' // 邀请人手机号
		}
	}
	// 返回登录
	backLogin = () => {
		window.history.back();
	}
	// 监听昵称
	personageNameChange = (e) => {
		this.setState({
			personageNameChange: e.target.value
		})
	}
	// 监听手机号
	personagePhoneChange = (e) => {
		this.setState({
			personagePhoneChange: e.target.value
		})
	}
	recommendPhoneChange = (e) => {
		this.setState({
			recommendPhoneChange: e.target.value
		})
	}
	// 监听密码
	personagePasswordChange = (e) => {
		this.setState({
			personagePasswordChange: e.target.value
		})
	}
	// 监听验证码
	phoneCaptchaChange = (e) => {
		this.setState({
			personageCaptchaChange: e.target.value
		})
	}
	// 获取验证码
	getCode = () => {
		// 重新发送动画
		if (this.state.personagePhoneChange === "") {
			message.error('手机号不能为空')
			return;
		}
		let time = 60;
		this.state.isClick = !this.state.isClick;
		var timer = setInterval(() => {
			time--;
			this.setState({
				codeContent: `重新发送(${time})`
			})
			if (time <= 0) {
				clearInterval(timer);
				time = 5;
				this.setState({
					codeContent: `获取验证码`,
					isClick: !this.state.isClick
				})
			}
		}, 1000);
		let encrypt = {
			Code: "00021003",
			Mobile: this.state.personagePhoneChange,
			TimeSptams: String(Date.parse(new Date())),
			SendMsgType: "5",
			Tokens: ""
		};
		let msg = `${encrypt.Mobile}${encrypt.SendMsgType}${encrypt.TimeSptams}`;

		encrypt.Tokens = encryptByDES(msg, 'jinmusen7931BEIJING');

		BackEnd.getData(encrypt)
			.then(res => {
				console.log(res)
				if (res.data.Message.ErrorMessage) {
					message.error(res.data.Message.ErrorMessage.ErrMsg)
					return;
				}
				window.sessionStorage.setItem('personageTimeSptams', encrypt.TimeSptams)
				message.success('短信发送成功!')
			})
			.catch(err => {
				message.error('短信发送失败!')
			});
	};
	// 格式数据
	initData() {
		let initData = {
			Code: 21005,
			Mobile: this.state.personagePhoneChange,
			MsgCodes: this.state.personageCaptchaChange,
			TimeSptams: window.sessionStorage.getItem('personageTimeSptams'),
			SendMsgType: "5",
			
		}

		let key = `${initData.Mobile}${initData.MsgCodes}${initData.TimeSptams}`;

		initData = Object.assign(
			{},
			initData,
			{
				Tokens: encryptByDES(key, 'jinmusen7931BEIJING')
			}
		)
		return initData
	}
	handlePersonageSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				BackEnd.getData(this.initData()).then(res => {
					if (res.data.Message.ErrorMessage) {
						message.error('验证码不正确!');
						return;
					}
					let newInitData =
						values.personagePhone.substring(values.personagePhone.length - 4) + "00kt";
					let newLoginPwd = encryptPassword(values.personagePassword);
					BackEnd.getData({
						Code: "21103",
						Mobile: this.state.personagePhoneChange,
						nickName: this.state.personageNameChange,
						LoginPwd: encryptEDSPwds(newLoginPwd, newInitData),
						EnterpriseCategory: '3',
						Province: values.personageArea[0],
						City: values.personageArea[1],
						County: values.personageArea[2],
						OriMobile:this.state.recommendPhoneChange
					}).then(res => {
						if (res.data.Message.ErrorMessage) {
							message.error(res.data.Message.ErrorMessage.ErrMsg);
							return;
						}
						message.success('个人用户注册成功!');
						window.history.back()
					})
				})
			}
		});
	}
	componentWillUnmount() {
		clearInterval(this.timer);
	}
	render() {
		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 24,
					offset: 0,
				},
			},
		};
		return (
			<div className='registerContentPersonage' style={this.props.isActive ? { display: 'block' } : { display: 'none' }}>
				<Form {...formItemLayout} onSubmit={this.handlePersonageSubmit}>
					{/* 
						  name:wangji
						  date:2020.5.14
						  content:个人注册添加姓名/昵称
						*/}
					<Form.Item label="姓名/昵称">
						{getFieldDecorator('personageName', {
							rules: [
								{
									required: true,
									message: '请输入姓名/昵称',
								}, {
									pattern: "^[A-z0-9\\u4e00-\\u9fa5]*$",
									message: '禁止输入特殊字符'
								}
							],
						})(<Input maxLength={11} onChange={this.personageNameChange} placeholder="请输入姓名/昵称" autoComplete='nickname' />)}
					</Form.Item>
					<Form.Item label="手机号">
						{getFieldDecorator('personagePhone', {
							rules: [
								{
									required: true,
									message: '请输入手机号',
								}, {
									pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/,
									message: '手机号输入不正确'
								}
							],
						})(<Input maxLength={11} onChange={this.personagePhoneChange} placeholder="请输入手机号" autoComplete='new-password' />)}
					</Form.Item>
					<Form.Item label="所属地区">
						{getFieldDecorator('personageArea', {
							// initialValue: ['zhejiang', 'hangzhou', 'xihu'],
							rules: [
								{ type: 'array', required: true, message: '请输入地区' },
							],
						})(<Cascader options={areaJson} placeholder='请输入地区' onChange={this.companyArea} />)}
					</Form.Item>
					<Form.Item label="验证码">
						<Row gutter={8}>
							<Col span={12}>
								{getFieldDecorator('phoneCaptcha', {
									rules: [{ required: true, message: '请输入验证码' }],
								})(<Input maxLength={6} onChange={this.phoneCaptchaChange} placeholder='请输入验证码' />)}
							</Col>
							<Col span={12}>
								<Button onClick={this.getCode} disabled={this.state.isClick}>{this.state.codeContent}</Button>
							</Col>
						</Row>
					</Form.Item>
					<Form.Item label="登录密码">
						{getFieldDecorator('personagePassword', {
							rules: [
								{
									required: true,
									message: '请输入密码',
								},
							],
						})(<Input.Password maxLength={20} onChange={this.personagePasswordChange} placeholder="请输入密码" autoComplete='new-password' />)}
					</Form.Item>
					<Form.Item label="邀请人手机号">
						{getFieldDecorator('recommendPhone', {
							
						})(<Input maxLength={11} onChange={this.recommendPhoneChange} placeholder="" autoComplete='new-password' />)}
					</Form.Item>
					<Form.Item {...tailFormItemLayout} className='registerArea'>
						<Button type="primary" htmlType="submit" className='registerButton'>注册</Button>
					</Form.Item>
				</Form>
				<p className='ownNumber' onClick={this.backLogin}>已注册,去登陆</p>
			</div>
		)
	}
}
export default Form.create({})(PersonageRegister)

