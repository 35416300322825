import React, { Component } from 'react'
import Header from './component/Header/Header'
import Footer from './component/Footer/Footer'
import './PersonalStore.scss'
import { Pagination, Modal, Carousel, Empty, List } from 'antd'
import BackEnd from '../../../services/api'
import { setCookie, getCookie } from '../../../utils/env'
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}
class PersonalStore extends Component {
  constructor() {
    super()
    this.state = {
      Flag: '', //类型
      InStoreImgUrl: '', // 门头照片
      GoodsImgUrl: '', // 商品图片
      CheckStandImgUrl: '', // 收银台照片
      commitmentMore: false,
      Success: '', // 是否显示详情验证
      PeopleService: [], // 业务咨询信息
      StoreDetail: {}, // 门店详情
      visible: false,
      storeVisible: false, // 门店大图
      storeBigPic: [],
      showContent: '', // 弹框要展示的内容
      totalCount: '',
      page: '',
      promotionCommoditySearch: {
        authCode: '',
        promotionStatus: 1,  // 0是全部商品 1是促销商品
        isZero: true,// 是否显示有货
        userId: Number(window.localStorage.getItem('userId')),
        content: '',
        currentPage: 1,
        pageSize: 9
      },
      promotionCommoditySearchs: {
        authCode: '',
        promotionStatus: 2,  // 0是全部商品 1是促销商品
        isZero: true,// 是否显示有货
        userId: Number(window.localStorage.getItem('userId')),
        content: '',
        currentPage: 1,
        pageSize: 9
      },
      promotionCommodityList: [],// 促销商品
      promotionCommodityLists: [],// 热销商品
      promotionCommodityValue: '', // 促销商品搜索
      settings: {
        arrows: true,
        dots: true,
      }
    }
  }
  componentWillMount() { }
  componentDidMount() {
    console.log(this.getCook('LoginCacheKey'))

   

    if (this.getCook('Tokens') != undefined && this.getCook('Tokens') != 0) {
      window.localStorage.setItem('userInfo', this.getCook('userInfo'))
      window.localStorage.setItem('sessionId', this.getCook('sessionId'))
      window.localStorage.setItem('userPhone', this.getCook('userPhone'))
      // window.localStorage.setItem('EnterpriseCategory', this.getCook('EnterpriseCategory'))
      window.localStorage.setItem('userId', this.getCook('userId'))
      window.localStorage.setItem('AuthCode', this.getCook('AuthCode'))
      window.localStorage.setItem('Tokens', this.getCook('Tokens'))
      window.sessionStorage.setItem('Tokens', this.getCook('Tokens'))
      window.sessionStorage.setItem('LoginCacheKey', this.getCook('LoginCacheKey'))
      setCookie('LoginCacheKey', this.getCook('AuthCode'), 30)
    } else {
      window.localStorage.removeItem('EnterpriseCategory')
      window.localStorage.removeItem('userInfo')
      window.localStorage.removeItem('userPhone')
      window.localStorage.removeItem('sessionId')
      window.localStorage.removeItem('userId')
      window.localStorage.removeItem('AuthCode')
      window.localStorage.removeItem('Tokens')
      window.sessionStorage.removeItem('Tokens')
      window.sessionStorage.removeItem('LoginCacheKey')
    }
    //语音播报
   

    this.detail() // 门店信息
    this.promotionCommodity() // 促销商品
    this.promotionCommoditys() //热销商品
    this.playAudio() //语音播报
  }
  getCook(cookie_name) {
    var allcookies = document.cookie;
    //索引长度，开始索引的位置
    var cookie_pos = allcookies.indexOf(cookie_name);

    // 如果找到了索引，就代表cookie存在,否则不存在
    if (cookie_pos != -1) {
      // 把cookie_pos放在值的开始，只要给值加1即可
      //计算取cookie值得开始索引，加的1为“=”
      cookie_pos = cookie_pos + cookie_name.length + 1;
      //计算取cookie值得结束索引
      var cookie_end = allcookies.indexOf(";", cookie_pos);

      if (cookie_end == -1) {
        cookie_end = allcookies.length;

      }
      //得到想要的cookie的值
      var value = unescape(allcookies.substring(cookie_pos, cookie_end));
    }
    return value;
  }
  // 门店信息请求
  detail() {

    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const id = searchParams.get('id')
    BackEnd
      .companySquare('/api/EnterpriseSquareInfo/GetQueryStoreSquareListdetails', {
        authCode: id
      })
      .then(res => {
        console.log('门店详情', res.data)
        if (res.data.Message == "Success") {
          if (res.data.ResponseObj) {
            this.setState({
              StoreDetail: res.data.ResponseObj,
              PeopleService: res.data.ResponseObj.storeLoginUserModel,
              storeTitle: res.data.ResponseObj.Store
            }, () => {
              window.sessionStorage.setItem('Brand', this.state.StoreDetail.Store)
              window.sessionStorage.setItem('Telephone', this.state.StoreDetail.Telephone)
              window.sessionStorage.setItem('Address', this.state.StoreDetail.Address)
              if (this.state.StoreDetail.SmallImgUrl == null) {
                window.sessionStorage.setItem('ImgUrl', this.state.StoreDetail.SmallImgUrl)

              } else {
                window.sessionStorage.setItem('ImgUrl', this.state.StoreDetail.SmallImgUrl[0])

              }


              document.title = sessionStorage.getItem('Brand')
              console.log('标题', window.sessionStorage.getItem('SmallImgUrl'))
            })
            if (res.data.ResponseObj.qrapplication) {
              this.setState({
                InStoreImgUrl: res.data.ResponseObj.qrapplication.InStoreImgUrl,
                GoodsImgUrl: res.data.ResponseObj.qrapplication.GoodsImgUrl,
                CheckStandImgUrl: res.data.ResponseObj.qrapplication.CheckStandImgUrl,
                Success: res.data.ResponseObj.qrapplication.Success,
                Flag: Number(res.data.ResponseObj.qrapplication.Flag),
              })
            }
          }
        }
      })
  }
  //语音播报
  playAudio(){
    if(this.getCook('LoginCacheKeys') != 0 || window.sessionStorage.getItem("LoginCacheKeys") != null){
      // alert(val)
      console.log("111"+this.getCook('LoginCacheKeys'))
      console.log(window.jq("video")[0])
      
      // 建立WebSocket链接
      // var ws = new WebSocket("ws://192.168.1.47:5101/ws/accept?loginkey="+this.getCook('LoginCacheKeys')); //测试
      // var ws = new WebSocket("wss://prewsapi.chexd.com/ws/accept?loginkey="+this.getCook('LoginCacheKeys')); //准成产
      var ws = new WebSocket("wss://wsapi.chexd.com/ws/accept?loginkey="+this.getCook('LoginCacheKeys')); //成产

      ws.onopen = function(evt) { 
          console.log("已经连接"); 
      };
      ws.onmessage = function(evt) {

          // console.log(window.jq('#root')[0])
          var vdo = window.jq("video")[0]
          console.log(vdo)
          vdo.src = JSON.parse(evt.data).data
          vdo.oncanplay = function(){
              console.log('111')
              this.play();
          }
      };
      ws.onclose = function(evt) {
          // alert("Connection closed.");
          console.log(evt)
      }; 
  }
  }
  // 促销商品
  promotionCommodity = () => {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const id = searchParams.get('id')
    const prevType = searchParams.get('prevType')
    window.sessionStorage.setItem('StoreId', id)
    window.localStorage.setItem('prevType', prevType)
    let newAuthcode = Object.assign({}, this.state.promotionCommoditySearch, {
      authCode: id
    })
    this.setState({
      promotionCommoditySearch: newAuthcode
    }, () => {
      BackEnd
        .companySquare('/api/ShoppingCart/QueryAutopartsGWList', this.state.promotionCommoditySearch).then(res => {
          // window.sessionStorage.setItem('IsRegState',res.data.ResponseObj.Items[0].RegState)
          console.log('促销商品', res.data, this.state.StoreDetail)
          if (res.data.Message === 'Success' && res.data.ResponseObj.Items.length) {
            this.setState({
              promotionCommodityList: res.data.ResponseObj.Items,
              totalCount: res.data.ResponseObj.TotalNum
            })
          } else {
            this.setState({
              promotionCommodityList: [],
              totalCount: res.data.ResponseObj.TotalNum || 9
            })
          }
        })
    })
  }
  // 热销商品
  promotionCommoditys = () => {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const id = searchParams.get('id')
    const prevType = searchParams.get('prevType')
    window.sessionStorage.setItem('StoreId', id)
    window.localStorage.setItem('prevType', prevType)
    let newAuthcode = Object.assign({}, this.state.promotionCommoditySearchs, {
      authCode: id
    })
    this.setState({
      promotionCommoditySearchs: newAuthcode
    }, () => {
      BackEnd
        .companySquare('/api/ShoppingCart/QueryAutopartsRDList', this.state.promotionCommoditySearchs).then(res => {
          // window.sessionStorage.setItem('IsRegState',res.data.ResponseObj.Items[0].RegState)
          console.log('促销商品', res.data, this.state.StoreDetail)
          if (res.data.Message === 'Success' && res.data.ResponseObj.Items.length) {
            this.setState({
              promotionCommodityLists: res.data.ResponseObj.Items,
              totalCounts: res.data.ResponseObj.TotalNum
            })
          } else {
            this.setState({
              promotionCommodityLists: [],
              totalCounts: res.data.ResponseObj.TotalNum
            })
          }
        })
    })
  }
  // 全部商品
  bothGoods = () => {
    this.props.history.push({ pathname: '/productList', search: `?id=${this.state.promotionCommoditySearch.authCode}` })
  }
  // 查看配件详情
  goProductDetail = (item) => {
    console.log('配件详情数据', item);
    this.props.history.push({ pathname: '/productDetail', search: `?id=${item.ComponentId}` })
    // this.props.history.push('/productDetail')
  }
  // 弹框关闭
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }
  // 门店弹框
  handleCancel_02 = () => {
    this.setState({
      storeVisible: false,
    });
  }
  // 门店弹框图片
  storeMagnify() {
    this.setState({
      storeVisible: true,
      storeBigPic: this.state.StoreDetail.SmallImgUrl
    })
  }
  // 认证弹框图片
  magnify(type, img) {
    if (type == 1) {
      this.setState({
        showContent: this.state.InStoreImgUrl,
        visible: true
      })
    }
    if (type == 2) {
      this.setState({
        showContent: this.state.GoodsImgUrl,
        visible: true
      })
    }
    if (type == 3) {
      this.setState({
        showContent: this.state.CheckStandImgUrl,
        visible: true
      })
    }
    if (type == 'WeChat') {
      this.setState({
        showContent: img,
        visible: true
      })
    }
  }
  // 搜索商品
  searchCommodity = (item) => {
    let newCommodity = Object.assign({}, this.state.promotionCommoditySearch, { content: this.refs.promotionCommodity.value }, { currentPage: 1 })
    this.setState({
      promotionCommoditySearch: newCommodity
    }, () => {
      this.promotionCommodity()
    })
  }
  // 切换分页
  conversion = (page) => {
    let changePage = Object.assign({}, this.state.promotionCommoditySearch,
      { currentPage: page }
    )
    this.setState({
      promotionCommoditySearch: changePage
    }, () => {
      this.promotionCommodity()
    })
  }
  firstPage = () => {
    let changePage = Object.assign({}, this.state.promotionCommoditySearch, { currentPage: 1 })
    this.setState({
      promotionCommoditySearch: changePage
    }, () => { this.promotionCommodity() })
  }
  endPage = () => {
    let changePage = Object.assign({}, this.state.promotionCommoditySearch, { currentPage: Math.ceil(this.state.totalCount / this.state.promotionCommoditySearch.pageSize) || this.state.promotionCommoditySearch.pageSize })
    this.setState({
      promotionCommoditySearch: changePage
    }, () => { this.promotionCommodity() })
  }

  conversions = (page) => {
    let changePage = Object.assign({}, this.state.promotionCommoditySearchs,
      { currentPage: page }
    )
    this.setState({
      promotionCommoditySearchs: changePage
    }, () => {
      this.promotionCommoditys()
    })
  }
  firstPages = () => {
    let changePage = Object.assign({}, this.state.promotionCommoditySearchs, { currentPage: 1 })
    this.setState({
      promotionCommoditySearchs: changePage
    }, () => { this.promotionCommoditys() })
  }
  endPages = () => {
    let changePage = Object.assign({}, this.state.promotionCommoditySearchs, { currentPage: Math.ceil(this.state.totalCounts / this.state.promotionCommoditySearch.pageSize) || this.state.promotionCommoditySearch.pageSize })
    this.setState({
      promotionCommoditySearchs: changePage
    }, () => { this.promotionCommoditys() })
  }
  render() {
    const { settings } = this.state
    return (
      <div className="personalStore">
        <Header text={this.state.StoreDetail} />
        <div className="store_content">
          <div className="store_info">
            <dl>
              <dt>
                {
                  this.state.StoreDetail.SmallImgUrl && this.state.StoreDetail.SmallImgUrl.length ?
                    <img src={this.state.StoreDetail.SmallImgUrl[0]} alt="门店图片" className="storePic" onClick={this.storeMagnify.bind(this)} /> :
                    <img src={require("../images/SquareDefaultImg.png")} alt="门店默认图片" className="storePic" />
                }
                <p><img src={require("../images/biao.png")} alt="图表" className="pic" /><span>{this.state.StoreDetail.SmallImgUrl ? this.state.StoreDetail.SmallImgUrl.length : 0}</span></p>
              </dt>
              <dd>
                <div className="store_discribe">
                  <h4>{this.state.StoreDetail.Store}</h4>
                  <p><span>经营品牌:</span><span className="conceal" title={this.state.StoreDetail.Brand}>{this.state.StoreDetail.Brand}</span></p>
                  <p><span>联系电话:</span><span className="conceal" title={this.state.StoreDetail.Telephone}>{this.state.StoreDetail.Telephone}</span></p>
                  <p><span>所在地址:</span><span className="conceal" title={this.state.StoreDetail.Address}>{this.state.StoreDetail.Address}</span></p>
                  <p>
                    <span>认证状况:</span>
                    {
                      this.state.StoreDetail.Legalize == "1" ? <span><img src={require("../images/approve.png")} alt="已认证" /></span> :
                        <span><img src={require("../images/noapprove.png")} alt="未认证" /></span>
                    }
                  </p>
                </div>
                <div className="attestationContainer">
                  <div className="Certification_details" style={this.state.Success === "true" ? { display: "block" } : { display: "none" }}>
                    <span className="Certification_title">认证详情:</span>
                    <div className="Certification_card">
                      <div className="Certification_card_list">
                        <span><img src={require("../images/kl.png")} alt="营业执照" />营业执照</span>
                        <span><img src={require("../images/kl.png")} alt="验证认证" />验证认证</span>
                        <span className="card_01" onClick={this.magnify.bind(this, "1")}><img src={require("../images/kl.png")} alt="门头认证" />门头认证</span>
                      </div>
                      <div className="Certification_card_list">
                        <span><img src={require("../images/kl.png")} alt="法人身份认证" />法人身份认证</span>
                        <span><img src={require("../images/kl.png")} alt="手机号认证" />手机号认证</span>
                        <span className="card_02" onClick={this.magnify.bind(this, "2")}><img src={require("../images/kl.png")} alt="内部商品认证" />内部商品认证</span>
                      </div>
                      <div className="Certification_card_list">
                        {
                          this.state.Flag ? <span><img src={require("../images/kl.png")} alt="开户许可证" />开户许可证</span> : <span><img src={require("../images/jkljl.png")} />开户许可证</span>
                        }
                        <span><img src={require("../images/kl.png")} alt="结算银行卡" />结算银行卡</span>
                        <span className="card_03" onClick={this.magnify.bind(this, "3")}><img src={require("../images/kl.png")} alt="收银台认证" />收银台认证</span>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          {/* 门店信息 */}
          <video src=""  ></video>
          <div className="informationContent">
            {/* 左侧信息列表 */}
            <div className="companyInfo">
              {/* 质保承诺 */}
              <div className="commitmentContainer">
                <p className="title">
                  <span>|</span>
                  <span>质保承诺</span>
                </p>
                <div className="commitmentContent">
                  {this.state.StoreDetail.WhetherNoReason7days == "1" ? <span><img src={require("../images/seven.png")} alt="七天无理由退款" />支持七天无理由退款</span> : ""}
                  {this.state.StoreDetail.WhetherOriginalParts == "1" ? <span><img src={require("../images/factory.png")} alt="原厂件承诺" />原厂件承诺:<i>{this.state.StoreDetail.OriginalPartsMonth}个月或{this.state.StoreDetail.OriginalPartsK}公里承诺</i></span> : <span><img src={require("../images/factory.png")} alt="原厂件承诺" />原厂件承诺:</span>}
                  {this.state.StoreDetail.WhetherOE == "1" ? <span><img src={require("../images/OE.png")} alt="O E件承诺" />O&nbsp;&nbsp;E件承诺:<i>{this.state.StoreDetail.OEMonth}个月或{this.state.StoreDetail.OEK}公里承诺</i></span> : <span><img src={require("../images/OE.png")} alt="O E件承诺" />OE&nbsp;&nbsp;件承诺:</span>}
                  {this.state.StoreDetail.WhetherDisassemble == "1" ? <span><img src={require("../images/takeDown.png")} alt="拆车件承诺" />拆车件承诺:<i>{this.state.StoreDetail.DisassembleMonth}个月或{this.state.StoreDetail.DisassembleK}公里承诺</i></span> : <span><img src={require("../images/takeDown.png")} alt="拆车件承诺" />拆车件承诺:</span>}
                  {/* </div> */}
                  {/* <div className="commitmentContent" style={this.state.commitmentMore ? { display: "flex" } : { display: "none" }}> */}
                  {this.state.StoreDetail.BrandPromiseListGroup ? this.state.StoreDetail.BrandPromiseListGroup.map((item, index) => (
                    item.Whether == "1" ? <span key={index}><img className="image" src={require("../images/brand.png")} alt="品牌件" />品牌件承诺:<i>{item.BrandName}{item.WarrantyMonth}个月或{item.WarrantyK}公里质保</i></span> : ""
                  )) : <div></div>}
                </div>
              </div>
              {/* 联系电话 */}
              <div className="contact">
                <p className="title">
                  <span>|</span>
                  <span>联系电话</span>
                </p>
                <div className="contactContent">
                  {
                    this.state.StoreDetail ?
                      <span>{this.state.StoreDetail.Telephone}</span>
                      : <span>暂无咨询信息</span>}
                </div>
              </div>
              {/* 公司介绍 */}
              <div className="companyIntroduce">
                <p className="title">
                  <span>|</span>
                  <span>公司介绍</span>
                </p>
                <div className="companyContent">
                  {
                    this.state.StoreDetail.SmallImgUrl && this.state.StoreDetail.SmallImgUrl.length ? <dl>
                      <dt><img src={this.state.StoreDetail.SmallImgUrl[0]} alt="门店图片" /></dt>
                      <dd>{this.state.StoreDetail.Memo}</dd>
                    </dl> : <dl>
                        <dt><img src={require("../images/SquareDefaultImg_small.png")} alt="门店图片" /></dt>
                        <dd></dd>
                      </dl>
                  }
                </div>
              </div>
            </div>
            {/* 右侧商品 */}
            <div className="goodsContainer">
              <div className="goodsSearch">
                <div className="searchContainer">
                  <input type="text" placeholder="请输入配件编码、配件名称" ref="promotionCommodity" />
                  <span onClick={this.searchCommodity}>搜索</span>
                </div>
                <span className="bothGoods" onClick={this.bothGoods}>全部商品</span>
              </div>
              <div className="goodsListContainer">
                <p className="title">
                  <span>|</span>
                  <span>促销商品</span>
                </p>
                <div className="goodsList">
                  {
                    this.state.promotionCommodityList.length ?
                      this.state.promotionCommodityList.map((item, index) => (
                        <dl key={index} onClick={this.goProductDetail.bind(this, item)}>
                          <dt>
                            {item.PicturePath ? <img src={item.PicturePath.split('|')[0]} alt="商品图片" /> : <img src={require("../images/noCommodity.png")} alt="商品图片" />}
                          </dt>
                          <dd>
                            <p className="priceTitle"><span>{Number(item.EnterpriseCategoryPrice) > 0 ? <span>￥{Number(item.EnterpriseCategoryPrice).toFixed(2)}</span> : <span>{item.EnterpriseCategoryPrice}</span>}</span><span>库存{item.ShowQuantity}件</span></p>
                            <p>{item.ComponentCode}</p>
                            <p>{item.ComponentName}</p>
                          </dd>
                        </dl>)) : <List className="goodsList-div" />}
                </div>
                {/* 商品分页 */}
                <div className="list_info_pagination" >
                  <span className="first_button" onClick={this.firstPage}>首页</span>
                  <Pagination
                    total={this.state.totalCount || 9}
                    current={this.state.promotionCommoditySearch.currentPage}
                    itemRender={itemRender}
                    pageSize={this.state.promotionCommoditySearch.pageSize}
                    onChange={this.conversion}
                  />
                  <span className="last_button" onClick={this.endPage}>末页</span>
                </div>
              </div>

              <div className="goodsListContainer">
                <p className="title">
                  <span>|</span>
                  <span>热销商品</span>
                </p>
                <div className="goodsList">
                  {
                    this.state.promotionCommodityLists.length ?
                      this.state.promotionCommodityLists.map((item, index) => (
                        <dl key={index} onClick={this.goProductDetail.bind(this, item)}>
                          <dt>
                            {item.PicturePath ? <img src={item.PicturePath.split('|')[0]} alt="商品图片" /> : <img src={require("../images/noCommodity.png")} alt="商品图片" />}
                          </dt>
                          <dd>
                            <p className="priceTitle"><span>{Number(item.EnterpriseCategoryPrice) > 0 ? <span>￥{Number(item.EnterpriseCategoryPrice).toFixed(2)}</span> : <span>{item.EnterpriseCategoryPrice}</span>}</span><span>库存{item.ShowQuantity}件</span></p>
                            <p>{item.ComponentCode}</p>
                            <p>{item.ComponentName}</p>
                          </dd>
                        </dl>)) : <List className="goodsList-div" />}
                </div>
                {/* 商品分页 */}
                <div className="list_info_pagination" style={{ width: "100%" }}>
                  <span className="first_button" onClick={this.firstPages}>首页</span>
                  <Pagination
                    total={this.state.totalCounts}
                    current={this.state.promotionCommoditySearchs.currentPage}
                    itemRender={itemRender}
                    pageSize={this.state.promotionCommoditySearchs.pageSize}
                    onChange={this.conversions}
                  />
                  <span className="last_button" onClick={this.endPagse}>末页</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer storeInfo={this.state.StoreDetail} />
        {/* 弹框 */}
        <Modal
          title="查看图片"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          centered={true}
        >
          <img src={this.state.showContent} alt="大图查看内容" style={style.banner} />
        </Modal>
        {/* 门店弹框 */}
        <Modal
          title="查看图片"
          visible={this.state.storeVisible}
          onCancel={this.handleCancel_02}
          footer={null}
          centered={true}
          wrapClassName="shopModel"
        >
          <div className="PopoutContent">
            <div>
              <Carousel ref={el => (this.slider = el)} {...settings}>
                {this.state.storeBigPic.map((item, index) => (
                  <div key={index}><img src={item} alt="门店图片" style={style.banner} /></div>
                ))}
              </Carousel>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
export default PersonalStore
const style = {
  banner: {
    width: '100%',
    height: '300px',
    display: 'inline-block',
    marginBottom: '35px',
    textAlign: 'center',
    marginTop: '15px'
  }
}