import React, { Component } from 'react'
import './Footer.scss'

class StoreFooter extends Component {
  constructor() {
    super()
    this.state = {

    }
  }
  componentDidMount() {

  }
  render() {
    return (
      <div className="storeFooter">
        <div className="storeFooterContent">
          <div className="storePic">
            {
              window.sessionStorage.getItem('ImgUrl') && window.sessionStorage.getItem('ImgUrl') != 'null'
                ?
                <img src={window.sessionStorage.getItem('ImgUrl')} alt="门店图片" />
                :
                <img src={require("../../../images/SquareDefaultImg_small.png")} alt="门店图片" />
            }
            <span>{window.sessionStorage.getItem('Brand')}</span></div>
          <div>
            <span onClick={() => {
              console.log(window.sessionStorage.getItem('ImgUrl'))
            }}>所在地址</span>
            <p><span>{window.sessionStorage.getItem('Address')}</span></p>
          </div>
          <div><span>联系电话</span><p><span>{window.sessionStorage.getItem('Telephone')}</span></p></div>
        </div>
        <p className="copyRight">版权所有 © 2019 <span>{window.sessionStorage.getItem('Brand')}</span></p>
      </div>
    )
  }
}

export default StoreFooter
