import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import './Header.scss'

class StoreHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      prevAddr: this.props.prevAddr // 1首页 2新品上架 3促销商品
    }
  }
  handleChange(e) {
    // 获取搜索内容
    this.setState({
      searchValue: e.target.value
    })
  }
  toSearchResult(value) {
    console.log(111111,value)
    this.props.history.push({ pathname: '/SearchResult', search: `?value=${value}` })
  }
  // 是否给收搜索添加时间更新数据
  isShopData() {

    if (this.props.location.pathname == '/partsShopping' 
    || this.props.location.pathname == '/NewArrivals' 
    || this.props.location.pathname == '/Cheap'
    || this.props.location.pathname == '/CheapDetail') {
      return <span className="search_btn" onClick={this.toSearchResult.bind(this, this.state.searchValue)}>搜索</span>
    } else if(this.props.location.pathname == '/Information'){
      return <span className="search_btn" onClick={this.props.searchInformation.bind(this, this.state.searchValue)}>搜索</span>    
    } else {
       if (this.props.location.pathname == '/SearchResult') {
          return <span className="search_btn" onClick={this.props.searchShop.bind(this, this.state.searchValue)}>搜索</span>
      }else {
         return <span className="search_btn">搜索</span>
      }
    }
  }
  render() {
    return (
      <div className="personalStoreNavs" >
        <div className="navContainer">

          <div className="storeOptions">
            <div className="shopHomeSearch">
              {
                this.props.location.pathname == '/Information' || this.props.location.pathname == '/Recharge' || this.props.location.pathname == '/InformationDetail' 
                  ? 
                  <img src={require('./../../imgage/logo_a.png')} alt="" />
                  :

                  <img src={require('./../../imgage/logo.png')} alt="" />

              }
              {
                this.props.location.pathname == '/InformationDetail' ? '':
                <div className="search_input">

                {
                  this.props.location.pathname == '/Information' || this.props.location.pathname == '/Recharge'? 
                      <input type="text" placeholder="请输入资料名称进行查询" onChange={this.handleChange.bind(this)} value={this.state.searchValue} /> : 
                      // { 1 == 1?1:2}
                      
                      <input type="text" placeholder="请输入配件编码、配件名称进行查询" onChange={this.handleChange.bind(this)} value={this.state.searchValue} />

                }
                  
                  {/* <input type="text" placeholder="请输入配件编码、配件名称进行查询" onChange={this.handleChange.bind(this)} value={this.state.searchValue} /> */}
                  {this.isShopData()}
              </div>
              }
              
            </div>
               {
                 this.props.location.pathname == '/Information' || this.props.location.pathname == '/Recharge' || this.props.location.pathname == '/InformationDetail' ? '' : 
                 
                 <div className="storeOption">

                      {this.props.location.pathname == '/partsShopping' ? <div className="classify-titile">全部商品分类</div> : ''}
        
                      <NavLink style={{marginLeft: 0}} to={`/partsShopping`} className={this.props.location.pathname == '/personalStore' || this.props.location.pathname == '/SearchResult' || this.state.prevAddr == '1' ? 'active' : ""} activeStyle={{ color: "#02a7f0" }} >首页</NavLink>
                      <NavLink to={`/NewArrivals`} className={this.props.location.pathname == '/productList' || this.state.prevAddr == '2' ? 'active' : ""} activeStyle={{ color: "#02a7f0" }}>新品上架</NavLink>
                      <NavLink to={`/Cheap`} className={this.props.location.pathname == '/contactUs' || this.state.prevAddr == '3' ? 'active' : ""} activeStyle={{ color: "#02a7f0" }}>促销商品</NavLink>
                      {/* <NavLink to={`/Information`} className={this.props.location.pathname == '/information'|| this.props.location.pathname == '/InformationDetail'|| this.props.location.pathname == '/Recharge'? 'active' : ""} activeStyle={{ color: "#02a7f0" }}>维修资料</NavLink> */}
    
                </div>
                 
               }
              
     



          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(StoreHeader)

