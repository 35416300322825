import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import './EnterpriseInformation.scss'
import { Tabs, Table, Modal, message, Checkbox, Empty, Input, Button, DatePicker, ConfigProvider, Cascader, Upload, Icon } from 'antd'
import areaJson from './area.json'

import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传类型是JPG/PNG文件');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('上传图片不能超过2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class EnterpriseInformation extends Component {
	constructor() {
		super()
		this.state = {
		locale: {
			// emptyText: <img src={(require('./images/hint.png'))}></img>
		},
		basicInformation: {},
		nickName: '',//联系人
		name: '',//公司名称
		nameAbbreviation: '',
		address: '',
		province: '',
		city: '',
		county: '',
		telephone: '',
		storeImgUrl: [],
		isShowBrand: false,
		loading: false, // 上传图片的loading图
		imageUrl: '', // 上传图片的url
		brandUrl: [],
		isShowDelShopPopup: false,
		TheMainBrand: false,
		pinying: '',
		pinYin: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'S', 'Y', 'Z'],
		Brand: [],
		Brand_name: '',
		Brand_code: '',
		Brand_picUrl: '',
		previewVisible: false,
		previewImage: '',
		img_storeImgUrl: [],
		imgList: [],
		Brand_idx: [],
		left_li: 0
		}
	}

	componentWillMount() {
		window.scrollTo(0, 0);
	}
	componentDidMount() {

		this.information()
		this.getBrandView()
		// this.shoppinglist()
	}

	information() {
		BackEnd.enterprise('/api/UserCenter/GetUserBasicInformation', {
		userId: Number(window.localStorage.getItem('userId'))
		// userId: 3221
		}).then(res => {
		this.setState({
			basicInformation: res.data.responseObj,
			nickName: res.data.responseObj.nickName,
			name: res.data.responseObj.name,
			nameAbbreviation: res.data.responseObj.nameAbbreviation,
			address: res.data.responseObj.address,
			province: res.data.responseObj.province,
			city: res.data.responseObj.city,
			county: res.data.responseObj.county,
			telephone: res.data.responseObj.telephone,
			storeImgUrl: res.data.responseObj.storeImgUrl.length != '0' ? res.data.responseObj.storeImgUrl[0].split('|') : [],
			imageUrl: res.data.responseObj.photos == "" ? null : res.data.responseObj.photos,
			brandUrl: res.data.responseObj.brandUrl.length != '0' ? res.data.responseObj.brandUrl : [],
		})
		})
	};

	//上传图片
	handleChange = (info, fileList, event) => {

		if (info.file.status === 'uploading') {
		this.setState({ loading: true });
		return;
		}
		if (info.file.status === 'done') {
		// Get this url from response in real world.
		getBase64(info.file.originFileObj, imageUrl =>
			this.setState({
			imageUrl: info.file.response.datas.baseUrl + info.file.response.datas.filePath,
			loading: false,
			}),
		);
		}

	};

	onChanges = ({ file, fileList }) => {
    
		// console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
		// console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表
		this.setState({
		imgList: fileList,
		});
		console.log(this.state.imgList)
		if(this.state.imgList.length == 0) {
			return
		}else{
			if (this.state.imgList[0].response != undefined) {
				for (var i = 0; i < this.state.imgList.length; i++) {
					if (this.state.storeImgUrl.indexOf(this.state.imgList[i]) === -1) {
					this.state.storeImgUrl.push(this.state.imgList[i].response.datas.baseUrl + this.state.imgList[i].response.datas.filePath)
					}
				}
				this.state.imgList = []
				this.setState({
					imgList: this.state.imgList,
				});
				this.setState({
					storeImgUrl: this.state.storeImgUrl
				})
			}
		}
	};
	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = file => {
		this.setState({
		previewImage: file.url || file.thumbUrl,
		previewVisible: true,
		});
	};

	refresh(idx) {
		this.state.brandUrl.splice(idx, 1);
		this.setState({
		brandUrl: this.state.brandUrl
		})
	}
	refreshs(idx) {
		this.state.storeImgUrl.splice(idx, 1);
		this.setState({
		storeImgUrl: this.state.storeImgUrl
		})
	}
	Refreshs(idx) {
		this.state.Brand_idx.splice(idx, 1);
		this.setState({
		Brand_idx: this.state.Brand_idx
		})
	}

	//地区
	companyArea(value) {
		this.setState({
		province: value[0],
		city: value[1],
		county: value[2],
		// address: value.toString()
		})
	};
	mainBusiness() {
		this.setState({
		TheMainBrand: true
		}, () => {
		})
		this.getBrandView()
	}

	getBrandView() {
		BackEnd.enterprise('/api/UserCenter/getBrandView', {
		"pinYin": this.state.pinying
		}).then(res => {
		this.setState({
			Brand: res.data.responseObj
		})
		})
	};

	UpdaUserBasicInformation() {
		let modification = {
		"photos": this.state.imageUrl,
		"nickName": this.state.nickName,
		"contactor": this.state.nickName,
		"name": this.state.name,
		"nameAbbreviation": this.state.nameAbbreviation,
		"address": this.state.address,
		"province": this.state.province,
		"city": this.state.city,
		"county": this.state.county,
		"telephone": this.state.telephone,
		"imId": Number(window.localStorage.getItem('IMId')),

		"brandUrl": this.state.brandUrl,
		"storeImgUrl": this.state.storeImgUrl.length == 0 ? null :  this.state.storeImgUrl

		}
		BackEnd.modificationEnterprise('/api/UserCenter/UpdaUserBasicInformation', modification).then(res => {
		if (res.data.code == "0") {
			this.setState({
			isShowDelShopPopup: true
			})
		}
		})
	};

  render() {


    const uploadButton = (
      <div className='ant-upload_int'>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">修改上传</div>
      </div>
    );
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="Essentialinfobox">
          <div className='title'>基本信息</div>
          <div className="Essentialinfo">
            <div style={{ marginBottom: '10px' }}>

              用户类型 ：  {this.state.basicInformation.enterpriseCategory == '1' ? <span>汽配商</span> : " "}
              {this.state.basicInformation.enterpriseCategory == '2' ? <span>汽修厂</span> : " "}
              {this.state.basicInformation.enterpriseCategory == '3' ? <span>个人</span> : " "}
              {this.state.basicInformation.enterpriseCategory == '4' ? <span>厂家</span> : " "}
              {this.state.basicInformation.enterpriseCategory == '5' ? <span>报废车企业</span> : " "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            <div style={{ display: 'flex', marginBottom: '20px' }}><div style={{ display: 'inline-block', lineHeight: '80px' }}>
              企业logo ：
              </div>
              <div className="photos_ke">
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://shop.chexd.com/apis/upload/uploadFile"
                  beforeUpload={beforeUpload}
                  onChange={this.handleChange}
                >
                  {this.state.imageUrl ? <img className="photos_img" src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </div>

            </div>
            <div className="enterprise_div"> <span style={{ color: 'red' }}>*</span>企业名称：<Input style={{ width: "40%" }} placeholder=""
              onChange={(e) => { this.setState({ name: e.target.value }) }}
              value={this.state.name} />&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="enterprise_div">  企业简称：<span >&nbsp; </span><Input style={{ width: "40%" }} placeholder=""
              onChange={(e) => { this.setState({ nameAbbreviation: e.target.value }) }}
              value={this.state.nameAbbreviation} />&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="enterprise_div"> <span style={{ color: 'red' }}>*</span>所属地区：
            <Cascader options={areaJson}
                style={{ width: '377px' }}
                value={[this.state.province, this.state.city, this.state.county]}
                onChange={this.companyArea.bind(this)} />
             &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className="enterprise_div"> <span style={{ color: 'red' }}>*</span>所在地址：<Input style={{ width: "40%" }} placeholder=""

              value={this.state.address} onChange={(e) => { this.setState({ address: e.target.value }) }} />&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="enterprise_div"> <span style={{ color: 'red' }}>*</span>联系人：&nbsp;&nbsp;&nbsp;&nbsp;<Input style={{ width: "40%" }} placeholder=""
              onChange={(e) => { this.setState({ nickName: e.target.value }) }}
              value={this.state.nickName} />&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="enterprise_div"> <span style={{ color: 'red' }}>*</span>联系电话：<Input style={{ width: "40%" }} placeholder=""
              onChange={(e) => { this.setState({ telephone: e.target.value }) }}
              value={this.state.telephone} />&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="enterprise_div " > 主营品牌：&nbsp;&nbsp;<button style={{ padding: '0 10px' }} onClick={() => {
              this.mainBusiness()
            }}>添加主营品牌</button>

            </div>


            <ul className="brandUrl_kuang">

              {
                this.state.brandUrl.length != 0
                  ?
                  this.state.brandUrl.map((item, index) => {
                    return <li className="brandUrl_li" style={{ width: '80px', height: '80px', float: 'left' }} key={index}>
                      <span className="spann" onClick={() => {
                        this.refresh(index)
                      }}></span>
                      <img style={{ width: '60px', height: '60px', marginLeft: '9px' }} src={item.picUrl} alt="" />
                      <div style={{ textAlign: 'center', paddingTop: '5px' }}>{item.name}</div>
                    </li>
                  })
                  :
                  null
              }
            </ul>


            <div className="storeImgUrl" style={{ marginTop: "10px" }}><br />
              <div style={{ clear: "both" }}></div>
              <div style={{ 'line-height': '30px' }}>形象照片 ：</div>
              <div style={{ float: 'left' }}>
                <ul style={{ marginLeft: '20px' }}>
                  {
                    this.state.storeImgUrl.length != "0"
                      ?
                      this.state.storeImgUrl.map((item, index) => {
                        return <li className="img_li" key={index}>
                          <span className="myspan" onClick={() => {
                            this.refreshs(index)
                          }}></span>
                          <img className="store_img" src={item} alt="" />
                        </li>
                      })
                      :
                      null
                  }
                </ul>
              </div>
              <div style={{ float: 'left' }}>
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://shop.chexd.com/apis/upload/uploadFile"
                  fileList={this.state.imgList}
                  beforeUpload={beforeUpload}
                  onChange={this.onChanges}
                >
                  {this.state.storeImgUrl.length >= 6 ? null : uploadButton}
                </Upload>
              </div>
              <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />


            <Button className="Submit" onClick={() => {
              if (this.state.name == '') {
                message.error('请填写您的企业名称');
              } else if (this.state.address == "") {
                message.error('请填写所在地区');
              } else if (this.state.nickName == "") {
                message.error('请填写联系人姓名');
              }else if(!/(?:[\u4E00-\u9FFF]{1,8}·\u4E00-\u9FFF]{1,8})|(?:[\u4E00-\u9FFF]{2,5})/.test(this.state.nickName)){
                message.error('请输入正确的联系人姓名');
              }else if (this.state.telephone == "") {
                message.error('请填写联系人手机号');
              } else if (this.state.address == "") {
                message.error('请填您的详细地址');
              } else {
                this.UpdaUserBasicInformation()
              }

            }}>提交</Button>

          </div>
        </div>
        {//确认弹窗
          this.state.isShowDelShopPopup ?
            <div className="shopPopup">
              <div className="main">
                <div className="title">
                  <span>提示</span>
                  <span onClick={() => { this.setState({ isShowDelShopPopup: false }) }}>x</span>
                </div>
                <div className="shopCont">
                  <div style={{ color: '#000' }}>修改成功</div>
                </div>
                <div className="btnBox">
                  <button onClick={() => { this.setState({ isShowDelShopPopup: false }) }}>确定</button>
                </div>
              </div>
            </div>
            : ""
        }
        {//品牌弹窗
          this.state.TheMainBrand ?
            <div className="shopPopup_brand">
              <div className="shopPopup_con">
                <div className="shopPopup_title">
                  <span className="shop_span">编辑主营品牌</span>
                </div>
                <div className="shopPopup_content">
                  <ul className="shopPopup_left">
                    {
                      // console.log(this.state.Brand_idx),
                      
                      this.state.Brand.length != 0
                        ?
                        this.state.Brand.map((item, index) => {
                          return <li className="left_li" key={index} onClick={() => {
                            let onoff = false
                            console.log(this.state.brandUrl)
                            if (this.state.brandUrl.length == 0) {
                              this.state.brandUrl.push(item)

                            } else {
                              for (var i in this.state.brandUrl) {

                                if (this.state.brandUrl[i].name == item.name) {
                                  onoff = true
                                }
                              }
                              if (onoff === false) {
                                this.state.brandUrl.push(item)
                              }
                              this.setState({
                                brandUrl: this.state.brandUrl,
                              })
                            }
                          }
                          }>{item.name}</li>
                        })
                        :
                        ''
                    }
                  </ul>
                  <ul className="shopPopup_pinyin">
                    {
                      this.state.pinYin.map((item, index) => {
                        return <li className={this.state.left_li == index ? "pinyin_li cur" : "pinyin_li"} onClick={() => { this.setState({ pinying: item, left_li: index }, () => { this.getBrandView() }); }} key={index}>{item}</li>
                      })
                    }
                  </ul>
                  <div className="shopPopup_rigth">
                    <div className="shopPopup_top">
                      <div>已选品牌</div>

                      <ul>

                        {
                          this.state.brandUrl.length != '0' ?
                            this.state.brandUrl.map((item, index) => {
                              return <li className='rigth_li' key={index}
                              >
                                <span className="myspann" onClick={() => {
                                  this.Refreshs(index)
                                }}></span>
                                <div style={{ width: '80px', height: '80px' }} onClick={() => {
                                  console.log(index)

                                }}>
                                  <div style={{ width: '60px', height: '60px', margin: '0 auto' }}>
                                    <img className=" rigth_img" src={item.picUrl} alt={item.name} />

                                  </div>
                                  <div style={{ textAlign: 'center' }}>{item.name}</div>
                                </div>


                              </li>
                            })
                            :
                            ""
                        }
                      </ul>

                    </div>
                    <div className="shopPopup_bottom">
                      <Button className="OK" onClick={() => {
                        let onoff = false
                        
                        for (let i = 0; i < this.state.Brand_idx.length; i++) {
                          if(this.state.brandUrl.length==0){
                            this.state.brandUrl.push(this.state.Brand_idx[i])
                          }else{
                          for (var j in this.state.brandUrl) {
                            if (this.state.brandUrl[j].name == this.state.Brand_idx[i].name) {
                              onoff = true
                            }
                          }
                          if (onoff === false) {
                            console.log(this.state.brandUrl, this.state.Brand_idx[i])
                            this.state.brandUrl.push(this.state.Brand_idx[i])
                          }
                        }
                      }
                        this.setState({
                          brandUrl: this.state.brandUrl,
                          TheMainBrand: false,
                        }, () => {
                        });
                      }}>确定</Button>
                      <Button className="NO" onClick={() => {
                        this.setState({
                          TheMainBrand: false
                        })
                      }}>取消</Button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            : ""
        }

      </div >
    )
  }
}
export default withRouter(EnterpriseInformation)
