export const formatDate = (val, format = 'yyyy-mm-dd') => {
    if (val.length && val.length === 13) {
        val = parseInt(val)
    }
    var d = new Date(val)
    var year = d.getFullYear()
    var month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1)
    var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate()
    var hour = d.getHours() < 10 ? '0' + d.getHours() : '' + d.getHours()
    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : '' + d.getMinutes()
    var seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : '' + d.getSeconds()
    let result = ''
    switch (format) {
        case 'yyyymmdd':
            result = year + month + day
            break
        case 'yyyymm':
            result = year + month
            break
        case 'yyyy-mm-dd':
            result = year + '-' + month + '-' + day
            break
        case 'yyyy-mm-dd hh:MM':
            result = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes
            break
        case 'yyyy-mm-dd hh:MM:ss':
            result = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
            break
        default:
            break
    }
    return result
}
//日期时间计算
export const getBeforeDate = (n) => {
    var n = n;
    var d = new Date();
    var year = d.getFullYear();
    var mon = d.getMonth() + 1;
    var day = d.getDate();
    if (day <= n) {
        if (mon > 1) {
            mon = mon - 1;
        } else {
            year = year - 1;
            mon = 12;
        }
    }
    d.setDate(d.getDate() - n);
    year = d.getFullYear();
    mon = d.getMonth() + 1;
    day = d.getDate();
    var s = year + (mon < 10 ? ('0' + mon) : mon) + (day < 10 ? ('0' + day) : day);
    return s;
}