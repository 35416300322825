import React, { PureComponent } from 'react'
import {
	Table,
	Select,
	message,
	Pagination,
	Modal,
	Tabs,
	DatePicker,
	ConfigProvider
} from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import moment from 'moment'
import BackEnd from '../../services/api'
import areaData from './data.json'
import InquiryDetail from './InquiryDetail'
import QuotedPriceDetail from './QuotedPriceDetail'
import { formatDate, getBeforeDate } from '../../utils/filter'
import './HaveInquiry.scss'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const { TabPane } = Tabs
const { confirm } = Modal
function itemRender(current, type, originalElement) {
	if (type === 'prev') {
		return <span>上一页</span>;
	} if (type === 'next') {
		return <span>下一页</span>;
	}
	return originalElement;
}
class HaveInquiry extends PureComponent {
	constructor() {
		super()
		this.state = {
			activeIndex: '',
			TotalCount: 10,
			Page: 1,
			visibleProvider: false, // 供应商名片弹框
			visibleEnquiry: false, // 询价弹框
			disableButton: false,
			provinceValue: undefined,// 省级查询值
			cityValue: undefined, // 市级查询值
			regionValue: undefined, // 区级查询值
			cityDisabled: true, // 区域市级按钮
			regionDisabled: true, // 区域区级按钮
			resultList: [], // 查询结果列表
			ModelList: [], // 弹框数据列表
			CompanyDetail: [],
			enquiryoddNumber: '', // 查询明细的订单号
			enquiryDetailList: [], // 询价明细列表
			quotedPriceDetailList: [], // 报价明细列表
			locale: {
				emptyText: <img src={(require('./images/noData.png'))}></img>
			},
			startValue: null,
			endValue: null,
			endOpen: false,
			searchList: {
				userId: Number(window.localStorage.getItem('userId')), // 登录人ID
				inquiryVoucherCode: "", // 询价单号
				authName: "", // 供应商
				customerType: 0, // 用户类别
				status: 0, // 报价状态
				province: "", // 省
				city: "", // 市
				county: "", // 区
				invalidStatus: 0, // 过期状态
				beginTime: getBeforeDate(7) + '000000',
				// beginTime: '20200107000000', // 开始时间
				endTime: formatDate(new Date(), 'yyyymmdd') + '235960',// 结束时间
				currentPage: 0,
				pageSize: 10
			},
			columns: [{
				title: '序号',
				dataIndex: 'ID',
				key: 'ID',
				width: 80,
				render: (text, record) => (
					<span>{text}</span>
				)
			}, {
				title: '询价单号',
				dataIndex: 'InquiryVoucherCode',
				key: 'InquiryVoucherCode',
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '询价时间',
				dataIndex: 'OccurTime',
				key: 'OccurTime',
				width: 150,
				ellipsis: true,
				render: (text, record) => (
					<div title={text} >
						{text}
					</div>
				)
			}, {
				title: '供应商',
				dataIndex: 'CompanyName',
				key: 'CompanyName',
				width: 200,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}
						className='storer'
						onClick={this.FittingsModel.bind(this, record)}
					>
						{text}
					</span>
				)
			}, {
				title: '联系电话',
				dataIndex: 'Mobile',
				key: 'Mobile',
				width: 140,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<div title={text} className='omit' style={{ width: '110px' }}>
						{text}
					</div>
				)
			}, {
				title: '区域',
				dataIndex: 'Area',
				key: 'Area',
				width: 120,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '用户类型',
				dataIndex: 'CustomerType',
				key: 'CustomerType',
				width: 100,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					text === "1" ?
						<span title={text}>
							汽配商
					</span> : text === "2" ? <span title={text}>
							汽修厂
					</span> : text === "4" ? <span title={text}>
								厂家
					</span> : ''
				)
			}, {
				title: '报价状态',
				dataIndex: 'StatusStr',
				key: 'StatusStr',
				width: 150,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '过期时间(7天)',
				dataIndex: 'InvalidTime',
				key: 'InvalidTime',
				width: 150,
				ellipsis: true,
				render: (text, record) => (
					<div title={text}>
						{text}
					</div>
				)
			}, {
				title: '过期状态',
				dataIndex: 'InvalidStatus',
				key: 'InvalidStatus',
				width: 100,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '操作',
				width: 100,
				ellipsis: true,
				render: (text, record) => (
					<span className='storer' onClick={this.deteleHaveEnquiry.bind(this, record)}>删除</span>
				)
			}]
		}
	}
	componentDidMount() {
		this.getData()
		if (Number(window.localStorage.getItem('userId'))) {
			this.setState({
                userId:Number(window.localStorage.getItem('userId'))
			})
			
		}
	}
	// 删除过期的询价单
	deteleHaveEnquiry = (record) => {
		console.log('删除订单', record.InquiryVoucherCode);
		let that = this
		confirm({
			title: '提示',
			content: '确认删除此询价单吗？',
			// okText: 'Yes',
			okType: 'danger',
			// cancelText: 'No',
			onOk() {
				BackEnd
					.deteleHaveEnquiry(record.InquiryVoucherCode)
					.then(res => {
						console.log('删除成功', res.data);
						if(res.data.Message ==='Success') {
							that.getData()
						}
					})
			},
			onCancel() {},
		})
	}
	// 获取已询价单数据
	getData() {
		this.setState({
			disableButton: true,
			activeIndex: ''
		}, () => {
			BackEnd
				.getHaveInquiry(this.state.searchList)
				.then(res => {
					console.log('询价单数据', res.data)
					if (res.data.Message === 'Success') {
						let changeResultList = []
						for (let i = 0; i < res.data.ResponseObj.Items.length; i++) {
							res.data.ResponseObj.Items[i].serialNumber = i
							changeResultList.push(res.data.ResponseObj.Items[i])
						}
						this.setState({
							resultList: changeResultList,
							TotalCount: res.data.ResponseObj.TotalNum
						})
					}
				}).catch(error => {
					message.error('暂无信息!');
				})
		})
	}
	// 获取已询价明细
	getEnquiryDetail(EnquiryCode) {
		BackEnd
			.getEnquiryDetail(EnquiryCode)
			.then(res => {
				console.log('已询价明细', res.data);
				if (res.data.Message === 'Success') {
					this.setState({
						enquiryDetailList: res.data.ResponseObj
					}, () => {
						console.log('1111', this.state.enquiryDetailList);
					})
				}

			})
	}
	// 获取报价明细
	quotedPriceDetail(EnquiryCode) {
		BackEnd
			.getQuotedPriceDetail(EnquiryCode)
			.then(res => {
				console.log('报价明细', res.data);
				if (res.data.Message === 'Success') {
					console.log('报价明细', res.data);
					this.setState({
						quotedPriceDetailList: res.data.ResponseObj
					}, () => {
						console.log('222', this.state.quotedPriceDetailList)
					})
				}
			})
	}
	// 搜索
	seek = () => {
		console.log('知心')
		let searchChange = Object.assign({}, this.state.searchList,
			{ inquiryVoucherCode: this.refs.enquiryCode.value.replace(/(^\s*)|(\s*$)/g, "") },
			{ authName: this.refs.enquiryCompanyName.value.replace(/(^\s*)|(\s*$)/g, "") },
		)
		this.setState({
			searchList: searchChange,
			Page: 1
		}, () => {
			this.getData()
		})
	}
	// 供应商名片弹框
	FittingsModel(record) {
		console.log('数据', record);
		this.setState({
			ModelList: record,
			visibleProvider: true,
			activeIndex: ''
		})
	}
	// 取消弹框
	handleCancel = () => {
		this.setState({
			visibleFitting: false,
			visibleProvider: false,
			visibleEnquiry: false,
			activeIndex: ''
		});
	}
	// 日期开始时间范围
	disabledStartDate = startValue => {
		const { endValue } = this.state;
		if (!startValue || !endValue) {
			return false;
		}
		return startValue.valueOf() > endValue.valueOf();
	};
	// 日期结束时间范围
	disabledEndDate = endValue => {
		const { startValue } = this.state;
		if (!endValue || !startValue) {
			return false;
		}
		return endValue.valueOf() <= startValue.valueOf();
	};

	onChange = (field, value) => {
		if (field === 'startValue') {
			let newDate = Object.assign({}, this.state.searchList, { beginTime: value ? formatDate(value._d, 'yyyymmdd') + '000000' : null })
			this.setState({
				[field]: value,
				searchList: newDate
			});
		} else if (field === 'endValue') {
			let newDate = Object.assign({}, this.state.searchList, { endTime: value ? formatDate(value._d, 'yyyymmdd') + '235960' : null })
			this.setState({
				[field]: value,
				searchList: newDate
			});
		}
	};

	onStartChange = value => {
		this.onChange('startValue', value);
	};

	onEndChange = value => {
		this.onChange('endValue', value);
	};

	handleStartOpenChange = open => {
		if (!open) {
			this.setState({ endOpen: true });
		}
	};

	handleEndOpenChange = open => {
		this.setState({ endOpen: open });
	};
	// 改变页码
	changePage = (page) => {
		console.log('改变的页码', page)
		let changePage = Object.assign({}, this.state.searchList, { currentPage: page })
		this.setState({
			searchList: changePage,
			Page: page
		}, () => { this.getData() })
	}
	// 首页
	firstPage = () => {
		let changePage = Object.assign({}, this.state.searchList, { currentPage: 1 })
		this.setState({
			searchList: changePage,
			Page: 1,
		}, () => {
			this.getData()
		})
	}
	// 尾页
	endPage = () => {
		let changePage = Object.assign({}, this.state.searchList, { currentPage: Math.ceil(this.state.TotalCount / 10) })
		this.setState({
			searchList: changePage,
			Page: Math.ceil(this.state.TotalCount / 10),
		}, () => {
			this.getData()
		})
	}
	RemindUser = () => {
		message.error('暂无QQ信息!')
	}
	// 省级下拉选项
	selectProvince = (value) => {
		let newProvinceValue = Object.assign({}, this.state.searchList,
			{ province: value },
			{ city: '' },
			{ county: '' }
		)
		this.setState({
			searchList: newProvinceValue,
			provinceValue: value,
			cityValue: undefined,
			regionValue: undefined
		})
		if (value) {
			this.setState({
				cityDisabled: false,
				regionDisabled: true // 禁用区级选项
			})
		} else {
			// 清空按钮触发时
			let newProvinceValue = Object.assign({}, this.state.searchList,
				{ province: '' },
				{ city: '' },
				{ county: '' }
			)
			this.setState({
				searchList: newProvinceValue,
				cityDisabled: true,
				regionDisabled: true,
				provinceValue: value,
			})
		}
	}
	// 市级下拉选项
	selectCity = (value) => {
		let newCityValue = Object.assign({}, this.state.searchList,
			{ city: value },
			{ county: '' }
		)
		this.setState({
			searchList: newCityValue,
			cityValue: value,
			regionValue: undefined
		})
		if (value) {
			this.setState({
				regionDisabled: false
			})
		} else {
			// 清空按钮触发时
			let newCityValue = Object.assign({}, this.state.searchList,
				{ city: '' },
				{ county: '' }
			)
			this.setState({
				searchList: newCityValue,
				regionDisabled: true,
				regionValue: value
			})
		}
	}
	// 区级下拉选项
	selectRegion = (value) => {
		let newRegionValue = Object.assign({}, this.state.searchList,
			{ county: value }
		)
		if (value) {
			this.setState({
				searchList: newRegionValue,
				regionValue: value,
			})
		} else {
			// 清空按钮触发时
			let newRegionValue = Object.assign({}, this.state.searchList,
				{ County: '' }
			)
			this.setState({
				searchList: newRegionValue,
				regionValue: value,
			})
		}
	}
	// 用户类型
	userTypeChange = (value) => {
		console.log('用户类型', value);
		let changeUserType = Object.assign({}, this.state.searchList, {
			customerType: value
		})
		this.setState({
			searchList: changeUserType
		}, () => {
			console.log('更改的用户类型', this.state.searchList);
		})
	}
	// 报价状态
	quotedPriceTypeChange = (value) => {
		console.log('报价状态', value);
		let changeQuotedPriceType = Object.assign({}, this.state.searchList, {
			status: value
		})
		this.setState({
			searchList: changeQuotedPriceType
		})
	}
	// 过期状态
	pastDueTypeChange = (value) => {
		console.log('过期状态', value);
		let changePastDueType = Object.assign({}, this.state.searchList, {
			pastDueType: value
		})
		this.setState({
			searchList: changePastDueType
		})
	}
	// 获取点击行的索引
	clickRow(num) {
		console.log('当前', num);
		this.setState({
			activeIndex: num.key,// 获取点击行的索引
			enquiryoddNumber: num.InquiryVoucherCode
		}, () => {
			this.getEnquiryDetail(this.state.enquiryoddNumber);
			this.quotedPriceDetail(this.state.enquiryoddNumber)
		})
	}
	setClassName = (record, index) => {// record代表表格行的内容，index代表行索引
		// 判断索引相等时添加行的高亮样式
		return index === this.state.activeIndex ? 'l-table-row-show' : '';
	}
	render() {
		const { startValue, endValue, endOpen } = this.state;
		return (
			<div className="haveInquiry BusinessQuery_container">
				<div className="BusinessQuery">
					<div className='search_accessories'>
						<div className='search_content'>
							<div><span>询价单号</span><input type="text" ref='enquiryCode' /></div>
							<div><span>供应商</span><input type="text" ref='enquiryCompanyName' /></div>
							<div className='userType'>
								<span>用户类别</span>
								<Select defaultValue="全部" style={{ width: '250px' }} onChange={this.userTypeChange}>
									<Option value="0">全部</Option>
									<Option value="1">汽配商</Option>
									<Option value="2">汽修厂</Option>
									<Option value="3">个人</Option>
									<Option value="4">厂家</Option>
								</Select>
							</div>
							<div className='userType'>
								<span>报价状态</span>
								<Select defaultValue="全部" style={{ width: '250px' }} onChange={this.quotedPriceTypeChange}>
									<Option value="0">全部</Option>
									<Option value="1">未报价</Option>
									<Option value="2">已报价</Option>
								</Select>
							</div>
							<div>
								<span>区域</span>
								<Select
									// showSearch
									style={{ width: 76.5, marginTop: 10, marginRight: 10, textAlign: 'center' }}
									placeholder="省"
									optionFilterProp="children"
									value={this.state.provinceValue}
									onChange={this.selectProvince}
									showArrow={false}
									allowClear={true}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{
										Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
									}
								</Select>
								<Select
									// showSearch
									style={{ width: 76.5, marginTop: 10, marginRight: 10, textAlign: 'center' }}
									placeholder="市"
									optionFilterProp="children"
									value={this.state.cityValue}
									onChange={this.selectCity}
									allowClear={true}
									showArrow={false}
									allowClear={true}
									disabled={this.state.cityDisabled}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>{
										areaData.ProvinceCity[this.state.provinceValue] ?
											Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
												areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
													<Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
												))
											)) : []
									}
								</Select>
								<Select
									// showSearch
									style={{ width: 76.5, marginTop: 10, textAlign: 'center' }}
									placeholder="区"
									optionFilterProp="children"
									value={this.state.regionValue}
									onChange={this.selectRegion}
									showArrow={false}
									allowClear={true}
									disabled={this.state.regionDisabled}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>{

										areaData.ProvinceCity[this.state.provinceValue] ?
											Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
												areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
													item.Name == this.state.cityValue ?
														item.CityArea.map(items => (
															<Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
														)) : ''
												))
											)) : []
									}
								</Select>
							</div>
							<div className='userType'>
								<span>过期状态</span>
								<Select defaultValue="全部" style={{ width: '250px' }} onChange={this.pastDueTypeChange}>
									<Option value="0">全部</Option>
									<Option value="1">未过期</Option>
									<Option value="2">已过期</Option>
								</Select>
							</div>
							<div className='BusinessQueryData'>
								<span>询价时间</span>
								<ConfigProvider locale={zh_CN}>
									<DatePicker
										defaultValue={moment(getBeforeDate(7), dateFormat)}
										disabledDate={this.disabledStartDate}
										locale={zh_CN}
										format={dateFormat}
										//value={startValue}
										placeholder="开始时间"
										onChange={this.onStartChange}
										onOpenChange={this.handleStartOpenChange}
									/></ConfigProvider>
								<b className='separator'>至</b>
								<ConfigProvider locale={zh_CN}>
									<DatePicker
										defaultValue={moment(new Date(), dateFormat)}
										disabledDate={this.disabledEndDate}
										locale={zh_CN}
										format={dateFormat}
										//value={endValue}
										placeholder="结束时间"
										onChange={this.onEndChange}
										open={endOpen}
										onOpenChange={this.handleEndOpenChange}
									/>
								</ConfigProvider>
							</div>
						</div>
						<div className='functionButton' >
							<p><button className='search_btn' onClick={this.seek} >查询</button></p>
						</div>
					</div>
				</div>

				<div className='accessories_result'>
					<Table
						width='100%'
						rowKey={(record, index) => index}
						columns={this.state.columns}
						dataSource={this.state.resultList}
						bordered={true}
						pagination={false}
						scroll={{ y: 513 }}
						locale={this.state.locale}
						className='accessoriesTable'
						rowClassName={this.setClassName}
						onRow={(record) => {//表格行点击事件
							return {
								onClick: this.clickRow.bind(this, record)
							};
						}}
					/>
					<div className='list_info_pagination'>
						<div className="enquiryContainer">
							<p>合计:数目条数:{this.state.TotalCount}</p>
						</div>
						<div className="accessoriesPagination">
							<span className={`first_button ${this.state.TotalCount <= 10 ? 'ban' : ''}`} onClick={this.firstPage}>首页</span>
							<Pagination
								total={this.state.TotalCount || 10}
								current={this.state.Page}
								itemRender={itemRender}
								pageSize={10}
								onChange={this.changePage}
							/>
							<span className={`last_button ${this.state.TotalCount <= 10 ? 'ban' : ''}`} onClick={this.endPage}>末页</span>
						</div>
					</div>
				</div>
				<div className="detailContainer">
					<Tabs defaultActiveKey="2">
						<TabPane tab="询价明细" key="1">
							<InquiryDetail enquiryDetailList={this.state.enquiryDetailList}></InquiryDetail>
						</TabPane>
						<TabPane tab="报价明细" key="2">
							<QuotedPriceDetail quotedPriceDetailList={this.state.quotedPriceDetailList}></QuotedPriceDetail>
						</TabPane>
					</Tabs>
				</div>
				<Modal
					title="供应商名片"
					visible={this.state.visibleProvider}
					onCancel={this.handleCancel}
					footer={null}
					centered
					width={350}
					maskStyle={{ background: 'rgba(129,148,148,.5)' }}
				>
					<div className='adjust'><span>供应商名称:</span><span className='conceal' title={this.state.ModelList.CompanyName}>{this.state.ModelList.CompanyName}</span></div>
					<div className='adjust'><span>区域:</span><span className='conceal'>{this.state.ModelList.Area}</span></div>
					<div className='adjust'><span>地址:</span><span className='conceal' title={this.state.ModelList.Address}>{this.state.ModelList.Address}</span></div>
					<div className='adjust'><span>联系人:</span><span className='conceal'>{this.state.ModelList.Contactor}</span></div>
					<div className='adjust'><span>联系电话:</span><span className='conceal' title={this.state.ModelList.Mobile}>{this.state.ModelList.Mobile}</span></div>
					<div className='adjust'><span>QQ:</span><span className='conceal' title={this.state.ModelList.QQ}>{this.state.ModelList.QQ}</span></div>
					<div className='consult'>
						<img src={require('./images/QQ.png')} />
						{
							this.state.ModelList.QQ ?
								<a href={'tencent://message/?uin=' + this.state.ModelList.QQ} className='interview'>立即咨询</a> :
								<a href='javascript:;' className='interview' onClick={this.RemindUser}>立即咨询</a>
						}
					</div>
				</Modal>
			</div>
		)
	}
}
export default HaveInquiry
