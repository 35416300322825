import React, { Component } from 'react';
import Header from './component/Header/Header'
import Footer from './component/Footer/Footer'
import Banner from './component/Banner'
import BackEnd from '../../services/api'
import { List, Spin } from 'antd'
import Base64 from 'base-64';
import {
    encryptByDES,
    encryptPassword,
    encryptEDSPwd
  } from '../../utils/encrypt';
import './NewArrivals/NewArrivals.scss'
import './PartsShopping.scss'

class partsShopping extends Component {
    constructor(props) {
        super()
        this.state = {
            showLeftBox: false, // 是否显示车型列表
            boxTop: 0,
            carSeries: [], // 车系
            carType: [], //车型
            shopList: [], // 商品列表
            promoteShopList: [], // 促销商品
            searchValue: '', // 搜索内容
            carSeriesName: '', //车系
            brandName: '', // 车型品牌
            getNewListLoading: true,
            getPromoteShopListLoading: true
        }

    }
    componentDidMount() {
        this.getCarSeriesList();
        this.getNewList();
        this.getPromoteShopList();
    }
    // 左侧车系
    showList(e, carSeriesName, index) {
        let leftLi = document.querySelectorAll('.left-li');
        let boxTop = e.currentTarget.offsetTop;
        for (let i = 0; i < leftLi.length; i++) {
            leftLi[i].classList.remove('left-li-actived')

        }
        e.currentTarget.classList.add('left-li-actived');
        console.log(carSeriesName)
        let carList = this.state.carSeries[index].Brand.split(",")
        this.setState({
            showLeftBox: true,
            boxTop: boxTop,
            carType: carList.slice(0, carList.length - 1),
            carSeriesName: carSeriesName
        })
    }
    showLeftBox(e) {
        // console.log('移入')
        this.setState({
            showLeftBox: true
        })
    }
    hideLeftBox(e) {
        // console.log('移除')
        let leftLi = document.querySelectorAll('.left-li');
        for (let i = 0; i < leftLi.length; i++) {
            leftLi[i].classList.remove('left-li-actived')

        }
        this.setState({
            showLeftBox: false,
            carType: [],
            carSeriesName: ''
        })
    }
    // 车型
    carTypeClick(e, brandName, index) {
        // let emDom = document.querySelectorAll('.left-box em');

        // for (let i = 0; i < emDom.length; i++) {
        //     emDom[i].classList.remove('em-actived')

        // }
        // e.currentTarget.classList.add('em-actived');
        this.setState({
            brandName: brandName
        }, () => {
            // 搜索结果页
            this.props.history.push({ pathname: '/SearchResult', search: `?carSeriesName=${this.state.carSeriesName}&brandName=${this.state.brandName}` })
            // this.getNewList()
            // this.getPromoteShopList()
        })
    }

    // 查看配件详情
    goProductDetail = (item) => {
        console.log('配件详情数据', item);
        this.props.history.push({ pathname: '/CheapDetail', search: `?id=${item.ComponentId}&prevAddr=1` })
        // this.props.history.push('/productDetail')
    }
    // 查看更多新品上架
    tolookMoreNew() {
        this.props.history.push({ pathname: '/NewArrivals', search: `` })
    }
    // 查看更多促销商品
    tolookMoreCheap() {
        this.props.history.push({ pathname: '/Cheap', search: `` })
    }

    // 获取列表数据
    getCarSeriesList() {
        BackEnd
            .getData({
                Code: '27005'
            })
            .then(res => {
                if (res.data.Message.CarSeriesListGroup && res.data.Message.CarSeriesListGroup.length > 0) {
                    this.setState({
                        carSeries: res.data.Message.CarSeriesListGroup
                    })
                }
            })
    }
    // 获取新品上架
    getNewList() {
        this.setState({
            getNewListLoading: true,
            shopList: []
        }, () => {
            BackEnd
                .QueryShareAutoparts({ ciphertext: Base64.encode(encryptEDSPwd(JSON.stringify({
                    "carSeriesName": this.state.carSeriesName,
                    "brandName": "",
                    "authCode": 0,
                    "content": this.state.searchValue, // 搜索内容
                    "brand": this.state.brandName,
                    "orderByDescending": true,
                    "sort": "2",
                    "userId": localStorage.getItem('userId') || 0,
                    "promotionStatus": "2",
                    "currentPage": 1,
                    "pageSize": 5,
                    "isHomePage": true
                }), '66666cxd')) })
                .then(res => {

                    if (res.data.Code == 0) {
                        console.log('新品上架获取成功', res)
                        this.setState({
                            shopList: res.data.ResponseObj.Items,
                            getNewListLoading: false
                        })
                    }

                })
        })

    }
    getPromoteShopList() {
        this.setState({
            getPromoteShopListLoading: true,
            promoteShopList: []
        }, () => {
            BackEnd
                .QueryShareAutoparts( { ciphertext: Base64.encode(encryptEDSPwd(JSON.stringify({
                    "carSeriesName": this.state.carSeriesName,
                    "brandName": "",
                    "authCode": 0,
                    "content": this.state.searchValue, // 搜索内容
                    "brand": this.state.brandName,
                    "orderByDescending": true,
                    "sort": "2",
                    "userId": localStorage.getItem('userId') || 0,
                    "promotionStatus": "1",
                    "currentPage": 1,
                    "pageSize": 5,
                    "isHomePage": true
                }))) })
                .then(res => {

                    if (res.data.Code == 0) {
                        console.log('促销商品获取成功', res)
                        this.setState({
                            promoteShopList: res.data.ResponseObj.Items,
                            getPromoteShopListLoading: false
                        })
                    }

                })
        })

    }
    // 搜索获取数据
    searchData(content) {
        this.setState({
            searchValue: content
        }, () => {
            this.getNewList()
            this.getPromoteShopList()
        })
    }
    isShopList() {
        if (this.state.shopList.length) {
            return this.state.shopList.map(item => <div className="productMain" key={'shopList_' + item.Id}>
                <div className="imgBoxs">
                    <img onClick={this.goProductDetail.bind(this, item)} src={item.PicturePathArray != null ? item.PicturePathArray[0] : require("./imgage/prouctImg.png")} alt="" />
                </div>
                <p>{item.ComponentName}</p>
                <p onClick={this.goProductDetail.bind(this, item)}>{item.ComponentCode}</p>
                <p style={{ color: '#ff6b37' }}>￥{item.EnterpriseCategoryPrice}</p>

            </div>)
        } else {
            if (!this.state.getNewListLoading) {
                return <List className="goodsList-div" />
            }
        }
    }
    isPromoteShopList() {
        if (this.state.promoteShopList.length) {
            return this.state.promoteShopList.map(item => <div className="productMain" key={'promoteShopList_' + item.Id}>
                <div className="imgBoxs">
                    <img onClick={this.goProductDetail.bind(this, item)} src={item.PicturePathArray != null ? item.PicturePathArray[0] : require("./imgage/prouctImg.png")} alt="" />
                </div>
                <p>{item.ComponentName}</p>
                <p onClick={this.goProductDetail.bind(this, item)}>{item.ComponentCode}</p>
                <p style={{ color: '#ff6b37' }}>￥{item.EnterpriseCategoryPrice}</p>

            </div>)
        } else {
            if (!this.state.getPromoteShopListLoading) {
                return <List className="goodsList-div" />
            }
        }
    }
    render() {

        return (
            <div>
                <Header searchShop={this.searchData.bind(this)} />
                <div className="shopHome">

                    <div className="shopClassify">
                        <ul className="classify-left">
                            {
                                this.state.carSeries.map((item, index) => {
                                    return (
                                        <li className="left-li"
                                            key={index}
                                            onMouseOver={(e) => { this.showList(e, item.Content, index) }}
                                        >
                                            <span></span> {item.Content}

                                        </li>
                                    )
                                })

                            }

                            {
                                this.state.showLeftBox ? (<div className="left-box"
                                    style={{ top: this.state.boxTop }}
                                    onMouseEnter={(e) => { this.showLeftBox(e) }}
                                    onMouseLeave={(e) => { this.hideLeftBox(e) }}
                                >
                                    {
                                        this.state.carType.map((item, index, carType) => {
                                            return (<div key={index}
                                                
                                                >
                                                <em
                                                    onClick={(e) => { this.carTypeClick(e, item, index) }}
                                                >
                                                    {item}
                                                </em>
                                                {index === carType.length - 1 ? null : <span>|</span>}
                                            </div>)
                                        })
                                    }


                                </div>) : null
                            }
                        </ul>
                        <div className="classify-right">
                            <Banner />
                        </div>

                    </div>
                    <h1>
                        <span>新品上架</span>
                        <span onClick={this.tolookMoreNew.bind(this)}>更多</span>
                    </h1>
                    <div>
                        <div className="NewArrivals_list">

                            <Spin spinning={this.state.getNewListLoading} className="loading-spin" size="large" />

                            <div>
                                {this.isShopList()}
                            </div>

                        </div>
                    </div>
                    <h1>
                        <span>促销商品</span>
                        <span onClick={this.tolookMoreCheap.bind(this)}>更多</span>
                    </h1>
                    <div>
                        <div className="NewArrivals_list">

                            <Spin spinning={this.state.getPromoteShopListLoading} className="loading-spin" size="large" />
                            <div className="lists_box">
                                {this.isPromoteShopList()}
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        );
    }
}

export default partsShopping;
