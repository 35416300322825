import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import BusinessQuery from '../../BusinessQuery/BusinessQuery'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty, Input, Select, ConfigProvider, DatePicker, Button, Tooltip,Upload } from 'antd'
import './Businessmanagement.scss'
import { getCookie, deleteCookie } from '../../../utils/env'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import BackEnd from '../../../services/api'
import areaData from '../../../data.json'
import { formatDate } from '../../../utils/filter'
// const {  SearchOutlined  } = icons;
const { TabPane } = Tabs
const { confirm } = Modal
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const props = {
  name: 'file',
  action: 'https://shop.chexd.com/apis/upload/uploadFile',
  accept: '.xlsx',
  headers: {
    authorization: 'authorization-text',
  },
  showUploadList: false,
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status == 'done') {
      if (getCookie('LoginCacheKey')) {
        BackEnd.download({
          Message: {
            ExcelURL: info.file.response.datas.baseUrl + info.file.response.datas.filePath
          }
        }).then(res => {
          if (res.data.code == '0') {
            message.success(`${info.file.name} 导入成功`);
          } else if (res.data.code == '8001') {
            message.error('登录失效,请重新登录');
          } else {
            message.error(`${info.file.name} 导入失败`);
          }
        }).catch(erro => {
          deleteCookie('LoginCacheKey')
          message.error('导入异常!请重新登录')
        })
      } else {
        window.localStorage.removeItem('EnterpriseCategory')
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('userPhone')
        window.localStorage.removeItem('sessionId')
        deleteCookie('LoginCacheKey')
        message.error(`登录失效,请重新登录`);
        window.location.reload()
      }

    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 导入失败`);
    }
  },
};

class Businessmanagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
   
    }
  }

    // 下载数据模板
    downloadTemplate = () => {
      window.location.href = 'http://down.chexd.com/共享库存导入模板.xlsx'
    }
  
  render() {
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="BusinessmanagementBox">
          <p className='title'>商机管理</p>
          <div className="Businessmanagementcenter">
            <div className="functionButton">
          {window.localStorage.getItem('EnterpriseCategory') && window.localStorage.getItem('EnterpriseCategory') !== '3' && getCookie('LoginCacheKey') ? <div className='templateFunction'>
                
                <span className='downloadTemplate' onClick={this.downloadTemplate}>下载模板</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Upload {...props} className='toLead'>
                  <Button>
                    导入</Button>
                </Upload><br />
                <i className='tipTitle'>数据导入EXCEL模板</i>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className='tipTitle'>导入EXCEL数据</i>
              </div> : ''}
              </div>
            <BusinessQuery></BusinessQuery>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Businessmanagement)
