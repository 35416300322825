import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import {
  Tabs, Table, Modal, message, Checkbox, Empty, Form, Input,
  Cascader,
  Row,
  Col,
  Button,

} from 'antd'
import {
  encryptByDES,
  encryptPassword,
  encryptEDSPwds
} from "../../../utils/encrypt";
// import areaJson from './area.json'
import './Securityset.scss'
import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal




class Securityset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isClick: false, // 验证码按钮禁用
      codeContent: '获取验证码', // 验证码按钮文案
      personagePhoneChange: '',// 个人注册手机号
      personageCaptchaChange: '', // 个人注册验证码
      personagePasswordChange: '', // 个人注册密码
      personagePassword: '' // 重复密码

    }
  }

  // 监听手机号
  personagePhoneChange = (e) => {
    this.setState({
      personagePhoneChange: e.target.value
    })
  }
  // // 监听密码
  // personagePasswordChange = (e) => {
  //   this.setState({
  //     personagePasswordChange: e.target.value
  //   })
  // }
  // 监听验证码
  phoneCaptchaChange = (e) => {
    this.setState({
      personageCaptchaChange: e.target.value
    })
  }
  // 获取验证码
  getCode = () => {
    // 重新发送动画
    if (this.state.personagePhoneChange === "") {
      message.error('手机号不能为空')
      return;
    }
    let time = 60;
    this.state.isClick = !this.state.isClick;
    var timer = setInterval(() => {
      time--;
      this.setState({
        codeContent: `重新发送(${time})`
      })
      if (time <= 0) {
        clearInterval(timer);
        time = 5;
        this.setState({
          codeContent: `获取验证码`,
          isClick: !this.state.isClick
        })
      }
    }, 1000);
    let encrypt = {
      Code: "00021003",
      Mobile: this.state.personagePhoneChange,
      TimeSptams: String(Date.parse(new Date())),
      SendMsgType: "5",
      Tokens: ""
    };
    let msg = `${encrypt.Mobile}${encrypt.SendMsgType}${encrypt.TimeSptams}`;

    encrypt.Tokens = encryptByDES(msg, 'jinmusen7931BEIJING');

    BackEnd.getData(encrypt)
      .then(res => {
        console.log(res)
        if (res.data.Message.ErrorMessage) {
          message.error(res.data.Message.ErrorMessage.ErrMsg)
          return;
        }
        window.sessionStorage.setItem('personageTimeSptams', encrypt.TimeSptams)
        message.success('短信发送成功!')
      })
      .catch(err => {
        message.error('短信发送失败!')
      });
  };
  // 格式数据
  initData() {
    let initData = {
      Code: 21005,
      Mobile: this.state.personagePhoneChange,
      MsgCodes: this.state.personageCaptchaChange,
      TimeSptams: window.sessionStorage.getItem('personageTimeSptams'),
      SendMsgType: "5"
    }

    let key = `${initData.Mobile}${initData.MsgCodes}${initData.TimeSptams}`;

    initData = Object.assign(
      {},
      initData,
      {
        Tokens: encryptByDES(key, 'jinmusen7931BEIJING')
      }
    )
    return initData
  }
  handlePersonageSubmit = (e) => {

    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        BackEnd.getData(this.initData()).then(res => {
          if (res.data.Message.ErrorMessage) {
            message.error('验证码不正确!');
            return;
          }
          let newInitData = values.personagePhone.substring(values.personagePhone.length - 4) + "00kt";
          let newLoginPwd = encryptPassword(values.personagePassword);

          console.log(newLoginPwd)

          BackEnd.changePassword({

            newPassWord: encryptEDSPwds(newLoginPwd, newInitData),
            secondPassWord: encryptEDSPwds(newLoginPwd, newInitData),
            mobile: this.state.personagePhoneChange,

          }).then(res => {
            console.log(res)
            if (res.data.message == "Success") {
              message.success('修改密码成功!');

            } else {
              message.error('修改密码失败，请重试');

            }
            // window.history.back()
          })
        })
      }
    });
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  checkPsd(rule, value, callback) {
    let password2 = this.props.form.getFieldValue('personagePassword');
    if (password2 && password2 !== value) {
      callback(new Error('两次密码输入不一致'));
    } else {
      callback();
    }
  }

  checkPsd2(rule, value, callback) {
    let password = this.props.form.getFieldValue('personagePasswordChange');
    if (password && password !== value) {
      callback(new Error('两次密码输入不一致'));
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    return (
      <div className="">
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="SecuritysetBox">
          <p className='title'>安全设置</p>
          <div className="Securityset">
            <div className='SecuritysetFrom'>
              <Form {...formItemLayout} onSubmit={this.handlePersonageSubmit}>
                <Form.Item label='新的登录密码：'>
                  {getFieldDecorator('personagePasswordChange', {
                    rules: [
                      { required: true, message: '请输入密码' },
                      { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } },
                      {
                        pattern: "^(?![A-Za-z]+$)(?!\\d+$)(?![\\W_]+$)\\S{6,12}$",
                        message: '密码长度不正确 / 密码必须包含数字、字母、特殊字符两种以上类型 /'
                      }
                    ],
                    validateTrigger: 'onBlur',

                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item label='重复登录密码：'>
                  {getFieldDecorator('personagePassword', {
                    rules: [
                      { required: true, message: '请输入密码' },
                      { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }
                    ],
                    validateTrigger: 'onBlur'
                  })(
                    <Input />
                  )}
                </Form.Item>
                <Form.Item label="密保手机号码">
                  {getFieldDecorator('personagePhone', {
                    rules: [
                      {
                        required: true,
                        message: '请输入手机号',
                      }, {
                        pattern:/^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/,
                        message: '手机号输入不正确'
                      }
                    ],
                  })(<Input maxLength={11} onChange={this.personagePhoneChange} placeholder="请输入手机号" autoComplete='new-password' />)}
                </Form.Item>
                <Form.Item label="验&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码">
                  <Row gutter={8}>
                    <Col span={12} className="verificationCode">
                      {getFieldDecorator('phoneCaptcha', {
                        rules: [{ required: true, message: '请输入验证码' }],
                      })(<Input maxLength={6} onChange={this.phoneCaptchaChange} placeholder='请输入验证码' />)}
                    </Col>
                    <Button onClick={this.getCode} disabled={this.state.isClick}>{this.state.codeContent}</Button>
                    {/* <Col>
                      <Button onClick={this.getCode} disabled={this.state.isClick}>{this.state.codeContent}</Button>
                    </Col> */}
                  </Row>
                </Form.Item>
                <Form.Item {...tailFormItemLayout} className='submitBut'>
                  <Button type="primary" htmlType="submit" className='registerButton'>确定修改密码</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
export default Form.create({})(Securityset)
