import React, { PureComponent } from 'react'
import {
	Table,
	message,
	Modal
} from 'antd'
class QuotedPriceDetail extends PureComponent {
	constructor() {
		super()
		this.state = {
			activeIndex: '',
			visibleFitting: false, // 配件查询弹框
			resultList: [], // 查询结果列表
			ModelList: [], // 弹框数据列表
			locale: {
				emptyText: <img src={(require('../images/noData.png'))}></img>
			},
			columns: [{
				title: '序号',
				dataIndex: 'QQ',
				key: 'QQ',
				width: 80,
				render: (text, record, index) => (
					<span>{index}</span>
				)
			}, {
				title: '报价单号',
				dataIndex: 'VoucherCode',
				key: 'VoucherCode',
				width: 240,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>{text}</span>
				)
			}, {
				title: '报价编码',
				dataIndex: 'ComponentCode',
				key: 'ComponentCode',
				width: 150,
				ellipsis: true,
				render: (text, record) => (
					<div title={text} className='storer' onClick={this.FittingsModel.bind(this, record)}>
						{text}
					</div>
				)
			}, {
				title: '报价名称',
				dataIndex: 'ComponentName',
				key: 'quotedPriceName',
				width: 120,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '报价数量',
				dataIndex: 'Quantity',
				key: 'Quantity',
				width: 140,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<div title={text} className='omit' style={{ width: '110px' }}>
						{text}
					</div>
				)
			}, {
				title: '单位',
				dataIndex: 'Unit',
				key: 'Unit',
				width: 80,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '原厂件价',
				dataIndex: 'OriPrice',
				key: 'OriPrice',
				width: 100,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '品牌件价',
				dataIndex: 'BrandPrice',
				key: 'BrandPrice',
				width: 100,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '折车件价',
				dataIndex: 'ReforgePrice',
				key: 'ReforgePrice',
				width: 100,
				ellipsis: true,
				render: (text, record) => (
					<div title={text}>
						{text}
					</div>
				)
			}, {
				title: '其他件价',
				dataIndex: 'OtherPrice',
				key: 'OtherPrice',
				width: 100,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '询价名称',
				dataIndex: 'ComponentName',
				key: 'EnquiryName',
				width: 140,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '报价备注',
				dataIndex: 'Memo',
				key: 'Memo',
				width: 100,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}]
		}
	}
	// 配件编码弹框
	FittingsModel(record) {
		console.log('弹框', record)
		this.setState({
			ModelList: record,
			visibleFitting: true,
			activeIndex: ''
		})
	}
	// 取消弹框
	handleCancel = () => {
		this.setState({
			visibleFitting: false,
			visibleProvider: false,
			visibleEnquiry: false,
			activeIndex: ''
		});
	}
	RemindUser = () => {
		message.error('暂无QQ信息!')
	}
	// 获取点击行的索引
	clickRow(num) {
		this.setState({
			activeIndex: (num.key)// 获取点击行的索引
		})
	}
	setClassName = (record, index) => {// record代表表格行的内容，index代表行索引
		// 判断索引相等时添加行的高亮样式
		return index === this.state.activeIndex ? 'l-table-row-show' : '';
	}
	render() {
		return (
			<div className="inquiryDetail">
				<div className='accessories_result'>
					<p>最晚到货时间:&nbsp;&nbsp;{this.props.quotedPriceDetailList.length > 0 ? this.props.quotedPriceDetailList[0].ArrivalTime : ""}</p>
					<Table
						width='100%'
						rowKey={(record, index) => index}
						columns={this.state.columns}
						dataSource={this.props.quotedPriceDetailList}
						bordered={true}
						pagination={false}
						// scroll={{ y: 513 }}
						locale={this.state.locale}
						className='accessoriesTable'
						rowClassName={this.setClassName}
						onRow={(record) => {//表格行点击事件
							return {
								onClick: this.clickRow.bind(this, record)
							};
						}}
					/>
					<div className='list_info_pagination'>
						<div className="enquiryContainer">
							<p>合计:数目条数: {this.props.quotedPriceDetailList.length}</p>
						</div>
						{/* 保留方便后期更改 */}
						<div className="accessoriesPagination"></div>
					</div>
				</div>
				<Modal
					title="配件查询"
					visible={this.state.visibleFitting}
					onCancel={this.handleCancel}
					footer={null}
					centered
					style={{ margin: 'auto' }}
					width={300}
					maskStyle={{ background: 'rgba(129,148,148,.5)' }}
				>
					<div className='adjust'><span>配件编码:</span><span className='conceal' title={this.state.ModelList.ComponentCode}>{this.state.ModelList.ComponentCode}</span></div>
					<div className='adjust'><span>配件名称:</span><span className='conceal' title={this.state.ModelList.ComponentName}>{this.state.ModelList.ComponentName}</span></div>
					<div className='adjust'><span>品牌:</span><span className='conceal' title={this.state.ModelList.Brand}>{this.state.ModelList.Brand}</span></div>
					<div className='adjust'><span>产地:</span><span className='conceal' title={this.state.ModelList.Origin}>{this.state.ModelList.Origin}</span></div>
					<div className='adjust'><span>数量:</span><span className='conceal'>{this.state.ModelList.Quantity}</span></div>
					<div className='adjust'><span>单位:</span><span className='conceal'>{this.state.ModelList.Unit}</span></div>
					<div className='consult'>
						<img src={require('../images/QQ.png')} />
						{
							this.state.ModelList.QQ ?
								<a href={'tencent://message/?uin=' + this.state.ModelList.QQ} className='interview'>立即咨询</a> :
								<a href='javascript:;' className='interview' onClick={this.RemindUser}>立即咨询</a>
						}
					</div>
				</Modal>
			</div>
		)
	}
}
export default QuotedPriceDetail
