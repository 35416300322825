import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty, Input, Button, DatePicker, ConfigProvider, Pagination, InputNumber } from 'antd'
import QRCode from 'qrcode.react';
import './Mymoney.scss'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import axios from 'axios'
import { formatDate } from '../../../utils/filter'
import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal
const dateFormat = 'YYYY-MM-DD';



class Mymoney extends Component {
  constructor() {
    super()
    this.state = {
      availablebalance: 0,
      cashbalance: 0,
      Presentedbalance: 0,
      cashbalanceText: 0,
      PresentedbalanceText: 0,
      endOpen: false,
      CountPerPage: 1,// 每页数量
      page: 0,
      totalCount: 0, // 数据总条数
      currentPage: 1,
      pageSize: 5,
      current: 1,
      tooltip: false,
      rechargerecordStatus: true,
      RechargePackagelist: [],
      productId: "",
      PayId: "",
      CodeImg: '',
      BeginTime: '',
      EndTime: '',
      rechargerecordcolumns: [{
        title: "交易流水",
        dataIndex: 'voucherCode',
        key: 'voucherCode',
        // width: 120,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '充值渠道',
        dataIndex: 'rechargeChannel',
        key: 'rechargeChannel',
        // width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text == 1 ? "微信" : "支付宝"}</span>
        )
      }, {
        title: '充值金额',
        dataIndex: 'originalPrice',
        key: 'originalPrice',
        // width: 80,
        ellipsis: true,
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '赠送金额',
        dataIndex: 'givenPrice',
        key: 'givenPrice',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, , {
        title: '实付金额',
        dataIndex: 'paymentPrice',
        key: 'paymentPrice',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '充值时间',
        dataIndex: 'addTime',
        key: 'addTime',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</div>
        )
      }],
      columns: [{
        title: "交易单号",
        dataIndex: 'voucherCode',
        key: 'voucherCode',
        // width: 120,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '消费类型',
        dataIndex: 'serviceName',
        key: 'serviceName',
        // width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '余额扣减',
        dataIndex: 'paymentPrice',
        key: 'paymentPrice',
        // width: 80,
        ellipsis: true,
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '消费备注',
        dataIndex: 'consumptionRemarks',
        key: 'consumptionRemarks',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '充值时间',
        dataIndex: 'addTime',
        key: 'addTime',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</div>
        )
      }],
      dataSource: []
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    this.getavailablebalance()
    this.getrechargerecord()
    this.GetRechargePackage()
  }
  // 日期开始时间范围
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  // 日期结束时间范围
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    if (field === 'startValue') {
      let newDate = Object.assign({}, this.state.filterOption, { BeginTime: value ? formatDate(value._d, 'yyyymmdd') + '000000' : 0 })
      console.log(newDate)
      this.setState({
        [field]: value,
        filterOption: newDate
      });
    } else if (field === 'endValue') {
      let newDate = Object.assign({}, this.state.filterOption, { EndTime: value ? formatDate(value._d, 'yyyymmdd') + '235960' : 0 })
      this.setState({
        [field]: value,
        filterOption: newDate
      });
    }
  };

  onStartChange = (value, dateString) => {
    this.setState({
      BeginTime: formatDate(dateString, 'yyyymmdd') + '000000'
    })
    this.onChange('startValue', value);
  };

  onEndChange = (value, dateString) => {
    this.setState({
      EndTime: formatDate(dateString, 'yyyymmdd') + '235959'
    })
    this.onChange('endValue', value);
  };
  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  // 点击套餐
  setmoney = (cashbalanceText, PresentedbalanceText, productId) => {
    this.setState({
      cashbalanceText: cashbalanceText,
      PresentedbalanceText: PresentedbalanceText,
      productId: productId
    });
  }
  // 获取用户金额
  getavailablebalance() {
    BackEnd.orderDetails("/api/PayCenter/GetAccountBalance", {
    }).then(res => {
      if (res.data.message != "Success") {
        message.error("登录后才可以查看余额")
        this.props.history.push("/login")
        return
      }
      else {
        this.setState({
          availablebalance: res.data.responseObj.balance + res.data.responseObj.givenBalance,
          cashbalance: res.data.responseObj.balance,
          Presentedbalance: res.data.responseObj.givenBalance
        })
      }
    })
  }
  // 获取充值记录
  getrechargerecord() {
    BackEnd.orderDetails("/api/UserCenter/GetRechargeRecord", {
      imId: 250,
      currentPage: this.state.currentPage,
      pageSize: this.state.pageSize,
      beginTime: this.state.BeginTime == "NaNNaNNaN000000" ? "" : this.state.BeginTime,
      endTime: this.state.EndTime == "NaNNaNNaN000000" ? "" : this.state.EndTime,
    }).then(res => {
      if (res.data.message == "Success") {
        this.setState({
          totalCount: res.data.responseObj.totalNum,
          dataSource: res.data.responseObj.items
        })
      }
    })
  }
  // 获取消费记录
  getconsumerecord() {
    BackEnd.orderDetails("/api/UserCenter/GetConsumeRecord", {
      imId: 250,
      currentPage: this.state.currentPage,
      pageSize: this.state.pageSize,
      beginTime: this.state.BeginTime == "NaNNaNNaN000000" ? "" : this.state.BeginTime,
      endTime: this.state.EndTime == "NaNNaNNaN000000" ? "" : this.state.EndTime,
    }).then(res => {
      if (res.data.message == "Success") {
        this.setState({
          dataSource: res.data.responseObj.items,
          totalCount: res.data.responseObj.totalNum,
        })
      }
    })
  }
  // 充值套餐查询
  GetRechargePackage() {
    BackEnd.orderDetails("/api/PayCenter/GetRechargePackage", {
    }).then(res => {
      console.log(res)
      if (res.data.Message == "Success") {
        this.setState({
          RechargePackagelist: res.data.ResponseObj
        }, () => {
        })
      }
    })
  }
  // 分页改变
  onChangePage = page => {
    this.setState({
      currentPage: page,
    }, () => {
      if (this.state.rechargerecordStatus == true) {
        this.getrechargerecord()
      } else {
        this.getconsumerecord()
      }
    });
  };
  // 充值记录，消费记录切换
  setStatus = () => {
    this.setState({
      rechargerecordStatus: this.state.rechargerecordStatus ? false : true,
      currentPage: 1
    })
    if (this.state.rechargerecordStatus == false) {
      this.getrechargerecord()
    } else {
      this.getconsumerecord()
    }
  }
  // 赠送金额
  setGiveprice = (cashbalanceText) => {
    this.setState({
      cashbalanceText: cashbalanceText
    })
    var PresentedbalanceText = 0
    if (cashbalanceText < 300) {
      PresentedbalanceText = 0
    } else if (300 <= cashbalanceText && cashbalanceText < 500) {
      PresentedbalanceText = 20
    } else if (500 <= cashbalanceText && cashbalanceText < 1000) {
      PresentedbalanceText = 75
    } else if (1000 <= cashbalanceText && cashbalanceText < 3000) {
      PresentedbalanceText = 200
    } else if (3000 <= cashbalanceText && cashbalanceText < 5000) {
      PresentedbalanceText = 750
    } else if (5000 <= cashbalanceText && cashbalanceText < 10000) {
      PresentedbalanceText = 1750
    } else if (cashbalanceText > 10000) {
      PresentedbalanceText = 5000
    }
    this.setState({
      PresentedbalanceText: PresentedbalanceText,
    })
  }
  // 充值函数
  recharge = () => {
    let params
    if (this.state.productId == "") {
      params = {
        productId: "",
        tatal_fee: this.state.cashbalanceText,
        givenPrice: this.state.PresentedbalanceText,
      }
    } else {
      params = {
        productId: this.state.productId + "",
        // tatal_fee: this.state.cashbalanceText,
        // givenPrice: this.state.PresentedbalanceText,
      }
    }
    BackEnd.orderDetails("/api/PayCenter/GetPayUrl", params).then(res => {
      if (res.data.code == "1") {
        message.error('服务器错误')
      } else {
        if (res.data.message == "Success") {
          this.setState({
            PayId: res.data.responseObj.PayId,
            CodeImg: res.data.responseObj.url,
            tooltip: true
          })
        }
      }
    })

  }

  tubeHandletooltip = () => {
    message.success("充值有轻微延迟，预计10s后到账，您可稍后刷新查看！");
    this.setState({
      tooltip: false
    })
    this.timerID = setInterval(
      () => {
        this.getOrderStatus()
      },
      3000
    );
  }
  // 获取订单状态
  getOrderStatus() {
    BackEnd.companySquare(' /api/PayCenter/GetPayStaus', { Id: this.state.PayId }).then(res => {
      console.log(res)
      if (res.data.Code == '0' && res.data.ResponseObj == "1") {
        this.GetRepaidBalanceVoucher();
        this.getAccountBalance() // 获取充值记录
        clearInterval(this.timerID);
      } else {
      }

    })
  }
  // 日期查询订单记录
  datefind = () => {
    if (this.state.rechargerecordStatus == true) {
      this.getrechargerecord()
    } else {
      this.getconsumerecord()
    }
  }
  render() {
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="MymoneyBox">
          <p className='title'>我的钱包</p>
          <div className="Mymoney">
            <p style={{ color: "red" }}>（注意：余额仅可用于购买车企典平台提供的软件服务，暂不支持企业商城配件交易）</p>
            <div>
              <p className="balance">可用余额：{this.state.availablebalance}元&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="small">现金余额：{this.state.cashbalance}元</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="small">赠送余额：{this.state.Presentedbalance}元</span></p>
            </div>
            <div>
              {
                this.state.RechargePackagelist.map(item => {
                  return (<li className="rechargeamount" onClick={this.setmoney.bind(this, item.Price, item.GivenPrice, item.ID)}>{item.Name}</li>)
                })
              }
              <div style={{ clear: "both" }}></div>
            </div>
            <br />
            <p>充值金额：<InputNumber style={{ width: "20%" }} placeholder="0" value={this.state.cashbalanceText} onChange={this.setGiveprice.bind(this.state.cashbalanceText)} />&nbsp;&nbsp;&nbsp;&nbsp;元</p>
            <p>赠送金额：<InputNumber style={{ width: "20%" }} placeholder="0" disabled value={this.state.PresentedbalanceText} />&nbsp;&nbsp;&nbsp;&nbsp;元</p>
            <p><Button style={{ width: "28%" }} onClick={this.recharge}>立即充值</Button></p>
            <Modal
              title="微信支付"
              closeIcon="关闭"
              visible={this.state.tooltip}
              onCancel={this.tubeHandletooltip}
              footer={null}
              centered
              style={{ margin: 'auto' }}
              wrapClassName="placeTubeModel"
              width={400}
            >
              <div className="tubePlacetoolti" style={{ height: '350px', width: "200px" }}>
                <p style={{ textAlign: 'center', marginTop: '20px', color: "#FF6B37" }}>付款金额{this.state.priceNnm}元</p>
                <QRCode className="QRCode-qrCode" value={this.state.CodeImg} size={200} />
                <p style={{ textAlign: 'center' }}>微信支付完成后，请关闭</p>
              </div>
            </Modal>
            <div>
              <p><Button className={this.state.rechargerecordStatus ? 'gray' : ''} style={{ width: "10%" }} onClick={this.setStatus}>充值记录</Button><Button className={this.state.rechargerecordStatus ? '' : 'gray'} style={{ width: "10%" }} onClick={this.setStatus}>消费记录</Button></p>
              <p className="orderTime secondTr">开始日期：
                  <ConfigProvider locale={zh_CN}>
                  <DatePicker
                    defaultValue={""}
                    disabledDate={this.disabledStartDate}
                    locale={zh_CN}
                    format={dateFormat}
                    //value={startValue}
                    placeholder="开始时间"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                  /></ConfigProvider>
                                &nbsp;&nbsp;&nbsp;结束日期：
                                <ConfigProvider locale={zh_CN}>
                  <DatePicker
                    defaultValue={""}
                    disabledDate={this.disabledEndDate}
                    locale={zh_CN}
                    format={dateFormat}
                    //value={endValue}
                    placeholder="结束时间"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                  />
                </ConfigProvider>
                                &nbsp;&nbsp;&nbsp;<Button style={{ width: "15%" }, { background: "#f2f2f2" }} onClick={this.datefind}>检索</Button>
              </p>
              <Table bordered dataSource={this.state.dataSource} columns={this.state.rechargerecordStatus ? this.state.rechargerecordcolumns : this.state.columns} pagination={false} /><br />
              <div className="totleP">
                <Pagination
                  total={this.state.totalCount}
                  onChange={this.onChangePage}
                  pageSize={this.state.pageSize}
                  current={this.state.currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Mymoney)
