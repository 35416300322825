import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import  './Broadside.scss'



class Broadside extends Component {
    constructor() {
        super();
        this.state = {
            now:0,
            idx:1,
            index:2,
            arr:[
                {'id':1,'title':'订单管理(买家)'}
                ,
                {'id':2,'title':'订单管理(卖家)'}
            ]
        }
    }
    render() {
        return (
            <div>
                 <div className="Broadside">
                        <div className="title">
                           <span>订单管理</span>
                        </div>
                        11
                        {/* <p 
                            className={this.state.now== 1 ? 'cur' : '' }
                        onClick={()=>{ 
                            this.setState({
                                now:this.state.idx
                            },()=>{
                                this.props.history.push('/buyerOrder')

                            })
                        }}>
                            <span >订单管理(买家)</span>
                        </p>
                        <p 
                         className={this.state.now== 2? 'cur' : ''}
                            onClick={()=>{
                                this.setState({
                                    now:this.state.index
                                },()=>{
                                    this.props.history.push('/SellerOrder')

                                })
                        }}>
                            <span>订单管理(卖家)</span> 
                        </p> */}
                         {/* {
                             this.state.arr.map((item)=>
                         <p 
                         key={item.id}
                         onClick={()=>{
                             this.setState({
                                 now:item.id
                                },()=>{
                                    // this.state.now == 1 ? this.props.history.push('/buyerOrder') : this.props.history.push('/SellerOrder') 
                                    // console.log(this.state.now,this.state.arr)
                                    if(this.state.now == 1){
                                        this.props.history.push('/buyerOrder')
                                    }else if(this.state.now == 2){
                                        this.props.history.push('/SellerOrder') 
                                    }
                                    console.log(item.id,this.state.now)
                                })  
                            }}
                            className={this.state.now == item.id ? 'content-p cur' : 'content-p'  }

                        >{item.title}</p> )
                         } */}
                    </div>
            </div>
        )
    }
}
export default withRouter(Broadside)

