import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import Management from '../../OrderManagement/SellerOrder/management'

import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty, Input, Select, ConfigProvider, DatePicker, Button, Tooltip } from 'antd'
import './Orderseller.scss'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import BackEnd from '../../../services/api'
import areaData from '../../../data.json'
import { formatDate } from '../../../utils/filter'
// const {  SearchOutlined  } = icons;
const { TabPane } = Tabs
const { confirm } = Modal
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';





class ShoppingCart extends Component {
  constructor() {
    super()
    this.state = {
      endOpen: false,
      columns: [{
        title: "订单号",
        dataIndex: 'name',
        key: 'name',
        // width: 120,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '下单时间',
        dataIndex: 'VIN',
        key: 'VIN',
        // width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '卖家名称',
        dataIndex: 'brand',
        key: 'brand',
        // width: 80,
        ellipsis: true,
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '卖家地区',
        dataIndex: 'enginenumber',
        key: 'enginenumber',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, , {
        title: '订单金额',
        dataIndex: 'type',
        key: 'type',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '状态',
        dataIndex: 'yearmoney',
        key: 'yearmoney',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '操作',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div className="operation">
            <a className="delete" >详情</a>&nbsp;&nbsp;
          </div>
        )
      }],
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {

    // this.shoppinglist()
  }
  // 日期开始时间范围
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  // 日期结束时间范围
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    if (field === 'startValue') {
      let newDate = Object.assign({}, this.state.filterOption, { BeginTime: value ? formatDate(value._d, 'yyyymmdd') + '000000' : 0 })
      this.setState({
        [field]: value,
        filterOption: newDate
      });
    } else if (field === 'endValue') {
      let newDate = Object.assign({}, this.state.filterOption, { EndTime: value ? formatDate(value._d, 'yyyymmdd') + '235960' : 0 })
      this.setState({
        [field]: value,
        filterOption: newDate
      });
    }
  };

  onStartChange = value => {
    this.onChange('startValue', value);
  };

  onEndChange = value => {
    this.onChange('endValue', value);
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  render() {
    const dataSource = [
      {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      },
      {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      }, {
        key: '1',
        name: '京F9678',
        VIN: "12345678",
        brand: "宝马",
        enginenumber: "12311111",
        type: "跑车",
        yearmoney: 32,
        typenum: '3657',
      },
    ];
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="Ordersellerbox">
          <p className='title'>卖家订单</p>
          <div className="Orderseller">
          <Management></Management>
          </div>
        </div>

      </div>
    )
  }
}
export default withRouter(ShoppingCart)
