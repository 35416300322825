import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Select, Empty } from 'antd'
import './Selfpickupsite.scss'
import BackEnd from '../../../services/api'
import areaData from '../../../data.json'
const { Option } = Select
const { TabPane } = Tabs
const { confirm } = Modal




class Selfpickupsite extends Component {
  constructor() {
    super()
    this.state = {
      columns: [{
        title: "联系人姓名",
        dataIndex: 'consignee',
        key: 'consignee',
        width: 120,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '联系电话',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '省',
        dataIndex: 'province',
        key: 'province',
        width: 100,
        ellipsis: true,
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '市',
        dataIndex: 'city',
        key: 'city',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, , {
        title: '区(县)',
        dataIndex: 'county',
        key: 'county',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '详细地址',
        dataIndex: 'address',
        key: 'address',
        // width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '操作',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div className="operation">
            <button className="delete" onClick={this.increasePlace.bind(this, 1, record)}>编辑</button>&nbsp;&nbsp;
            <button className="delete" onClick={this.deleted.bind(this, record)}>删除</button>&nbsp;&nbsp;
            {/* <button className="delete" >查看适用配件</button> */}
          </div>

        )
      }],
      visibleEnquiry: false,
      addressList: [],
      visibleIncrease: false,
      increaseList: {
        id: 0,
        userId: Number(window.localStorage.getItem('userId')),
        consignee: '',
        mobile: '',
        province: '',
        city: '',
        county: '',
        address: '',
        isDefault: true,
        updateTime: String(Date.parse(new Date())),
        addressType: 0
      },
      provinceValue: undefined,// 省级查询值
      cityValue: undefined, // 市级查询值
      regionValue: undefined, // 区级查询值
      cityDisabled: true, // 区域市级按钮
      regionDisabled: true, // 区域区级按钮
      placeType: 0, // 修改 || 编辑
      editPlace: {}, // 编辑地址数据


    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {

    this.addAddressList()
  }
  // 关闭新增地址弹框
  handleCancel = () => {
    this.setState({
      visibleIncrease: false
    })
  }
  // 省级下拉选项
  selectProvince = (value) => {
    let newProvinceValue = Object.assign({}, this.state.increaseList,
      { province: value },
      { city: '' },
      { county: '' }
    )
    this.setState({
      increaseList: newProvinceValue,
      provinceValue: value,
      cityValue: undefined,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        cityDisabled: false,
        regionDisabled: true // 禁用区级选项
      })
    } else {
      // 清空按钮触发时
      let newProvinceValue = Object.assign({}, this.state.increaseList,
        { province: '' },
        { city: '' },
        { county: '' }
      )
      this.setState({
        increaseList: newProvinceValue,
        cityDisabled: true,
        regionDisabled: true,
        provinceValue: value,
      })
    }
  }
  // 市级下拉选项
  selectCity = (value) => {
    let newCityValue = Object.assign({}, this.state.increaseList,
      { city: value },
      { county: '' }
    )
    this.setState({
      increaseList: newCityValue,
      cityValue: value,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        regionDisabled: false
      })
    } else {
      // 清空按钮触发时
      let newCityValue = Object.assign({}, this.state.increaseList,
        { city: '' },
        { county: '' }
      )
      this.setState({
        increaseList: newCityValue,
        regionDisabled: true,
        regionValue: value
      })
    }
  }
  // 区级下拉选项
  selectRegion = (value) => {
    let newRegionValue = Object.assign({}, this.state.increaseList,
      { county: value }
    )
    if (value) {
      this.setState({
        increaseList: newRegionValue,
        regionValue: value,
      })
    } else {
      // 清空按钮触发时
      let newRegionValue = Object.assign({}, this.state.increaseList,
        { county: '' }
      )
      this.setState({
        increaseList: newRegionValue,
        regionValue: value,
      })
    }
  }
  //自提点列表
  addAddressList() {
    BackEnd.ZTaddressList(window.localStorage.getItem('userId')).then(res => {
      console.log(res.data.message)
      if (res.data.message == "Success") {
        this.setState({
          addressList: res.data.responseObj
        })
      }


      // if (res.data.Result.Message == "Success") {
      //   this.setState({
      //     addressList: res.data.Result.ResponseObj
      //   })
      // }

    })
  }
  //添加自提点地址
  increasePlace = (type, record) => {

    console.log('编辑', type, record)
    this.setState({
      visibleIncrease: true
    }, () => {
      if (type) {
        this.setState({
          placeType: 1,
          editPlace: record
        }, () => {
          console.log('暂存编辑数据', this.state.editPlace)
          this.refs.Consignee.value = this.state.editPlace.consignee
          this.refs.phonePlace.value = this.state.editPlace.mobile
          this.refs.detailPlace.value = this.state.editPlace.address
          let data = this.state.increaseList
          data.isDefault = this.state.editPlace.IsDefault
          data.id = this.state.editPlace.id
          this.setState({
            provinceValue: this.state.editPlace.province,
            cityValue: this.state.editPlace.city,
            regionValue: this.state.editPlace.county,
            increaseList: data
          }, () => {
            console.log('更改状态', this.state.increaseList)
          })
        })
      } else {
        this.setState({
          placeType: 0,
        }, () => {
          this.refs.Consignee.value = ''
          this.refs.phonePlace.value = ''
          this.refs.detailPlace.value = ''
          let data = this.state.increaseList
          data.isDefault = true
          data.id = 0
          this.setState({
            provinceValue: undefined,
            cityValue: undefined,
            regionValue: undefined,
            increaseList: data
          })
          console.log('点击新增', this.refs.Consignee.value)
        })
      }
    })
  }
  // 确认增加收货地址 || 编辑收货地址
  handleOk = () => {
    let r = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
    if (this.refs.Consignee.value == '') {
      message.info('收货人不能为空')
      return
    } else if (this.refs.phonePlace.value == '') {
      message.info('手机号不能为空')
      return
    } else if (!r.test(this.refs.phonePlace.value)) {
      message.info('手机号格式不正确')
      return

    } else if (this.state.regionValue == undefined) {
      message.info("地区信息填写不完整")
      return
    } else if (this.refs.detailPlace.value == '') {
      message.info("请填写详细地址")
      return

    }
    let increasePlaceInfo = Object.assign({}, this.state.increaseList, {
      consignee: this.refs.Consignee.value,
      mobile: this.refs.phonePlace.value,
      province: this.state.provinceValue,
      city: this.state.cityValue,
      county: this.state.regionValue,
      address: this.refs.detailPlace.value
    })
    this.setState({
      increaseList: increasePlaceInfo
    }, () => {
      BackEnd.companySquare('/api/UserCenter/SaveOrder_SelfInfo', this.state.increaseList).then(res => {
        console.log('保存', res.data)
        if (res.data.message == "Success") {
          message.success('自提地址添加成功')
          this.setState({
            visibleIncrease: false
          }, () => {
            this.addAddressList();
          })
        }
      })
    })
  }
  //编辑自提点地址
  edit(record) {
    console.log(record)

  }

  //删除自提点地址
  deleted(record) {
    BackEnd.deletedZTAddress(record.id).then(res => {
      if (res.data.Result.ResponseObj == true) {
        message.success('删除成功');
        this.addAddressList()
      } else {
        message.error('删除失败');
      }
    })
    // console.log(record)
  }

  render() {

    return (
      <div className="">
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="Selfpickupsitebox">
          <p className='title'>自提点</p>
          <div className="Selfpickupsite">
            <div className="addSelfpickupsite" onClick={this.increasePlace.bind(this, 0)}>
              添加自提点
            </div>
            <div style={{ marginTop: "20px" }}>
              <Table bordered dataSource={this.state.addressList} columns={this.state.columns} />
            </div>
          </div>


          <Modal
            width={"width", "350px"}
            title="自提点"
            closeIcon="关闭"
            visible={this.state.visibleIncrease}
            onCancel={this.handleCancel}
            onOk={this.handleOk}
            centered={true}
            wrapClassName="Model"
            okText="确认"
            cancelText="取消"
            zIndex={100}

          >
            <div className="increasePlaceContainer">
              <div className="increasePlaceContent">
                <p style={{ 'marginBottom': '10px' }}><span>收货人</span><input type="text" ref="Consignee" /></p>
                <p style={{ 'marginBottom': '10px' }}><span>手机号</span><input type="text" ref="phonePlace" /></p>
                <div className="areaContainer"><span>地区</span>
                  <Select
                    // showSearch
                    style={{ width: 70, marginRight: 2, marginLeft: 10, marginBottom: 5, textAlign: 'center' }}
                    placeholder="省"
                    optionFilterProp="children"
                    value={this.state.provinceValue}
                    onChange={this.selectProvince}
                    showArrow={false}
                    allowClear={true}

                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                    }
                  </Select>
                  <Select
                    // showSearch
                    style={{ width: 60, marginRight: 2, marginBottom: 5, textAlign: 'center' }}
                    placeholder="市"
                    optionFilterProp="children"
                    value={this.state.cityValue}
                    onChange={this.selectCity}
                    allowClear={true}
                    showArrow={false}
                    allowClear={true}

                    disabled={this.state.cityDisabled}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >{
                      areaData.ProvinceCity[this.state.provinceValue] ?
                        Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                          areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                            <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                          ))
                        )) : []
                    }
                  </Select>
                  <Select
                    // showSearch

                    style={{ width: 95, textAlign: 'center' }}
                    placeholder="区"
                    optionFilterProp="children"
                    value={this.state.regionValue}
                    onChange={this.selectRegion}
                    showArrow={false}
                    allowClear={true}
                    disabled={this.state.regionDisabled}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >{

                      areaData.ProvinceCity[this.state.provinceValue] ?
                        Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                          areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                            item.Name == this.state.cityValue ?
                              item.CityArea.map(items => (
                                <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                              )) : ''
                          ))
                        )) : []
                    }
                  </Select>
                </div>
                <p><span className="detailPlace">详细地址</span>
                  <textarea rows="3" cols="30" maxlength="200" style={{ resize: 'none', overflow: 'hidden' }} ref="detailPlace"></textarea>
                </p>

              </div>
            </div>
          </Modal>
        </div>

      </div>
    )
  }
}
export default withRouter(Selfpickupsite)
