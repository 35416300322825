import React, { PureComponent } from 'react'
import { getCookie, deleteCookie } from '../../utils/env'
// import Base64 from 'base-64';

import {
  Table,
  Upload,
  Select,
  Button,
  message,
  Pagination,
  Modal,
  Checkbox,
  Spin
} from 'antd';
import BackEnd from '../../services/api'
import './Accessories.scss'
import areaData from './data.json'
import Base64 from 'base-64';
import {
  encryptByDES,
  encryptPassword,
  encryptEDSPwd
} from '../../utils/encrypt';
const { Option } = Select
const props = {
  name: 'file',
  action: 'https://shop.chexd.com/apis/upload/uploadFile',
  accept: '.xlsx',
  headers: {
    authorization: 'authorization-text',
  },
  showUploadList: false,
  onChange(info) {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status == 'done') {
      if (getCookie('LoginCacheKey')) {
        BackEnd.download({
          Message: {
            ExcelURL: info.file.response.datas.baseUrl + info.file.response.datas.filePath
          }
        }).then(res => {
          if (res.data.code == '0') {
            message.success(`${info.file.name} 导入成功`);
          } else if (res.data.code == '8001') {
            message.error('登录失效,请重新登录');
          } else {
            message.error(`${info.file.name} 导入失败`);
          }
        }).catch(erro => {
          deleteCookie('LoginCacheKey')
          message.error('导入异常!请重新登录')
        })
      } else {
        window.localStorage.removeItem('EnterpriseCategory')
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('userPhone')
        window.localStorage.removeItem('sessionId')
        deleteCookie('LoginCacheKey')
        message.error(`登录失效,请重新登录`);
        window.location.reload()
      }

    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} 导入失败`);
    }
  },
};
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}
class Accessories extends PureComponent {
  constructor() {
    super()
    this.state = {
      ZipCode:'',
      filteredInfo: null, // 排序
      sortedInfo: null, // 排序
      activeIndex: '',
      TotalCount: 5,
      Page: 1,
      selectedRowKeys: [],
      visibleProvider: false, // 供应商名片弹框
      visibleFitting: false, // 配件查询弹框
      visibleEnquiry: false, // 询价弹框
      resultListLoading: false,
      disableButton: false,
      provinceValue: undefined,// 省级查询值
      cityValue: undefined, // 市级查询值
      regionValue: undefined, // 区级查询值
      cityDisabled: true, // 区域市级按钮
      regionDisabled: true, // 区域区级按钮
      resultList: [], // 查询结果列表
      ModelList: [], // 弹框数据列表
      CompanyDetail: [],
      locale: {
        emptyText: <img src={(require('./images/hint.png'))}></img>
      },
      searchList: {
        PageSize: 5,
        CurrentPage: 0, // CountPerPage 之前字段
        Province: "", // 省份
        City: "", // 城市
        County: "", // 区县
        IsShowNoGoods: false, // 库存
        CompanyName: "", // 供应商
        VehicleMode: "", // 车型
        Origin: "", // 产地
        Brand: "", // 品牌
        ComponentCode: '', // 配件编码
        ComponentName: '', // 配件名称
        OrderByDescending: true,
        QueryType: 10,
        WithCompanyDetail: true,
        IsQueryZHB: true,
        IsQueryHP: true
      },
      // 加入询价单
      setInquiryCondition: [],
      columns: [{
        title: '',
        dataIndex: 'QQ',
        key: 'QQ',
        width: 40,
        render: (text, record) => (
          text ?
            <a href={'tencent://message/?uin=' + text}><img border="0" src={require('./images/QQ.png')} alt="添加好友" title={text} /></a> :
            <img border="0" src={require('./images/QQ.png')} alt="添加好友" title={text} onClick={this.RemindUser} />
        )
      }, {
        title: '供应商',
        dataIndex: 'CompanyItem',
        key: 'CompanyItem',
        width: 230,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span className='storer' title={record.CompanyName} onClick={this.providerModel.bind(this, record)}>
            {record.CompanyName}
          </span>
        )
      }, {
        title: '区域',
        dataIndex: 'Region',
        key: 'Region',
        width: 150,
        ellipsis: true,
        render: (text, record) => (
          <div title={text} >
            {text}
          </div>
        )
      }, {
        title: '配件编码',
        dataIndex: 'ComponentCode',
        key: 'ComponentCode',
        width: 180,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}
            className='storer'
            onClick={this.FittingsModel.bind(this, record)}
          >
            {text}
          </span>
        )
      }, {
        title: '配件名称',
        dataIndex: 'ComponentName',
        key: 'ComponentName',
        width: 140,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div title={text} className='omit' style={{ width: '110px' }}>
            {text}
          </div>
        )
      }, {
        title: '品牌',
        dataIndex: 'Brand',
        key: 'Brand',
        width: 80,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '产地',
        dataIndex: 'Origin',
        key: 'Origin',
        width: 130,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '车型',
        dataIndex: 'VehicleMode',
        key: 'VehicleMode',
        width: 120,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '数量',
        dataIndex: 'ShowQuantity',
        key: 'ShowQuantity',
        width: 70,
        ellipsis: true,
        render: (text, record) => (
          window.localStorage.getItem('sessionId') ?
            <span title={text}>
              {text}
            </span> : <span>有货</span>
        )
      }, {
        title: '单位',
        dataIndex: 'Unit',
        key: 'Unit',
        width: 60,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '单价',
        dataIndex: 'EnterpriseCategoryPrice',
        key: 'EnterpriseCategoryPrice',
        width: 100,
        ellipsis: true,
        sorter: (a, b) => a.EnterpriseCategoryPrice - b.EnterpriseCategoryPrice,
        render: (text, record) => (
          getCookie('LoginCacheKey') ?
            Number(text) > 0 ? <span title={text}>
              {text}
            </span> : <span>面议</span> : <span onClick={this.goLogin} className='storer'>登录可查看</span>
        )
      }, {
        title: '操作',
        width: 100,
        ellipsis: true,
        render: (text, record) => (
          <span onClick={this.enquiry.bind(this, record)} className='storer'>询价</span>
        )
      }]
    }
  }
  componentDidMount() {
    if(this.props.location.query == undefined){
      
    }else{
      this.setState({

        ZipCode : this.props.location.query.ZipCode
      },()=>{
        this.refs.fittingInput.value = this.state.ZipCode
        console.log(this.refs.fittingInput.value)
        this.seek()
        // console.log(this.state.ZipCode)
      })
    }
    
  };
  getData() {
    this.setState({
      disableButton: true,
      activeIndex: '',
      resultListLoading: true
    }, () => {

      console.log(JSON.stringify(this.state.searchList))
      BackEnd
        .findAccessories({ ciphertext: Base64.encode(encryptEDSPwd(JSON.stringify(this.state.searchList))) })
        .then(res => {
          console.log(res)
          if (res.data.ResponseObj.Items.length > 0 && res.data.ResponseObj.Items) {
            let dataList = []
            for (let i = 0; i < res.data.ResponseObj.Items.length; i++) {
              res.data.ResponseObj.Items[i].key = i
              res.data.ResponseObj.Items[i].repertory = '有货'
              res.data.ResponseObj.Items[i].univalence = '面议'
              dataList.push(res.data.ResponseObj.Items[i])
            }
            this.setState({
              // TotalCount: Number(res.data.Message.TotalCount),
              TotalCount: res.data.ResponseObj.TotalNum,
              resultList: dataList,
              disableButton: false,
              setInquiryCondition: [],
              selectedRowKeys: [],
              resultListLoading: false
            })
          } else {
            this.setState({
              TotalCount: 5,
              resultList: [],
              disableButton: false,
              setInquiryCondition: [],
              selectedRowKeys: [],
              resultListLoading: false
            })
            message.error('暂无此配件信息!');
          }
        }).catch(error => {
          if (error) {
            this.setState({
              TotalCount: 5,
              resultList: [],
              disableButton: false,
              setInquiryCondition: [],
              selectedRowKeys: [],
              resultListLoading: false
            })
            message.error('查询过于频繁，请稍后再试!');
          }

        })
    })
  }
  // 搜索
  seek = () => {
    let searchChange = Object.assign({}, this.state.searchList,
      { ComponentCode: this.refs.fittingInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      { ComponentName: this.refs.fittingNameInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      { Brand: this.refs.brandInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      { VehicleMode: this.refs.vehicleInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      { Origin: this.refs.placeInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      { CompanyName: this.refs.giverInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      { CurrentPage: 0 }
    )
    this.setState({
      searchList: searchChange,
      Page: 1
    }, () => {
      if (this.state.searchList.ComponentCode !== '' ||
        this.state.searchList.VehicleMode !== '' ||
        this.state.searchList.Origin !== '' ||
        this.state.searchList.Brand !== '' ||
        this.state.searchList.CompanyName !== '' ||
        this.state.searchList.ComponentName !== '' ||
        this.state.searchList.Province !== ''
      ) {
        if (this.state.searchList.ComponentCode.length < 7 && this.state.searchList.ComponentCode !== '') {
          message.info('配件编码不能小于7位')
        } else {
          this.getData()
        }
      } else {
        message.info('请输入至少一个查询条件!')
        this.setState({
          resultList: [],
          TotalCount: 5,
        })

      }
    })
  }
  BusinessQuery = () => {
    this.props.history.push('/businessquery')
  }
  // 下载数据模板
  downloadTemplate = () => {
    window.location.href = 'http://down.chexd.com/共享库存导入模板.xlsx'
  }
  // 去登陆
  goLogin = () => {
    this.props.history.push('/login')
  }
  // 配件编码回车查询
  enter = (e) => {
    if (e.keyCode == 13) {
      this.refs.fittingInput.blur()
      let searchChange = Object.assign({}, this.state.searchList,
        { ComponentCode: this.refs.fittingInput.value.replace(/(^\s*)|(\s*$)/g, "") },
      )
      this.setState({
        searchList: searchChange
      }, () => {
        if (this.state.searchList.ComponentCode.length > 6) {
          this.getData()
        } else {
          message.info('配件编码不能小于7位')
        }
      })
    }
  }
  // 排序
  // sortChange = (pagination, filters, sorter) => {
  //   console.log('Various parameters', pagination, filters, sorter);
  //   this.setState({
  //     filteredInfo: filters,
  //     sortedInfo: sorter,
  //   });
  // };
  // 批量加入
  onSelectChange = (selectedRowKeys, selectedRows) => {
    let inquiryList = []
    for (let i = 0; i < selectedRows.length; i++) {
      selectedRows[i].Quantity = 1
      inquiryList.push(selectedRows[i])
    }
    this.setState({
      setInquiryCondition: inquiryList,
      selectedRowKeys
    }, () => {
      console.log('参数', this.state.setInquiryCondition, this.state.selectedRowKeys)
    })
  }
  // 加入询价单
  // addInquieryBill = () => {
  //   if (this.state.setInquiryCondition.length <= 0) {
  //     message.info('请选择配件')
  //     return
  //   } else {
  //     BackEnd
  //       .addInquiry({ userId: 5417, inquiryVoucherItem: this.state.setInquiryCondition }).then(res => {
  //         if (res.data.Message === 'Success') {
  //           message.info('已加入询价单！')
  //         } else {
  //           message.info('加入询价单失败，请您稍后重试！')
  //         }
  //       })
  //   }
  // }
  // 查看询价单
  lookEnquiryIndent = () => {
    this.props.history.push('/enquiry')
  }
  // 供应商名片弹框
  providerModel(record) {
    if (getCookie('LoginCacheKey')) {
      this.setState({
        ModelList: record,
        visibleProvider: true,
        CompanyDetail: record.CompanyItem
      })
    } else {
      this.setState({
        activeIndex: ''
      })
      message.info('登录后可查看')
    }
  }
  // 配件编码弹框
  FittingsModel(record) {
    if (getCookie('LoginCacheKey')) {
      this.setState({
        ModelList: record,
        visibleFitting: true,
        CompanyDetail: record.CompanyItem
      })
    } else {
      this.setState({
        activeIndex: ''
      })
      message.info('登录后可查看')
    }

  }
  // 询价
  enquiry(record) {
    if (getCookie('LoginCacheKey')) {
      if (record.AuthCode === 0) {
        message.info('该配件暂不支持询价!可以点击供应商名称,获取联系方式!')
        return
      }
      this.setState({
        ModelList: record,
        visibleEnquiry: true,
        CompanyDetail: record.CompanyItem
      })
    } else {
      this.setState({
        activeIndex: ''
      })
      message.info('登录后可查看')
    }
  }
  // 单个询价单
  singleEnquiry = (record) => {
    console.log('询价参数', record)
    if (!/^[1-9]{1,}[\d]*$/.test(this.refs.singleInquiryAmount.value)) {
      message.info('请正确输入数量')
    } else {
      let arr = Object.assign({}, record, { ShowQuantity: this.refs.singleInquiryAmount.value, Memo: this.refs.singleInquiryMemo.value })
      console.log(arr)
      BackEnd
        .getData({
          Code: 22027,
          OwebInquiryCompListGroup: [arr]
        }).then(res => {
          console.log(res)
          if (res.data.Message.Success === 'True') {
            this.setState({
              visibleEnquiry: false
            })
            this.refs.singleInquiryAmount.value = 1
            this.refs.singleInquiryMemo.value = ' '
            message.info('已提交询价单，请留意报价短信提醒！')
          } else {
            message.info('报价失败，请您稍后重试！')
          }
        }
        )
    }
  }

  // 取消弹框
  handleCancel = () => {
    this.setState({
      visibleFitting: false,
      visibleProvider: false,
      visibleEnquiry: false,
      activeIndex: ''
    });
  }
  // 改变页码
  changePage = (page) => {
    let changePage = Object.assign({}, this.state.searchList, { CurrentPage: page - 1 })
    this.setState({
      searchList: changePage,
      Page: page
    }, () => { this.getData(); console.log('选中状态', this.state.selectedRowKeys) })
  }
  // 首页
  firstPage = () => {
    let changePage = Object.assign({}, this.state.searchList, { CurrentPage: 0 })
    this.setState({
      searchList: changePage,
      Page: 1
    }, () => {
      if (this.state.searchList.CompanyName !== '' ||
        this.state.searchList.VehicleMode !== '' ||
        this.state.searchList.Origin !== '' ||
        this.state.searchList.Brand !== '' ||
        this.state.searchList.ComponentCode !== '' ||
        this.state.searchList.ComponentName !== '' ||
        this.state.searchList.Province !== '') {
        this.getData()
      }
    })
  }
  // 尾页
  endPage = () => {
    let changePage = Object.assign({}, this.state.searchList, { CurrentPage: Math.ceil(this.state.TotalCount / 5) - 1 })
    this.setState({
      searchList: changePage,
      Page: Math.ceil(this.state.TotalCount / 5)
    }, () => {
      if (this.state.searchList.CompanyName !== '' ||
        this.state.searchList.VehicleMode !== '' ||
        this.state.searchList.Origin !== '' ||
        this.state.searchList.Brand !== '' ||
        this.state.searchList.ComponentCode !== '' ||
        this.state.searchList.ComponentName !== '' ||
        this.state.searchList.Province !== '') {
        this.getData()
      }
    })
  }
  RemindUser = () => {
    message.error('暂无QQ信息!')
  }
  // 省级下拉选项
  selectProvince = (value) => {
    let newProvinceValue = Object.assign({}, this.state.searchList,
      { Province: value },
      { City: '' },
      { County: '' }
    )
    this.setState({
      searchList: newProvinceValue,
      provinceValue: value,
      cityValue: undefined,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        cityDisabled: false,
        regionDisabled: true // 禁用区级选项
      })
    } else {
      // 清空按钮触发时
      let newProvinceValue = Object.assign({}, this.state.searchList,
        { Province: '' },
        { City: '' },
        { County: '' }
      )
      this.setState({
        searchList: newProvinceValue,
        cityDisabled: true,
        regionDisabled: true,
        provinceValue: value,
      })
    }
  }
  // 市级下拉选项
  selectCity = (value) => {
    let newCityValue = Object.assign({}, this.state.searchList,
      { City: value },
      { County: '' }
    )
    this.setState({
      searchList: newCityValue,
      cityValue: value,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        regionDisabled: false
      })
    } else {
      // 清空按钮触发时
      let newCityValue = Object.assign({}, this.state.searchList,
        { City: '' },
        { County: '' }
      )
      this.setState({
        searchList: newCityValue,
        regionDisabled: true,
        regionValue: value
      })
    }
  }
  // 区级下拉选项
  selectRegion = (value) => {
    let newRegionValue = Object.assign({}, this.state.searchList,
      { County: value }
    )
    if (value) {
      this.setState({
        searchList: newRegionValue,
        regionValue: value,
      })
    } else {
      // 清空按钮触发时
      let newRegionValue = Object.assign({}, this.state.searchList,
        { County: '' }
      )
      this.setState({
        searchList: newRegionValue,
        regionValue: value,
      })
    }
  }
  // 库存显示状态
  storeQuantity = (e) => {
    let newIsShowNoGoods = Object.assign({}, this.state.searchList,
      { IsShowNoGoods: !e.target.checked }
    )
    this.setState({
      searchList: newIsShowNoGoods
    }, () => {
      console.log('状态', this.state.searchList);
    })
  }
  // 获取点击行的索引
  clickRow(num) {
    this.setState({
      activeIndex: (num.key)// 获取点击行的索引
    })
  }
  setClassName = (record, index) => {// record代表表格行的内容，index代表行索引
    // 判断索引相等时添加行的高亮样式
    return index === this.state.activeIndex ? 'l-table-row-show' : '';
  }
  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      getCheckboxProps: record => ({
        disabled: record.AuthCode === 0, // Column configuration not to be checked
        name: record.AuthCode + '',
      }),
    };
    return (
      <div className='accessories_container'>
        <div className='accessories'>
          <div className='parts_banner'>
            <img src={require('./images/Accessories.png')} alt="配件查询" />
          </div>
          <div className='search_accessories'>
            <div className='search_content'>
              <div className='componentCode'><span>配件编码</span><textarea ref='fittingInput' placeholder='请输入零件号查询不支持多配件查询' name="" id="" style={{ resize: 'none', width: '256px', height: '78px', overflow: 'hidden' }} onKeyUp={this.enter}></textarea></div>
              <div className='middleFilter'>
                {/* <div><span>配件编码</span><input type="text" ref='fittingInput' /></div> */}
                <div><span>配件名称</span><input type="text" ref='fittingNameInput' /></div>
                <div><span>品牌</span><input type="text" ref='brandInput' /></div>
                <div><span>车型</span><input type="text" ref='vehicleInput' /></div>
                <div><span>产地</span><input type="text" ref='placeInput' /></div>
              </div>
              <div className='endFilter'>
                <div><span>供应商</span><input type="text" ref='giverInput' /></div>
                <div>
                  <span>区域</span>
                  <Select
                    // showSearch
                    style={{ width: 79, marginTop: 10, marginRight: 10, textAlign: 'center' }}
                    placeholder="省"
                    optionFilterProp="children"
                    value={this.state.provinceValue}
                    onChange={this.selectProvince}
                    showArrow={false}
                    allowClear={true}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                    }
                  </Select>
                  <Select
                    // showSearch
                    style={{ width: 79, marginTop: 10, marginRight: 10, textAlign: 'center' }}
                    placeholder="市"
                    optionFilterProp="children"
                    value={this.state.cityValue}
                    onChange={this.selectCity}
                    allowClear={true}
                    showArrow={false}
                    allowClear={true}
                    disabled={this.state.cityDisabled}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >{
                      areaData.ProvinceCity[this.state.provinceValue] ?
                        Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                          areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                            <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                          ))
                        )) : []
                    }
                  </Select>
                  <Select
                    // showSearch
                    style={{ width: 79, marginTop: 10, marginRight: 10, textAlign: 'center' }}
                    placeholder="区"
                    optionFilterProp="children"
                    value={this.state.regionValue}
                    onChange={this.selectRegion}
                    showArrow={false}
                    allowClear={true}
                    disabled={this.state.regionDisabled}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >{

                      areaData.ProvinceCity[this.state.provinceValue] ?
                        Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                          areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                            item.Name == this.state.cityValue ?
                              item.CityArea.map(items => (
                                <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                              )) : ''
                          ))
                        )) : []
                    }
                  </Select>
                </div>
                <Checkbox onChange={this.storeQuantity} checked={!this.state.searchList.IsShowNoGoods}>只显示有货</Checkbox>
              </div>
              <div>
              </div>
            </div>
            <div className='functionButton' >
              <p>


                <button className='search_btn' onClick={this.seek} disabled={this.state.disableButton}>查询</button>
                {
                  window.localStorage.getItem('EnterpriseCategory') != '3' ? <button className='search_btn' onClick={this.BusinessQuery} disabled={this.state.disableButton}>商机查询</button> : ""
                }
                {/* <button className='search_btn' onClick={this.BusinessQuery} disabled={this.state.disableButton}>商机查询</button> */}
              </p>
              {window.localStorage.getItem('EnterpriseCategory') && window.localStorage.getItem('EnterpriseCategory') !== '3' && getCookie('LoginCacheKey') ? <div className='templateFunction'>
                <span className='downloadTemplate' onClick={this.downloadTemplate}>下载模板</span>
                <i className='tipTitle'>数据导入EXCEL模板</i>
                <Upload {...props} className='toLead'>
                  <Button>
                    导入</Button>
                </Upload>
                <i className='tipTitle'>导入EXCEL数据</i>
              </div> : ''}
            </div>
          </div>
          <div className='accessories_result'>
            <Spin tip="数据正在加载中..." spinning={this.state.resultListLoading}>
              <Table
                width='100%'
                rowSelection={rowSelection}
                rowKey={(record, index) => index}
                columns={this.state.columns}
                dataSource={this.state.resultList}
                bordered={true}
                pagination={false}
                scroll={{ y: 513 }}
                locale={this.state.locale}
                className='accessoriesTable'
                rowClassName={this.setClassName}
                onChange={this.sortChange}
                onRow={(record) => {//表格行点击事件
                  return {
                    onClick: this.clickRow.bind(this, record)
                  };
                }}
              />
              <div className='list_info_pagination'>
                <div className="enquiryContainer">
                  {/* <span onClick={this.addInquieryBill}>加入询价单</span>
                  <span onClick={this.lookEnquiryIndent}>查看询价单</span> */}
                </div>
                <div className="accessoriesPagination">
                  <span className={`first_button ${this.state.TotalCount <= 5 ? 'ban' : ''}`} onClick={this.firstPage}>首页</span>
                  <Pagination
                    total={this.state.TotalCount || 5}
                    current={this.state.Page}
                    itemRender={itemRender}
                    pageSize={5}
                    onChange={this.changePage}
                  />
                  <span className={`last_button ${this.state.TotalCount <= 5 ? 'ban' : ''}`} onClick={this.endPage}>末页</span>
                </div>
                <div className="reserved">
                  {/* <span onClick={this.lookEnquiryIndent}>询报价记录</span> */}
                </div>
              </div>
            </Spin>
          </div>
        </div>
        <Modal
          title="供应商名片"
          visible={this.state.visibleProvider}
          onCancel={this.handleCancel}
          footer={null}
          centered
          width={350}
          maskStyle={{ background: 'rgba(129,148,148,.5)' }}
        >
          <div className='adjust'><span>供应商名称:</span><span className='conceal' title={this.state.CompanyDetail.CompanyName}>{this.state.CompanyDetail.CompanyName}</span></div>
          <div className='adjust'><span>区域:</span><span className='conceal'>{this.state.ModelList.Region}</span></div>
          <div className='adjust'><span>地址:</span><span className='conceal' title={this.state.CompanyDetail.Address}>{this.state.CompanyDetail.Address}</span></div>
          <div className='adjust'><span>联系人:</span><span className='conceal'>{this.state.CompanyDetail.Contractor}</span></div>
          {/* <div className='adjust'><span>联系电话:</span><span className='conceal' title={this.state.CompanyDetail.Telephone}>{this.state.CompanyDetail.Telephone}</span></div> */}
          <div className='adjust'><span>联系电话:</span><span className='conceal' title={this.state.CompanyDetail.Mobile !== '' && this.state.CompanyDetail.Mobile ? this.state.CompanyDetail.Mobile : this.state.CompanyDetail.Telephone}>{this.state.CompanyDetail.Mobile !== '' && this.state.CompanyDetail.Mobile ? this.state.CompanyDetail.Mobile : this.state.CompanyDetail.Telephone}</span></div>
          <div className='adjust'><span>QQ:</span><span className='conceal' title={this.state.ModelList.QQ}>{this.state.ModelList.QQ}</span></div>
          <div className='consult'>
            <img src={require('./images/QQ.png')} />
            {
              this.state.ModelList.QQ ?
                <a href={'tencent://message/?uin=' + this.state.ModelList.QQ} className='interview'>立即咨询</a> :
                <a href='javascript:;' className='interview' onClick={this.RemindUser}>立即咨询</a>
            }
          </div>
        </Modal>
        <Modal
          title="配件查询"
          visible={this.state.visibleFitting}
          onCancel={this.handleCancel}
          footer={null}
          centered
          style={{ margin: 'auto' }}
          width={300}
          maskStyle={{ background: 'rgba(129,148,148,.5)' }}
        >
          <div className='adjust'><span>配件编码:</span><span className='conceal' title={this.state.ModelList.ComponentCode}>{this.state.ModelList.ComponentCode}</span></div>
          <div className='adjust'><span>配件名称:</span><span className='conceal' title={this.state.ModelList.ComponentName}>{this.state.ModelList.ComponentName}</span></div>
          <div className='adjust'><span>品牌:</span><span className='conceal' title={this.state.ModelList.Brand}>{this.state.ModelList.Brand}</span></div>
          <div className='adjust'><span>产地:</span><span className='conceal' title={this.state.ModelList.Origin}>{this.state.ModelList.Origin}</span></div>
          <div className='adjust'><span>数量:</span><span className='conceal'>{this.state.ModelList.ShowQuantity}</span></div>
          <div className='adjust'><span>单位:</span><span className='conceal'>{this.state.ModelList.Unit}</span></div>
          <div className='adjust'><span>单价:</span><span className='conceal'>{Number(this.state.ModelList.EnterpriseCategoryPrice) > 0 ? this.state.ModelList.EnterpriseCategoryPrice : '面议'}</span></div>
          <div className='adjust'><span>介绍:</span><span className='conceal' title={this.state.ModelList.Memo}>{this.state.ModelList.Memo ? this.state.ModelList.Memo : '暂无信息'}</span></div>
          <div className='consult'>
            <img src={require('./images/QQ.png')} />
            {
              this.state.ModelList.QQ ?
                <a href={'tencent://message/?uin=' + this.state.ModelList.QQ} className='interview'>立即咨询</a> :
                <a href='javascript:;' className='interview' onClick={this.RemindUser}>立即咨询</a>
            }
          </div>
        </Modal>
        <Modal
          title="询价"
          visible={this.state.visibleEnquiry}
          onCancel={this.handleCancel}
          footer={null}
          centered
          style={{ margin: "auto" }}
          width={330}
          maskStyle={{ background: "rgba(129,148,148,.5)" }}
          className="enquiryModal"
        >
          <div className="adjust"><span>供应商名称:</span><span className="conceal" title={this.state.CompanyDetail.CompanyName}>{this.state.CompanyDetail.CompanyName}</span></div>
          <div className="adjust"><span>配件编码:</span><span className="conceal" title={this.state.ModelList.ComponentCode}>{this.state.ModelList.ComponentCode}</span></div>
          <div className="adjust"><span>配件名称:</span><span className="conceal" title={this.state.ModelList.ComponentName}>{this.state.ModelList.ComponentName}</span></div>
          <div className="adjust"><span>配件品牌:</span><span className="conceal" title={this.state.ModelList.Brand}>{this.state.ModelList.Brand}</span></div>
          <div className="adjust"><span>车型:</span><span className="conceal" title={this.state.ModelList.VehicleMode}>{this.state.ModelList.VehicleMode}</span></div>
          <div className="adjust"><span>产地:</span><span className="conceal" title={this.state.ModelList.Origin}>{this.state.ModelList.Origin}</span></div>
          <div className="adjust"><span>单位:</span><span className="conceal">{this.state.ModelList.Unit}</span></div>
          <div className="adjust"><span>单价:</span><span className="conceal">{Number(this.state.ModelList.EnterpriseCategoryPrice) > 0 ? this.state.ModelList.EnterpriseCategoryPrice : "面议"}</span></div>
          <div className="adjust"><span>数量:</span><input type="text" maxLength="5" ref="singleInquiryAmount" defaultValue="1" /></div>
          <div className="adjust"><span>备注:</span><input type="text" ref="singleInquiryMemo" /></div>
          <div className="enquiryBtn" onClick={this.singleEnquiry.bind(this, this.state.ModelList)}>立即询价</div>
        </Modal>
      </div >
    )
  }
}
export default Accessories
