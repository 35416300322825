import React, { Component } from 'react';
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'
import BackEnd from '../../../../services/api'
import './informationDetail.scss'

import moment from 'moment'; 

import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();

class informationDetail extends Component {
    constructor(){
        super()
        this.state = {
            arr:[]
        }
      }
    goBackPage = () => {
        history.goBack();  //返回上一页这段代码
         
      }
    componentDidMount() {
        
        this.getDetail()
      }
    getDetail(){
        const { search } = this.props.location
        const paramsString = search.substring(1)
        const searchParams = new URLSearchParams(paramsString)
        const id = searchParams.get('id')
        BackEnd.companySquare('/api/IndustryInformation/QueryIndustryInformationModel',{industryInformationId:id}).then(res =>{
            console.log(res)
            this.setState({
                arr:res.data.ResponseObj
            })
            console.log(res.data.ResponseObj)

            console.log(this.state.arr)
        })
    }
    render() {
        return (
            <div>
                <Header/>
                    <div className="informationDetail">
                        <div className="informationDetail_main">
                            <p className="back" onClick={this.goBackPage}>返回</p>
                            <p className="informationDetail_title">{this.state.arr.Title}</p>
                            
                            <p className="time">发布时间：{moment(this.state.arr.AddTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</p>
                            <div dangerouslySetInnerHTML={{__html: this.state.arr.ContentTxt}}  className="informationMain"></div>
                        </div>
                    </div>
                <Footer/>
            </div>
        );
    }
}

export default informationDetail;
