import React, { Component } from 'react'
import { Modal } from 'antd'
import { Carousel } from 'antd'
import './Detail.scss'
import BackEnd from '../../services/api'
class Detail extends Component {
  constructor() {
    super()
    this.state = {
      // StoreId: '',
      Flag: '', //类型
      InStoreImgUrl: '', // 门头照片
      GoodsImgUrl: '', // 商品图片
      CheckStandImgUrl: '', // 收银台照片
      commitmentMore: false,
      Success: '', // 是否显示详情验证
      PeopleService: [], // 业务咨询信息
      detailInfo: {}, // 门店详情
      visible: false,
      storeVisible: false, // 门店大图
      storeBigPic: [],
      showContent: '', // 弹框要展示的内容
      settings: {
        arrows: true,
        dots: true,
      }
    }
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    // if (JSON.parse(sessionStorage.getItem('detail'))) {
    //   this.setState({
    //     StoreDetail: JSON.parse(sessionStorage.getItem('detail'))
    //   })
    // } else {
    //   this.setState({
    //     StoreDetail: this.props.location.state.StoreDetailInfo
    //   }, () => { sessionStorage.setItem('detail', JSON.stringify(this.state.StoreDetail)) })
    // }
  }
  componentDidMount() {
    // this.findCard()
    // this.findPeople()
    this.detail()
  }
  // 门店信息请求
  detail() {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const id = searchParams.get('id')
    BackEnd
      .companySquare('/api/EnterpriseSquareInfo/GetQueryStoreSquareListdetails', {
        authCode: id
      })
      .then(res => {
        console.log('门店详情', res.data)
        if (res.data.Message == "Success") {
          if (res.data.ResponseObj) {
            this.setState({
              detailInfo: res.data.ResponseObj,
              PeopleService: res.data.ResponseObj.storeLoginUserModel,
              storeTitle: res.data.ResponseObj.Store
            }, () => {
              console.log('标题', this.state.detailInfo.SmallImgUrl)
            })
            if (res.data.ResponseObj.qrapplication) {
              this.setState({
                InStoreImgUrl: res.data.ResponseObj.qrapplication.InStoreImgUrl,
                GoodsImgUrl: res.data.ResponseObj.qrapplication.GoodsImgUrl,
                CheckStandImgUrl: res.data.ResponseObj.qrapplication.CheckStandImgUrl,
                Success: res.data.ResponseObj.qrapplication.Success,
                Flag: Number(res.data.ResponseObj.qrapplication.Flag),
              })
            }
          }
        }
      })
  }
  findCard() {
    BackEnd
      .getData({
        Code: 27011,
        StoreId: this.state.StoreDetail.StoreId
      })
      .then(res => {
        this.setState({
          InStoreImgUrl: res.data.Message.InStoreImgUrl,
          GoodsImgUrl: res.data.Message.GoodsImgUrl,
          CheckStandImgUrl: res.data.Message.CheckStandImgUrl,
          Success: res.data.Message.Success,
          Flag: Number(res.data.Message.Flag)
        })
      })
  }
  findPeople() {
    BackEnd
      .getData({
        Code: 27009,
        StoreId: this.state.StoreDetail.StoreId
      })
      .then(res => {
        this.setState({
          PeopleService: res.data.Message.StoreLoginUserListGroup
        })
      })
  }
  // 弹框关闭
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }
  // 门店弹框
  handleCancel_02 = () => {
    this.setState({
      storeVisible: false,
    });
  }
  // 认证弹框图片
  magnify(type, img) {
    if (type == 1) {
      this.setState({
        showContent: this.state.InStoreImgUrl,
        visible: true
      })
    }
    if (type == 2) {
      this.setState({
        showContent: this.state.GoodsImgUrl,
        visible: true
      })
    }
    if (type == 3) {
      this.setState({
        showContent: this.state.CheckStandImgUrl,
        visible: true
      })
    }
    if (type == 'WeChat') {
      this.setState({
        showContent: img,
        visible: true
      })
    }
  }
  // 门店弹框图片
  storeMagnify() {
    this.setState({
      storeVisible: true,
      storeBigPic: this.state.detailInfo.SmallImgUrl
    })
  }
  // 查看更多
  readMore = () => {
    this.setState({
      commitmentMore: !this.state.commitmentMore
    })
  }
  render() {
    const { settings } = this.state
    return (
      <div className="detail_container">
        <div className="detail_content">
          <div className="detail_info">
            <dl>
              <dt>
                {
                  this.state.detailInfo.SmallImgUrl && this.state.detailInfo.SmallImgUrl.length != "" ?
                    <img src={this.state.detailInfo.SmallImgUrl[0]} alt="详情描述" className="storePic" onClick={this.storeMagnify.bind(this)} /> :
                    <img src={require("./images/SquareDefaultImg.png")} alt="门店默认图片" className="storePic" />
                }
                <p><img src={require("./images/biao.png")} alt="图表" className="pic" /><span>{this.state.detailInfo.SmallImgUrl ? this.state.detailInfo.SmallImgUrl.length : 0}</span></p>
              </dt>
              <dd>
                <div className="detail_discribe">
                  <h4>{this.state.detailInfo.Store}</h4>
                  <p><span>经营品牌:</span><span className="conceal" title={this.state.detailInfo.Brand}>{this.state.detailInfo.Brand}</span></p>
                  <p><span>联系电话:</span><span className="conceal" title={this.state.detailInfo.Telephone}>{this.state.detailInfo.Telephone}</span></p>
                  <p><span>所在地址:</span><span className="conceal" title={this.state.detailInfo.Address}>{this.state.detailInfo.Address}</span></p>
                  <p><span>简介:</span><span className="conceal" title={this.state.detailInfo.Memo}>{this.state.detailInfo.Memo}</span></p>
                  <p>
                    <span>认证状况:</span>
                    {
                      this.state.detailInfo.Legalize == "1" ? <span><img src={require("./images/approve.png")} alt="已认证" /></span> :
                        <span><img src={require("./images/noapprove.png")} alt="未认证" /></span>
                    }
                  </p>
                </div>
                <div className="attestationContainer">
                  <div className="Certification_details" style={this.state.Success === "true" ? { display: "block" } : { display: "none" }}>
                    <span className="Certification_title">认证详情:</span>
                    <div className="Certification_card">
                      <div className="Certification_card_list">
                        <span><img src={require("./images/kl.png")} alt="营业执照" />营业执照</span>
                        <span><img src={require("./images/kl.png")} alt="验证认证" />验证认证</span>
                        <span className="card_01" onClick={this.magnify.bind(this, "1")}><img src={require("./images/kl.png")} alt="门头认证" />门头认证</span>
                      </div>
                      <div className="Certification_card_list">
                        <span><img src={require("./images/kl.png")} alt="法人身份认证" />法人身份认证</span>
                        <span><img src={require("./images/kl.png")} alt="手机号认证" />手机号认证</span>
                        <span className="card_02" onClick={this.magnify.bind(this, "2")}><img src={require("./images/kl.png")} alt="内部商品认证" />内部商品认证</span>
                      </div>
                      <div className="Certification_card_list">
                        {
                          this.state.Flag ? <span><img src={require("./images/kl.png")} alt="开户许可证" />开户许可证</span> : <span><img src={require("./images/jkljl.png")} />开户许可证</span>
                        }
                        <span><img src={require("./images/kl.png")} alt="结算银行卡" />结算银行卡</span>
                        <span className="card_03" onClick={this.magnify.bind(this, "3")}><img src={require("./images/kl.png")} alt="收银台认证" />收银台认证</span>
                      </div>
                    </div>
                  </div>
                  <div className="commitmentContainer" style={this.state.detailInfo.WhetherNoReason7days == "1" || this.state.detailInfo.WhetherOriginalParts == "1" || this.state.detailInfo.WhetherOE == "1" || this.state.detailInfo.WhetherDisassemble == "1" || this.state.detailInfo.BrandPromiseListGroup ? { display: "block" } : { display: "none" }}>
                    <span className="commitmentTitle">质保承诺:</span>
                    <div className="commitmentContent">
                      {this.state.detailInfo.WhetherNoReason7days == "1" ? <span><img src={require("./images/seven.png")} alt="七天无理由退款" />支持七天无理由退款</span> : ""}
                      {this.state.detailInfo.WhetherOriginalParts == "1" ? <span><img src={require("./images/factory.png")} alt="原厂件承诺" />原厂件承诺:<i>{this.state.detailInfo.OriginalPartsMonth}个月或{this.state.detailInfo.OriginalPartsK}万公里承诺</i></span> : ""}
                      {this.state.detailInfo.WhetherOE == "1" ? <span><img src={require("./images/OE.png")} alt="O E件承诺" />O&nbsp;&nbsp;E件承诺:<i>{this.state.detailInfo.OEMonth}个月或{this.state.StoreDetail.OEK}万公里承诺</i></span> : ""}
                      {this.state.detailInfo.WhetherDisassemble == "1" ? <span><img src={require("./images/takeDown.png")} alt="拆车件承诺" />拆车件承诺:<i>{this.state.detailInfo.DisassembleMonth}个月或{this.state.detailInfo.DisassembleK}万公里承诺</i></span> : ""}
                    </div>
                    <div className="commitmentContent" style={this.state.commitmentMore ? { display: "flex" } : { display: "none" }}>
                      {this.state.detailInfo.BrandPromiseListGroup ? this.state.detailInfo.BrandPromiseListGroup.map((item, index) => (
                        item.Whether == "1" ? <span key={index}><img src={require("./images/brand.png")} alt="品牌件" />品牌件承诺:<i>{item.BrandName}{item.WarrantyMonth}个月或{item.WarrantyK}万公里质保</i></span> : ""
                      )) : <div></div>}
                    </div>
                    <div className="readMore" onClick={this.readMore} style={this.state.detailInfo.BrandPromiseListGroup ? { display: "block" } : { display: "none" }}>{this.state.commitmentMore ? "收起更多" : "展开更多"}</div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <p className="line"></p>
          <div className="business_consultation">
            <p className="business_consultation_title">
              <span>|</span>
              <span>业务咨询</span>
            </p>
            <div className="business_consultation_relation">
              {
                this.state.PeopleService ?
                  this.state.PeopleService.map((item, index) => (
                    <p key={index}>
                      <span className="shortened">{item.UserName.substr(item.UserName.length - 1, 1)}</span>
                      <span className="peopleName">{item.UserName}</span>
                      {
                        item.Telephone ? <span className="phone"><img src={require("./images/dsv.png")} alt="手机号" />{item.Telephone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}</span> : <span className="phone"></span>
                      }
                      {
                        item.QQ ? <span className="QQ"><img src={require("./images/sdbsbd.png")} alt="QQ" />{item.QQ}</span> : ""
                      }
                      {
                        item.WeChatQRUrl ? <span className="checkPic" onClick={this.magnify.bind(this, "WeChat", item.WeChatQRUrl)}><img src={require("./images/vsd.png")} alt="微信" />查看微信二维码</span> : <span></span>
                      }
                    </p>
                  )) : <span>暂无咨询信息</span>}
            </div>
          </div>
          {/* 弹框 */}
          <Modal
            title="查看图片"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
            centered={true}
          >
            <img src={this.state.showContent} alt="大图查看内容" style={style.banner} />
          </Modal>
          {/* 门店弹框 */}
          <Modal
            title="查看图片"
            visible={this.state.storeVisible}
            onCancel={this.handleCancel_02}
            footer={null}
            centered={true}
            wrapClassName="shopModel"
          >
            <div className="PopoutContent">
              <div>
                <Carousel ref={el => (this.slider = el)} {...settings}>
                  {this.state.storeBigPic.map((item, index) => (
                    <div key={index}><img src={item} alt="门店图片" style={style.banner} /></div>
                  ))}
                </Carousel>
              </div>
            </div>
          </Modal>
        </div>
      </div >
    )
  }
}
export default Detail
const style = {
  banner: {
    width: '100%',
    height: '300px',
    display: 'inline-block',
    marginBottom: '35px',
    textAlign: 'center',
    marginTop: '15px'
  }
}