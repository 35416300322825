import React, { Component } from 'react'
import Header from '../../../components/Header'
import { Link, withRouter } from 'react-router-dom'
import { Modal, Select, message, Table, Radio } from 'antd'
import InvoiceOrder from './Invoice/InvoiceOrder'
import SpecialInvoice from './Invoice/SpecialInvoice'
import BackEnd from '../../../services/api'
import './PlaceOrder.scss'
import areaData from '../../../data.json'
const { Option } = Select
const { confirm } = Modal
class PlaceOrder extends Component {
    constructor() {
        super()
        this.state = {
            value:'',
            delivery :[
                {id:1,name:'隔天'},
                {id:2,name:'自提'},
                {id:3,name:'急件'}
            ],
            invoiceArr:"1",
            now: ' ' ,
            visible: false, // 未登录提示框
            visibleIncrease: false, // 新增地址
            visibleTube: false,// 地址管理
            provinceValue: undefined,// 省级查询值
            cityValue: undefined, // 市级查询值
            regionValue: undefined, // 区级查询值
            cityDisabled: true, // 区域市级按钮
            regionDisabled: true, // 区域区级按钮
            placeType: 0, // 修改 || 编辑
            editPlace: {}, // 编辑地址数据
            increaseList: {
                id: 0,
                userId: Number(window.localStorage.getItem('userId')),
                consignee: '',
                mobile: '',
                province: '',
                city: '',
                county: '',
                address: '',
                isDefault: true,
                updateTime: String(Date.parse(new Date()))
            },
            placeTubeList: [], // 收货地址
            shopCartList: [], // 购物车清单
            columns: [{
                title: '收货人',
                dataIndex: 'Consignee',
                key: 'Consignee',
                width: 80,
                render: (text, record) => (
                    <span>{text}</span>
                )
            }, {
                title: '手机号',
                dataIndex: 'Mobile',
                key: 'Mobile',
                width: 100,
                ellipsis: true,
                className: 'resultColumns',
                render: (text, record) => (
                    <span>{text}</span>
                )
            }, {
                title: '所在地区',
                width: 150,
                ellipsis: true,
                render: (text, record) => (
                    <div>{record.Province}{record.City}{record.County}</div>
                )
            }, {
                title: '详细地址',
                dataIndex: 'Address',
                key: 'Address',
                width: 180,
                ellipsis: true,
                className: 'resultColumns',
                render: (text, record) => (
                    <span>{text}</span>
                )
            }, {
                title: '是否默认',
                dataIndex: 'IsDefault',
                key: 'IsDefault',
                width: 80,
                ellipsis: true,
                className: 'resultColumns',
                render: (text, record) => (
                    <span>{text ? '默认' : ''}</span>
                )
            }, {
                title: '操作',
                width: 80,
                ellipsis: true,
                className: 'resultColumns',
                render: (text, record) => (
                    <div className="operation">
                        <span onClick={this.increasePlace.bind(this, 1, record)}>编辑</span>
                        <span onClick={this.deletePlace.bind(this, record)}>删除</span>
                    </div>
                )
            }],
            zongshu:0,
            isduoshao:0,
            tradingRemarks:'',
            orderAmount:0,
            placeId :0,
            tooltip:false, //提示提交
            column: [{
                title: '卖家名称',
                dataIndex: 'CompanyName',
                key: 'CompanyName ',
                width: 100,
                render: (text, record) => (
                    <div>{text}</div>
                  )
            }, {
                title: '配件编码',
                dataIndex: 'ComponentCode',
                key: 'ComponentCode',
                width: 100,
                render: (text, record) => (
                    <div>{text}</div>
                  )
                
            }, {
                title: '配件名称',
                dataIndex: 'ComponentName',
                key: 'ComponentName ',
                width: 100,
                render: (text, record) => (
                    <div>{text}</div>
                  )
            }, {
                title: '产地',
                dataIndex: 'Origin',
                key: 'Origin',
                width: 100,
                render: (text, record) => (
                    <div>{text}</div>
                  )
                
            }, {
                title: '单位',
                dataIndex: 'Unit',
                key: 'Unit',
                width: 100,
                render: (text, record) => (
                    <div>{text}</div>
                  )
            }, {
                title: '单价',
                dataIndex: 'UnitPrice',
                key: 'UnitPrice',
                width: 100,
                render: (text, record) => (
                    <div>{Number(text).toFixed(2)}</div>
                  )
            },  {
                title: '订货数量',
                dataIndex: 'Quantity',
                key: 'Quantity',
                width: 100,
                render: (text, record) => (
                    <div>{text}</div>
                  )
            },, {
                title: '小计',
                dataIndex: 'OrderStatus',
                key: 'OrderStatus',
                width: 100,
                render: (text, record) => (
                    <div>{(Number(record.UnitPrice) * record.Quantity).toFixed(2) }</div>
                  )
            },],
            TotalMoney:0,
            Quantity:0,
            defeated:false,
            Code:0,
            Message:''
        }
        
    }
   

    componentWillMount() {
        window.scrollTo(0, 0);
        if (window.localStorage.getItem('Tokens') == null) {
            this.setState({
              visible: true
            })
          }
        // this.totalMoney()
    }
    componentDidMount() {
        // this.totalMoney();
        this.getShopCartList();
        // this.getShopCartPut();
        
    }
    // 去登录
  
    getShopCartList() {
        BackEnd.companySquare('/api/ShoppingCart/GetPlaceAnOrder', {
            userId: Number(window.localStorage.getItem('userId')),
        }).then(res => {
            console.log("新增", res)
            console.log(Number(window.localStorage.getItem('userId')))
            if (res.data.Message == 'Success') {
                this.setState({
                    placeTubeList: res.data.ResponseObj.GetOrder_AddressListByUser,
                    shopCartList: res.data.ResponseObj.ShoppingCartItem,
                    // TotalMoney:(this.state.shopCartList.UnitPrice)*this.state.shopCartList.ShowQuantity + this.state.TotalMoney
                },()=>{
                    this.totalMoney()
                    this.lodData()
                    console.log(this.state.shopCartList)
                })
            }
           
        })
    }
    
    //订单发布
    getShopCartPut() {
        BackEnd.submitOrder('/api/ShoppingCart/InSubmPlaceAnOrder',
          {
            orderAddressId: this.state.placeId,
            orderInvoice: {
                "id":0,
                "invoiceType":this.state.invoiceArr,
                "companyName": this.state.invoiceArr==3 ? '' :this.refs.minner.state.CompanyName  ,//公司名字
                "identificationNo": this.state.invoiceArr==3 ? '' : this.refs.minner.state.identify,//識別碼
                "invoiceContent":  this.state.invoiceArr==3 ? '' : this.refs.minner.state.content ,//發票內容
                "mobile": this.state.invoiceArr==3 ? '' :this.refs.minner.state.invoiceNumber ,//電話
                "companyAddress": this.state.invoiceArr==3 ? '' : this.refs.minner.state.companyAddress,//註冊地址
                "companyPhone":  this.state.invoiceArr==3 ? '' : this.refs.minner.state.companyPhone,//註冊電話
                "companyBankName": this.state.invoiceArr==3 ? '' : this.refs.minner.state.companyBankName ,//開戶行
                "companyBankAccount": this.state.invoiceArr==3 ? '' : this.refs.minner.state.companyBankAccount,//銀行賬戶
                "updateTime": String(Date.parse(new Date()))
              },
              tradingRemarks: this.state.tradingRemarks,
              deliveryRequire: this.state.now,
              userId: Number(window.localStorage.getItem('userId')),
              orderAmount:  this.state.TotalMoney
          }
        ).then(res => {
            if(res.data.Code == 1){
                this.setState({
                    Message:res.data.Message
                },()=>{
                    this.Defeated()

                })

            }else{
                this.tubeHandletooltip()
            }
           this.setState({
               Code:res.data.Code
           })
        },()=>{
            this.totalMoney()
        })
    }
    
    lodData() {
        for(var i =0 ; i< this.state.placeTubeList.length; i++){
            if(this.state.placeTubeList[i].IsDefault==true){
                this.setState({
                    placeId : this.state.placeTubeList[i].Id
                })
               console.log(this.state.placeId)
            }
        }
    }
    // 新增地址
    increasePlace = (type, record) => {
        console.log('编辑', type, record)
        this.setState({
            visibleIncrease: true
        }, () => {
            if (type) {
                this.setState({
                    placeType: 1,
                    editPlace: record
                }, () => {
                    console.log('暂存编辑数据', this.state.editPlace)
                    this.refs.Consignee.value = this.state.editPlace.Consignee
                    this.refs.phonePlace.value = this.state.editPlace.Mobile
                    this.refs.detailPlace.value = this.state.editPlace.Address
                    let data = this.state.increaseList
                    data.isDefault = this.state.editPlace.IsDefault
                    data.id = this.state.editPlace.Id
                    this.setState({
                        provinceValue: this.state.editPlace.Province,
                        cityValue: this.state.editPlace.City,
                        regionValue: this.state.editPlace.County,
                        increaseList: data
                    }, () => {
                        console.log('更改状态', this.state.increaseList)
                    })
                })
            } else {
                this.setState({
                    placeType: 0,
                }, () => {
                    this.refs.Consignee.value = ''
                    this.refs.phonePlace.value = ''
                    this.refs.detailPlace.value = ''
                    let data = this.state.increaseList
                    data.isDefault = true
                    data.id = 0
                    this.setState({
                        provinceValue: undefined,
                        cityValue: undefined,
                        regionValue: undefined,
                        increaseList: data
                    })
                    console.log('点击新增', this.refs.Consignee.value)
                })
            }
        })
    }
    // 地址管理
    addressManagement = () => {
        this.setState({
            visibleTube: true
        })
    }
    // 省级下拉选项
    selectProvince = (value) => {
        let newProvinceValue = Object.assign({}, this.state.increaseList,
            { province: value },
            { city: '' },
            { county: '' }
        )
        this.setState({
            increaseList: newProvinceValue,
            provinceValue: value,
            cityValue: undefined,
            regionValue: undefined
        })
        if (value) {
            this.setState({
                cityDisabled: false,
                regionDisabled: true // 禁用区级选项
            })
        } else {
            // 清空按钮触发时
            let newProvinceValue = Object.assign({}, this.state.increaseList,
                { province: '' },
                { city: '' },
                { county: '' }
            )
            this.setState({
                increaseList: newProvinceValue,
                cityDisabled: true,
                regionDisabled: true,
                provinceValue: value,
            })
        }
    }
    // 市级下拉选项
    selectCity = (value) => {
        let newCityValue = Object.assign({}, this.state.increaseList,
            { city: value },
            { county: '' }
        )
        this.setState({
            increaseList: newCityValue,
            cityValue: value,
            regionValue: undefined
        })
        if (value) {
            this.setState({
                regionDisabled: false
            })
        } else {
            // 清空按钮触发时
            let newCityValue = Object.assign({}, this.state.increaseList,
                { city: '' },
                { county: '' }
            )
            this.setState({
                increaseList: newCityValue,
                regionDisabled: true,
                regionValue: value
            })
        }
    }
    // 区级下拉选项
    selectRegion = (value) => {
        let newRegionValue = Object.assign({}, this.state.increaseList,
            { county: value }
        )
        if (value) {
            this.setState({
                increaseList: newRegionValue,
                regionValue: value,
            })
        } else {
            // 清空按钮触发时
            let newRegionValue = Object.assign({}, this.state.increaseList,
                { county: '' }
            )
            this.setState({
                increaseList: newRegionValue,
                regionValue: value,
            })
        }
    }
    // 是否为默认地址
    isDefaultPlace = (e) => {
        console.log('设为默认', e.target.checked)
        let changeDefaultPlace = Object.assign({}, this.state.increaseList, {
            isDefault: !this.state.increaseList.isDefault
        })
        this.setState({
            increaseList: changeDefaultPlace
        })
    }
    // 关闭新增地址弹框
    handleCancel = () => {
        this.setState({
            visibleIncrease: false
        })
    }
    //关闭提示框
    tubeHandleTooltip = () =>{
        this.setState({
            tooltip: false
        },()=>{
            this.props.history.push('/buyerOrder')
        })
    } 
    tubeHandletooltip = () => {
        this.setState({
            tooltip: true
        })
    }
        // 关闭地址管理
    tubeHandleCancel = () => {
        
        this.setState({
            visibleTube: false
        })
    }

    defeated = () => {
        
        this.setState({
            defeated: false
        })
    }
    Defeated = () => {
        
        this.setState({
            defeated: true
        })
    }

    // 删除收货地址
    deletePlace(record) {
        console.log('删除信息', record)
        let that = this
        confirm({
            title: '提示',
            content: '确认删除此询价单吗？',
            okText: '确认',
            okType: 'warning',
            cancelText: '取消',
            onOk() {
                BackEnd.deletePlace(record.Id).then(res => {
                    console.log('删除项', res.data)
                    if (res.data.Result.Message == "Success") {
                        message.success('删除成功')
                        that.getShopCartList();
                    }
                })
            },
            onCancel() { },
        })

    }
   
    // 确认增加收货地址 || 编辑收货地址
    handleOk = () => {
        if (this.refs.Consignee.value == '' || this.refs.phonePlace.value == '' || this.state.regionValue == undefined || this.refs.detailPlace.value == '') {
            message.info('填写信息不能为空')
            return
        }
        let increasePlaceInfo = Object.assign({}, this.state.increaseList, {
            consignee: this.refs.Consignee.value,
            mobile: this.refs.phonePlace.value,
            province: this.state.provinceValue,
            city: this.state.cityValue,
            county: this.state.regionValue,
            address: this.refs.detailPlace.value
        })
        this.setState({
            increaseList: increasePlaceInfo
        }, () => {
            BackEnd.companySquare('/api/ShoppingCart/SaveOrder_AddressInfo', this.state.increaseList).then(res => {
                console.log('保存',res)
                if (res.data.Result.Message == "Success") {
                    message.success('新增成功')
                    this.setState({
                        visibleIncrease: false
                    }, () => {
                        this.getShopCartList();
                    })
                }
            })
        })
    }
    // 发票管理
    ordinaryInvoice=(e)=>{
       console.log(e,12313213)
    }

    loginVisible = () => {
        this.setState({
          visible: false
        }, () => {
          this.props.history.push('/')
        })
      }
      goLogin = () => {
        this.props.history.push('/login')
      }
    totalMoney =() => {
        let TotalPice = 0;
        let TotalPices = 0;
        
        for(let i =0 ; i<this.state.shopCartList.length ;i++ ){
            
            TotalPice=TotalPice + Number(this.state.shopCartList[i].UnitPrice)*Number(this.state.shopCartList[i].Quantity)
            TotalPices=TotalPices+Number(this.state.shopCartList[i].Quantity)
        }
        this.setState({
            TotalMoney:TotalPice ,                
            Quantity:TotalPices
        },()=>{

            console.log('结果',this.state.TotalMoney,this.state.Quantity)
        })
        
    }

    render() {
        
        let value = this.state.value;
        return (
            <div className="placeOrderPage">
                <Header></Header>
                <div className="placeOrderContainer">
                    <div className="navigation">
                        <span>购物车</span><span>></span><span>下单</span>
                    </div>
                    <div className="placeOrderContent">
                        <div className="title">收货地址</div>
                        {/* 收货地址 */}
                        <div className="goodsPlaceContent">
                            {
                                this.state.placeTubeList.length 
                                ?   
                                this.state.placeTubeList.map((item, index) => (
                                    <div key={index} className={`goodsPlace ${item.IsDefault ? "defaullt" : ""}`} onClick={()=>{
                                       this.setState({
                                        placeId:item.Id
                                       },()=>{
                                           console.log(this.state.placeId)
                                       })
                                    }}
                                        className={this.state.placeId == item.Id ? 'goodsPlace defaullt' : 'goodsPlace'}
                                    >
                                        <p className="isDefault"><span>{item.Consignee}</span><span>{item.IsDefault ? '默认地址' : ''}</span></p>
                                        <p>{item.Mobile}</p>
                                        <p>{item.Province}{item.City}{item.County}</p>
                                        <p className="Address">{item.Address}</p>
                                    </div>
                                )) 
                                : 
                                ''
                            }
                        </div>
                        <div className="placeAdministration">
                            <span onClick={this.increasePlace.bind(this, 0)}>新增地址</span>
                            <span onClick={this.addressManagement}>地址管理</span>
                        </div>
                        {/* 发票管理 */}
                        <div className="invoiceContainer">
                            <div className="title">发票信息</div>
                            <div className="invoiceInfo">
                                <div className="invoiceTypeContent">
                                    <span>发票类型:</span>
                                    <div className="invoiceType">
                                        <p>
                                            <input name="invoice" type="radio" checked={this.state.invoiceArr==="1"} id="default1" onChange={(e)=>{
                                                 this.setState({
                                                    invoiceArr:"1"
                                                },
                                                ()=>{
                                                    console.log(this.state.invoiceArr)
                                                })
                                            }}  
                                            />
                                            <label htmlFor="default1"> 普通发票</label> 
                                        </p>
                                       
                                        <p>
                                            <input name="invoice" type="radio" checked={this.state.invoiceArr==="2"}  id="default2" onChange={(e)=>{
                                                 this.setState({
                                                    invoiceArr:"2"
                                                },()=>{
                                                    console.log(this.state.invoiceArr)
                                                })
                                                
                                            }}  />
                                            <label htmlFor="default2">增值税专用发票</label>
                                        </p>
                                        <p>
                                            <input name="invoice" type="radio" checked={this.state.invoiceArr==="3"}  id="default3"  onChange={(e)=>{
                                                 this.setState({
                                                    invoiceArr:"3"
                                                },()=>{
                                                    console.log(this.state.invoiceArr)
                                                })
                                                
                                            }}  />
                                            <label htmlFor="default3" >不用发票</label>
                                        </p>
                                    </div>
                                </div>
                                <div className="invoiceContent">
                                   {
                                    this.state.invoiceArr=="1" ? <InvoiceOrder ref="minner"></InvoiceOrder> : ''
                                   }
                                   {
                                    this.state.invoiceArr=="2" ? <SpecialInvoice ref="minner"></SpecialInvoice> : ''
                                    }
                                </div>
                                <div className="no-invoice">
                                    <h4>商品价格均为含票价，特殊情况请联系供应商</h4>
                                </div>
                            </div>
                        </div>
                        {/* 物流与结算 */}
                        <div className="logisticszContainer">
                            <div className="title">物流与结算</div>
                            <div className="logisticszContent">
                                <div className="logisticszTypeContent">
                                    <span>送货要求:</span>
                                    <div className="logisticszType">
                                    {
                                            this.state.delivery.map((item,index)=><span 
                                            key={index}
                                            onClick={()=>{
                                                this.setState({
                                                    now:item.id
                                                })
                                            }}
                                            className={this.state.now == item.id ? 'cur' : ''}
                                            >{item.name}</span>)
                                    }
    
                                    </div>
                                </div>
                                <p><span>备注内容:</span><input type="text" maxlength="85" placeholder="对本次交易的补充说明(85字内)"  
                                 onChange={e=>{
                                    this.setState({
                                        tradingRemarks:e.target.value
                                    })
                                }} 
                                /></p>
                            </div>
                        </div>
                        {/* 购物清单 */}
                        <div className="shoppingListContainer">
                        <div  className="title" >购物清单</div>
                            <div className="buyerOrderList">
                                    <Table
                                        width="100%"
                                        rowKey={(record, index) => index}
                                        columns={this.state.column}
                                        dataSource={this.state.shopCartList}
                                        bordered={true}
                                        pagination={false}
                                        className="buyerOrderListTable"
                                    />
                                </div>
                        </div>
                        {/* 应付合计 */}
                        <div  className="shoppingListContent">
                                <div className="totalContainer">
                                    <div className="totalContent"> 
                                    <p className="Content-p">
                                        <span>共{this.state.Quantity}件商品,总计金额:</span><span>￥{(this.state.TotalMoney).toFixed(2)}</span>
                                    </p>
                                        <p className="Content-p"><span>运费(卖家接单后确认):</span><span>￥0.00</span></p>
                                        <p className="Content-p"><span>优惠(卖家接单后确认):</span><span>￥0.00</span></p>
                                    </div>
                                </div>
                                <div className="copeWithTotal">
                                    <div className="title">
                                        <div className="copeWithTotal-right">
                                            <span>应付金额：￥{(this.state.TotalMoney).toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <p><span className="submissionOrder" onClick={()=>{
                                       let phoneReg = /^(13[0-9]|15[012356789]|17[0-9]|18[0-9]|14[5678]|19[8-9]|166)[0-9]{8}$/; //验证手机号正则
                                       let identify = /^[0-9a-zA-Z]{15,20}$/; //纳税人识别号
                                       if( this.state.invoiceArr == '1'){
                                            if(this.refs.minner.state.CompanyName == ''){
                                                message.info('请填写正确的发票抬头')
                                            }else if(!identify.test(this.refs.minner.state.identify)){
                                                message.info('请填写正确的纳税人识别号')
                                            }else if(this.refs.minner.state.content == ''){
                                                message.info('请填写正确的发票内容')
                                            }else if(!phoneReg.test(this.refs.minner.state.invoiceNumber)){
                                                message.info('请填写正确的发票人手机号')
                                            }else if(this.state.now == ' ') {
                                                message.info('请填写您的送货要求')
                                            }else if(this.state.placeId == 0) {
                                                message.info('请填写您的收货地址')
                                            }else  if(this.state.Code == 1){
                                                this.Defeated()
                                            }else{this.getShopCartPut()}
                                       }else if(this.state.invoiceArr == '2'){
                                            if(this.refs.minner.state.CompanyName == ''){
                                                message.info('请填写正确的单位名称')
                                            }else if(!identify.test(this.refs.minner.state.identify)){
                                                message.info('请填写正确的纳税人识别号')
                                            }else if(this.refs.minner.state.companyAddress == ''){
                                                message.info('请填写正确的注册地址')
                                            }else if(!phoneReg.test(this.refs.minner.state.companyPhone)){
                                                message.info('请填写正确的注册电话')
                                            }else if(this.refs.minner.state.companyBankName == ''){
                                                message.info('请填写正确的开户银行')
                                            }else if(this.refs.minner.state.companyBankAccount == ''){
                                                message.info('请填写正确的银行账号')
                                            }else if(this.state.now == ' ') {
                                                message.info('请填写您的送货要求')
                                            }else if(this.state.placeId == 0) {
                                                message.info('请填写您的收货地址')
                                            }else  if(this.state.Code == 1){
                                                this.Defeated()
                                            }else{this.getShopCartPut()}
                                       }else if(this.state.invoiceArr == '3') {
                                            if(this.state.now == ' ') {
                                                message.info('请填写您的送货要求')
                                            }else if(this.state.placeId == 0) {
                                                message.info('请填写您的收货地址')
                                            }else  if(this.state.Code == 1){
                                                this.Defeated()
                                            }else{
                                                this.getShopCartPut()}
                                       }
                                       
                                    //    this.getShopCartPut()
                                    }}>提交订单</span></p>
                                </div>
                            </div>
                            
                    </div>
                </div>
                {/* 新增收货地址 */}
                <Modal
                    width={"width", "350px"}
                    title="收货地址"
                    closeIcon="关闭"
                    visible={this.state.visibleIncrease}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    centered={true}
                    wrapClassName="Model"
                    okText="确认"
                    cancelText="取消"
                    zIndex={100}
                >
                    <div className="increasePlaceContainer">
                        <div className="increasePlaceContent">
                            <p><span>收货人</span><input type="text" ref="Consignee" /></p>
                            <p><span>手机号</span><input type="text" ref="phonePlace" /></p>
                            <div className="areaContainer"><span>地区</span>
                                <Select
                                    // showSearch
                                    style={{ width: 70, marginRight: 2, marginLeft: 10, marginBottom: 5, textAlign: 'center' }}
                                    placeholder="省"
                                    optionFilterProp="children"
                                    value={this.state.provinceValue}
                                    onChange={this.selectProvince}
                                    showArrow={false}
                                    allowClear={true}
                                   
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                                    }
                                </Select>
                                <Select
                                    // showSearch
                                    style={{ width: 60, marginRight: 2, marginBottom: 5, textAlign: 'center' }}
                                    placeholder="市"
                                    optionFilterProp="children"
                                    value={this.state.cityValue}
                                    onChange={this.selectCity}
                                    allowClear={true}
                                    showArrow={false}
                                    allowClear={true}
                                  
                                    disabled={this.state.cityDisabled}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >{
                                        areaData.ProvinceCity[this.state.provinceValue] ?
                                            Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                                                areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                                                    <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                                                ))
                                            )) : []
                                    }
                                </Select>
                                <Select
                                    // showSearch
                                    
                                    style={{ width: 95, textAlign: 'center' }}
                                    placeholder="区"
                                    optionFilterProp="children"
                                    value={this.state.regionValue}
                                    onChange={this.selectRegion}
                                    showArrow={false}
                                    allowClear={true}
                                    disabled={this.state.regionDisabled}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >{

                                        areaData.ProvinceCity[this.state.provinceValue] ?
                                            Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                                                areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                                                    item.Name == this.state.cityValue ?
                                                        item.CityArea.map(items => (
                                                            <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                                                        )) : ''
                                                ))
                                            )) : []
                                    }
                                </Select>
                            </div>
                            <p><span className="detailPlace">详细地址</span>
                                <textarea rows="3" cols="30" maxlength="200" style={{ resize: 'none', overflow: 'hidden' }} ref="detailPlace"></textarea>
                            </p>
                            <p className="defaultPlaceContainer"><input type="checkbox" id="default" checked={this.state.increaseList.isDefault} onChange={this.isDefaultPlace} />
                                <label htmlFor="default">默认地址</label></p>
                        </div>
                    </div>
                </Modal>
                {/* 地址管理 */}
                <Modal
                    title="编辑收货地址"
                    closeIcon="关闭"
                    visible={this.state.visibleTube}
                    onCancel={this.tubeHandleCancel}
                    footer={null}
                    centered
                    style={{ margin: 'auto' }}
                    wrapClassName="placeTubeModel"
                    width={970}
                    maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                    zIndex={10}
                >
                    <div className="tubePlaceContainer">
                        <Table
                            width='100%'
                            rowKey={(record, index) => index}
                            columns={this.state.columns}
                            dataSource={this.state.placeTubeList}
                            bordered={true}
                            pagination={false}
                            locale={this.state.locale}
                            className='placeTable'
                        />
                        <p className="increasePlace"><span onClick={this.increasePlace.bind(this, 0)}>新增</span></p>
                    </div>
                </Modal>
                <Modal
                    title="提示"
                    closeIcon="关闭"
                    visible={this.state.tooltip}
                    onCancel={this.tubeHandleTooltip}
                    footer={null}
                    centered
                    style={{ margin: 'auto' }}
                    wrapClassName="placeTubeModel"
                    width={400}
                    maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                >
                    <div className="tubePlacetooltip" >
                       <p className="tooltipP">订单已提交，卖家确认后需及时付款，请留意在线信息和收货人手机短信提示。</p>
                       <span className="tooltipSpan" onClick={()=>{
                           
                            this.props.history.push('/buyerOrder')
                           
                       }}>查看订单</span>
                    </div>
                </Modal>
                <Modal
                    title="提示"
                    visible={this.state.visible}
                    onCancel={this.loginVisible}
                    footer={null}
                    maskClosable={false}
                    wrapClassName='isLoginModel'
                    >
                    <p className="goLogin-p">登录下单</p>
                    <p className='loginFunction'><span className="goLogin" onClick={this.goLogin}>登录</span></p>
                    </Modal>
                <Modal
                title="提示"
                visible={this.state.defeated}
                onCancel={this.defeated}
                footer={null}
                maskClosable={false}
                wrapClassName='isLoginModel'
                >
                <p className="goLogin-p">下单失败</p>
                    <p className="span-p">{this.state.Message}</p>
                </Modal>
            </div>
        )
    }
}
export default withRouter(PlaceOrder)
