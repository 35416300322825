import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import './Header.scss'

class StoreHeader extends Component {
  constructor(props) {
    super()
    this.state = {

    }
  }
  // 查看购物车
  seeShopCart = () => {
    this.props.history.push('/shoppingCart')
  }
  render() {
    return (
      <div className="personalStoreNav">
        <div className="navContainer">
          <div className="storeName">
            {/* <span >{this.props.text.Store}</span>
          <span >{this.props.text.Name}</span> 

          <span  >{this.props.text.CompanyName}</span> */}
            <span>{sessionStorage.getItem('Brand')}</span>
            {/* <span  >{this.props.text.CompanyName}</span> */}

          </div>
          <div className="storeOptions">
            <div className="storeOption">

              <NavLink to={`/personalStore?id=${sessionStorage.getItem('StoreId') || ''}`} className={this.props.location.pathname == '/personalStore' ? 'active' : ""} activeStyle={{ color: "#02a7f0" }} >首页</NavLink>
              <NavLink to={`/productList?id=${sessionStorage.getItem('StoreId') || ''}`} className={this.props.location.pathname == '/productList' ? 'active' : ""} activeStyle={{ color: "#02a7f0" }}>产品列表</NavLink>
              <NavLink to={`/contactUs?id=${sessionStorage.getItem('StoreId') || ''}`} className={this.props.location.pathname == '/contactUs' ? 'active' : ""} activeStyle={{ color: "#02a7f0" }}>联系我们</NavLink>
              {/*<NavLink to="/shoppingCart" activeStyle={{ color: "#02a7f0" }}>购物车</NavLink>*/}
              {/*<NavLink to="/square" activeStyle={{ color: "#02a7f0" }}>返回主站</NavLink>*/}
              <a href={`http://${window.mainHostName}/#/shoppingCart`} activeStyle={{ color: "#02a7f0" }}>购物车</a>
              {
                localStorage.getItem('prevType') === '1' ? 
                  <a href={`http://${window.mainHostName}/#/square`} activeStyle={{ color: "#02a7f0" }}>返回主站</a>
                  : ''
              }
              {/* <NavLink to="/productList" activeStyle={{ color: "#02a7f0" }}>产品列表</NavLink>
              <NavLink to="/shoppingCart" activeStyle={{ color: "#02a7f0" }}>购物车</NavLink>
              <span>17610190370</span>
              <NavLink to="/square" activeStyle={{ color: "#02a7f0" }}>返回主站</NavLink> */}
              {/* <span>店铺装修</span> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(StoreHeader)
