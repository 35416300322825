import React, { Component } from 'react'
import { Link, withRouter, NavLink } from 'react-router-dom'
import { Menu, Layout, message, Button, Dropdown } from 'antd'
import { setCookie, getCookie, deleteCookie } from '../../utils/env'
import Logo from '../Logo'
import './Header.scss'
import BackEnd from '../../services/api'
import { headerMenuConfig } from '../../MenuConfig/MenuConfig'

const { SubMenu, Item } = Menu
const { Header } = Layout


class CbHeader extends Component {
	static propTypes = {}

	static defaultProps = {}

	constructor(props) {
		super(props)
		this.state = {
			ispopup: false,
			ResponseObj: 0,
			userId: Number(window.localStorage.getItem('userId')),
			AuthCode: window.localStorage.getItem('AuthCode') != undefined ? window.localStorage.getItem('AuthCode') : "",
			usertype: window.localStorage.getItem('EnterpriseCategory'),
			liArr: window.localStorage.getItem('EnterpriseCategory') == 1 || window.localStorage.getItem('EnterpriseCategory') == 4 ? ['基本信息', '行业培训','我的车辆', '我的钱包','扫码付', '安全设置', '买家订单', '收货地址', '质保承诺', "商机管理", "店铺管理", "卖家订单", "自提点"] : ['基本信息', '我的车辆', '我的钱包','扫码付', '安全设置', '买家订单', '收货地址']
		}
	}
	componentDidMount() {
		// console.log(getCookie('LoginCacheKey')== null,'时间你好',getCookie('LoginCacheKey'))
		this.shoppingamount()
	}
	// 登录
	goLogin = () => {
		this.props.history.push('/login')
	}
	// 注册
	goRegister = () => {
		this.props.history.push('/register')
	}
	// 退出登录 
	exitLogin = () => {
		window.localStorage.removeItem('EnterpriseCategory')
		window.localStorage.removeItem('userInfo')
		window.localStorage.removeItem('userPhone')
		window.localStorage.removeItem('sessionId')
		window.localStorage.removeItem('userId')
		window.localStorage.removeItem('AuthCode')
		window.localStorage.removeItem('Tokens')
		window.localStorage.removeItem('IMId')
		window.localStorage.removeItem('IMKey')
		window.localStorage.removeItem('IMTokens')
		window.sessionStorage.removeItem('Tokens')
		window.sessionStorage.removeItem('LoginCacheKey')
		window.sessionStorage.removeItem('CompanyName')
		
		deleteCookie('LoginCacheKey')
		// window.jq.cookie('LoginCacheKey', 0, { path: '/', domain: 'chexd.com' })
		// window.jq.cookie('LoginCacheKey', 0, { path: '/', domain: 'prewww.chexd.com' })
		console.log(window.jq)
		window.jq.cookie('userInfo', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('userPhone', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('sessionId', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('userId', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('AuthCode', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('Tokens', 0, { path: '/', domain: 'chexd.com' })
		// window.jq.cookie('LoginCacheKey',0,{ path: '/',domain: 'chexd.com'})

		



		BackEnd.getData({
			Code: "10973",
			ConnectStatus: "0"
		}).then(res => {
			console.log(res)
			window.localStorage.removeItem('EnterpriseCategory')
			window.localStorage.removeItem('userInfo')
			window.localStorage.removeItem('userPhone')
			window.localStorage.removeItem('sessionId')
			window.localStorage.removeItem('userId')
			window.localStorage.removeItem('AuthCode')
			window.localStorage.removeItem('Tokens')
			window.localStorage.removeItem('IMId')
			window.localStorage.removeItem('IMKey')
			window.localStorage.removeItem('IMTokens')
			window.sessionStorage.removeItem('Tokens')
			deleteCookie('LoginCacheKey')

			// window.jq.cookie('EnterpriseCategory',null,{ path: '/',domain: 'chexd.com'})
			window.jq.cookie('userInfo', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('userPhone', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('sessionId', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('userId', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('AuthCode', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('Tokens', 0, { path: '/', domain: 'chexd.com' })
			// window.jq.cookie('LoginCacheKey',0,{ path: '/',domain: 'chexd.com'})

			

		}).catch(err => {
			window.location.reload();
			this.props.history.push('/')
		})
		window.location.reload();
		message.success('退出成功!')
		this.props.history.push('/')
		// alert('ss')
	}

	shoppingamount() {
		if (this.state.userId == null) {
			return
		} else {
			BackEnd.amount('/api/ShoppingCart/GetShoppingCount', {
				userId: this.state.userId
			}).then(res => {

				// console.log('小數', res)
				this.setState({
					ResponseObj: res.data.ResponseObj.Count,
				})
				if (res.data.ResponseObj.DomainName != '') {
					window.localStorage.setItem('storeUrl', res.data.ResponseObj.DomainName)
					// message.error('我的店铺加载失败，请重新登录!')

				}
			})
		}

	}
	getCook(cookie_name) {
		var allcookies = document.cookie;
		//索引长度，开始索引的位置
		var cookie_pos = allcookies.indexOf(cookie_name);

		// 如果找到了索引，就代表cookie存在,否则不存在
		if (cookie_pos != -1) {
			// 把cookie_pos放在值的开始，只要给值加1即可
			//计算取cookie值得开始索引，加的1为“=”
			cookie_pos = cookie_pos + cookie_name.length + 1;
			//计算取cookie值得结束索引
			var cookie_end = allcookies.indexOf(";", cookie_pos);

			if (cookie_end == -1) {
				cookie_end = allcookies.length;

			}
			//得到想要的cookie的值
			var value = unescape(allcookies.substring(cookie_pos, cookie_end));
		}
		return value;
	}
	render() {
		const { location = {} } = this.props
		const { pathname } = location
		let categoryArr = ['基本信息', '行业培训','我的车辆', '我的钱包','扫码付',

			'安全设置', '买家订单', '收货地址', '质保承诺', "商机管理", "店铺管理", "卖家订单", "自提点"];
		var roter = window.localStorage.getItem("EnterpriseCategory") != 3 ? "/Usercenter/EnterpriseInformation" : "/Usercenter/Essentialinfo"
		let href = [roter,'/Usercenter/VocationalTraining', '/Usercenter/Mycar', '/Usercenter/Mymoney','/Usercenter/SweepToPay',
			'/Usercenter/Securityset', '/Usercenter/Buyersorder', '/Usercenter/Shippingaddress', '/Usercenter/Qualityassurancecommitment', "/Usercenter/Businessmanagement", "/Usercenter/Shopadministration", "/Usercenter/Orderseller", "/Usercenter/Selfpickupsite"]
		let itemList = [];
		for (let i = 0; i < categoryArr.length; i++) {
			itemList.push(<Menu.Item className='textceng' style={this.state.liArr.indexOf(categoryArr[i]) > -1 ? { display: 'block' } : { display: "none" }} >
				
				<Link key={i} className={this.props.location.pathname === href[i] ? 'active' : ''} style={this.state.liArr.indexOf(categoryArr[i]) > -1 ? { display: 'block' } : { display: "none" }} index={i} to={href[i]}>{categoryArr[i]}</Link>
				
			</Menu.Item>);
		}
		const menu = (
			<Menu>
				{/* <Menu.Item key="1">
					<p onClick={() => {
						this.props.history.push('/buyerOrder')
					}} >订单管理</p>
				</Menu.Item>
				<Menu.Item key="0" onClick={
					this.exitLogin
				}>
					<a  >退出账号</a>
				</Menu.Item> */}
				<Menu.Item className='textceng'>
					

					<Link to={roter} style={{ display: this.state.AuthCode == 0 ? "block" : "none" }}>免费开店</Link>
					
				</Menu.Item>
				<Menu.Item className='textceng'>
					<Link to={roter}>用户中心</Link>
				</Menu.Item>
				{itemList}
				<Menu.Item className='textceng' key="0" onClick={
					this.exitLogin
				}>
					<Link to="/">退出登录</Link>
				</Menu.Item>
			</Menu>
		)
		return (
			<Header style={style.header}>
				<div className="header-container">
					<div className="header-content">
						<Logo />
						<div className='navMenu'>
							<Menu
								className="header-navbar-menu"
								mode="horizontal"
								selectedKeys={[pathname]}
								defaultSelectedKeys={[pathname]}
							>
								{headerMenuConfig &&
									headerMenuConfig.length > 0 &&
									headerMenuConfig.map((nav, index) => {
										if (nav.children && nav.children.length > 0) {
											return (
												<SubMenu key={index} title={<span>{nav.name}</span>}>
													{nav.children.map((item) => {
														const linkProps = {}
														if (item.external) {
															if (item.newWindow) {
																linkProps.target = '_blank'
															}
															linkProps.href = item.path
															return (
																<Item key={item.path}>
																	<a {...linkProps}>
																		<span>{item.name}</span>
																	</a>
																</Item>
															)
														}
														linkProps.to = item.path
														return (
															<Item key={item.path}>
																<Link {...linkProps}>
																	<span>{item.name}</span>
																</Link>
															</Item>
														)
													})}
												</SubMenu>
											)
										}
										const linkProps = {}
										if (nav.external) {
											if (nav.newWindow) {
												linkProps.target = '_blank'
											}
											linkProps.href = nav.path
											return (
												<Item key={nav.path}>
													<a {...linkProps}>
														<span>{nav.name}</span>
													</a>
												</Item>
											)
										}
										linkProps.to = nav.path
										return (
											<Item key={nav.path}>
												<Link {...linkProps}>
													<span>{nav.name}</span>
												</Link>
											</Item>
										)
									})}
								<Item key='/Disassembly'
									className={
										(this.props.location.pathname == '/Disassembly')}>
									<Link to='/Disassembly'>
										<span>拆解厂管理</span>
								</Link>
								</Item>
								<Item key='/Usercenter/VocationalTraining'
									className={
										(this.props.location.pathname == '/Usercenter/VocationalTraining')}>
									<Link to='/Usercenter/VocationalTraining'>
										<span>行业培训</span>
								</Link>
								</Item>
								<Item key='/'>
									<Link to='/'>
										<span>找货宝</span>
									</Link>
								</Item>
								<Item key='/EPC_query'
									className={
										(this.props.location.pathname == '/EPC_query')}>
									<Link to='/EPC_query'>
										<span>EPC查询</span>
									</Link>
								</Item>
								{/* {window.localStorage.getItem('EnterpriseCategory') != '3' ? <Item key='/businessquery'>
									<Link to='/businessquery'>
										<span>商机查询</span>
									</Link>
								</Item> : ''} */}
								<Item key='/square'>
									<Link to='/square'>
										<span>企业广场</span>
									</Link>
								</Item>
								<Item key='/partsShopping'
									className={(this.props.location.pathname == '/partsShopping'
										|| this.props.location.pathname == '/NewArrivals'
										|| this.props.location.pathname == '/Cheap'
										// || this.props.location.pathname == '/Information'
										|| this.props.location.pathname == '/CheapDetail'
										// || this.props.location.pathname == '/InformationDetail'
										// || this.props.location.pathname == '/Recharge'
									)
										? 'ant-menu-item-selected' : ""}
								>
									<Link to='/partsShopping'>
										<span>配件商城</span>
									</Link>

								</Item>
								<Item key='/Information'
									className={
										(this.props.location.pathname == '/Information' || this.props.location.pathname == '/InformationDetail' || this.props.location.pathname == '/Recharge'

											? 'ant-menu-item-selected' : ""
										)}>
									<Link to='/Information'>
										<span>维修资料</span>
									</Link>
								</Item>
								
							</Menu>
							<div className='userOperation'>{ getCookie('LoginCacheKey') == 0 ||  getCookie('LoginCacheKey') == null ?	
								<p>
									<span onClick={this.goLogin}>
										登录</span>|
									<span onClick={this.goRegister}>
										免费注册</span>
								</p>
								:
								<span className="NavLink">
									<div className="myP" onClick={() => {
										this.props.history.push('/shoppingCart')
									}}>
										{
											this.state.ResponseObj == 0 ? '' :
												<p className="myp-box">{this.state.ResponseObj}</p>
										}
									</div>
									<p className='readyLogin'><span className='userPhone'>
										{window.localStorage.getItem('userPhone') ? window.localStorage.getItem('userPhone').replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') : ''}
									</span>
										<Dropdown overlay={menu} trigger={['click']}>
											<p className="ant-dropdown-link" onClick={e => e.preventDefault()}>
												<p className="myspan"></p>
											</p>
										</Dropdown>
									</p>
								</span>
								}
								</div>
						</div>

					</div>

				</div>
			</Header>
		)
	}
}
export default withRouter(CbHeader)
const style = {
	header: {
		background: '#ffffff'
	}
}

