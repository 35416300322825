import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import './SweepToPay.scss'
import BackEnd from '../../../services/api'

import { formatDate } from '../../../utils/filter'
import { DatePicker, Button, Select, Input, message, Table, Pagination, Spin } from 'antd';
const { Option } = Select;


function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <span>上一页</span>;
    } if (type === 'next') {
        return <span>下一页</span>;
    }
    return originalElement;
}

export default class SweepToPay extends Component {
    constructor() {
        super()
        this.state = {
            columns: [

                {
                    title: "企业名称",
                    dataIndex: 'compayName',
                    key: 'compayName',
                    width: 80,
                    render: (text, record) => (
                        <span>{text}</span>
                    )

                },
                {
                    title: "支付金额（元）",
                    dataIndex: 'payAmount',
                    key: 'payAmount',
                    width: 80,
                    render: (text, record) => (
                        <span>{text}</span>
                    )

                },
                {
                    title: "交易流水号",
                    dataIndex: 'transactionId',
                    key: 'transactionId',
                    width: 100,
                    render: (text, record) => (
                        <span>{text}</span>
                    )

                },
                {
                    title: "交易类型",
                    dataIndex: 'payFlatformName',
                    key: 'payFlatformName',
                    width: 60,
                    render: (text, record) => (
                        <span>{text}</span>
                    )

                },
                {
                    title: "交易时间",
                    dataIndex: 'createTime',
                    key: 'createTime',
                    width: 80,
                    render: (text, record) => (
                        <span>{text}</span>
                    )

                },
                {
                    title: "支付状态",
                    dataIndex: 'payStatus',
                    key: 'payStatus',
                    width: 60,
                    render: (text, record) => (
                       text == 1 ? <span>已支付</span>:<span>未支付</span>
                        // console.log(record.payStatus,text)
                    )

                }
              ],
            dataList: '',
            startDate:'',
            endDate: '',
            transactionId: '',
            TotalCount: '',
            orderStatus: '0',
            totoalAmount:'',
            totoalNotPayAmount:'',
            totoalPayAmount:'',
            pages:'1',
            loading:true
        }
    }
    componentDidMount() {
        if(window.localStorage.getItem('JHPayRegistStatus') == 1){
            this.getDataList()

        }
    }
    //获得时间
    startChange = (date, dateString) => {
        if(dateString){
            this.setState({
                startDate: formatDate(dateString, 'yyyy-mm-dd')
            })
        }else{
            this.setState({
                startDate: ''
            })
        }
        
    }
    endChange = (date, dateString) => {
        if(dateString){
            this.setState({
                endDate: formatDate(dateString, 'yyyy-mm-dd')
            })
        }else{
            this.setState({
                endDate: ''
            })
        }
    }
    payOrderChange = (e) => {
        this.setState({
            transactionId: e.target.value
          })
    }
    //获取数据
    getDataList = () => {
        let infoList = {
            startDate: this.state.startDate,
            endDate:this.state.endDate,
            transactionId: this.state.transactionId,
            type: this.state.orderStatus,
            export:0,
            currentPage: 1,
            pageSize: 10
        }
        BackEnd.SweepToPay(infoList).then(res => {
            if(res.data.code == 0){
                if(res.data.responseObj.items.length != 0){
                    message.success('查询成功');

                    this.setState({
                        TotalCount: res.data.responseObj.customTotalNum,
                        dataList: res.data.responseObj.items,
                        totoalAmount:res.data.responseObj.totoalAmount,
                        totoalPayAmount:res.data.responseObj.totoalPayAmount,
                        totoalNotPayAmount:res.data.responseObj.totoalNotPayAmount,
                        loading:false,
                        pages:'1',
                        
                    })
                 
                }else{
                    message.error('该条件下暂无数据');
                    this.setState({
                        TotalCount: res.data.responseObj.customTotalNum,
                        dataList: res.data.responseObj.items,
                        totoalAmount:res.data.responseObj.totoalAmount,
                        totoalPayAmount:res.data.responseObj.totoalPayAmount,
                        totoalNotPayAmount:res.data.responseObj.totoalNotPayAmount,
                        pages:'1', 
                    })

                }
                
            }else{
                message.error('查询失败，请重新再试');
                this.setState({
                    loading:false,
                  })
            }
            

        })
    }
    //分页
    conversion = (page) => {
        let infoList = {
            startDate: this.state.startDate,
            endDate:this.state.endDate,
            transactionId: this.state.transactionId,
            type: this.state.orderStatus,
            currentPage: 1,
            pageSize: 10
        }
        let changePage = Object.assign({}, infoList, { currentPage: page })
        console.log(changePage.currentPage)
        // window.scrollTo(0, 0);
        BackEnd.SweepToPay(changePage).then(res => {
            console.log(res)
            this.setState({
                pages:changePage.currentPage,
                TotalCount: res.data.responseObj.customTotalNum,
                dataList: res.data.responseObj.items,
                totoalAmount:res.data.responseObj.totoalAmount,
                totoalPayAmount:res.data.responseObj.totoalPayAmount,
                totoalNotPayAmount:res.data.responseObj.totoalNotPayAmount,
                // pages:'1',
            })
        })
      }
   
      //导出本页
      exportData = (num) =>{
          window.location.href="/api/QrPay/QrPayQueryListExport?startDate="
          +this.state.startDate+"&endDate="
          +this.state.endDate+"&transactionId="
          +this.state.transactionId+"&type="
          +this.state.orderStatus+"&export="
          +num+"&currentPage="+this.state.pages+"&pageSize=10"
      }
    render() {
        return (
            <div>
                <Header></Header>
                <Usercenter></Usercenter>
                <div className="scantopay">
                    <p className='title'>扫码付</p>
                {window.localStorage.getItem('JHPayRegistStatus') == 1?
                   
                    <div className="payscan">
                        <div className="title_box">
                            <span style={{ lineHeight: '30px',fontSize: '12px' }}>开始日期：</span>  <DatePicker placeholder="开始时间" style={{ width: '120px' }} onChange={this.startChange} />
                            <span style={{ marginLeft: '20px',fontSize: '12px', lineHeight: '30px'  }}>结束日期：</span>  <DatePicker placeholder="结束时间" style={{ width: '120px' }} onChange={this.endChange} />
                            <span style={{ marginLeft: '20px', lineHeight: '30px'  }}>交易流水号：</span>
                            <Input style={{ width: '15%' }} onChange={this.payOrderChange}></Input>
                            <span style={{ marginLeft: '20px', lineHeight: '30px'  }}>支付状态：</span>
                            <Select defaultValue="全部" style={{ width: '80px' }} onChange={e => {
                                this.setState({
                                    orderStatus: e
                                })
                            }}>
                                <Option value="0">全部</Option>
                                <Option value="1">已支付</Option>
                                <Option value="2">未支付</Option>
                            </Select>
                            <Button type="primary" style={{ marginLeft: '30px' }} onClick={() => { this.getDataList() }}>查询</Button>
                        </div>
                        <div className="title_box" style={{ marginTop: '30px' }}>
                            <h2>总金额：{this.state.totoalAmount}元</h2>
                            <h2 style={{ marginLeft: '20px' }}>已支付：{this.state.totoalPayAmount}元</h2>
                            <h2 style={{ marginLeft: '20px' }}>未支付：{this.state.totoalNotPayAmount}元</h2>
                            <Button type="primary" style={{ marginLeft: '30px' }}  onClick={() => { this.exportData(0) }}>导出本页</Button>
                            <Button type="primary" style={{ marginLeft: '30px' }}  onClick={() => { this.exportData(1) }}>导出全部</Button>
                        </div>
                        <Spin tip="Loading..." spinning={this.state.loading}>
                            <Table bordered dataSource={this.state.dataList} columns={this.state.columns} pagination={false} style={{marginTop:'20px'}}/>
                        </Spin>
                        <div className="list_info_pagination">
                            {/* <span className="first_button" onClick={this.firstPage}>首页</span> */}
                            <Pagination
                                total={this.state.TotalCount || 10}
                                //   current={this.state.searchFirm.currentPage}
                                itemRender={itemRender}
                                pageSize={10}
                                onChange={this.conversion}
                            />
                            {/* <span className="last_button" onClick={this.endPage}>末页</span> */}
                        </div>
                   
                </div>:<h2>请开通扫码付再进行操作</h2>
                }
                 </div>
            </div>
        )
    }
}
