import React, { Component } from 'react'
import Header from '../../components/Header'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table } from 'antd'
import {
    Form,
    Input,
    Cascader,
    message,
    Button,
    Tooltip,
    Icon,
    Upload,
    Select,
    Row,
    Col,
    Checkbox,
    AutoComplete,
} from 'antd';
import areaJson from './area.json'
import './AccountNumber.scss'
const { Option } = Select;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('只能上传类型是JPG/PNG文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('上传图片不能超过2MB!');
    }
    return isJpgOrPng && isLt2M;
}
class AccountNumber extends Component {
    constructor() {
        super()
        this.state = {
            loading: false, // 上传图片的loading图
			imageUrl: '', // 上传图片的url
        }
    }

    componentWillMount() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {}
    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };
        const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">上传</div>
			</div>
		);
        return (
            <div className="accountNumberPage">
                <Header></Header>
                <div className="accountNumberContainer">
                    <div className="accountNumberCatalog">
                        <div className="title">
                            <icon></icon><span>账号设置</span>
                        </div>
                        <p><span>账户资料</span></p>
                    </div>
                    <div className="accountNumberContent">
                        <p className="title"><span>账户资料</span></p>
                        <div className="personalInfo">
                            <p><span>用户类型</span><span>个人</span></p>
                            <p><span>手机号</span><span>13966669693</span></p>
                            <p><span>所属地区</span><span>北京/北京/丰台区</span></p>
                        </div>
                        <div className="upgradeCompany">
                            <p className="remarks">升级为企业用户</p>
                            <div className="upgradeCompanyContent">
                                <Form {...formItemLayout} onSubmit={this.handlePersonageSubmit}>
                                    <Form.Item label="企业名称">
                                        {getFieldDecorator('personagePhone', {
                                            rules: [
                                                { type: 'string', required: true, message: '企业名称' },
                                            ],
                                        })(<Input placeholder="请输入企业名称" autoComplete='new-password' />)}
                                    </Form.Item>
                                    <Form.Item label="门店名称">
                                        {getFieldDecorator('personagePhone', {})(<span>空</span>)}
                                    </Form.Item>
                                    <Form.Item label="所属地区">
                                        {getFieldDecorator('personageArea', {
                                            rules: [
                                                { type: 'array', required: true, message: '请输入地区' },
                                            ],
                                        })(<Cascader options={areaJson} placeholder='请输入地区' onChange={this.companyArea} />)}
                                    </Form.Item>
                                    <Form.Item label="企业类别">
                                        {getFieldDecorator('companyType', {
                                            rules: [{ required: true, message: '请选择企业类别' }],
                                        })(
                                            <Select placeholder="请选择企业类型">
                                                <Option value="1">汽配商</Option>
                                                <Option value="2">汽修厂</Option>
                                                <Option value="4">厂家</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="营业执照">
                                        {getFieldDecorator('upload', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '请上传营业执照'
                                                }
                                            ]
                                        })(
                                            <Upload
                                                name="file"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action="https://shop.chexd.com/apis/upload/uploadFile"
                                                beforeUpload={beforeUpload}
                                                onChange={this.handleChange}
                                            >
                                                {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        )}
                                    </Form.Item>
                                    <Form.Item {...tailFormItemLayout} className='registerArea'>
                                        <Button type="primary" htmlType="submit" className='registerButton'>提交</Button>
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Form.create({})(withRouter(AccountNumber))
