import React, { Component } from 'react'
import Header from '../../../components/Header'


import { Tabs } from 'antd';
import './SellerOrder.scss'
// import Shipments from './Shipments'
import Management from './management'
import Notopened from './inner/Notopened'
const { TabPane } = Tabs;



export default class SellerOrder extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        return (
            <div className="sellerOrderPage">
                <Header></Header>
                <div className="sellerOrderContainer">
                    {/* 侧边公共 */}
                        {/* <Broadside></Broadside> */}
                    <div className="Broadside">
                            <div className="title">
                                <span>订单管理</span>
                            </div>
                        <p onClick={()=>{
                                this.props.history.push('/buyerOrder')
                        }}>
                        <span  >订单管理(买家)</span>
                        </p>
                        <p className="title-p">
                        <span className="title-span" >订单管理(卖家)</span>
                        </p>
                    </div>
                    <div className="sellerOrderContent">
                        <div className="headerTitle">
                            <Tabs type="card">
                                <TabPane tab="订单管理" key="1">
                                    {
                                        // window.localStorage.getItem('AuthCode') ?
                                        // <Notopened></Notopened>
                                        // :
                                        <Management></Management>
                                    }
                                   
                                </TabPane>
                                {/* <TabPane tab="发货管理" key="2">
                                    <Shipments></Shipments>
                                </TabPane> */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
