import React, { PureComponent } from 'react'

import { Form, Icon, Input, Button,Select  } from 'antd';
import BackEnd from "../../../../services/api";
import UploadImg from "./UploadImg";
const { Option } = Select;
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
class InvoiceToApplyFor extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            form:{
                type:0,//发票状态
                titel:"",//发票开头
                identification:"",//纳税识别号不能为空
                bank_name:"",//开户行
                bank_number:"",//银行账号
                bank_address:"",//开户行地址
                tel:"",//联系电话
                send_name:"",//寄送人名
                send_tel:"",//你寄送人电话
                send_address:"",//寄送人地址
                money:'',//金额
                id:'',
                email:'',
            },
            classList:[],
        }
        this.formRef = React.createRef()
        this.uploadRef1 = React.createRef()
    }
    kong=()=>{
        let obj =  {
            type:0,//发票状态
            titel:"",//发票开头
            identification:"",//纳税识别号不能为空
            bank_name:"",//开户行
            bank_number:"",//银行账号
            bank_address:"",//开户行地址
            tel:"",//联系电话
            send_name:"",//寄送人名
            send_tel:"",//你寄送人电话
            send_address:"",//寄送人地址
            money:'',//金额
            id:'',
            email:''
        }
        this.setState({
            form:obj,
            classList:this.props.isdata
        })
    }
    set(){
        BackEnd.paymentList({userId:window.localStorage.getItem('userId')}).then(res=>{
            console.log(res)
            if(res.data.code == 0 ){
                this.setState({
                    classList:res.data.data
                })
            }
        })
    }
    componentDidMount() {
        // To disable submit button at the beginning.
        // this.props.form.validateFields();
    }
   
    SelectHandle=(e)=>{
        this.setState({form:{...this.state.form,type:e}})
    }
    IdHandle=(e)=>{
        this.setState({form:{...this.state.form,id:e}})
    }
  
    render() {
        return (
            <div>
                <Form layout="inline" labelAlign="right" >
                    <Form.Item  label="付 款 凭 证："  className='mb-10' > 
                        <Select defaultValue={0} value={this.state.form.id} onChange={this.IdHandle} style={{minWidth:'183px'}} >
                            {
                                this.state.classList.map ((item)=>(
                                    <Option value={item.id} key={item.id}>{item.TrainTitle}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <br />
                    <Form.Item  label="发 票 类 型："  className='mb-10' >
                        <Select defaultValue={0} value={this.state.form.type} onChange={this.SelectHandle} style={{minWidth:'183px'}}>
                            <Option value={0}>普票</Option>
                            <Option value={1}>专票</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item  label="发 票 金 额："  className='mb-10' >
                        <Input value={this.state.form.money} onChange={(e)=>{this.setState({form:{...this.state.form,money:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
                    <br />
                    <Form.Item  label="发 票 抬 头：" className='mb-10' name="title" rules={[{ required: true, message: '请输入标题' }]} >
                        <Input value={this.state.form.titel} onChange={(e)=>{this.setState({form:{...this.state.form,titel:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
                    <Form.Item  label="纳税识别号：" className='mb-10' rules={[{ required: true, message: '纳税识别号不能为空',}]}>
                        <Input value={this.state.form.identification} onChange={(e)=>{this.setState({form:{...this.state.form,identification:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
                    {
                        this.state.form.type === 1 ? 
                            <Form.Item  label="开 户 银 行：" className='mb-10'>
                                <Input value={this.state.form.bank_name} onChange={(e)=>{this.setState({form:{...this.state.form,bank_name:e.target.value}})}} style={{minWidth:'183px'}}/>
                            </Form.Item>
                        : ''
                    }
                    {
                        this.state.form.type === 1 ? 
                            <Form.Item  label="银 行 账 号：" className='mb-10'>
                                <Input value={this.state.form.bank_number} onChange={(e)=>{this.setState({form:{...this.state.form,bank_number:e.target.value}})}} style={{minWidth:'183px'}}/>
                            </Form.Item>
                        : ''
                    }
                    {
                        this.state.form.type === 1 ?
                        <Form.Item  label="注 册 地 址：" className='mb-10'>
                            <Input value={this.state.form.bank_address} onChange={(e)=>{this.setState({form:{...this.state.form,bank_address:e.target.value}})}} style={{minWidth:'183px'}}/>
                        </Form.Item>
                        :""
                    }
                    {
                        this.state.form.type === 1 ?
                        <Form.Item  label="联 系 电 话：" className='mb-10'>
                            <Input value={this.state.form.tel} onChange={(e)=>{this.setState({form:{...this.state.form,tel:e.target.value}})}} style={{minWidth:'183px'}}/>
                        </Form.Item>
                        :""
                    }
                
                    <Form.Item  label="寄 送 人 名：" className='mb-10'>
                        <Input value={this.state.form.send_name} onChange={(e)=>{this.setState({form:{...this.state.form,send_name:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
                    <Form.Item  label="寄 送 电 话：" className='mb-10'>
                        <Input value={this.state.form.send_tel} onChange={(e)=>{this.setState({form:{...this.state.form,send_tel:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
                    <Form.Item  label="寄 送 地 址：" className='mb-10'>
                        <Input value={this.state.form.send_address} onChange={(e)=>{this.setState({form:{...this.state.form,send_address:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
                    <Form.Item  label="电 子 邮 箱：" className='mb-10'>
                        <Input value={this.state.form.email} onChange={(e)=>{this.setState({form:{...this.state.form,email:e.target.value}})}} style={{minWidth:'183px'}}/>
                    </Form.Item>
            </Form>
                <p >
                    提示：<span className='redspan'>每月15日、22日开票</span>
                </p>
         </div>
        )
    }
}

export default InvoiceToApplyFor