import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty ,Layout,Menu,Icon  } from 'antd'
import './Usercenter.scss'
import BackEnd from '../../services/api'
import { setCookie, getCookie, deleteCookie } from '../../utils/env'
import Headers from '../../components/Header'
const { TabPane } = Tabs
const { confirm } = Modal
const { Header, Sider, Content } = Layout;



class usercenter extends Component {
  constructor() {
    super()
    this.state = {
      currentIndex: 0,collapsed: false,
      userId: Number(window.localStorage.getItem('userId')),
      AuthCode:window.localStorage.getItem('AuthCode'),
			liArr:window.localStorage.getItem('EnterpriseCategory')==1||window.localStorage.getItem('EnterpriseCategory')==4?[ '基本信息', '行业培训','我的车辆', '我的钱包','扫码付','安全设置', '买家订单', '收货地址', '质保承诺',"商机管理","店铺管理","卖家订单","自提点"]:['基本信息','行业培训','我的车辆', '我的钱包','安全设置', '买家订单', '收货地址']
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {

  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
	// 退出登录 
	exitLogin = () => {
		window.localStorage.removeItem('EnterpriseCategory')
		window.localStorage.removeItem('userInfo')
		window.localStorage.removeItem('userPhone')
		window.localStorage.removeItem('sessionId')
		window.localStorage.removeItem('userId')
		window.localStorage.removeItem('AuthCode')
		window.localStorage.removeItem('Tokens')
		window.sessionStorage.removeItem('Tokens')
		deleteCookie('LoginCacheKey')

		window.jq.cookie('userInfo', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('userPhone', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('sessionId', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('userId', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('AuthCode', 0, { path: '/', domain: 'chexd.com' })
		window.jq.cookie('Tokens', 0, { path: '/', domain: 'chexd.com' })
		// window.jq.cookie('LoginCacheKey',0,{ path: '/',domain: 'chexd.com'})

		BackEnd.getData({
			Code: "10973",
			ConnectStatus: "0"
		}).then(res => {
			console.log(res)
			window.localStorage.removeItem('EnterpriseCategory')
			window.localStorage.removeItem('userInfo')
			window.localStorage.removeItem('userPhone')
			window.localStorage.removeItem('sessionId')
			window.localStorage.removeItem('userId')
			window.localStorage.removeItem('AuthCode')
			window.localStorage.removeItem('Tokens')
			window.sessionStorage.removeItem('Tokens')
			deleteCookie('LoginCacheKey')

			// window.jq.cookie('EnterpriseCategory',null,{ path: '/',domain: 'chexd.com'})
			window.jq.cookie('userInfo', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('userPhone', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('sessionId', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('userId', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('AuthCode', 0, { path: '/', domain: 'chexd.com' })
			window.jq.cookie('Tokens', 0, { path: '/', domain: 'chexd.com' })
			// window.jq.cookie('LoginCacheKey',0,{ path: '/',domain: 'chexd.com'})



		}).catch(err => {
			window.location.reload();
			this.props.history.push('/')
		})
		window.location.reload();
		message.success('退出成功!')
		this.props.history.push('/')
	}




  render() {
    let categoryArr = [ '基本信息', '行业培训','我的车辆', '我的钱包','扫码付',
	'安全设置', '买家订单', '收货地址', '质保承诺',"商机管理","店铺管理","卖家订单","自提点"];
	var roter=window.localStorage.getItem("EnterpriseCategory")!=3?"/Usercenter/EnterpriseInformation":"/Usercenter/Essentialinfo"
    let href=[roter,'/Usercenter/VocationalTraining', '/Usercenter/Mycar',  '/Usercenter/Mymoney','/Usercenter/SweepToPay',
    '/Usercenter/Securityset', '/Usercenter/Buyersorder', '/Usercenter/Shippingaddress', '/Usercenter/Qualityassurancecommitment',"/Usercenter/Businessmanagement","/Usercenter/Shopadministration","/Usercenter/Orderseller","/Usercenter/Selfpickupsite"]
    let itemList = [];
    for(let i = 0; i < categoryArr.length; i++) {
    itemList.push(
      <Link key={i} className={this.props.location.pathname ===href[i]  ? 'heightPage active' : 'heightPage'} style={this.state.liArr.indexOf(categoryArr[i])>-1?{display:'block'}:{display:"none"}} index={i} to={href[i]}>{categoryArr[i]}</Link>
      );
    }
	
    return (
      <div className='usercenterbox'>
		  <br></br>
        <div className="usercenter">
           <ul>
              <h3 className="usercentertitle">用户中心</h3>
              <li>
				  <Link className='Linka' to='/Usercenter/Essentialinfo' style={{display:this.state.AuthCode==0?"block":"none"}}>免费开店</Link></li>
               		{itemList}
               <li  onClick={this.exitLogin}><Link to="/">退出登录</Link></li>
           </ul>
        </div>
      
	  
	
      </div>
    )
  }
}
export default withRouter(usercenter)
