// 菜单配置
// headerMenuConfig：头部导航配置
// asideMenuConfig：侧边导航配置

const headerMenuConfig = [{
        name: '服务支持',
        path: '/home'
    },
    // {
    //     name: '软件下载',
    //     path: '/download'
    // },
    // {
    //     name: '补丁包下载',
    //     path: '/pack'
    // }
    // {
    //     name: '关于我们',
    //     path: '/aboutus'
    // }
]

const asideMenuConfig = []

export { headerMenuConfig, asideMenuConfig }