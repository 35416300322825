import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import areaJson from './area.json'
import './Essentialinfo.scss'
import { Tabs, Table, Modal, message, Checkbox, Empty, Form, Input, Button, DatePicker, ConfigProvider, Cascader, Upload, Icon } from 'antd'
import UsersToUpgrade from './UsersToUpgrade.jsx'
import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传类型是JPG/PNG文件');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('上传图片不能超过2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class Essentialinfo extends Component {
  constructor() {
    super()
    this.state = {
      locale: {
        // emptyText: <img src={(require('./images/hint.png'))}></img>
      },
      personageMessage: {},
      city: '',//市
      province: '',//省
      county: '',//县区
      nickName: '',
      loading: false, // 上传图片的loading图
      imageUrl: '', // 上传图片的url
      upgrade: '0',
      isShowDelShopPopup: false,
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    this.Essential()
    // this.shoppinglist()
  }

  Essential() {
    BackEnd.information('/api/UserCenter/GetBaseis', {
      userId: Number(window.localStorage.getItem('userId'))
      // userId: 3221
    }).then(res => {
      console.log('信息', res)
      this.setState({
        personageMessage: res.data.responseObj,
        imageUrl: res.data.responseObj.photos == '' ? null : res.data.responseObj.photos,
        nickName:res.data.responseObj.nickName,
        city:  res.data.responseObj.city,//
        province:  res.data.responseObj.province,
        county:  res.data.responseObj.county,
      })
    })
  };

  companyArea(value) {
    console.log(value.toString());
    this.setState({
      province: value[0],
      city: value[1],
      county: value[2],
    })
  };
  handleChange = (info, fileList, event) => {

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>

        this.setState({
          imageUrl: info.file.response.datas.baseUrl + info.file.response.datas.filePath,
          loading: false,
        }),
      );
    }
  };
  UpdaBaseis() {
    let modification = {
      "imId": Number(window.localStorage.getItem('IMId')),
      "photos": this.state.imageUrl,
      "nickName": this.state.nickName,
      "province": this.state.province,
      "city": this.state.city,
      "county": this.state.county
    }
    console.log(modification)
    BackEnd.amendinFormation('/api/UserCenter/UpdaBaseis', modification).then(res => {
      console.log('信息123', res)
      if (res.data.code == "0") {
        this.setState({
          isShowDelShopPopup: true
        })
      }
    })
  };

  render() {
    const uploadButton = (
      <div className='ant-upload_int'>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    return (
      <div>


        <Header></Header>
        <Usercenter></Usercenter>
        <div className="Essentialinfobox">
          <p className='title'>基本信息</p> 
          <div className="Essentialinfo">
            {
              this.state.upgrade === '1'
                ? this.props.history.push("/Usercenter/UsersToUpgrade") :
                <div>
                  <p>用户类型 ：
                    {this.state.personageMessage.enterpriseCategory == '1' ? <span>汽配商</span>  : " "}
                    {this.state.personageMessage.enterpriseCategory == '2' ? <span>汽修厂</span>  : " "}
                    {this.state.personageMessage.enterpriseCategory == '3' ? <span>个人</span>    : " "}
                    {this.state.personageMessage.enterpriseCategory == '4' ? <span>厂家</span>    : " "}
                   &nbsp;&nbsp;&nbsp;&nbsp;
                   {
                      window.localStorage.getItem("EnterpriseCategory") == '3' 
                      ?
                      <button style={{ padding: '0 10px' }} onClick={() => { this.setState({ upgrade: "1" }) }}>升级为企业用户</button> 
                      : ""
                   }
                  
                  </p>
                  <div   style={{ display: 'flex' , marginBottom:'20px'}}><div style={{ display: 'inline-block', 'line-height': '80px' }}>用户头像 ：</div>
                    <div className="photos_ke">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://shop.chexd.com/apis/upload/uploadFile"
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                      >
                        {this.state.imageUrl ? <img className="photos_img" src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                      </Upload>
                    </div>
                  </div>
                  <p style={{marginBottom:'17px'}}>姓名/昵称：<Input style={{ width: "187px" }} placeholder="" value={this.state.nickName} onChange={(e) => { this.setState({ nickName: e.target.value }, () => { console.log(this.state.nickName) }) }} />&nbsp;&nbsp;&nbsp;&nbsp;</p>


                  <div>所属地区 :&nbsp;&nbsp;&nbsp;
                    
                    <Cascader options={areaJson}
                    style={{width:'187px'}}
                    onChange={this.companyArea.bind(this)}
                    value={[this.state.province,this.state.city,this.state.county]}
            />&nbsp;&nbsp;&nbsp;&nbsp; 
            
            </div>
                  <div className="Submits" onClick={() => { 
                   if (this.state.nickName == "") {
                    message.error('请填写联系人姓名');
                    }else if(!/(?:[\u4E00-\u9FFF]{1,8}·\u4E00-\u9FFF]{1,8})|(?:[\u4E00-\u9FFF]{2,5})/.test(this.state.nickName)){
                      message.error('请输入正确的联系人姓名');
                    }else{
                      this.UpdaBaseis() 
                    }
                    }}>提交</div>
                </div>
            }
          </div>
        </div>
        {
          this.state.isShowDelShopPopup ? 
          <div className="shopPopup">
            <div className="main">
              <div className="title">
                <span>提示</span>
                <span onClick={() => { this.setState({ isShowDelShopPopup: false }) }}>x</span>
              </div>
              <div className="shopCont">
                <p style={{ color: '#000' }}>修改成功</p>
              </div>
              <div className="btnBox">
                <button onClick={() => { this.setState({ isShowDelShopPopup: false }) }}>确定</button>
              </div>
            </div>
          </div>
            : ""
        }

      </div >
    )
  }
}
export default Form.create({})(Essentialinfo)
