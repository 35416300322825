import React, { Component } from 'react';
import BackEnd from '../../../../services/api'
import Header from '../../component/Header/Header'
import Footer from '../../component/Footer/Footer'
import "./Recharge.scss"
import { Pagination, message, Select ,Modal,Spin} from 'antd'
import QRCode  from 'qrcode.react'; 
import {getBeforeDate} from "../../../../utils/filter"
import moment from 'moment'; 
import creatHistory from 'history/createHashHistory'  //返回上一页这段代码
const history = creatHistory();


function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <span>上一页</span>;
    } if (type === 'next') {
      return <span>下一页</span>;
    }
    return originalElement;
  }
class Recharge extends Component {
    constructor(){
        super()
        this.state = {
            TabStatus:2,
            arr:[],
            arrs:[],
            CountPerPage: 0, // 每页数量
            TotalCount: 0, // 查询充值结果总数量
            TotalCounts: 0, // 查询消费结果总数量
            Page: 1, 
            informationList:{
                currentPage: 1,
                pageSize: 5
            },
            priceNnm:'',
            tooltip:false,
            CodeImg:'',
            PayId:"",
            balance:'0.00',
            loading:true,
            loadings:true,
            loadingss:true,


        }
        
    }

    componentDidMount() {
        this.GetRepaidBalanceVoucher() // 获取充值记录
        this.GetIndustryInformationVoucher() // 获取充值记录
        this.getAccountBalance() // 获取充值记录
      }
      componentWillUnmount() {
        clearInterval(this.timerID);
      }
    
      
    changeStatus(){
        this.setState({
            TabStatus:1
        })
        console.log(this.state.TabStatus)
    }
    changeStatuss(){
        this.setState({
            TabStatus:2
        })
        console.log(this.state.TabStatus)
    }
      //充值记录分页
      conversion = (page) => {
          console.log()
        let changePage = Object.assign({}, this.state.informationList, { currentPage: page })
        console.log(changePage)
        window.scrollTo(0, 0);
        this.setState({
            informationList: changePage
        }, () => { this.GetRepaidBalanceVoucher() })
      }
      firstPage = () => {
        let changePage = Object.assign({}, this.state.informationList, { currentPage: 1 })
        window.scrollTo(0, 0);
        this.setState({
            informationList: changePage
        }, () => { this.GetRepaidBalanceVoucher() })
      }
      endPage = () => {
        let changePage = Object.assign({}, this.state.informationList, { currentPage: Math.ceil(this.state.TotalCount / 5) || 1 })
        window.scrollTo(0, 0);
        this.setState({
            informationList: changePage
        }, () => { this.GetRepaidBalanceVoucher() })
      }

    //充值记录
    GetRepaidBalanceVoucher(){
            BackEnd.companySquare('/api/IndustryInformation/GetRepaidBalanceVoucher',this.state.informationList).then(res =>{
                console.log(res)
                if(res.data.Message == "Success"){
                    this.setState({
                        TotalCount: res.data.ResponseObj.CustomTotalNum,
                        loadings:false,
                        arr:res.data.ResponseObj.Items,

                    })
                }
                // console.log(res.data.ResponseObj.Items)
                
            })
    }
     //购买记录
     GetIndustryInformationVoucher(){
        BackEnd.companySquare('/api/IndustryInformation/GetIndustryInformationVoucher',this.state.informationList).then(res =>{
            console.log(res)
            if(res.data.Message == "Success"){
                this.setState({
                    TotalCounts: res.data.ResponseObj.CustomTotalNum,
                    loadings:false,
                    arrs:res.data.ResponseObj.Items
                })
            }
            
        })
}
    //消费记录分页
    conversions = (page) => {
        console.log(page)
        let changePage = Object.assign({}, this.state.informationList, { currentPage: page })
        console.log(changePage)
        window.scrollTo(0, 0);
        this.setState({
            informationList: changePage
        }, 
        () => { this.GetIndustryInformationVoucher() })
    }
    firstPages = () => {
        let changePage = Object.assign({}, this.state.informationList, { currentPage: 1 })
        window.scrollTo(0, 0);
        this.setState({
            informationList: changePage
        }, () => { this.GetIndustryInformationVoucher() })
    }
    endPages = () => {
        let changePage = Object.assign({}, this.state.informationList, { currentPage: Math.ceil(this.state.TotalCount / 5) || 1 })
        window.scrollTo(0, 0);
        this.setState({
            informationList: changePage
        }, () => { this.GetIndustryInformationVoucher() })
    }
    
    handleChange = (event) => {
        this.setState({
            priceNnm: event.target.value
        })
        console.log(this.state.priceNnm)
    }


    tubeHandletooltip = () => {
        message.success("充值有轻微延迟，预计10s后到账，您可稍后刷新查看！");
        this.setState({
            tooltip: false
        })
        this.timerID = setInterval(
            () => {
                this.getOrderStatus()
            },
            3000
        );
      
    }
    
    //获取余额
    getAccountBalance(){
        BackEnd.companySquare('/api/IndustryInformation/GetAccountBalance').then(res =>{
            console.log(res)
            if(res.data.Message == "Success"){
             
                this.setState({
                  loading:false,

                  balance:Number(res.data.ResponseObj).toFixed(2)
                })
            }
            
        })
    }
    
  
    goUp(){
        history.goBack();
    }
    //支付
    goPay(){
        //下单
        BackEnd.companySquare('/api/IndustryInformation/GetRepaidBalanceQRCode',{Price:this.state.priceNnm}).then( res =>{

            this.setState({
                PayId:res.data.ResponseObj,
            })
            // BackEnd.companySquare('/api/IndustryInformation/GetRepaidBalanceQRCode?Price='+${this.state.priceNnm}).then( res =>{
            console.log(res)
            //下单成功
            if(res.data.Code == '0'){
                // 支付参数
                let initData = {
                    Code:31005,
                    VoucherCode:res.data.Message,
                    Amount:this.state.priceNnm,
                    ServiceId:'8',
                    serviceName:'官网账户充值'
                }
               //获取二维码
                BackEnd.getData(initData).then(res =>{
                    if(res.data.Message.Success == "True"){
                        console.log(res)
                        this.setState({  
                            CodeImg:res.data.Message.Content,
                            // PayId:res.data.Message.PayId,
                            tooltip:true
                        })

                    }else{
                        message.error("获取付款码失败");
                    }
                   
                })
            }else{
                message.error("下单失败");
            }
          

        })
        console.log(this.state.priceNnm)
    }
      // 获取订单状态
      getOrderStatus(){
        // console.log("1111")
        BackEnd.companySquare('/api/IndustryInformation/GetRepaidBalanceVoucherState',{Id:this.state.PayId}).then(res => {
            console.log(res)
            if(res.data.Code == '0' && res.data.ResponseObj == "1"){
                
                this.GetRepaidBalanceVoucher();
                this.getAccountBalance() // 获取充值记录
                clearInterval(this.timerID);


                // window.location.href="/"
            }else{
            }

        })
    }


    
    render() {
        return (
            <div>
                <Header />
                    <div className="Recharge">
                        <div className="Recharge_main">
                            
                            <p className="next" onClick={()=>{this.goUp()}}>返回</p>
                            <Spin spinning={this.state.loading}>
                                <p className="Recharge_title">账户余额<i style={{color:"#ff6b37"}}>{this.state.balance}</i>元</p>
                            </Spin>
                            <input type="text"  placeholder="请输入充值金额，大于1的整数" onChange={this.handleChange}/>

                                {
                                    this.state.priceNnm >= 1 ?  
                                    <div   className='Recharge_btns' onClick={()=>{this.goPay()}} >充值</div>
                                    :
                                    <div    className='Recharge_btn'>充值</div>

                                }
                               <Modal
                                    title="微信支付"
                                    closeIcon="关闭"
                                    visible={this.state.tooltip}
                                    onCancel={this.tubeHandletooltip}
                                    footer={null}
                                    centered
                                    style={{ margin: 'auto' }}
                                    wrapClassName="placeTubeModel"
                                    width={400}
                                >
                                    <div className="tubePlacetoolti" style={{height:'350px',width:"200px"}}>
                                    <p style={{textAlign:'center',marginTop:'20px',color:"#FF6B37"}}>付款金额{this.state.priceNnm}元</p>
                                        <QRCode className="QRCode-qrCode" value={this.state.CodeImg}  size={200}/>
                                    <p style={{textAlign:'center'}}>微信支付完成后，请关闭</p>
                                    </div>
                                </Modal>
                           
                            <div className="line"></div>
                            

                            <div className="tab_main">
                                <div className="tab_title">
                                    <span style={{float:"left"}}   onClick={()=>{this.changeStatus()}}  className={this.state.TabStatus == 1 ? 'add' : ''}>充值记录</span>
                                    <span style={{float:"right"}}  onClick={()=>{this.changeStatuss()}}  className={this.state.TabStatus == 2 ? 'add' : ''}>消费记录</span>
                                </div>
                                <Spin spinning={this.state.loadings}>
                                
                                {
                                    this.state.TabStatus == 1 ?
                                    <div className="tab_a">
                                        <div className="tab_a_main">
                                            <span className="fl">时间</span>
                                            <span className="fr">金额</span>
                                        </div>
                                        
                                        {
                                                this.state.arr.map(
                                                    (item,index) =>  <div  key={index} className="tab_a_mains">
                                                        <span className="fl">{moment(item.UpdateTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        <span className="fr">{item.OriginalPrice}元</span>
                                                        </div>
                                                    )
                                            }
                                        
                                        <div className="list_info_paginations">
                                                <span className="first_button" onClick={this.firstPage}>首页</span>
                                                <Pagination
                                                total={this.state.TotalCount || 5}
                                                //   current={this.state.searchFirm.currentPage}
                                                itemRender={itemRender}
                                                pageSize={5}
                                                onChange={this.conversion}
                                                />
                                                <span className="last_button" onClick={this.endPage}>末页</span>
                                            </div>
                                        
                                    </div> 
                                    : 
                                    <div className="tab_b">
                                            <div className="tab_b_main">
                                                <span style={{width:'170px'}}>时间</span>
                                                <span style={{width:'100px'}}>金额</span>
                                                <span style={{width:'300px'}}>项目</span>
                                            </div>
                                            {
                                                this.state.arrs.map(
                                                    (item,index) =>  <div  key={index} className="tab_b_mains">
                                                        <span style={{width:'170px'}}>{moment(item.AddTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</span>
                                                        <span style={{width:'100px'}}>{item.Price}元</span>
                                                        <span style={{width:'300px',height:'27px',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{item.Title}</span>
                                                        </div>
                                                          
                                                    )
                                            }
                                             <div className="list_info_paginations">
                                                <span className="first_button" onClick={this.firstPages}>首页</span>
                                                <Pagination
                                                total={this.state.TotalCounts || 5}
                                                //   current={this.state.searchFirm.currentPage}
                                                itemRender={itemRender}
                                                pageSize={5}
                                                onChange={this.conversions}
                                                />
                                                <span className="last_button" onClick={this.endPages}>末页</span>
                                            </div>
                                    </div>
                                    
                                }
                             </Spin>
                            </div>
                           
                        </div>

                    </div>
                <Footer />
            
            </div>
        );
    }
}

export default Recharge;
