import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { getCookie, deleteCookie } from '../../utils/env'
import { Pagination, message, Select, Dropdown, Menu,Modal } from 'antd'
import BackEnd from '../../services/api'
import areaData from './data.json'
import './Square.scss'
import Base64 from 'base-64';
import {
  encryptByDES,
  encryptPassword,
  encryptEDSPwd,
  jsdaijdojs
} from '../../utils/encrypt';
const { Option } = Select;
message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}
class Square extends Component {
  constructor() {
    super()
    this.state = {
      provinceValue: undefined,// 省级查询值
      cityValue: undefined, // 市级查询值
      regionValue: undefined, // 区级查询值
      cityDisabled: true, // 区域市级按钮
      regionDisabled: true, // 区域区级按钮
      isParts: false,// 零件查找
      carSeries: [],// 车系
      reminder: '请输入企业名称、品牌、简介', // 搜索框提示文字
      currentIndex: -1, // 一级标题
      currentIndexs: -1, // 二级标题
      showMold: false, // 显示2级
      visible: false, // 登录
      searchFirm: {
        // Code: 27001,
        Flag: 0,
        Content: '',
        Page: 1,
        CountPerPage: 5,
        Province: '', // 省份
        City: '', // 城市
        County: '', // 区县
        OrderType: 0, // 排序类型 默认为智能
        Legalize: 2,  // 是否认证 默认为全部
        CarSeriesName: '', // 车系
        BrandName: '', // 品牌名字
        OrderByDescending: true
      },
      isapprove: false, // 认证状态
      isCapacity: true, // 智能筛选
      isPopularity: false, // 人气筛选
      isLively: false, // 活跃筛选
      searchList: [],
      CountPerPage: 0, // 每页数量
      TotalCount: 0, // 查询结果总数量
      Page: 1, // 第几页
      isService: false,
      ImServices: null

    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    if (JSON.parse(sessionStorage.getItem('choose'))) {
      let Reserve = JSON.parse(sessionStorage.getItem('choose'))[1]
      let changeActive = JSON.parse(sessionStorage.getItem('choose'))[0]
      let changeReserve = Object.assign({}, this.state.searchFirm,
        { Flag: Reserve.Flag },
        { Content: Reserve.Content },
        { OrderType: Reserve.OrderType },
        { CarSeriesName: Reserve.CarSeriesName },
        { BrandName: Reserve.BrandName },
        { Page: Reserve.Page },
        { Province: Reserve.Province },
        { City: Reserve.City },
        { County: Reserve.County },
      )
      this.setState({
        searchFirm: changeReserve,
        isParts: changeActive.isParts,
        currentIndex: changeActive.currentIndex,
        currentIndexs: changeActive.currentIndexs,
        showMold: changeActive.showMold,
        isCapacity: changeActive.isCapacity, // 智能筛选
        isPopularity: changeActive.isPopularity, // 人气筛选
        isLively: changeActive.isLively, // 活跃筛选
        provinceValue: Reserve.Province || undefined, // 省级查询值
        cityValue: Reserve.City || undefined, // 市级查询值
        regionValue: Reserve.County || undefined,// 区级查询值
        cityDisabled: Reserve.cityDisabled, // 区域市级按钮
        regionDisabled: Reserve.regionDisabled, // 区域区级按钮
      })
    }
  }
  componentDidMount() {
    // alert("222")
    this.trademark() // 对应品牌请求
    this.findData() // 请求列表数据
  }
  findData() {
    // BackEnd.companySquare('/api/EnterpriseSquareInfo/GetQueryStoreSquareList', this.state.searchFirm).then(res => {
    BackEnd.companySquare('/api/EnterpriseSquareInfo/GetQueryStoreSquareEncryptionList', { ciphertext: Base64.encode(encryptEDSPwd(JSON.stringify(this.state.searchFirm))) }).then(res => {
      console.log('加密', Base64.encode(encryptEDSPwd(JSON.stringify(this.state.searchFirm))));
      if (res.data.Message == "Success") {
        if (res.data.ResponseObj) {
          this.setState({
            TotalCount: res.data.ResponseObj.TotalCount,
            searchList: res.data.ResponseObj.StoreSquareListGroup
          }, () => {
            sessionStorage.setItem('choose', JSON.stringify(
              [{
                isParts: this.state.isParts, // 是否按零件查找
                currentIndex: this.state.currentIndex, // 一级标题
                currentIndexs: this.state.currentIndexs, // 二级标题
                showMold: this.state.showMold, // 是否显示二级标题
                isCapacity: this.state.isCapacity, // 智能筛选
                isPopularity: this.state.isPopularity, // 人气筛选
                isLively: this.state.isLively, // 活跃筛选
              }, {
                Flag: this.state.searchFirm.Flag, // 搜索类型
                Content: this.state.searchFirm.Content, // 搜索框的内容
                Province: this.state.searchFirm.Province, // 省份
                City: this.state.searchFirm.City, // 城市
                County: this.state.searchFirm.County, // 区县
                Page: this.state.searchFirm.Page, // 当前的页码
                OrderType: this.state.searchFirm.OrderType, // 排序类型 默认为智能
                CarSeriesName: this.state.searchFirm.CarSeriesName, // 车系
                BrandName: this.state.searchFirm.BrandName, // 品牌名字
                Page: this.state.searchFirm.Page, // 页码
                cityDisabled: this.state.cityDisabled, // 区域市级按钮
                regionDisabled: this.state.regionDisabled, // 区域区级按钮
              }]
            ))
          })
        }
      }
    })
  }
  trademark() {
    BackEnd
      .getData({
        Code: '27005'
      })
      .then(res => {
        if (res.data.Message.CarSeriesListGroup && res.data.Message.CarSeriesListGroup.length > 0) {
          this.setState({
            carSeries: res.data.Message.CarSeriesListGroup
          })
        }
      })
  }
  // 清除缓存数据
  clearData = () => {
    sessionStorage.removeItem('detail')

  }
  // 是否按零件排行查找
  isParts = () => {
    this.setState({
      isParts: !this.state.isParts
    }, () => {
      if (this.state.isParts) {
        let ChangeFlag = Object.assign({}, this.state.searchFirm, { Flag: 1 }, { Page: 1 })
        this.setState({
          searchFirm: ChangeFlag,
          reminder: '请输入配件编码、配件名称进行查询'
        })
      } else {
        let ChangeFlag = Object.assign({}, this.state.searchFirm, { Flag: 0 }, { Page: 1 })
        this.setState({
          searchFirm: ChangeFlag,
          reminder: '请输入企业名称、品牌、简介'
        })
      }
    })
  }
  // 点击搜索
  search = () => {
    let inputValue = Object.assign({}, this.state.searchFirm, { Content: this.input.value }, { Page: 1 }, { OrderType: 0 }, { CarSeriesName: '' }, { BrandName: '' })
    this.setState({
      searchFirm: inputValue,
      currentIndex: -1,
      currentIndexs: -1,
      showMold: false,
      isCapacity: true, // 智能筛选
      isPopularity: false, // 人气筛选
      isLively: false, // 活跃筛选
    }, () => {
      this.findData()
    })
  }
  // 一级标题
  setCurrentIndex(index, e) {
    if (index !== '-1') {
      this.setState({
        currentIndex: index,
        currentIndexs: -1,
        showMold: true,
      })
    } else {
      let ChangeCarSeriesName = Object.assign({}, this.state.searchFirm, { CarSeriesName: '' }, { BrandName: '' }, { Page: 1 })
      this.setState({
        currentIndex: -1,
        currentIndexs: -1,
        showMold: false,
        searchFirm: ChangeCarSeriesName,
      }, () => { this.findData() })
    }
  }
  // 二级标题
  setCurrentIndexs(indexs, e) {
    e.stopPropagation()// 阻止事件冒泡
    let ChangeBrandName = Object.assign({}, this.state.searchFirm, { BrandName: e.target.getAttribute('value') }, { Page: 1 })
    this.setState({
      currentIndexs: indexs,
      searchFirm: ChangeBrandName
    }, () => { this.findData() })
  }
  // 是否为认证企业
  // isapprove =()=> {
  //   this.state.isapprove = !this.state.isapprove
  //   if (this.state.isapprove) {
  //     let newLegalize = Object.assign({}, this.state.searchFirm, { Legalize: 1 }, { Page: 1 })
  //     this.setState({
  //       searchFirm: newLegalize
  //     }, () => { this.findData() })
  //   } else {
  //     let newLegalize = Object.assign({}, this.state.searchFirm, { Legalize: 0 }, { Page: 1 })
  //     this.setState({
  //       searchFirm: newLegalize
  //     }, () => { this.findData() })
  //   }
  // }
  // 智能能筛选 
  capacity = () => {
    let changeOrderType = Object.assign({}, this.state.searchFirm, { OrderType: 0 }, { Page: 1 })
    this.setState({
      searchFirm: changeOrderType,
      isCapacity: true,
      isPopularity: false,
      isLively: false
    }, () => { this.findData() })
  }
  // 人气筛选
  popularity = () => {
    let changeOrderType = Object.assign({}, this.state.searchFirm, { OrderType: 1 }, { Page: 1 })
    this.setState({
      searchFirm: changeOrderType,
      isCapacity: false,
      isPopularity: true,
      isLively: false
    }, () => { this.findData() })
  }
  // 活跃筛选
  lively = () => {
    let changeOrderType = Object.assign({}, this.state.searchFirm, { OrderType: 2 }, { Page: 1 })
    this.setState({
      searchFirm: changeOrderType,
      isCapacity: false,
      isPopularity: false,
      isLively: true
    }, () => { this.findData() })
  }
  // 切换分页
  conversion = (page) => {
    let changePage = Object.assign({}, this.state.searchFirm, { Page: page })
    window.scrollTo(0, 0);
    this.setState({
      searchFirm: changePage
    }, () => { this.findData() })
  }
  firstPage = () => {
    let changePage = Object.assign({}, this.state.searchFirm, { Page: 1 })
    window.scrollTo(0, 0);
    this.setState({
      searchFirm: changePage
    }, () => { this.findData() })
  }
  endPage = () => {
    let changePage = Object.assign({}, this.state.searchFirm, { Page: Math.ceil(this.state.TotalCount / 5) || 1 })
    window.scrollTo(0, 0);
    this.setState({
      searchFirm: changePage
    }, () => { this.findData() })
  }
  // 省级下拉选项
  selectProvince = (value) => {
    let newProvinceValue = Object.assign({}, this.state.searchFirm,
      { Province: value },
      { City: '' },
      { County: '' }
    )
    this.setState({
      searchFirm: newProvinceValue,
      provinceValue: value,
      cityValue: undefined,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        cityDisabled: false,
        regionDisabled: true // 禁用区级选项
      })
    } else {
      // 清空按钮触发时
      let newProvinceValue = Object.assign({}, this.state.searchFirm,
        { Province: '' },
        { City: '' },
        { County: '' }
      )
      this.setState({
        searchFirm: newProvinceValue,
        cityDisabled: true,
        regionDisabled: true,
        provinceValue: value,
      })
    }
  }
  // 市级下拉选项
  selectCity = (value) => {
    let newCityValue = Object.assign({}, this.state.searchFirm,
      { City: value },
      { County: '' }
    )
    this.setState({
      searchFirm: newCityValue,
      cityValue: value,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        regionDisabled: false
      })
    } else {
      // 清空按钮触发时
      let newCityValue = Object.assign({}, this.state.searchFirm,
        { City: '' },
        { County: '' }
      )
      this.setState({
        searchFirm: newCityValue,
        regionDisabled: true,
        regionValue: value
      })
    }
  }
  // 区级下拉选项
  selectRegion = (value) => {
    let newRegionValue = Object.assign({}, this.state.searchFirm,
      { County: value }
    )
    if (value) {
      this.setState({
        searchFirm: newRegionValue,
        regionValue: value,
      })
    } else {
      // 清空按钮触发时
      let newRegionValue = Object.assign({}, this.state.searchFirm,
        { County: '' }
      )
      this.setState({
        searchFirm: newRegionValue,
        regionValue: value,
      })
    }
  }

  loginVisible = () => {
		this.setState({
			visible: false
		}, () => {
			this.props.history.push('/')
		})
	}

  goLogin = () => {
		this.props.history.push('/login')
	}

  ImChat(index) {
    if(window.localStorage.getItem('userId') != null ){
        this.setState({
          ImServices:index
        },()=>{
          if(this.state.ImServices == index ){
            this.setState({ isService:!this.state.isService })
          }
        })
    }else{
      this.setState({
         visible: true, 
      })
    }
    
  }

  render() {


    return (
      <div className="square_container">
        <div className="square_content">
          {/* 搜索头部 */}
          <div className="square_header">
            <div className="square_loge">
              <img src={require("./images/square_log.png")} alt="loge" />
            </div>
            <div className="square_search">
              <div className="choose_search">
                <span>找企业</span>
                {/* <span>主营配件企业排行</span> */}
                <div className="parts">
                  <input type="checkbox" id={"parts"} onClick={this.isParts} defaultChecked={this.state.isParts} />
                  <label htmlFor={"parts"}>主营配件企业排行</label>
                </div>
                <div>
                  {
                    window.localStorage.getItem('AuthCode') == undefined ?

                      (<Link className={window.localStorage.getItem('AuthCode') != '0' ? 'myShop' : "myShops"}
                        to={{
                          pathname: "/login",
                        }
                        }
                      >我的店铺</Link>)

                      :
                      // (<Link className={window.localStorage.getItem('AuthCode') != '0' ? 'myShop' : "myShops"}
                      //   to={{
                      //     pathname: "/personalStore",
                      //     search: `?id=${window.localStorage.getItem('AuthCode')}`
                      //   }
                      //   }
                      // >我的店铺</Link>)
                      (<a className={window.localStorage.getItem('AuthCode') != '0' ? 'myShop' : "myShops"} href={window.localStorage.getItem('storeUrl') + "&prevType=1"}>我的店铺</a>)
                  }
                  <div className={window.localStorage.getItem('AuthCode') == '0' ? 'myShop' : "myShops"}>请联系客服入驻</div>
                </div>
              </div>

              <div className="search_input">
                <input type="text" placeholder={this.state.reminder} ref={input => this.input = input} defaultValue={this.state.searchFirm.Content} />
                <span className="search_btn" onClick={this.search}>搜索</span>
              </div>
              {/* 区域查询 */}
              <div className="districtSearch">
                <span className="districtTitle">区域</span>
                <Select
                  // showSearch
                  style={{ width: 76.5, marginTop: 10, marginRight: 10, textAlign: "center" }}
                  placeholder="省"
                  optionFilterProp="children"
                  value={this.state.provinceValue}
                  onChange={this.selectProvince}
                  showArrow={false}
                  allowClear={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                  }
                </Select>
                <Select
                  // showSearch
                  style={{ width: 76.5, marginTop: 10, marginRight: 10, textAlign: "center" }}
                  placeholder="市"
                  optionFilterProp="children"
                  value={this.state.cityValue}
                  onChange={this.selectCity}
                  allowClear={true}
                  showArrow={false}
                  allowClear={true}
                  disabled={this.state.cityDisabled}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >{
                    areaData.ProvinceCity[this.state.provinceValue] ?
                      Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                        areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                          <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                        ))
                      )) : []
                  }
                </Select>
                <Select
                  // showSearch
                  style={{ width: 100, marginTop: 10, marginRight: 10, textAlign: "center" }}
                  placeholder="区"
                  optionFilterProp="children"
                  value={this.state.regionValue}
                  onChange={this.selectRegion}
                  showArrow={false}
                  allowClear={true}
                  disabled={this.state.regionDisabled}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >{
                    areaData.ProvinceCity[this.state.provinceValue] ?
                      Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                        areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                          item.Name == this.state.cityValue ?
                            item.CityArea.map(items => (
                              <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                            )) : ""
                        ))
                      )) : []
                  }
                </Select>
              </div>
            </div>
            <div className="service_phone">
              <img src={require("./images/service_phone.png")} alt="phone" />
            </div>
          </div>
          {/* 品牌列表 */}
          <div className="brand_list">
            <div className="brand_title">
              <span className="brand_listName">品牌:</span>
              <span onClick={this.setCurrentIndex.bind(this, "-1")} className={this.state.currentIndex == "-1" ? "active_01" : ""}>不限</span>
              {this.state.carSeries.map((item, index) => (
                <span
                  key={index}
                  value={item.Content}
                  className={this.state.currentIndex === index ? "active" : ""}
                  onClick={this.setCurrentIndex.bind(this, index)}
                >{item.Content}</span>
              ))}
            </div>
            <div className="list_detail" style={this.state.showMold ? { display: "block" } : { display: "none" }}>
              {this.state.carSeries[this.state.currentIndex] ? this.state.carSeries[this.state.currentIndex].Brand.split(",").map((items, indexs) => (
                <span
                  key={indexs}
                  value={items}
                  className={this.state.currentIndexs === indexs ? "active" : ""}
                  onClick={this.setCurrentIndexs.bind(this, indexs)}
                >{items}</span>
              )) : ""}
            </div>
            <div className="vacancy"></div>
          </div>
          {/* 查询结果 */}
          <div className="result_list">
            <div className="choose_result_list">
              {/* <div className="attestation">
                <input type="checkbox" id={"checkbox"} onChange={this.isapprove} />
                <label htmlFor={"checkbox"} className="word">认证企业</label>
              </div> */}
              <div></div>
              <div className="choose_rule">
                <span className={`capacity ${this.state.isCapacity ? "active" : ""}`} onClick={this.capacity}>智能</span>
                <span>|</span>
                <span className={`popularity ${this.state.isPopularity ? "active" : ""}`} onClick={this.popularity}>人气</span>
                <span>|</span>
                <span className={`lively ${this.state.isLively ? "active" : ""}`} onClick={this.lively}>活跃</span>
              </div>
            </div>
            <div className="result_list_content">
              {/* <Spin tip="查询中..."pinning={this.state.spinning} > */}
              {this.state.searchList ? this.state.searchList.map((item, index) => (
                <div className="list_info" key={index}>
                  <dl>
                    <dt>
                      {
                        item.SmallImgUrl ?
                          <img src={item.SmallImgUrl.split("|").slice(0, 1)} alt="门店图片" /> : <img src={require("./images/SquareDefaultImg_small.png")} alt="门店" />
                      }
                    </dt>
                    <dd>
                      <div className="storeInfo">
                        <div className="storeName">
                          {
                            item.DomainName == '' ?

                              <Link onClick={this.clearData}
                                to={
                                  {
                                    pathname: item.DomainName
                                    // search: `?id=${item.AuthCode}`
                                  }
                                }
                              >{item.Store}
                              </Link> : <a href={item.DomainName + '&prevType=1'}>{item.Store}</a>
                          }

                          {
                            // <Link onClick={this.clearData}
                            //   to={
                            //     {
                            //       pathname: "/personalStore",
                            //       search: `?id=${item.AuthCode}`
                            //     }
                            //   }
                            // >{item.Store}
                            // </Link>
                          }

                          {/* <Link onClick={this.clearData}
                            to={
                              {
                                pathname: "/personalStore",
                                search: `?id=${item.AuthCode}`
                              }
                            }
                          >{item.Store}
                          </Link> */}

                          {/* <a href={item.DomainName}>{item.Store}</a> */}
                        </div>
                        <p className="manage"><span>经营品牌:</span><span className="conceal" title={item.Brand}>{item.Brand}</span></p>
                        <p><span>所在地:<i></i></span><span>{item.Address}</span></p>
                        <p className="intro"><span>简介:<i></i></span><span className="conceal" title={item.Memo}>{item.Memo}</span></p>
                      </div>
                      <div className="storeCommitment">
                        {
                          <div className="im_span">

                            <i className="im_portrait"></i> <span className="im_zi" onClick={() => {
                              this.setState({
                                ImServices: index
                              }, () => {
                                if (this.state.ImServices == index) {
                                  this.setState({ isService: !this.state.isService })
                                }
                              })

                            }}>
                              客服聊天</span>
                            {this.state.ImServices == index && this.state.isService ?
                              <div className="service">
                                {
                                  item.ImServices.map((item_, index) =>
                                    <p key={index}>
                                      <a href="#"
                                        target="_blank"
                                        onClick={() => {
                                          window.open(`http://chatpre.chexd.com/webdemo/im/dialogue.html?ImKey=${window.localStorage.getItem('IMKey')}&Tokens=${window.localStorage.getItem
                                            ('IMTokens')}&To=${item_.IMKey},'', 'height=750, width=1100, top=200, left=300, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no`)
                                        }}
                                      >{item_.Name}</a>
                                    </p>

                                  )

                                }
                              </div>
                              :
                              ''
                            }
                          </div>

                        }

                        {item.Legalize == "1" ? <span><img src={require("./images/attestation.png")} alt="机构认证" />机构已认证</span> : ""}
                        {item.WhetherNoReason7days == "1" ? <span><img src={require("./images/seven.png")} alt="七天无理由退还" />支持七天无理由退款</span> : ""}
                        {item.WhetherOriginalParts == "1" ? <span><img src={require("./images/factory.png")} alt="原厂件承诺" />原厂件承诺</span> : ""}
                        {item.WhetherOE == "1" ? <span><img src={require("./images/OE.png")} alt="O E件承诺" />O E件承诺</span> : ""}
                        {item.WhetherDisassemble == "1" ? <span><img src={require("./images/takeDown.png")} alt="拆车件承诺" />拆车件承诺</span> : ""}
                        {item.BrandPromiseListGroup ?
                          <span><img src={require("./images/brand.png")} alt="品牌件承诺" />品牌件承诺</span> : ""}
                      </div>
                    </dd>
                  </dl>
                </div>
              )) : <div className="without">暂无信息</div>}
              {/* </Spin> */}
              <div className="list_info_pagination">
                <span className="first_button" onClick={this.firstPage}>首页</span>
                <Pagination
                  total={this.state.TotalCount || 5}
                  current={this.state.searchFirm.Page}
                  itemRender={itemRender}
                  pageSize={5}
                  onChange={this.conversion}
                />
                <span className="last_button" onClick={this.endPage}>末页</span>
              </div>
            </div>
          </div>
        </div>
        <Modal
					title="提示"
					visible={this.state.visible}
					onCancel={this.loginVisible}
					footer={null}
					maskClosable={false}
					wrapClassName='isLoginModel'
				>
					<p>请您先登录在与客服沟通</p>
					<p className='loginFunctions'><span className="spanis" onClick={this.goLogin}>登录</span></p>
				</Modal> 
      </div>
    )
  }
}
export default withRouter(Square)
