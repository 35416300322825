import React, { Component } from 'react'
import Header from '../Header'
import Broadside from './Broadside'
import './Particulars.scss'

export default class Particulars extends Component {
    render() {
        return (
            <div className="ParticularsPage">
                {/* 公共头部 */}
                <Header></Header>
                <div className="toBeConfirmedContainer">
                    {/* 公共侧边 */}
                    <Broadside></Broadside>
                </div>
            </div>
        )
    }
}
