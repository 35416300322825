import React, { Component } from 'react'
import Header from '../../components/Header'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty } from 'antd'
import './ShoppingCart.scss'
import BackEnd from '../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal




class ShoppingCart extends Component {
  constructor() {
    super()
    this.state = {
      locale: {
        emptyText: <img src={(require('./images/hint.png'))}></img>
      },
      selectedRowKeys: [], // 批量选中
      shoppingList: [], // 商品列表
      shopping: [], // 商品列表
      selectedRows: [], // 选中的行
      ListOrder: [],
      ProductDetailsList: [],
      userId: Number(window.localStorage.getItem('userId')),
      authCode: window.localStorage.getItem('AuthCode'),
      Id: 0,
      componentCode: '',
      componentName: '',
      componentId: 0,
      brand: '',
      vehicleMode: '',
      origin: '',
      companyName: '',
      region: '',
      unitPrice: '',
      unit: '',
      quantity: 0,
      showQuantity: '',
      Checked: false,
      columns: [{
        title: '配件编码',
        dataIndex: 'ComponentCode',
        key: 'ComponentCode',
        width: 120,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '配件名称',
        dataIndex: 'ComponentName',
        key: 'ComponentName',
        width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '产地',
        dataIndex: 'Origin',
        key: 'Origin',
        width: 80,
        ellipsis: true,
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '库存',
        dataIndex: 'ShowQuantity',
        key: 'ShowQuantity',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{text}</div>
        )
      }, {
        title: '单价',
        dataIndex: 'UnitPrice',
        key: 'UnitPrice',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{Number(text).toFixed(2)}</div>
        )
      }, {
        title: '订货数量',
        dataIndex: 'Quantity',
        key: 'Quantity',
        width: 150,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record, index) => (
          <div className="quantityContainer">
            <span onClick={this.removeEnquiryQuantity.bind(this, record, index)}>-</span>
            <input type="text" value={text} className="purchaseQuantity" maxLength="5" ref="quantity" readOnly />
            <span onClick={this.addEnquiryQuantity.bind(this, record, index)} >+</span>
          </div>
        )
      }, {
        title: '小计',
        width: 120,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div>{(Number(record.UnitPrice) * record.Quantity).toFixed(2)}</div>
        )
      }, {
        title: '操作',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div className="delete" onClick={this.deleteBill.bind(this, record)}>删除</div>
        )
      }],
      TotalMoney: 0,
      Quantity: 0,
      Code: 0,
      tooltip: false,
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    this.getShopCart()

    // this.shoppinglist()
  }
  // 获取全部商品列表数据
  getShopCart() {
    BackEnd.companySquare('/api/ShoppingCart/GetShoppingCartCache', {
      userId: Number(window.localStorage.getItem('userId'))
    }).then(res => {
      console.log('aaa', res)
      if (res.data.Code == 1) {

      } else {
        this.setState({
          shoppingList: res.data.ResponseObj.AuthCodeLiats,
        }, () => {

          console.log('购物车', this.state.shoppingList);
        })
      }
    })
  }

  // // 单行选中
  // isChecked(record, e) {
  //   this.setState({
  //     TotalMoney:(Number(record.UnitPrice) * record.Quantity) + this.state.TotalMoney,
  //     Quantity:record.Quantity + this.state.Quantity
  //   },()=>{
  //     console.log('单行选中', record,this.state.TotalMoney)

  //   })
  //   if (e.target.checked) {
  //     for (let i = 0; i < this.state.shoppingList.length; i++) {
  //       let tempA = this.state.shoppingList[i];
  //       for (let j = 0; j < tempA.ShoppingCartItem.length; j++) {
  //         let tempB = tempA.ShoppingCartItem[j].Id;

  //         if (tempB == record.Id) {
  //           this.state.shoppingList[i].ShoppingCartItem[j].Checked = e.target.checked;

  //           var bool = this.state.shoppingList[i].ShoppingCartItem.every(citem => citem.Checked)
  //             if(bool) {
  //               this.state.shoppingList[i].AllChecked = true
  //             }

  //             break;
  //         }
  //       }
  //     }

  //   } else {
  //     this.setState({
  //       TotalMoney:this.state.TotalMoney - (Number(record.UnitPrice) * record.Quantity) ,
  //       Quantity:  this.state.Quantity -record.Quantity
  //     },()=>{
  //       console.log('单行选中', record,this.state.TotalMoney)
  //     })

  //     for (let i = 0; i < this.state.shoppingList.length; i++) {
  //       let tempA = this.state.shoppingList[i];
  //       for (let j = 0; j < tempA.ShoppingCartItem.length; j++) {
  //         let tempB = tempA.ShoppingCartItem[j].Id;
  //         if (tempB == record.Id) {
  //           this.state.shoppingList[i].ShoppingCartItem[j].Checked = e.target.checked
  //           var bool = this.state.shoppingList[i].ShoppingCartItem.every(citem => citem.Checked)
  //           if(!bool) {
  //             this.state.shoppingList[i].AllChecked = false;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // 减少询价配件数量
  removeEnquiryQuantity(record, index) {
    console.log('减少数据', record)
    let newQuantity = this.state.shoppingList
    for (let i = 0; i < this.state.shoppingList.length; i++) {
      let tempA = this.state.shoppingList[i];
      for (let j = 0; j < tempA.ShoppingCartItem.length; j++) {
        let tempB = tempA.ShoppingCartItem[j].Id;
        if (tempB == record.Id) {
          if (tempA.ShoppingCartItem[j].Quantity <= 1) {
            return
          }
          this.state.shoppingList[i].ShoppingCartItem[j].Quantity--
          this.setState({
            shoppingList: newQuantity
          }, () => {
            console.log('修改后的', this.state.shoppingList);

            console.log(this.state.TotalMoney)
            console.log('减少数据', record.Checked)
            if (record.Checked == true) {
              this.setState({
                TotalMoney: Number(this.state.TotalMoney) - Number(record.UnitPrice),
                Quantity: Number(this.state.Quantity) - 1
              })
            }

          })
        }
      }
    }

  }
  // 增加询价配件数量
  addEnquiryQuantity(record, index) {
    let newQuantity = this.state.shoppingList

    for (let i = 0; i < this.state.shoppingList.length; i++) {
      let tempA = this.state.shoppingList[i];

      for (let j = 0; j < tempA.ShoppingCartItem.length; j++) {
        let tempB = tempA.ShoppingCartItem[j].Id;
        console.log(tempB)
        if (tempB == record.Id) {
          console.log(this.state.shoppingList[i].ShoppingCartItem[j].ShowQuantity)
          if (this.state.shoppingList[i].ShoppingCartItem[j].ShowQuantity == '>10') {
            if (this.state.shoppingList[i].ShoppingCartItem[j].Quantity + 1 > 10) {

              return message.success('单次下单不许超过10个', 1)
            }
          } else {
            if (this.state.shoppingList[i].ShoppingCartItem[j].Quantity + 1 > this.state.shoppingList[i].ShoppingCartItem[j].ShowQuantity) {
              return message.success('库存不足', 1)
            }
          }

          this.state.shoppingList[i].ShoppingCartItem[j].Quantity++

          this.setState({
            shoppingList: newQuantity,
            // ShowQuantity: this.state.shoppingList[i].ShoppingCartItem[j].ShowQuantity,
            // Quantity: this.state.shoppingList[i].ShoppingCartItem[j].Quantity
          }, () => {
            console.log('修改后的', this.state.shoppingList);
            if (record.Checked == true) {
              this.setState({
                TotalMoney: Number(this.state.TotalMoney) + Number(record.UnitPrice),
                Quantity: Number(this.state.Quantity) + 1
              })
            }
            // this.setState({
            //   TotalMoney: (Number(record.UnitPrice) * record.Quantity) + this.state.TotalMoney,
            //   Quantity: record.Quantity + this.state.Quantity
            // })
          })
        }
      }
    }

  }
  // 删除订单
  deleteBill = (record) => {
    console.log("删除订单", record);
    confirm({
      title: '提示',
      content: '确认删除此配件吗？',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        BackEnd
          .deteleHaveEnquiry(record.InquiryVoucherCode)
          .then(res => {
            console.log('删除成功', res.data);
            if (res.data.Message === 'Success') {
              BackEnd.companySquare('/api/ShoppingCart/DeleteShoppingCartCache', {
                authCodeAndId: [
                  {
                    authCode: record.AuthCode,
                    id: record.Id
                  }
                ],
                userId: Number(window.localStorage.getItem('userId'))
              }).then(res => {
                console.log('删除', res)
                if (res.data.Message == 'Success') {
                  window.location.reload()
                }
              })
            }
          })
      },
      onCancel() { },
    })

  }

  // 下单
  placeOrder = () => {

    let inSetPlaceAnOrder = {};
    inSetPlaceAnOrder.userId = Number(window.localStorage.getItem('userId'));
    let shoppingCartItemList = [];
    for (let i = 0; i < this.state.shoppingList.length; i++) {
      let item = this.state.shoppingList[i];
      for (let j = 0; j < item.ShoppingCartItem.length; j++) {

        if (item.ShoppingCartItem[j].Checked) {
          // console.log(item.ShoppingCartItem[j])
          let shoppingCartItem = {};
          shoppingCartItem.userId = Number(window.localStorage.getItem('userId'));
          shoppingCartItem.authCode = item.ShoppingCartItem[j].AuthCode;
          shoppingCartItem.id = item.ShoppingCartItem[j].Id;
          shoppingCartItem.componentCode = item.ShoppingCartItem[j].ComponentCode;
          shoppingCartItem.componentName = item.ShoppingCartItem[j].ComponentName;
          shoppingCartItem.componentId = item.ShoppingCartItem[j].ComponentId;
          shoppingCartItem.brand = item.ShoppingCartItem[j].Brand;
          shoppingCartItem.vehicleMode = item.ShoppingCartItem[j].VehicleMode;
          shoppingCartItem.origin = item.ShoppingCartItem[j].Origin;
          shoppingCartItem.companyName = item.ShoppingCartItem[j].CompanyName;
          shoppingCartItem.region = item.ShoppingCartItem[j].Region;
          shoppingCartItem.unitPrice = item.ShoppingCartItem[j].UnitPrice;
          shoppingCartItem.unit = item.ShoppingCartItem[j].Unit;
          shoppingCartItem.quantity = item.ShoppingCartItem[j].Quantity;
          shoppingCartItem.showQuantity = item.ShoppingCartItem[j].ShowQuantity;
          shoppingCartItemList.push(shoppingCartItem);
        }
      }
    }
    inSetPlaceAnOrder.shoppingCartItem = shoppingCartItemList;

    console.log('nihao', inSetPlaceAnOrder);
    BackEnd.companySquare('/api/ShoppingCart/InSetPlaceAnOrder', inSetPlaceAnOrder)
      .then(res => {
        if (res.data.Message == "Success") {
          message.success('下单成功')
          // return false
          this.props.history.push('/placeOrder')
        } else
          if (res.data.Code == 1) {
            message.info('请选择您要购买的商品！')
          }
        console.log('下单', res.data, res.data.Code, this.state.Id);
        this.setState({
          Code: res.data.Code
        })

        // if (res.data.Message == "Success") {
        //   this.setState({
        //     shoppingList: res.data.ResponseObj.AuthCodeLiats
        //   })
        // }
      })
  }


  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

    },
    onSelect: (record, selected, selectedRows) => {

      console.log('dada', record, selected)

      // this.removeEnquiryQuantity(record)
      if (selected) {
        record.Checked = selected
        console.log(record.UnitPrice)
        this.setState({
          TotalMoney: (Number(record.UnitPrice) * record.Quantity) + this.state.TotalMoney,
          Quantity: record.Quantity + this.state.Quantity
        }, () => {
          // console.log('单行选中', record,record.UnitPrice)

        })
      } else {
        record.Checked = selected
        this.setState({
          TotalMoney: this.state.TotalMoney - (Number(record.UnitPrice) * record.Quantity),
          Quantity: this.state.Quantity - record.Quantity
        }, () => {
          // console.log('单行', record,this.state.TotalMoney)

        })
      }

    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows)
      let TotalPice = 0;
      let TotalPices = 0;
      if (selected) {
        for (let i = 0; i < selectedRows.length; i++) {
          let tempA = selectedRows[i];
          tempA.Checked = selected
          TotalPice = TotalPice + Number(tempA.UnitPrice) * Number(tempA.Quantity)
          TotalPices = TotalPices + Number(tempA.Quantity)
        }

        this.state.Quantity += TotalPices;
        this.state.TotalMoney += TotalPice
        console.log(this.state.allprice)
      }
      else {
        for (let i = 0; i < changeRows.length; i++) {
          let tempA = changeRows[i];
          tempA.Checked = selected
          TotalPice = TotalPice + Number(tempA.UnitPrice) * Number(tempA.Quantity)
          TotalPices = TotalPices + Number(tempA.Quantity)
        }
        this.state.Quantity -= TotalPices;
        this.state.TotalMoney -= TotalPice

      }
      this.setState({
        TotalMoney: this.state.TotalMoney,
        Quantity: this.state.Quantity
      }, () => {
        // console.log('123456798',this.state.TotalMoney)
      })
    },

  };
  //关闭提示框
  tubeHandleTooltip = () => {
    this.setState({
      tooltip: false
    })
  }
  tubeHandletooltip = () => {
    this.setState({
      tooltip: true
    })
  }

  render() {
    // const { selectedRowKeys } = this.state;
    // const rowSelection = {
    //   selectedRowKeys,
    //   onChange: this.onSelectChange.bind(this)
    // };

    return (
      <div className="shoppoingCartPage">
        <Header></Header>
        <div className="shoppingContainer">
          <Tabs defaultActiveKey="1" className="Cart-big">
            <TabPane className="Cart" tab="购物车" key="1">
              <div className="shoppingContent">

                {this.state.shoppingList.length ?
                  this.state.shoppingList.map((item, index) => (
                    <div className="shoppingItem" key={index}>
                      <div className="storeInfo">
                        {/* <div className="allElection">
                           <Checkbox  onChange={this.select} /> 
                          <span>全选</span> 
                        </div> */}
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                            {item.ShoppingCartItem[0].CompanyName}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;<span>{item.Mobile}</span>
                            &nbsp;&nbsp;&nbsp;&nbsp;<span>{item.Address}
                        </span>
                      </div>
                      <div className="shoppingList">
                        <Table
                          width='100%'
                          // rowSelection={rowSelection}
                          rowKey={(record, index) => index}
                          columns={this.state.columns}
                          rowSelection={this.rowSelection}

                          dataSource={item.ShoppingCartItem}
                          bordered={true}
                          pagination={false}
                          locale={this.state.locale}
                          className='shoppingTable'
                        />
                      </div>
                    </div>
                  ))
                  : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </div>
              <div className="line"></div>
              <div className="settlementContent">
                {/* <span className="batchDetele">批量删除</span> */}
                <div className="finishOrder">
                  <p>共选{this.state.Quantity}件商品，总计：￥{(this.state.TotalMoney).toFixed(2)}（不含运费）</p>
                  <span onClick={() => {
                    // this.tubeHandletooltip() 
                    this.placeOrder();
                  }}>下单</span>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
        <Modal
          title="提示"
          closeIcon="关闭"
          visible={this.state.tooltip}
          onCancel={this.tubeHandleTooltip}
          footer={null}
          centered
          style={{ margin: 'auto' }}
          wrapClassName="placeTubeModel"
          width={400}
          maskStyle={{ background: 'rgba(129,148,148,.5)' }}
        >
          <div className="tubePlacetoolti" >
            <p className="tooltip">请确认已下单的商品！</p>
            <span className="tooltipSpan" onClick={() => {
              this.placeOrder();
              setTimeout(() => {
                if (this.state.Code == 1) {
                  message.info('请选择您要购买的商品！')
                } else {
                  this.props.history.push('/placeOrder')
                }
              }, 500)

            }}>确定</span>
          </div>
        </Modal>
      </div>
    )
  }
}
export default withRouter(ShoppingCart)
