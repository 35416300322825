import React, { PureComponent } from 'react'
import {
  Pagination,
  message,
  Table,
  DatePicker,
  Select,
  ConfigProvider,
  Modal
} from 'antd';
import { deleteCookie } from '../../utils/env'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import BackEnd from '../../services/api'
import areaData from './data.json'
import { formatDate } from '../../utils/filter'
import './BusinessQuery.scss'

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}
class BusinessQuery extends PureComponent {
  constructor() {
    super()
    this.state = {
      visible: true, // 未登录提示框
      TotalCount: 10,
      Page: 1,
      visibleProvider: false,
      visibleFitting: false,
      provinceValue: undefined,// 省级查询值
      cityValue: undefined, // 市级查询值
      regionValue: undefined, // 区级查询值
      cityDisabled: true, // 区域市级按钮
      regionDisabled: true, // 区域区级按钮
      resultList: [], // 查询结果列表
      ModelList: [], // 弹框数据列表
      CompanyDetail: [],
      locale: {
        emptyText: <img src={(require('./images/hint.png'))}></img>
      },
      startValue: null,
      endValue: null,
      endOpen: false,
      // 查询条件
      filterOption: {
        Code: 11031,
        UserName: "",
        Mobile: "",
        Region: "",
        ComponentCode: "",
        ComponentName: "",
        Brand: "",
        Origin: "",
        VehicleMode: "",
        EnterpriseCategory: 0,
        BeginTime: formatDate(new Date(), 'yyyymmdd') + '000000',
        EndTime: formatDate(new Date(), 'yyyymmdd') + '235960',
        CountPerPage: 10,
        Page: 0,
      },
      searchList: {
        Province: "", // 省份
        City: "", // 城市
        County: "", // 区县
      },
      columns: [{
        title: '企业名称',
        dataIndex: 'Name',
        key: 'Name',
        align: 'center',
        width: 180,
        ellipsis: true,
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '手机号',
        dataIndex: 'Mobile',
        key: 'Mobile',
        align: 'center',
        width: 120,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '用户类别',
        dataIndex: 'EnterpriseCategoryName',
        key: 'EnterpriseCategoryName',
        align: 'center',
        width: 100,
        ellipsis: true,
        render: (text, record) => (
          <div title={text} >
            {text}
          </div>
        )
      }, {
        title: '区域',
        dataIndex: 'Region',
        key: 'Region',
        align: 'center',
        width: 140,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '配件编码',
        dataIndex: 'ComponentCode',
        key: 'ComponentCode',
        align: 'center',
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <div title={text}
            className='omit'
            style={{ width: '230px' }}
          >
            {text}
          </div>
        )
      }, {
        title: '配件名称',
        dataIndex: 'ComponentName',
        key: 'ComponentName',
        align: 'center',
        width: 190,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '品牌',
        dataIndex: 'Brand',
        key: 'Brand',
        align: 'center',
        width: 120,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '车型',
        dataIndex: 'VehicleMode',
        key: 'VehicleMode',
        align: 'center',
        width: 100,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }, {
        title: '产地',
        dataIndex: 'Origin',
        key: 'Origin',
        align: 'center',
        width: 80,
        ellipsis: true,
        render: (text, record) => (
          window.localStorage.getItem('sessionId') ?
            <span title={text}>
              {text}
            </span> : <span>有货</span>
        )
      }, {
        title: '查询时间',
        dataIndex: 'OcurTime',
        key: 'OcurTime',
        align: 'center',
        width: 160,
        ellipsis: true,
        className: 'resultColumns',
        render: (text, record) => (
          <span title={text}>
            {text}
          </span>
        )
      }]
    }
    this.seek = this.seek.bind(this); // 搜索
    this.changePage = this.changePage.bind(this); // 分页切换
    this.firstPage = this.firstPage.bind(this); // 首页
    this.endPage = this.endPage.bind(this); // 末页
  }
  componentWillMount() {
    if (window.localStorage.getItem('EnterpriseCategory') && window.localStorage.getItem('EnterpriseCategory') != '3') {
      this.setState({
        visible: false
      })
    }
  }
  componentDidMount() {
    if (window.localStorage.getItem('EnterpriseCategory') && window.localStorage.getItem('EnterpriseCategory') != '3') {
      this.getData()
    }
  }
  getData() {
    BackEnd
      .getData(this.state.filterOption)
      .then(res => {
        if (res.data.Message.Code == '99996') {
          deleteCookie('LoginCacheKey')
          message.error('登录失效!请重新登录');
          return
        }
        if (res.data.Message.LookComponentRecordItemListGroup) {
          this.setState({
            TotalCount: Number(res.data.Message.TotalCount),
            resultList: res.data.Message.LookComponentRecordItemListGroup
          })
        } else {
          this.setState({
            TotalCount: 10,
            resultList: []
          })
          message.error('暂无此查询记录!');
        }
      }).catch(error => {
        this.setState({
          TotalCount: 10,
          resultList: []
        })
        message.error('暂无此查询记录!');
      })
  }
  seek() {
    let searchChange = Object.assign({}, this.state.filterOption,
      { UserName: this.refs.businessName.value.replace(/(^\s*)|(\s*$)/g, "") },
      { Mobile: this.refs.businessPhone.value.replace(/(^\s*)|(\s*$)/g, "") },
      { ComponentCode: this.refs.businessCode.value.replace(/(^\s*)|(\s*$)/g, "") },
      { ComponentName: this.refs.businessCodeName.value.replace(/(^\s*)|(\s*$)/g, "") },
      { Brand: this.refs.businessBrand.value.replace(/(^\s*)|(\s*$)/g, "") },
      { VehicleMode: this.refs.businessVehicleMode.value.replace(/(^\s*)|(\s*$)/g, "") },
      { Origin: this.refs.businessOrigin.value.replace(/(^\s*)|(\s*$)/g, "") },
      { Region: this.state.searchList.Province != '' ? this.state.searchList.County != '' ? `${this.state.searchList.Province},${this.state.searchList.City},${this.state.searchList.County}` : `${this.state.searchList.Province},${this.state.searchList.City}` : '' },
      { Page: 0 }
    )
    this.setState({
      filterOption: searchChange,
      Page: 1
    }, () => {
      this.getData()
    })
  }
  // 登录状态弹框
  loginVisible = () => {
    this.setState({
      visible: false
    }, () => {
      this.props.history.push('/')
    })
  }
  // 去注册
  goRegister = () => {
    this.props.history.push('/register')
  }
  // 去登录
  goLogin = () => {
    this.props.history.push('/login')
  }
  // 日期开始时间范围
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  // 日期结束时间范围
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    if (field === 'startValue') {
      let newDate = Object.assign({}, this.state.filterOption, { BeginTime: value ? formatDate(value._d, 'yyyymmdd') + '000000' : 0 })
      this.setState({
        [field]: value,
        filterOption: newDate
      });
    } else if (field === 'endValue') {
      let newDate = Object.assign({}, this.state.filterOption, { EndTime: value ? formatDate(value._d, 'yyyymmdd') + '235960' : 0 })
      this.setState({
        [field]: value,
        filterOption: newDate
      });
    }
  };

  onStartChange = value => {
    this.onChange('startValue', value);
  };

  onEndChange = value => {
    this.onChange('endValue', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  // 用户类别
  userTypeChange = (value) => {
    let newUserType = Object.assign({}, this.state.filterOption, { EnterpriseCategory: Number(value) })
    this.setState({
      filterOption: newUserType
    })
  }
  // 改变页码
  changePage(page) {
    let changePage = Object.assign({}, this.state.filterOption, { Page: page - 1 })
    this.setState({
      filterOption: changePage,
      Page: page
    }, () => { this.getData() })
  }
  // 首页
  firstPage() {
    if (this.state.resultList.length == '0') {
      return
    }
    let changePage = Object.assign({}, this.state.filterOption, { Page: 0 })
    this.setState({
      filterOption: changePage,
      Page: 1,
    }, () => {
      this.getData()
    })
  }
  // 尾页
  endPage() {
    if (this.state.resultList.length == '0') {
      return
    }
    let changePage = Object.assign({}, this.state.filterOption, { Page: Math.ceil(this.state.TotalCount / 10) - 1 })
    this.setState({
      filterOption: changePage,
      Page: Math.ceil(this.state.TotalCount / 10),
    }, () => {
      this.getData()
    })
  }
  RemindUser = () => {
    message.error('暂无QQ信息!')
  }
  // 省级下拉选项
  selectProvince = (value) => {
    let newProvinceValue = Object.assign({}, this.state.searchList,
      { Province: value },
      { City: '' },
      { County: '' }
    )
    this.setState({
      searchList: newProvinceValue,
      provinceValue: value,
      cityValue: undefined,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        cityDisabled: false,
        regionDisabled: true // 禁用区级选项
      })
    } else {
      // 清空按钮触发时
      let newProvinceValue = Object.assign({}, this.state.searchList,
        { Province: '' },
        { City: '' },
        { County: '' }
      )
      this.setState({
        searchList: newProvinceValue,
        cityDisabled: true,
        regionDisabled: true,
        provinceValue: value,
      })
    }
  }
  // 市级下拉选项
  selectCity = (value) => {
    let newCityValue = Object.assign({}, this.state.searchList,
      { City: value },
      { County: '' }
    )
    this.setState({
      searchList: newCityValue,
      cityValue: value,
      regionValue: undefined
    })
    if (value) {
      this.setState({
        regionDisabled: false
      })
    } else {
      // 清空按钮触发时
      let newCityValue = Object.assign({}, this.state.searchList,
        { City: '' },
        { County: '' }
      )
      this.setState({
        searchList: newCityValue,
        regionDisabled: true,
        regionValue: value
      })
    }
  }
  // 区级下拉选项
  selectRegion = (value) => {
    let newRegionValue = Object.assign({}, this.state.searchList,
      { County: value }
    )
    if (value) {
      this.setState({
        searchList: newRegionValue,
        regionValue: value,
      })
    } else {
      // 清空按钮触发时
      let newRegionValue = Object.assign({}, this.state.searchList,
        { County: '' }
      )
      this.setState({
        searchList: newRegionValue,
        regionValue: value,
      })
    }

  }
  render() {
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div className='BusinessQuery_container'>
        <div className='BusinessQuery'>
          <div className='search_accessories'>
            <div className='search_content'>
              <div className="firstTR"><span>企业名称</span><input type="text" ref='businessName' /></div>
              <div className="firstTR"><span>手机号</span><input type="text" ref='businessPhone' /></div>
              <div className='userType firstTR' >
                <span>用户类别</span>
                <Select defaultValue="全部" style={{ width: '250px' }} onChange={this.userTypeChange}>
                  <Option value="0">全部</Option>
                  <Option value="1">汽配商</Option>
                  <Option value="2">汽修厂</Option>
                  <Option value="3">个人</Option>
                  <Option value="4">厂家</Option>
                </Select>
              </div>
              <div>
                <span>区域</span>
                <Select
                  // showSearch
                  style={{ width: 76.5, marginTop: 10, marginRight: 10, textAlign: 'center' }}
                  placeholder="省"
                  optionFilterProp="children"
                  value={this.state.provinceValue}
                  onChange={this.selectProvince}
                  showArrow={false}
                  allowClear={true}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                  }
                </Select>
                <Select
                  // showSearch
                  style={{ width: 76.5, marginTop: 10, marginRight: 10, textAlign: 'center' }}
                  placeholder="市"
                  optionFilterProp="children"
                  value={this.state.cityValue}
                  onChange={this.selectCity}
                  allowClear={true}
                  showArrow={false}
                  allowClear={true}
                  disabled={this.state.cityDisabled}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >{
                    areaData.ProvinceCity[this.state.provinceValue] ?
                      Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                        areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                          <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                        ))
                      )) : []
                  }
                </Select>
                <Select
                  // showSearch
                  style={{ width: 76.5, marginTop: 10, textAlign: 'center' }}
                  placeholder="区"
                  optionFilterProp="children"
                  value={this.state.regionValue}
                  onChange={this.selectRegion}
                  showArrow={false}
                  allowClear={true}
                  disabled={this.state.regionDisabled}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >{

                    areaData.ProvinceCity[this.state.provinceValue] ?
                      Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                        areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                          item.Name == this.state.cityValue ?
                            item.CityArea.map(items => (
                              <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                            )) : ''
                        ))
                      )) : []
                  }
                </Select>
              </div>
              <div className="firstTR"><span>配件编码</span><input type="text" ref='businessCode' /></div>
              <div className="firstTR"><span>配件名称</span><input type="text" ref='businessCodeName' /></div>
              <div className="firstTR"><span>品牌</span><input type="text" ref='businessBrand' /></div>
              <div className="firstTRs"><span>车型</span><input type="text" ref='businessVehicleMode' /></div>
              <div className="firstTR"><span>产地</span><input type="text" ref='businessOrigin' /></div>
              <div className='BusinessQueryData'>
                <span>查询时间</span>
                <ConfigProvider locale={zh_CN}>
                  <DatePicker
                    defaultValue={moment(new Date(), dateFormat)}
                    disabledDate={this.disabledStartDate}
                    locale={zh_CN}
                    format={dateFormat}
                    //value={startValue}
                    placeholder="开始时间"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                  /></ConfigProvider>
                <b className='separator'>&nbsp;&nbsp;&nbsp;至</b>
                <ConfigProvider locale={zh_CN}>
                  <DatePicker
                    defaultValue={moment(new Date(), dateFormat)}
                    disabledDate={this.disabledEndDate}
                    locale={zh_CN}
                    format={dateFormat}
                    //value={endValue}
                    placeholder="结束时间"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                  />
                </ConfigProvider>
              </div>
            </div>
            <div className='functionButton' >
              <p><span className='search_btn' onClick={this.seek} onKeyDown={this.handleKeydown}>查询</span></p>
            </div>
          </div>
          <div className='accessories_result'>
            <Table
              width='100%'
              columns={this.state.columns}
              dataSource={this.state.resultList}
              rowKey={(record, index) => index}
              bordered={true}
              pagination={false}
              scroll={{ y: 513 }}
              locale={this.state.locale}
            />
            <div className='list_info_pagination'>
              <span className={`first_button ${this.state.TotalCount <= 10 ? 'ban' : ''}`} onClick={this.firstPage}>首页</span>
              <Pagination
                total={this.state.TotalCount}
                current={this.state.Page}
                itemRender={itemRender}
                pageSize={10}
                onChange={this.changePage}
              />
              <span className={`last_button ${this.state.TotalCount <= 10 ? 'ban' : ''}`} onClick={this.endPage}>末页</span>
            </div>
          </div>
        </div>
        <Modal
          title="提示"
          visible={this.state.visible}
          onCancel={this.loginVisible}
          footer={null}
          maskClosable={false}
          wrapClassName='isLoginModel'
        >
          <p>请使用<b>收到短信提醒</b>的手机号码进行企业注册</p>
          <p className='loginFunction'><span onClick={this.goRegister}>企业注册</span><span onClick={this.goLogin}>登录</span></p>
        </Modal>
      </div>
    )
  }
}
export default BusinessQuery
