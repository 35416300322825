// import apiService from './data'
import axios from 'axios'
import CryptoJS from 'crypto-js';
import Base64 from 'base-64';

// import { Encrypt, Decrypt } from './aes';
class BackEnd {

    // AesPasswords(password){
    //     var key = CryptoJS.enc.Utf8.parse("chexiongdi666666");
    //     var srcs = CryptoJS.enc.Utf8.parse(password);
    //     var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
    //     const result = Base64.encode(encrypted)
    //     console.log(result)
    //     return result
    // }

    AesPassword(password) {

        var key = CryptoJS.enc.Utf8.parse('chexiongdi666666chexiongdi666666');
        var iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');

        var encrypted = '';

        var srcs = CryptoJS.enc.Utf8.parse(password);

        encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

        console.log(encrypted)
        // return false

        return encrypted;



        // const secretKey = 'chexiongdi666666';
        // console.log(JSON.stringify(password))
        // const One = CryptoJS.AES.encrypt(JSON.stringify(password), secretKey, {
        //     mode: CryptoJS.mode.CBC,
        //     padding: CryptoJS.pad.Pkcs7,
        //     iv: CryptoJS.enc.Utf8.parse('abcdefghijklmnop')
        // })
        // console.log(One.toString())

        // const result = Base64.encode(One)
        // console.log(result)
        // return One


    }
    getData(message) {
        return axios.post('/ERPService.svc/DoService', {
            Message: message
        })
    }
    // 找货宝配件查询
    // findAccessories(Message) {
    //     return axios({
    //         method: 'post',
    //         url: '/api/ShareData/QueryAutopartsList',
    //         data: Message,
    //     })
    // }
    findAccessories(Message) {
        return axios({
            method: 'post',
            url: '/api/ShareData/QueryAutopartsListNew',
            data: Message,
        })
    }
    // 模板下载
    download(Message) {
        return axios({
            method: 'post',
            url: '/api/ShareData/ImportAutopartsData',
            params: Message,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }
    // 设置询价单缓存
    setInquiry(Message) {
        return axios({
            method: 'post',
            url: '/api/InquiryVoucher/SetInquiryVoucherCache',
            data: Message
        })
    }
    // 添加询价单缓存
    addInquiry(Message) {
        return axios({
            method: 'post',
            url: '/api/InquiryVoucher/AddInquiryVoucherCache',
            data: Message
        })
    }
    // 获取询价单缓存
    getInquiry(Message) {
        return axios({
            method: 'get',
            url: `/api/InquiryVoucher/GetInquiryVoucherCache/${5417}`,
        })
    }
    // 获取已询价单
    getHaveInquiry(Message) {
        return axios({
            method: 'post',
            url: '/api/InquiryVoucher/GetInquiryVoucherList',
            data: Message
        })
    }
    // 已询价明细
    getEnquiryDetail(Message) {
        return axios({
            method: 'get',
            url: `/api/InquiryVoucher/GetInquiryVoucherDetailLsit/${Message}`
        })
    }
    // 报价明细
    getQuotedPriceDetail(Message) {
        return axios({
            method: 'get',
            url: `/api/InquiryVoucher/GetQuoteVoucherDetailViewLsit/${Message}`
        })
    }

    // 已报价单删除
    deteleHaveEnquiry(Message) {
        return axios({
            method: 'get',
            url: `/api/InquiryVoucher/DeteleInquiryVoucher/${Message}`
        })
    }
    // 企业广场
    companySquare(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    // 获取收货地址
    getUserPlace(Message) {
        return axios.get(`/api/ShoppingCart/GetOrder_AddressListByUser?UserId=${Message}`)
    }
    // 删除收货地址
    deletePlace(Message) {
        return axios.get(`/api/ShoppingCart/DelOrder_AddressInfo?Id=${Message}`)
    }
    //提交订单
    submitOrder(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //订单查询(买家)
    orderinquire(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //订单详情(买家)
    orderDetails(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //订单查询(卖家)
    orderInquire(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //订单详情(卖家)
    orderSeller(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //订单详情(卖家)
    orderManage(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    CancelOrder(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    ordeOK(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    PaymentOrder(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    DeliverOrder(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    GoodsOrder(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    notarize(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    amount(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    // 查询配件商城
    QueryShareAutoparts(Message) {
        return axios({
            method: 'post',
            url: '/api/ShoppingCart/QueryShareDecryptAutoparts',
            data: Message
        })
    }
    //用户中心
    // 删除车辆信息
    deleteCar(Message) {
        return axios.get(`/api/UserCenter/DeleteMyVehiclmodel?Id=${Message}`)
    }
    //修改车辆信息
    modifySureCar(Message) {
        return axios({
            method: 'post',
            url: '/api/UserCenter/UpdateMyVehicle',
            data: Message
        })
    }
    //添加车辆
    addCarList(Message) {
        return axios({
            method: 'post',
            url: '/api/UserCenter/InsertMyVehicle',
            data: Message
        })
    }
    // 获取账户余额
    GetBalance(Message) {
        return axios({
            method: 'post',
            url: '/api/PayCenter/GetAccountBalance',
            data: Message
        })
    }
    //根据牌照获取车架号信息 (有余额情况下)
    haveMoeyGetvin(Message) {
        return axios({
            method: 'post',
            url: '/api/UserCenter/GetVinNoIMBanByLicensePlate',
            data: Message
        })
    }
    //根据牌照获取车架号信息 (有次数下)
    FreeAccessGetvin(Message) {
        return axios({
            method: 'post',
            url: '/api/UserCenter/GetVinNoByLicensePlate',
            data: Message
        })
    }
    //修改密码
    changePassword(Message) {
        return axios({
            method: 'post',
            url: '/api/UserCenter/UpdateIMPassword',
            data: Message
        })
    }
    //获取免费次数
    getCarvinnoNum(Message) {
        return axios({
            method: 'post',
            url: '/api/UserCenter/GetUserNumber',
            data: Message
        })
    }
    //获取全部的车牌地区简称
    getCityList() {
        return axios.get(`/api/UserCenter/GetLicencePlate`)
    }
    //自提地址列表
    ZTaddressList(Message) {
        return axios.get(`/api/UserCenter/Get_SelfAddress?UserId=${Message}`)
    }

    //删除自提点地址
    deletedZTAddress(Message) {
        return axios.get(`/api/ShoppingCart/DelOrder_AddressInfo?Id=${Message}`)
    }
    //获得个人基本信息
    information(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //修改个人基本信息
    amendinFormation(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //升级企业基本信息
    upgradeEnterprise(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //企业基本信息
    enterprise(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //修改企业基本信息
    modificationEnterprise(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //拼音
    pinying(url, Message) {
        return axios({
            method: 'post',
            url: url,
            data: Message
        })
    }
    //扫码付
    SweepToPay(Message){
        return axios({
            method: 'post',
            url: '/api/QrPay/QrPayQueryList',
            data: Message
        })
    }
    //导出
    SweepToPayExport(Message){
        return axios({
            method: 'get',
            url: `/api/QrPay/QrPayQueryListExport?${Message}`
        })
    }
    //拆车场获取信息
    onTrial(Message){
        return axios({
            method: 'post',
            url: '/index.php/phper/Intention/_save',
            data: Message
        })
    }
    /* 培训 */
    //添加学员
    addStudent(Message){
        return axios({
            method: 'post',
            url: '/app.php/Apply/addStudent',
            data: Message
        })
    }
    //培训列表
    trainList(Message){
        return axios({
            method: 'post',
            url: '/app.php/Peixun/list',
            data: Message
        })
    }
    //培训列表
    getUrl(Message){
        return axios({
            method: 'post',
            url: '/app.php/Peixun/get',
            data: Message
        })
    }
    //学员列表
    StudentList(Message){
        return axios({
            method: 'post',
            url: '/app.php/Apply/list',
            data: Message
        })
    }
    //学员列表
    StudentSave(Message){
        return axios({
            method: 'post',
            url: '/app.php/Apply/save',
            data: Message
        })
    }
    //学员列表
    StudentDelete(Message){
        return axios({
            method: 'post',
            url: '/app.php/Apply/delete',
            data: Message
        })
    }
    //申请发票
    AddInvoice(Message){
        return axios({
            method: 'post',
            url: '/app.php/Invoice/addInvoice',
            data: Message
        })
    }
    //付款证明
    paymentPhoto(Message){
        return axios({
            method: 'post',
            url: '/app.php/Invoice/paymentPhoto',
            data: Message
        })
    }
    //付款证明列表
    paymentList(Message){
        return axios({
            method: 'post',
            url: '/app.php/Invoice/paymentList',
            data: Message
        })
    }
    //提交
    checkCertificate(Message){
        return axios({
            method: 'post',
            url: '/app.php/Apply/checkCertificate',
            data: Message
        })
    }
    //获取图片链接
    makeCertificate(Message){
        return axios({
            method: 'post',
            url: '/app.php/Apply/makeCertificate',
            data: Message
        })
    }
}

export default new BackEnd()