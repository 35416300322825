import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty } from 'antd'
import './Freeopen.scss'
import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal




class ShoppingCart extends Component {
  constructor() {
    super()
    this.state = {
      locale: {
        // emptyText: <img src={(require('./images/hint.png'))}></img>
      },
   
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {

    // this.shoppinglist()
  }

  render() {
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className="FreeopenBox">
           <p className='title'>免费开店</p>
           <div className="Freeopen">
           免费开店
           </div>
        </div>
      
      </div>
    )
  }
}
export default withRouter(ShoppingCart)
