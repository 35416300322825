import HeaderFooter from '../../layouts/HeaderFooter'
import Home from '../../pages/Home'
import Register from '../../pages/Register'
import Login from '../../pages/Login'
import AccountNumber from '../../pages/AccountNumber/AccountNumber'
import BusinessQuery from '../../pages/BusinessQuery'
import Accessories from  '../../pages/Accessories'
import Enquiry from '../../pages/Accessories/Enquiry'
import Square from '../../pages/Square'
import BuyerOrder from '../../pages/OrderManagement/BuyerOrder/BuyerOrder'
import ToBeConfirmed from '../../pages/OrderManagement/BuyerOrder/ToBeConfirmed/ToBeConfirmed'
import ShoppingCart from '../../pages/ShoppingCart/ShoppingCart'
import placeOrder from '../../pages/ShoppingCart/PlaceOrder/PlaceOrder'
import PersonalStore from '../../pages/Square/PersonalStore/PersonalStore'
import ProductList from '../../pages/Square/PersonalStore/ProductList/ProductList'
import ContactUs from '../../pages/Square/PersonalStore/ContactUs/ContactUs'
import ProductDetail from '../../pages/Square/PersonalStore/ProductDetail/ProductDetail'
import Detail from '../../pages/Square/Detail'
import ForgetPassword from '../../pages/ForgetPassword'
import SellerOrder from '../../pages/OrderManagement/SellerOrder/SellerOrder'
import Particulars from '../../components/Particulars/Particulars'
import ToBeSalesslip from '../../pages/OrderManagement/SellerOrder/inner/ToBeSalesslip'
import Notopened from '../../pages/OrderManagement/SellerOrder/inner/Notopened'
//汽配商城
import PartsShopping from '../../pages/PartsShopping/partsShopping'
import Information from '../../pages/PartsShopping/information/information'
import InformationDetail from '../../pages/PartsShopping/information/InformationDetail/InformationDetail'
import NewArrivals from '../../pages/PartsShopping/NewArrivals/NewArrivals'
import Cheap from '../../pages/PartsShopping/Cheap/Cheap'
import SearchResult from '../../pages/PartsShopping/SearchResult/SearchResult'
import CheapDetail from '../../pages/PartsShopping/component/Detail/CheapDetail'
import Recharge from '../../pages/PartsShopping/information/Recharge/Recharge'

// 用户中心
import Usercenter from '../../pages/Usercenter/Usercenter'
import Businessmanagement from '../../pages/Usercenter/Businessmanagement/Businessmanagement'
import Buyersorder from '../../pages/Usercenter/Buyersorder/Buyersorder'
import Essentialinfo from '../../pages/Usercenter/Essentialinfo/Essentialinfo'
import EnterpriseInformation from '../../pages/Usercenter/EnterpriseInformation/EnterpriseInformation'
import Freeopen from '../../pages/Usercenter/Freeopen/Freeopen'
import Mycar from '../../pages/Usercenter/Mycar/Mycar'
import Mymoney from '../../pages/Usercenter/Mymoney/Mymoney'
import Orderseller from '../../pages/Usercenter/Orderseller/Orderseller'
import Qualityassurancecommitment from '../../pages/Usercenter/Qualityassurancecommitment/Qualityassurancecommitment'
import Securityset from '../../pages/Usercenter/Securityset/Securityset'
import Selfpickupsite from '../../pages/Usercenter/Selfpickupsite/Selfpickupsite'
import Shippingaddress from '../../pages/Usercenter/Shippingaddress/Shippingaddress'
import Shopadministration from '../../pages/Usercenter/Shopadministration/Shopadministration'
import UsersToUpgrade from '../../pages/Usercenter/Essentialinfo/UsersToUpgrade'
import SweepToPay from '../../pages/Usercenter/SweepToPay/SweepToPay'

import Disassembly from '../../pages/DisassemblyPlantManagement'

//EPC
import EPC_query from '../../pages/EPCquery/EPC_query'
//行业培训
import VocationalTraining from '../../pages/Usercenter/VocationalTraining'
//行业培训没有登录之后的登录界面 
import PseudoLogIn from '../../pages/PseudoLogIn'
const routerConfig = [
  {
    path: '/Usercenter', // 用户中心
    layout: Usercenter,
    component: Usercenter,
    children: [{
      path: '/Businessmanagement',
      layout: Businessmanagement,
      component: Businessmanagement
    },
    {
      path: '/Buyersorder',
      layout: Buyersorder,
      component: Buyersorder
    },
    {
      path: '/Essentialinfo',
      layout: Essentialinfo,
      component: Essentialinfo
    },
    {
      path: '/UsersToUpgrade',
      layout: UsersToUpgrade,
      component: UsersToUpgrade
    },
    {
      path: '/EnterpriseInformation',
      layout: EnterpriseInformation,
      component: EnterpriseInformation
    },
    {
      path: '/Freeopen',
      layout: Freeopen,
      component: Freeopen
    },
    {
      path: '/Mycar',
      layout: Mycar,
      component: Mycar
    },
    {
      path: '/Mymoney',
      layout: Mymoney,
      component: Mymoney
    },
    {
      path: '/VocationalTraining',
      layout: VocationalTraining,
      component: VocationalTraining
    },
    {
      path: '/SweepToPay',//扫码付
      layout : SweepToPay,
      component: SweepToPay
    },
    {
      path: '/Orderseller',
      layout: Orderseller,
      component: Orderseller
    },
    {
      path: '/Qualityassurancecommitment',
      layout: Qualityassurancecommitment,
      component: Qualityassurancecommitment
    },
    {
      path: '/Securityset',
      layout: Securityset,
      component: Securityset
    },
    {
      path: '/Selfpickupsite',
      layout: Selfpickupsite,
      component: Selfpickupsite
    },
    {
      path: '/Shippingaddress',
      layout: Shippingaddress,
      component: Shippingaddress
    }, {
      path: '/Shopadministration',
      layout: Shopadministration,
      component: Shopadministration
    }]
  },
  {
    path: '/',
    layout: HeaderFooter,
    component: Accessories
  },
  {
    path: '/PseudoLogIn',
    layout: PseudoLogIn,
    component: PseudoLogIn
  },
  {
    path: '/enquiry',
    layout: HeaderFooter,
    component: Enquiry
  },

  {
    path: '/login', // 登录
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/forgetPassword',
    component: ForgetPassword
  },
  {
    path: '/accountNumber',
    component: AccountNumber
  },
  {
    path: '/home',
    layout: HeaderFooter,
    component: Home
  },
  {
    path: '/businessquery',
    layout: HeaderFooter,
    component: BusinessQuery
  },
  {
    path: '/buyerOrder',
    component: BuyerOrder
  },
  {
    path: '/notopened',
    component: Notopened
  },
  {
    path: '/toBeConfirmed',
    component: ToBeConfirmed
  },
  {
    path: '/toBeSalesslip',
    component: ToBeSalesslip
  },
  {
    path: '/SellerOrder',
    component: SellerOrder
  },
  {
    path: '/Particulars',
    component: Particulars
  },
  {
    path: '/shoppingCart', // 购物车
    component: ShoppingCart
  },
  {
    path: '/placeOrder',
    component: placeOrder
  },
  {
    path: '/square', // 企业广场
    layout: HeaderFooter,
    component: Square,
    children: [{
      path: '/detail',
      layout: HeaderFooter,
      component: Detail
    }]
  },
  {
    path: '/partsShopping', // 配件商城首页
    layout: HeaderFooter,
    component: PartsShopping,

  },
  {
    path: '/NewArrivals', // 新品上架
    layout: HeaderFooter,
    component: NewArrivals,

  },
  {
    path: '/SearchResult', // 搜索结果
    layout: HeaderFooter,
    component: SearchResult,

  },
  {
    path: '/Cheap', // 促销商品
    layout: HeaderFooter,
    component: Cheap,
  },
  {
    path: '/CheapDetail', // 促销商品
    layout: HeaderFooter,
    component: CheapDetail,
  },
  {
    path: '/Information', // 行业资讯
    layout: HeaderFooter,
    component: Information,
  },
  {
    path: '/Recharge', // 充值
    layout: HeaderFooter,
    component: Recharge,
  },
  {
    path: '/InformationDetail', // 行业资讯详情
    layout: HeaderFooter,

    component: InformationDetail,
  },
  {
    path: '/personalStore',
    component: PersonalStore
  },
  {
    path: '/productList', // 产品列表
    component: ProductList
  },
  {
    path: '/contactUs', // 联系我们
    component: ContactUs
  },
  {
    path: '/productDetail',
    component: ProductDetail
  },
 
  {
    path: '/EPC_query',//EPC
    component: EPC_query
  },
  {
    path: '/Disassembly',//EPC
    component: Disassembly
  },
  {
    path: '*',
    layout: HeaderFooter,
    component: Home
  }
  // {
  //   path: '*',
  //   layout: HeaderFooter,
  //   component: NotFound
  // }
]

export default routerConfig
