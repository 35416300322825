import React, { Component } from 'react'
import { Form, Input, Button, Checkbox } from 'antd';

import './invoiceOrder.scss'
  

export default class SpecialInvoice extends Component {
    constructor() {
        super()
        this.state={
            CompanyName:'',
            identify:'',
            companyAddress:'',
            companyPhone:'',
            companyBankName:'',
            companyBankAccount:'',

        }
    }
    
    render() {

       

        return (
            <div className="invoiceContent">
           
                    <p><span>单位名称:</span>
                        <Input type="text"  placeholder="单位名称" required  onChange={e=>{
                            this.setState({
                                CompanyName:e.target.value
                            })
                        }} />
                    </p>
               
                    <p><span>纳税人识别号:</span>
                        <Input type="text" placeholder="纳税人识别号" required onChange={e=>{
                            this.setState({
                                identify:e.target.value
                            })
                        }}/>
                    </p>
              
                    <p><span>注册地址:</span>
                        <Input type="text"  placeholder="注册地址" required onChange={e=>{
                            this.setState({
                                companyAddress:e.target.value
                            })
                        }} />
                    </p>
                   
                    <p><span>注册电话:</span>
                        <Input type="text" placeholder="注册电话" required  onChange={e=>{
                            this.setState({
                                companyPhone:e.target.value
                            })
                        }} />
                    </p>
                   
                    <p><span>开户银行:</span>
                        <Input type="text"  placeholder="开户银行" required  onChange={e=>{
                            this.setState({
                                companyBankName:e.target.value
                            })
                        }} />
                    </p>
                   
                    <p><span>银行账号:</span>
                        <Input type="text" placeholder="银行账号" required  onChange={e=>{
                            this.setState({
                                companyBankAccount:e.target.value
                            })
                        }} />
                    </p>
                   
            </div>
            
        )
    }
}
