import React, { PureComponent } from 'react'

import { Table,Modal,Select ,Form,Input,Spin,message,Button} from 'antd'
import BackEnd from '../../../../services/api'
const { Option } = Select;
class ViewTheResults extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            columns:[
                {
                    title: "ID",
                    dataIndex: 'id',
                    key: 'id',
                    width: 80,
                },
                {
                    title: "照片",
                    dataIndex: 'ID2',
                    key: '2',
                    width: 80,
                    render: (text, record) => (
						<div className="img_photo">
							<img className='img' src={record.id_photo} alt="" />
						</div>
					)
                },
                {
                    title: "姓名",
                    dataIndex: 'name',
                    key: 'name',
                    width: 80,
                },
                {
                    title: "性别",
                    dataIndex: 'sex',
                    key: 'sex',
                    width: 80,
                },
                {
                    title: "联系方式",
                    dataIndex: 'tel',
                    key: 'tel',
                    width: 80,
                },
                {
                    title: "身份证号",
                    dataIndex: 'id_number',
                    key: 'id_number',
                    width: 80,
                },
                {
                    title: "学历",
                    dataIndex: 'EducationText',
                    key: 'EducationText',
                    width: 80,
                },
                {
                    title: "学员编码",
                    dataIndex: 'id_code',
                    key: 'id_code',
                    width: 80,
                },
                {
                    title: "培训成绩",
                    dataIndex: 'grade',
                    key: 'grade',
                    width: 120,
                    render: (text, record) => (
						<div className="img_photo">
                            {
                                 record.GradeList.length != 0 ?
                                record.GradeList.map((item,index)=> <div key={index+'102' }>
                                    <span  className="classtype"> <span> {item.name}</span>  <span> {item.classNum}</span>
                                    </span> 
                                </div>  )
                                :
                                ''
                            }
                            
						</div>
					)
                },
                {
                    title: "证书状态",
                    dataIndex: 'is_check',
                    key: 'is_check',
                    width: 80,
                    render: (text, record) => (
						<div className="img_photo">
                            {
                                record.is_check == 1
                                ?  
                                <span className="hedui" onClick={ ()=>{this.openPopup(record)}  }> 核对证书</span>
                                :(record.is_check == 2 ? '核对完成'  : '信息有误' )
                            }
						 </div>
					)
                },
            ],
            carList:[],
            popup:false,
            loadings:false,
            education:"0",
            objData:{},
            imgSvg:'',
            remark:'',
            SetData:{}
        }
       
    }
    SelectHandle = ()=>{
        this.setState({
            remark:'',
            education:3
        })
    }
    openPopup =(e)=>{
        this.setState({
            loadings:true,
            remark:'',
            education:0,
        })
          BackEnd.makeCertificate({id:e.id}).then(res=>{
            if(res.data.code == 0){
                this.setState({
                    loadings:false,
                    imgSvg:res.data.data,
                    popup:true,
                    objData:e,
                    education:'',
                    remark:"",
                })
            }else{
                this.setState({
                    loadings:false
                },()=>{
                    message.error(res.data.Message);
                })
            }
        })
    }
    handleinvoiceOk = ()=>{
       let obj = {
            id:this.state.objData.id,
            is_check:this.state.education,
            is_check_remark:this.state.remark,
       }
         BackEnd.checkCertificate(obj).then(res=>{
            if(res.data.code == 0){
                this.setState({
                    popup:false
                },()=>{
                    message.success(res.data.Message);
                    this.getData(this.state.SetData)
                })
            }
        })
        // this.setState({
        //     popup:true
        // })
    }
    handleinvoice = ()=>{
        this.setState({
            popup:false,
            imgSvg:false,
        })
    }
    //获取学院列表
    getData = (e)=>{
        this.setState({
            SetData:e
        })
        let obj = {
            userId:window.localStorage.getItem('userId')|| '',
			CompanyName:window.localStorage.getItem('CompanyName') ,
            train_id:e.id
        }
        BackEnd.StudentList(obj).then(res=>{
            if(res.data.code == 0){
                this.setState({
                    carList:res.data.data
                })
            }
        })
    }
    render() {
        return (
            <div >
                <div className={this.state.loadings?'zhe':''}>
                    <Spin className='SpinPopup' spinning={this.state.loadings}></Spin>
                </div>

                <Table className='table_os' rowKey="id" bordered dataSource={this.state.carList} columns={this.state.columns} pagination={false} />
                
                        <Modal
                        title="证书核对"
                        visible={this.state.popup}
                        maskClosable={false}
                        onOk={this.handleinvoiceOk}
                        onCancel={this.handleinvoice}
                        cancelText="关闭窗口"
                        okText="提交"
                        width='50%'
                    >
                        <div className='tup'>

                            <img className='imgwh' src={this.state.imgSvg} alt="证书" />
                        </div>
                        <br></br>
                        {/* <Form>
                            <Form.Item label="请选择核对结果" style={{marginBottom: '0'}}>
                                <Select defaultValue="0" value={this.state.education} onChange={this.SelectHandle}>
                                    <Option value="0">--请选择核对结果--</Option>
                                    <Option value="2">核对无误</Option>
                                    <Option value="3">证书内容有误</Option>
                                
                                </Select>
                            </Form.Item>
                            {
                                this.state.education == 3 ?<Form.Item label="证书错误描述" style={{marginBottom: '0'}}>
                                <Input  value={this.state.remark} onChange={e=>{
                                    this.setState({remark:e.target.value})
                                }}></Input>
                            </Form.Item>
                            :''
                            }
                            
                        </Form> */}
                        <div>
                            <p>请选择核对结果： 
                                <Button className='Button-lr' type='primary' onClick={()=>{ this.setState({education:2},()=>{this.handleinvoiceOk()}) }} >核对无误</Button>
                                <Button type="danger" onClick={this.SelectHandle}>证书内容有误</Button>
                            </p>
                            {
                                this.state.education == 3 ?<Form.Item label="证书错误描述" style={{marginBottom: '0'}}>
                                <Input  value={this.state.remark} onChange={e=>{
                                    this.setState({remark:e.target.value})
                                }}></Input>
                            </Form.Item>
                            :''
                            }
                        </div>
                    </Modal>
                
               
            </div>
        )
    }
}

export default ViewTheResults