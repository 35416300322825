import React, { Component } from 'react'
import './Register.scss'
import {Icon} from 'antd'
import CompanyRegister from '../../components/CompanyRegister'
import PersonageRegister from '../../components/PersonageRegister'
class Register extends Component {
    constructor() {
        super()
        this.state = {
            isActive: false, // tab切换
            confirmDirty: false,
            autoCompleteResult: [],
            loading: false, // 上传图片的loading图
            imageUrl: '', // 上传图片的url
            getFieldsValue: {},// 表单的值
        }
    }
    switchTab(item) {
        if (item) {
            this.setState({
                isActive: false
            })
        } else {
            this.setState({
                isActive: true
            })
        }
    }
    render() {
        return (
            <div className='registerPage'>
                <div className='registerContainer'>
                    <div className='RegistrationType'>
                        <Icon className='fanhui' type="left" onClick={()=>{window.history.go(-1)}} />
                      
                        {/* <span onClick={this.switchTab.bind(this, 0)} className={`${this.state.isActive ? '' : 'active1'}`}>个人用户注册</span>
                        <span onClick={this.switchTab.bind(this, 1)} className={`${this.state.isActive ? 'active1' : ''}`}>企业用户注册</span> */}
                        <span onClick={this.switchTab.bind(this, 1)} className= 'active1' style={{width:'100%'}}>
                        
                            企业用户注册
                        </span>
                    </div> 
                    <PersonageRegister isActive={this.state.isActive}></PersonageRegister>
                    <CompanyRegister isActive={this.state.isActive}></CompanyRegister>
                </div>
            </div>
        )
    }
}
export default Register
