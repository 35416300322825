import React, { PureComponent } from 'react'
import { getCookie, deleteCookie } from '../../../utils/env'
import {
  Table,
  Select,
  message,
  Pagination,
  Modal,
  Tabs,
  Checkbox
} from 'antd'
import BackEnd from '../../../services/api'
import areaData from '../data.json'
import NoInquiry from '../../../components/NoInquiry'
import HaveInquiry from '../../../components/HaveInquiry'
import './Enquiry.scss'
const { Option } = Select;
const { TabPane } = Tabs;
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  // getCheckboxProps: record => ({
  //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //   name: record.name,
  // }),
};
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <span>上一页</span>;
  } if (type === 'next') {
    return <span>下一页</span>;
  }
  return originalElement;
}
class Enquiry extends PureComponent {
  constructor() {
    super()
    this.state = {
      activeIndex: '',
      TotalCount: 5,
      Page: 1,
      visibleProvider: false, // 供应商名片弹框
      visibleFitting: false, // 配件查询弹框
      visibleEnquiry: false, // 询价弹框
      disableButton: false,
      provinceValue: undefined,// 省级查询值
      cityValue: undefined, // 市级查询值
      regionValue: undefined, // 区级查询值
      cityDisabled: true, // 区域市级按钮
      regionDisabled: true, // 区域区级按钮
      resultList: [], // 查询结果列表
      ModelList: [], // 弹框数据列表
      CompanyDetail: [],
      locale: {
        emptyText: <img src={(require('../images/hint.png'))}></img>
      },
      searchList: {
        Code: 11007,
        PageSize: 5,
        CurrentPage: 0, // CountPerPage 之前字段
        Province: "", // 省份
        City: "", // 城市
        County: "", // 区县
        IsShowNoGoods: false, // 库存
        CompanyName: "", // 供应商
        VehicleMode: "", // 车型
        Origin: "", // 产地
        Brand: "", // 品牌
        ComponentCode: '', // 配件编码
        ComponentName: '', // 配件名称
        OrderByDescending: true,
        QueryType: 10,
        WithCompanyDetail: true,
        IsQueryZHB: true,
        IsQueryHP: true
      }
    }
  }
  render() {
    return (
      <div className='accessories_container'>
        <div className='accessories'>
          <div className='parts_banner'>
            <img src={require('../images/Accessories.png')}alt="配件查询" />
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="未询价" key="1">
              <NoInquiry history={this.props.history}></NoInquiry>
            </TabPane>
            <TabPane tab="已询价" key="2">
              <HaveInquiry history={this.props.history}></HaveInquiry>
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
export default Enquiry
