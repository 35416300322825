import React, { PureComponent } from 'react'

class Download extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isHover: true,
      isIosHover: true,
      PCNameVersion: 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-win710.exe'
    }
  }
  componentDidMount() {
    let osData = [
      { name: 'Windows 2000', group: 'windows_server', identifier: 'Windows NT 5.0', version: '5.0' },
      { name: 'Windows XP', group: 'windows', identifier: 'Windows NT 5.1', version: '5.1' },
      { name: 'Windows 2003', group: 'windows_server', identifier: 'Windows NT 5.2', version: '5.2' },
      { name: 'Windows Vista', group: 'windows', identifier: 'Windows NT 6.0', version: '6.0' },
      { name: 'Windows 7', group: 'windows', identifier: 'Windows NT 6.1', version: '7.0' },
      { name: 'Windows 8', group: 'windows', identifier: 'Windows NT 6.2', version: '8.0' },
      { name: 'Windows 8.1', group: 'windows', identifier: 'Windows NT 6.3', version: '8.1' },
      { name: 'Windows 10', group: 'windows', identifier: 'Windows NT 10.0', version: '10.0' },
      { name: 'Windows 2008', group: 'windows_server', identifier: 'Windows NT 6.0; WOW64', version: '6.0' },
      { name: 'Windows 2008', group: 'windows_server', identifier: 'Windows NT 6.1; WOW64', version: '6.1' },
      { name: 'Windows 2012', group: 'windows_server', identifier: 'Windows NT 6.3; Win64', version: '6.3' },
      { name: 'Chrome OS', group: 'windows', identifier: 'CrOS' },
      { name: 'Mac OS X Capitan', group: 'mac', identifier: 'Mac OS X (10([_|\.])11([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Yosemite', group: 'mac', identifier: 'Mac OS X (10([_|\.])10([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Mavericks', group: 'mac', identifier: 'Mac OS X (10([_|\.])9([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Mountain Lion', group: 'mac', identifier: 'Mac OS X (10([_|\.])8([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Lion', group: 'mac', identifier: 'Mac OS X (10([_|\.])7([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Snow Leopard', group: 'mac', identifier: 'Mac OS X (10([_|\.])6([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Leopard', group: 'mac', identifier: 'Mac OS X (10([_|\.])5([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Tiger', group: 'mac', identifier: 'Mac OS X (10([_|\.])4([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Panther', group: 'mac', identifier: 'Mac OS X (10([_|\.])3([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Jaguar', group: 'mac', identifier: 'Mac OS X (10([_|\.])2([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Puma', group: 'mac', identifier: 'Mac OS X (10([_|\.])1([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS X Cheetah', group: 'mac', identifier: 'Mac OS X (10([_|\.])0([0-9_\.]*))', versionSeparator: '[_|\.]' },
      { name: 'Mac OS', group: 'mac', identifier: 'Mac OS' },
      { name: 'Ubuntu', group: 'linux_server', identifier: 'Ubuntu', versionIdentifier: 'Ubuntu/([0-9\.]*)' },
      { name: 'CentOs', group: 'linux_server', identifier: 'CentOs', versionIdentifier: 'CentOs/([0-9\.]*)' },
      { name: 'Debian', group: 'linux_server', identifier: 'Debian' },
      { name: 'Gentoo', group: 'linux_server', identifier: 'Gentoo' },
      { name: '国产系统', group: 'linux', identifier: 'Linux' }
    ];

    let setOsData = function (os) {
      let userAgent = navigator.userAgent.toLowerCase();
      // Check browser type
      for (let i in osData) {
        if (osData.hasOwnProperty(i)) {
          let osRegExp = new RegExp(osData[i].identifier.toLowerCase());
          let osRegExpResult = osRegExp.exec(userAgent);

          if (osRegExpResult != null) {
            os.name = osData[i].name;
            os.group = osData[i].group;
            break;
          }
        }
      }

      return true;
    };


    var setOsVersion = function (os, version, separator) {
      if (separator.substr(0, 1) == '[') {
        var splitVersion = version.split(new RegExp(separator, 'g'), 2);
      } else {
        var splitVersion = version.split(separator, 2);
      }

      if (separator != '.') {
        version = version.replace(new RegExp(separator, 'g'), '.');
      }

      os.fullVersion = version;

      // Major version
      if (splitVersion[0]) {
        os.majorVersion = parseInt(splitVersion[0]);
      }

      // Minor version
      if (splitVersion[1]) {
        os.minorVersion = parseInt(splitVersion[1]);
      }

      return true;
    };

    let os = {};
    setOsData(os);

    let nameVersion;
    let system = os.name
    switch (system) {
      case 'Windows XP':
        nameVersion = 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-xp.exe';
        break;
      case 'Windows Vista':
        nameVersion = 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-win710.exe';
        break;
      case 'Windows 7':
        nameVersion = 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-win710.exe';
        break;
      case 'Windows 8':
        nameVersion = 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-win710.exe';
        break;
      case 'Windows 8.1':
        nameVersion = 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-win710.exe';
        break;
      case 'Windows 10':
        nameVersion = 'https://cheqidian.oss-cn-beijing.aliyuncs.com/App/%E6%B1%BD%E9%85%8D%E7%BB%BC%E5%90%88%E6%9C%8D%E5%8A%A1%E5%B9%B3%E5%8F%B0-win710.exe';
        break;
      default:
        nameVersion = "javascript:(alert('您系统不支持'))";

    }
	console.log(nameVersion,'nameVersion')
    this.setState({
      PCNameVersion: nameVersion
    })
  }
  handleOver = (e) => {
    window.event ? (window.event.cancelBubble = true) : e.stopPropagation()
    this.setState({
      isHover: false
    })
  }
  handleOut = (e) => {
    window.event ? (window.event.cancelBubble = true) : e.stopPropagation()
    this.setState({
      isHover: true
    })
  }
  ioshandleOut = (e) => {
    window.event ? (window.event.cancelBubble = true) : e.stopPropagation()
    this.setState({
      isIosHover: true
    })
  }
  ioshandleOver = (e) => {
    window.event ? (window.event.cancelBubble = true) : e.stopPropagation()
    this.setState({
      isIosHover: false
    })
  }
  render() {
    return (
      <div className='downloadContainer'>
        <div className='cb-home'>
          <div className="downloadWrap">
            <h1 style={style.h1}>软件下载</h1>
            <div className="downloadContainer">
              <div className="download-block">
                <dl>
                  <dd>
                    <a href={this.state.PCNameVersion}>
                      <img src={require('./images/icon_cqd.png')} alt="PC端" />
                    </a>
                  </dd>
                  <dt>
                    <span>下载车企典-汽配综合服务平台</span>
                    <span><a href="http://down.chexd.com/车企典汽配信息管理与交易系统使用说明.pdf" target="_blank">软件使用说明书</a></span>
                  </dt>
                </dl>
              </div>
              <div className="download-block">
                <dl>
                  <dd onMouseOver={this.handleOver} onMouseOut={this.handleOut}>
                    {this.state.isHover ? (
                      <img src={require('./images/icon_anzhuo.png')} alt="android" />
                    ) : (
                        <img style={style.hover} src={require('./images/Android2.jpg')} alt="android" />
                      )}
                  </dd>
                  <dt>
                    <span>下载车企典安卓客户端</span>
                    <span><a href="http://down.chexd.com/车企典移动端聊天工具软件使用说明书.pdf" target="_blank">软件使用说明书</a></span>
                  </dt>
                </dl>
              </div>
              <div className="download-block">
                <dl>
                  <dd onMouseOver={this.ioshandleOver} onMouseOut={this.ioshandleOut}>
                    {this.state.isIosHover ? (
                      <img src={require('./images/icon_ios.png')} alt="android" />
                    ) : (
                        <img style={style.hover} src={require('./images/ios.png')} alt="android" />
                      )}
                  </dd>
                  <dt>
                    <span>下载车企典苹果客户端</span>
                    <span><a href="http://down.chexd.com/车企典移动端聊天工具软件使用说明书.pdf" target="_blank">软件使用说明书</a></span>
                  </dt>
                </dl>
              </div>
              <div className="download-block">
                <dl>
                  <dd>
                    <img src="images/officialAccounts.png" alt="公众号" className='officialAccounts' />
                  </dd>
                  <dt>
                    <span>车企典之家公众号</span>
                  </dt>
                </dl>
              </div>
            </div>
            <div className="contactNumber">
              <p>北京客服电话：<span>186-1138-5512</span><span>156-0011-5673</span></p>
              <p>沈阳客服电话：<span>186-1171-3917</span></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Download
const style = {
  h1: {
    textAlign: 'center',
    padding: '20px',
    marginTop: '-5px'
  },
  hover: {
    width: '154px',
    height: '154px'
  }
}