import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import areaJson from './area.json'
import './Essentialinfo.scss'
import './UsersToUpgrade.scss'

import {
	encryptByDES,
	encryptPassword,
	encryptEDSPwd
} from "../../../utils/encrypt";

import { Tabs, Table, Modal, message, Select, Row, Col, Empty, Form, Input, Button, DatePicker, ConfigProvider, Cascader, Upload, Icon } from 'antd'

import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal

const { Option } = Select;
function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

function beforeUpload(file) {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('只能上传类型是JPG/PNG文件');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('上传图片不能超过2MB!');
	}
	return isJpgOrPng && isLt2M;
}

class UsersToUpgrade extends Component {
	constructor() {
		super()
		this.state = {
			isActive: false, // tab切换
			isClick: false, // 验证码按钮禁用
			codeContent: '获取验证码', // 验证码按钮文案
			loading: false, // 上传图片的loading图
			imageUrl: '', // 上传图片的url
			getFieldsValue: {},// 表单的值
			captchaChange: '',// 监听的验证码
			companyPhoneChange: '',//监听手机号
			nickName: '',//公司名称
			city: '',//市
			province: '',//省
			county: '',//县区
			name: '',
			address: '',
			companyType: 0,
			Code: "",
			optionss: [
				{
					value: '1',
					label: '汽配商',
				},
				{
					value: '2',
					label: '汽修厂',
					children: [
						{
							value: '一类',
							label: '一类',
						},
						{
							value: '二类',
							label: '二类',
						},
						{
							value: '快修',
							label: '快修',
						},
						{
							value: '专修',
							label: '专修',
						},
						{
							value: '汽车美容',
							label: '汽车美容',
						},
						{
							value: '改装',
							label: '改装',
						},
					],
				},
				{
					value: '3',
					label: '厂家',
				},
				{
					value: '5',
					label: '报废车企业',
				}
			]
		}
	}

	componentWillMount() {
		window.scrollTo(0, 0);
	}
	componentDidMount() {
		// this.Essential()
		// this.shoppinglist()
	}

	backLogin = () => {
		window.history.back();
	}
	// 获取验证码
	getCode = () => {
		// 重新发送动画
		if (this.state.companyPhoneChange === "") {
			message.error('手机号不能为空')
			return;
		}
		let time = 60;
		this.state.isClick = !this.state.isClick;
		var timer = setInterval(() => {
			time--;
			this.setState({
				codeContent: `重新发送(${time})`
			})
			if (time <= 0) {
				clearInterval(timer);
				time = 5;
				this.setState({
					codeContent: `获取验证码`,
					isClick: !this.state.isClick
				})
			}
		}, 1000);
		let encrypt = {
			Code: "00021003",
			Mobile: this.state.companyPhoneChange,
			TimeSptams: String(Date.parse(new Date())),
			SendMsgType: "5",
			Tokens: ""
		};
		let msg = `${encrypt.Mobile}${encrypt.SendMsgType}${encrypt.TimeSptams}`;

		encrypt.Tokens = encryptByDES(msg, 'jinmusen7931BEIJING');

		BackEnd.getData(encrypt)
			.then(res => {
				if (res.data.Message.ErrorMessage) {
					message.error(res.data.Message.ErrorMessage.ErrMsg)
					return;
				}
				window.sessionStorage.setItem('TimeSptams', encrypt.TimeSptams)
				message.success('短信发送成功!')
			})
			.catch(err => {
				message.error('短信发送失败!')
			});
	};
	// 格式数据
	initData() {
		let initData = {
			Code: 21005,
			Mobile: this.state.companyPhoneChange,
			MsgCodes: this.state.captchaChange,
			TimeSptams: window.sessionStorage.getItem('TimeSptams'),
			SendMsgType: 5
		}

		let key = `${initData.Mobile}${initData.MsgCodes}${initData.TimeSptams}`;
		initData = Object.assign(
			{},
			initData,
			{
				Tokens: encryptByDES(key, 'jinmusen7931BEIJING')
			}
		)
		return initData
	}


	handleChange = (info, fileList, event) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageUrl =>
				this.setState({
					imageUrl: info.file.response.datas.baseUrl + info.file.response.datas.filePath,
					loading: false,
				}),
			);
		}
	};
	componentWillUnmount() {
		clearInterval(this.timer);
	}
	companyArea(value) {
		console.log(value.toString())
		this.setState({
			province: value[0],//省
			city: value[1],//市
			county: value[2],//县区
			address: value.toString()
		})
	}
	companyType(value) {
		console.log(value[0])
		this.setState({
			companyType: value[0]
		})
	}

	Essential = () => {

		BackEnd.getData(this.initData()).then(res => {

			if (res.data.Message.ErrorMessage) {
				message.error('验证码不正确!');
				return;
			}
			let dataDatum = {
				"photos": this.props.photos,
				"nickName": this.state.nickName,
				"contactor": this.state.nickName,
				"name": this.state.name,
				"address": this.state.address,
				"province": this.state.province,
				"city": this.state.city,
				"county": this.state.county,
				"telephone": this.state.companyPhoneChange,
				"licenceImgUrl": this.state.imageUrl,
				"imId": Number(window.localStorage.getItem('IMId')),
				"userId": Number(window.localStorage.getItem('userId')),
				"enterpriseCategory": this.state.companyType,
				"mainBusiness": "",
			}
			BackEnd.upgradeEnterprise('/api/UserCenter/UpdaUserPromotionEnterprise', dataDatum).then(res => { 

				if (res.data.code == "0") {
					this.props.history.push("/Usercenter/Shopadministration")
				} else {
					message.error('您的帐号已经绑定了公司不能再次绑定公司了');
				}
			})
		})
	};


	render() {
		const { getFieldDecorator } = this.props.form;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 24,
					offset: 0,
				},
			},
		};


		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">上传</div>
			</div>
		);
		return (
			<div>

				<Header></Header>
				<Usercenter></Usercenter>
				<div className="Essentialinfobox">
					<p className='title'>基本信息</p>
					<div className="Essentialinfo">
						<div className='registerContentCompany' style={this.props.isActive ? { display: 'none' } : { display: 'block' }}>
							<span className="getBack" onClick={() => {
								this.props.history.push("/Usercenter/Essentialinfo")
							}}></span>

							<Form {...formItemLayout} onSubmit={this.handleCompanySubmit} >
								<Form.Item label="企业名称" >
									{getFieldDecorator('companyName', {
										rules: [
											{
												required: true,
												message: '请输入企业名称',
											},
										],
									})(<Input placeholder='请输入公司名称' onChange={(e) => { this.setState({ name: e.target.value }) }} />)}
								</Form.Item>
								<Form.Item label="所属地区">
									{getFieldDecorator('companyArea', {
										// initialValue: ['zhejiang', 'hangzhou', 'xihu'],
										rules: [
											{ type: 'array', required: true, message: '请输入地区' },
										],
									})(<Cascader options={areaJson} placeholder='请输入地区' onChange={this.companyArea.bind(this)} />)}
								</Form.Item>
								<Form.Item label="企业类别">
									{getFieldDecorator('companyType', {
										rules: [{ required: true, message: '请选择企业类别' }],
									})(
										<Cascader options={this.state.optionss} placeholder="请选择企业类型" onChange={this.companyType.bind(this)} />
									)}
								</Form.Item>
								<Form.Item label="营业执照">
									{getFieldDecorator('upload', {
										rules: [
											{
												required: true,
												message: '请上传营业执照'
											}
										]
									})(
										<Upload
											name="file"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											action="https://shop.chexd.com/apis/upload/uploadFile"
											beforeUpload={beforeUpload}
											onChange={this.handleChange}
										>
											{this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
										</Upload>
									)}
								</Form.Item>
								<Form.Item label="联系人姓名">
									{/* 
						  name:wangji
						  date:2020.5.14
						  content:企业注册添加联系人姓名
						*/}
									{getFieldDecorator('nickname', {
										rules: [
											{
												required: true,
												message: '请输入联系人姓名',
											},
											{
												pattern: "^[A-z0-9\\u4e00-\\u9fa5]*$",
												message: '禁止输入特殊字符'
											}
										],
									})(<Input placeholder='请输入联系人姓名' onChange={(e) => { this.setState({ nickName: e.target.value }) }} />)}
								</Form.Item>
								<Form.Item label="手机号">
									{getFieldDecorator('companyPhone', {
										rules: [
											{
												required: true,
												message: '请输入手机号',
											},
											{
												pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/,
												message: '手机号输入不正确'
											}
										]
									})(<Input placeholder='请输入手机号' maxLength={11} onChange={(e) => { this.setState({ companyPhoneChange: e.target.value }) }} autoComplete='new-password' />)}
								</Form.Item>
								<Form.Item label="验证码">
									<Row gutter={8}>
										<Col span={12}>
											{getFieldDecorator('captcha', {
												rules: [{ required: true, message: '请输入验证码' }],
											})(<Input maxLength={6} placeholder='请输入验证码'
												onChange={(e) => { this.setState({ captchaChange: e.target.value }) }
												} />)}
										</Col>
										<Col span={12}>
											<Button onClick={this.getCode} disabled={this.state.isClick}>{this.state.codeContent}</Button>
										</Col>
									</Row>
								</Form.Item>
							</Form>
							<Button type="primary" htmlType="submit" className='registerButton' onClick={() => {
								if (this.state.name == "") {
									message.error('请填写您的公司名称');
								} else if (this.state.imageUrl == '') {
									message.error('请上传营业执照');
								} else if (this.state.nickName == '') {
									message.error('请填写联系人姓名');
								} else if (this.state.companyPhoneChange == '') {
									message.error('手机号不能为空');
								} else if (this.state.captchaChange == '') {
									message.error('请输入验证码');
								} else {
									this.Essential()
								}
							}} >确定</Button>

						</div>
					</div>
				</div>
				{
					this.state.isShowDelShopPopup ?
						<div className="shopPopup">
							<div className="main">
								<div className="title">
									<span>提示</span>
									<span onClick={() => { this.setState({ isShowDelShopPopup: false }) }}>x</span>
								</div>
								<div className="shopCont">
									<p style={{ color: '#000' }}>修改成功</p>
								</div>
								<div className="btnBox">
									<button onClick={() => { this.setState({ isShowDelShopPopup: false }) }}>确定</button>
								</div>
							</div>
						</div>
						: ""
				}

			</div >
		)
	}
}
export default Form.create({})(UsersToUpgrade)
