import React, { Component } from 'react'
import Broadside from '../../../../components/Particulars/Broadside'
import Header from '../../../../components/Header'
import { Link, withRouter } from 'react-router-dom'
import BackEnd from '../../../../services/api'
import { Table,Button,message,Modal,Select,Radio,Input, Row, Col} from 'antd'
import './ToBeSalesslip.scss'

import moment from 'moment'
import areaData from '../data.json'

const { Option } = Select
const { confirm } = Modal




class ToBeSalesslip extends Component {


constructor() {
    super()
    this.state = {
        information:{},
        addressinfo:[],
        details:[],
        table:{},
        UnitPrice:0,
        Quantity:0,
        OrderStatus:2,
        tooltip: false,
        too:false,
        Id:0,
        columns: [{
            title: '配件编码',
            dataIndex: 'ComponentCode',
            key: 'ComponentCode',
            width: 100,
            ellipsis: true,
            
        }, {
            title: '配件名称',
            dataIndex: 'ComponentName',
            key: 'ComponentName',
            width: 100,
           
        }, {
            title: '产地',
            dataIndex: 'Origin',
            key: 'Origin',
            width: 100,
           
            
        }, {
            title: '单位',
            dataIndex: 'Unit',
            key: 'Unit',
            width: 100,
           
        }, {
            title: '单价',
            dataIndex: 'UnitPrice',
            key: 'UnitPrice',
            width: 100,
            render: (text, record) => (
                <div>{Number(text).toFixed(2)}</div>
              )
        },  {
            title: '订货数量',
            dataIndex: 'Quantity',
            key: 'Quantity',
            width: 100,
            
        },, {
            title: '小计',
            dataIndex: 'OrderStatus',
            key: 'OrderStatus',
            width: 100,
            render: (text, record) => (
                <span>
                    {(record.Quantity *record.UnitPrice).toFixed(2)}
              </span>
            )
        },],
        TotalMoney:0,
        notarize:false,
        visible: false, // 新增地址
        delivery: false,// 地址管理
        provinceValue: undefined,// 省级查询值
        cityValue: undefined, // 市级查询值
        regionValue: undefined, // 区级查询值
        cityDisabled: true, // 区域市级按钮
        regionDisabled: true, // 区域区级按钮
        placeType: 0, // 修改 || 编辑
        editPlace: {}, // 编辑地址数据
        increaseList: {
            id: 0,
            userId: Number(window.localStorage.getItem('userId')),
            consignee: '',
            mobile: '',
            province: '',
            city: '',
            county: '',
            address: '',
            isDefault: true,
            updateTime: String(Date.parse(new Date()))
        },
          addressInfo: [], // 收货地址
        columoos: [{
            title: '收货人',
            dataIndex: 'Consignee',
            key: 'Consignee',
            width: 80,
            render: (text, record) => (
                <span>{text}</span>
            )
        }, {
            title: '手机号',
            dataIndex: 'Mobile',
            key: 'Mobile',
            width: 100,
            ellipsis: true,
            className: 'resultColumns',
            render: (text, record) => (
                <span>{text}</span>
            )
        }, {
            title: '所在地区',
            width: 150,
            ellipsis: true,
            render: (text, record) => (
                <div>{record.Province}{record.City}{record.County}</div>
            )
        }, {
            title: '详细地址',
            dataIndex: 'Address',
            key: 'Address',
            width: 180,
            ellipsis: true,
            className: 'resultColumns',
            render: (text, record) => (
                <span>{text}</span>
            )
        }, {
            title: '是否默认',
            dataIndex: 'IsDefault',
            key: 'IsDefault',
            width: 80,
            ellipsis: true,
            className: 'resultColumns',
            render: (text, record) => (
                <span>{text ? '默认' : ''}</span>
            )
        }, {
            title: '操作',
            width: 80,
            ellipsis: true,
            className: 'resultColumns',
            render: (text, record) => (
                <div className="operation">
                    <span onClick={this.increasePlace.bind(this, 1, record)}>编辑</span>
                    <span onClick={this.deletePlace.bind(this, record)}>删除</span>
                </div>
            )
        }],
        placeId:0,
        Freight :0,
        FavorablePrice :0,
        Code:0,
        isCode:0
       
    }
}

componentWillMount() {
    window.scrollTo(0, 0);
   
}
componentDidMount() {
    this.getShopCartSeller();
    this.getShopCartnotarize()
    this.getShopCart()
}
getShopCartSeller() {
    const { search } = this.props.location
    const paramsString = search.substring(4)
    const id = paramsString
    // console.log(id)
    BackEnd.orderSeller('/api/ShoppingCart/GetOrderDetails', {
        "id": Number(id)
        // "id":330,
    }).then(res => {
        console.log('你好',res)
        // console.log(this.props.location.state.name)
        this.setState({
            information: res.data.ResponseObj.orderInvoiceInfo,
            addressinfo: res.data.ResponseObj.order_AddressInfo,
            details: res.data.ResponseObj.order_Details,
            table: res.data.ResponseObj.order_Table,
        },()=>{
            this.totalMoney()
            // console.log(this.props.location.state.name)
        })
       
    })
}
getShopCartnotarize() {
    const { search } = this.props.location
    const paramsString = search.substring(4)
    const id = paramsString
    BackEnd.notarize('/api/ShoppingCart/GetOrdersellerDetails',{
        status: 1 ,
        id: Number(id),
        // "id":330,
        buyerSellerState: 0,
        userId: Number(window.localStorage.getItem('userId'))
    }).then(res => {
        console.log('niaho',res)
    })
}

getShopCart(userId) {
    BackEnd.getUserPlace(Number(window.localStorage.getItem('userId'))).then(res => {
        console.log("新增", res.data)
            this.setState({
                  addressInfo: res.data.Result.ResponseObj,
            },()=>{
                this.lodData()
            })
        
    })
}
lodData() {
    for(var i =0 ; i< this.state.  addressInfo.length; i++){
        if(this.state.  addressInfo[i].IsDefault==true){
            this.setState({
                placeId : this.state.  addressInfo[i].Id
            })
           console.log(this.state.placeId)
        }
    }
}

increasePlace = (type, record) => {
    console.log('编辑', type, record)
    this.setState({
        visible: true
    }, () => {
        if (type) {
            this.setState({
                placeType: 1,
                editPlace: record
            }, () => {
                console.log('暂存编辑数据', this.state.editPlace)
                this.refs.Consignee.value = this.state.editPlace.Consignee
                this.refs.phonePlace.value = this.state.editPlace.Mobile
                this.refs.detailPlace.value = this.state.editPlace.Address
                let data = this.state.increaseList
                data.isDefault = this.state.editPlace.IsDefault
                data.id = this.state.editPlace.Id
                this.setState({
                    provinceValue: this.state.editPlace.Province,
                    cityValue: this.state.editPlace.City,
                    regionValue: this.state.editPlace.County,
                    increaseList: data
                }, () => {
                    console.log('更改状态', this.state.increaseList)
                })
            })
        } else {
            this.setState({
                placeType: 0,
            }, () => {
                this.refs.Consignee.value = ''
                this.refs.phonePlace.value = ''
                this.refs.detailPlace.value = ''
                let data = this.state.increaseList
                data.isDefault = true
                data.id = 0
                this.setState({
                    provinceValue: undefined,
                    cityValue: undefined,
                    regionValue: undefined,
                    increaseList: data
                })
                console.log('点击新增', this.refs.Consignee.value)
            })
        }
    })
}
   // 地址管理
   addressdelivery = () => {
    this.setState({
        delivery: true
    })
}
tubeHandledelivery = () => {
        
    this.setState({
        delivery: false
    })
}
// 省级下拉选项
selectProvince = (value) => {
    let newProvinceValue = Object.assign({}, this.state.increaseList,
        { province: value },
        { city: '' },
        { county: '' }
    )
    this.setState({
        increaseList: newProvinceValue,
        provinceValue: value,
        cityValue: undefined,
        regionValue: undefined
    })
    if (value) {
        this.setState({
            cityDisabled: false,
            regionDisabled: true // 禁用区级选项
        })
    } else {
        // 清空按钮触发时
        let newProvinceValue = Object.assign({}, this.state.increaseList,
            { province: '' },
            { city: '' },
            { county: '' }
        )
        this.setState({
            increaseList: newProvinceValue,
            cityDisabled: true,
            regionDisabled: true,
            provinceValue: value,
        })
    }
}
// 市级下拉选项
selectCity = (value) => {
    let newCityValue = Object.assign({}, this.state.increaseList,
        { city: value },
        { county: '' }
    )
    this.setState({
        increaseList: newCityValue,
        cityValue: value,
        regionValue: undefined
    })
    if (value) {
        this.setState({
            regionDisabled: false
        })
    } else {
        // 清空按钮触发时
        let newCityValue = Object.assign({}, this.state.increaseList,
            { city: '' },
            { county: '' }
        )
        this.setState({
            increaseList: newCityValue,
            regionDisabled: true,
            regionValue: value
        })
    }
}
// 区级下拉选项
selectRegion = (value) => {
    let newRegionValue = Object.assign({}, this.state.increaseList,
        { county: value }
    )
    if (value) {
        this.setState({
            increaseList: newRegionValue,
            regionValue: value,
        })
    } else {
        // 清空按钮触发时
        let newRegionValue = Object.assign({}, this.state.increaseList,
            { county: '' }
        )
        this.setState({
            increaseList: newRegionValue,
            regionValue: value,
        })
    }
}
// 是否为默认地址
isDefaultPlace = (e) => {
    console.log('设为默认', e.target.checked)
    let changeDefaultPlace = Object.assign({}, this.state.increaseList, {
        isDefault: !this.state.increaseList.isDefault
    })
    this.setState({
        increaseList: changeDefaultPlace
    })
}
  // 删除收货地址
  deletePlace(record) {
    console.log('删除信息', record)
    let that = this
    confirm({
        title: '提示',
        content: '确认删除此询价单吗？',
        okText: '确认',
        okType: 'warning',
        cancelText: '取消',
        onOk() {
            BackEnd.deletePlace(record.Id).then(res => {
                console.log('删除项', res.data)
                if (res.data.Result.Message == "Success") {
                    message.success('删除成功')
                    that.getShopCart();
                }
            })
        },
        onCancel() { },
    })

}

// 确认增加收货地址 || 编辑收货地址
handleOk = () => {
    if (this.refs.Consignee.value == '' || this.refs.phonePlace.value == '' || this.state.regionValue == undefined || this.refs.detailPlace.value == '') {
        message.info('填写信息不能为空')
        return
    }
    let increasePlaceInfo = Object.assign({}, this.state.increaseList, {
        consignee: this.refs.Consignee.value,
        mobile: this.refs.phonePlace.value,
        province: this.state.provinceValue,
        city: this.state.cityValue,
        county: this.state.regionValue,
        address: this.refs.detailPlace.value
    })
    this.setState({
        increaseList: increasePlaceInfo
    }, () => {
        BackEnd.companySquare('/api/ShoppingCart/SaveOrder_AddressInfo', this.state.increaseList).then(res => {
            if (res.data.Result.Message == "Success") {
                message.success('新增成功')
                this.setState({
                    visible: false
                }, () => {
                    this.getShopCart();
                })
            }
        })
    })
}


totalMoney =() => {
    let TotalPice = 0;
    let TotalPices = 0;
    for(var i =0 ; i<this.state.details.length ;i++ ){
        TotalPice=TotalPice + Number(this.state.details[i].UnitPrice)*Number(this.state.details[i].Quantity)
        TotalPices=TotalPices+Number(this.state.details[i].Quantity)
    }
    this.setState({
        TotalMoney:TotalPice ,                
        Quantity:TotalPices
    },()=>{
    })
}
//确认订单
getShopCartOK() {
    const { search } = this.props.location
    const paramsString = search.substring(4)
    const id = paramsString
    BackEnd.ordeOK('/api/ShoppingCart/confirmOrder', {
        "status": 1,
        "id": Number(id),
        // "id":330,
        "freight": this.refs.favorable.value,
        "favorablePrice": this.refs.favorablePrice.value,
        "orderAddressId": this.state.table.DeliveryRequire == 2 ? this.state.placeId : this.state.addressInfo.Id,
    }).then(res => {
        console.log( 'nihao',res)
        if(res.data.Code ==1){
            message.info('系统错误，请重新确认！')
        }
    })
}
//发货订单
getShopCartDeliver() {
    const { search } = this.props.location
    
    const paramsString = search.substring(4)
    
    const id = paramsString
    BackEnd.DeliverOrder('/api/ShoppingCart/DeliverOrder', {
        "status": 3,
        "id": Number(id),
        // "id":330,
        "deliveryRemarks": this.refs.logisticsRemarks.value,
        "deliveryRequire":this.state.table.DeliveryRequire,
        "logisticsCompany": this.refs.logisticsCompany.value,
        "logisticsNumber":  this.refs.logisticsNumber.value
    }).then(res => {
        console.log(res)
        this.setState({
            isCode:res.data.Code
        })
    })
}

handleCancel = () => {
    this.setState({
        visible: false
    })
}
//关闭提示框
tubeHandleTooltip = () =>{
    this.setState({
        tooltip: false
    })
} 
tubeHandletooltip = () => {
    this.setState({
        tooltip: true
    })
}


//收货订单
getShopCartGoodsOrder() {
    BackEnd.GoodsOrder('/api/ShoppingCart/ReceivingGoodsOrder', {
            "status": 5,
            "id":this.props.location.state.name,
            // "id":326,
    }).then(res => {
        console.log(res)
    })
}
    
     //关闭
     tubeHandleTooltip = () =>{
        this.setState({
            tooltip: false
        },()=>{
            this.props.history.push('/SellerOrder')
        })
    } 
    
    //打开
      tubeHandletooltip = () => {
        this.setState({
            tooltip: true
        },)
    }
  //关闭
  Cancel = () =>{
    this.setState({
        tooltip: false
    })
} 

    //打开
    Cancels = () => {
        this.setState({
            tooltip: true
        },)
    }
    //关闭
    notarize = () =>{
        this.setState({
            notarize: false
        },()=>{
            
        })
    } 

    //打开
    Notarize = () => {
        this.setState({
            notarize: true
        },)
    }
    render() {
        return (
            <div className="toBeConfirmedPage">
                 <Header></Header>
                <div className="toBeConfirmedContainer">
              
                    {/* 侧边公共 */}
                        {/* <Broadside></Broadside> */}
                    <div className="Broadside">
                            <div className="title">
                                <span>订单管理</span>
                            </div>
                        <p onClick={()=>{
                                this.props.history.push('/buyerOrder')
                        }}>
                        <span>订单管理(买家)</span>
                        </p>
                        <p className="title-p">
                        <span className="title-span" >订单管理(卖家)</span>
                        </p>
                    </div>
                    <div className="toBeConfirmedContent">
                        <p className="headerTitle"><span>账户资料</span></p>
                        <p className="navigation"><span className="spann"  onClick={()=>{
                                this.props.history.push('/SellerOrder')
                        }}>订单管理</span><span>></span><span>订单详情</span></p>
                        {/* 订单详情 */}
                        {
                            this.state.table.OrderStatus == 0
                            ?
                            <div className="orderDetail">
                                <p className="title">订单详情</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>订单编号</th>
                                            <th>下单时间</th>
                                            <th>订单状态</th>
                                            <th>确认时间</th>
                                            <th>发货时间</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.OrderCode}</td>
                                            <td>{moment(this.state.table.OrderTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            { this.state.table.OrderStatus == 0 ?<td className="TD">待确认</td> :''}
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''
                        }
                         {
                            this.state.table.OrderStatus == 1
                            ?
                            <div className="orderDetail">
                                <p className="title">订单详情</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>订单编号</th>
                                            <th>下单时间</th>
                                            <th>订单状态</th>
                                            <th>确认时间</th>
                                            <th>发货时间</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.OrderCode}</td>
                                            <td>{moment(this.state.table.OrderTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            { this.state.table.OrderStatus == 1 ?<td className="TD">待支付</td> :''}
                                            <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>-</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''
                        } {
                            this.state.table.OrderStatus == 2
                            ?
                            <div className="orderDetail">
                                <p className="title">订单详情</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>订单编号</th>
                                            <th>下单时间</th>
                                            <th>订单状态</th>
                                            <th>确认时间</th>
                                            <th>发货时间</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.OrderCode}</td>
                                            <td>{moment(this.state.table.OrderTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            { this.state.table.OrderStatus == 2 ?<td className="TD">待发货</td> :''}
                                            <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''
                        } {
                            this.state.table.OrderStatus == 3
                            ?
                            <div className="orderDetail">
                                <p className="title">订单详情</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>订单编号</th>
                                            <th>下单时间</th>
                                            <th>订单状态</th>
                                            <th>确认时间</th>
                                            <th>发货时间</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.OrderCode}</td>
                                            <td>{moment(this.state.table.OrderTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            { this.state.table.OrderStatus == 3 ?<td className="TD">已发货</td> :''}
                                            <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{moment(this.state.table.DeliveryTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''
                        }
                         {
                            this.state.table.OrderStatus == 4
                            ?
                            <div className="orderDetail">
                                <p className="title">订单详情</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>订单编号</th>
                                            <th>下单时间</th>
                                            <th>订单状态</th>
                                            <th>取消原因</th>
                                            <th>确认时间</th>
                                            <th>发货时间</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.OrderCode}</td>
                                            <td>{moment(this.state.table.OrderTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            { this.state.table.OrderStatus == 4 ?<td className="TD">已取消</td> :''}
                                            <td>{this.state.table.Reason}</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''
                        }
                         {
                            this.state.table.OrderStatus == 5
                            ?
                            <div className="orderDetail">
                                <p className="title">订单详情</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>订单编号</th>
                                            <th>下单时间</th>
                                            <th>订单状态</th>
                                            <th>确认时间</th>
                                            <th>发货时间</th>
                                            <th>收货时间</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.OrderCode}</td>
                                            <td>{moment(this.state.table.OrderTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            { this.state.table.OrderStatus == 5 ?<td className="TD">已收货</td> :''}
                                            <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{moment(this.state.table.DeliveryTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{moment(this.state.table.UpdateTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            ''
                        }
                        {/* 物流与结算 */}
                        {
                            this.state.table.OrderStatus == 0
                            ?
                            <div className="logisticszContainer">
                                <p className="title">物流与结算</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>送货要求</th>
                                            <th>交易备注</th>
                                            <th className="money" >运费（元）</th>
                                            <th className="money" >优惠金额 </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.DeliveryRequire=='1'? '隔天' :""}
                                                    {this.state.table.DeliveryRequire=='2'? '自提' :""}
                                                    {this.state.table.DeliveryRequire=='3'? '急件' :""}</td>
                                            <td>{this.state.table.TradingRemarks}</td>
                                            <td><input className="myinput" placeholder="无运费填0" onChange={(e)=>{
                                                this.setState({
                                                    Freight : e.target.value
                                                })
                                            }} ref="favorable" type="text"/></td>
                                            <td><input className="myinput" placeholder="无优惠填0" onChange={(e)=>{
                                                this.setState({
                                                    FavorablePrice : e.target.value
                                                })
                                            }} ref="favorablePrice" type="text"/></td>
                                        </tr>
                                    </tbody>  
                                </table>
                            </div>
                        :
                        ''
                        }
                         {
                            this.state.table.OrderStatus==1
                            ?
                            <div className="logisticszContainer">
                                <p className="title">物流与结算</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>送货要求</th>
                                            <th>交易备注</th>
                                            <th>运费（元）</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.table.DeliveryRequire=='1'? '隔天' :""}
                                                {this.state.table.DeliveryRequire=='2'? '自提' :""}
                                                {this.state.table.DeliveryRequire=='3'? '急件' :""}</td>
                                            <td className="detail">{this.state.table.TradingRemarks}</td>
                                            <td>{this.state.table.Freight.toFixed(2)}</td>
                                            
                                        </tr>
                                    </tbody>  
                                </table>
                            </div>
                        :
                        ''
                        }
                        {
                            this.state.table.OrderStatus == 2
                            ?
                           
                                <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>送货要求</th>
                                                <th>交易备注</th>
                                                <th>运费（元）</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.DeliveryRequire=='1'? '隔天' :""}
                                                    {this.state.table.DeliveryRequire=='2'? '自提' :""}
                                                    {this.state.table.DeliveryRequire=='3'? '急件' :""}</td>
                                                <td className="detail">{this.state.table.TradingRemarks}</td>
                                                <td>{this.state.table.Freight.toFixed(2)}</td>
                                            </tr>
                                        </tbody>  
                                       
                                </table>
                                <table>
                                        <thead>
                                            <tr>
                                                <th>物流公司<span className="TD"> （发货必填）</span></th>
                                                <th>物流单号<span className="TD">（发货必填）</span></th>
                                                <th onClick={()=>{
                                                    console.log(this.refs.logisticsCompany.value)
                                                }}>发货备注<span></span></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {this.state.table.DeliveryRequire=='2'? <tr>
                                                <td><input className="myinput" placeholder="自提订单可不填" ref="logisticsCompany" type="text"/></td>
                                                <td><input className="myinput" placeholder="自提订单可不填" ref="logisticsNumber" type="text"/></td>
                                                <td><input className="myinput"  ref="logisticsRemarks"  type="text"/></td>
                                            </tr>
                                            :
                                            <tr>
                                                <td><input className="myinput" placeholder="请输入物流公司" ref="logisticsCompany" type="text"/></td>
                                                <td><input className="myinput" placeholder="请输入物流单号" ref="logisticsNumber" type="text"/></td>
                                                <td><input className="myinput"  ref="logisticsRemarks"  type="text"/></td>
                                            </tr>
                                            }
                                        </tbody>   
                                    </table>
                            </div>
                        :
                        ''
                        }
                         {
                            this.state.table.OrderStatus == 3
                            ?
                                <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>送货要求</th>
                                                <th>交易备注</th>
                                                <th>运费（元）</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.DeliveryRequire=='1'? '隔天' :""}
                                                    {this.state.table.DeliveryRequire=='2'? '自提' :""}
                                                    {this.state.table.DeliveryRequire=='3'? '急件' :""}</td>
                                                <td className="detail">{this.state.table.TradingRemarks}</td>
                                                <td>{this.state.table.Freight.toFixed(2)}</td>
                                              
                                            </tr>
                                        </tbody> 
                                    </table>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>物流公司</th>
                                                <th>物流单号</th>
                                                <th>发货备注</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.LogisticsCompany}</td>
                                                <td>{this.state.table.LogisticsNumber}</td>
                                                <td className="detail">{this.state.table.DeliveryRemarks}</td>
                                            </tr>
                                        </tbody>   
                                    </table>
                            </div>
                        :
                        ''
                        }
                         {
                            this.state.table.OrderStatus == 4
                            ?
                                <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>送货要求</th>
                                                <th>交易备注</th>
                                                <th>运费（元）</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.DeliveryRequire=='1'? '隔天' :""}
                                                    {this.state.table.DeliveryRequire=='2'? '自提' :""}
                                                    {this.state.table.DeliveryRequire=='3'? '急件' :""}
                                                    </td>
                                                <td className="detail">{this.state.table.TradingRemarks}</td>
                                                <td>{this.state.table.Freight.toFixed(2)}</td>
                                                
                                            </tr>
                                        </tbody>  
                                        <table>
                                        
                                    </table>
                                </table>
                            </div>
                        :
                        ''
                        }
                        {
                            this.state.table.OrderStatus == 5
                            ?
                                <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>送货要求</th>
                                                <th>交易备注</th>
                                                <th>运费（元）</th>
                                                <th>优惠金额</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.DeliveryRequire=='1'? '隔天' :""}
                                                    {this.state.table.DeliveryRequire=='2'? '自提' :""}
                                                    {this.state.table.DeliveryRequire=='3'? '急件' :""}</td>
                                                <td className="detail">{this.state.table.TradingRemarks}</td>
                                                <td>{this.state.table.Freight.toFixed(2)}</td>
                                                <td>{this.state.table.FavorablePrice.toFixed(2)}</td>
                                                
                                            </tr>
                                        </tbody>  
                                        <table>
                                        <table>
                                        <thead>
                                            <tr>
                                                <th>物流公司</th>
                                                <th>物流单号</th>
                                                <th>发货备注<span></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.LogisticsCompany}</td>
                                                <td>{this.state.table.LogisticsNumber}</td>
                                                <td>{this.state.table.DeliveryRemarks}</td>
                                            </tr>
                                        </tbody>   
                                    </table>
                                    </table>
                                </table>
                            </div>
                        :
                        ''
                        }
                       
                      {/* 收货地址 */}
                      {
                      this.state.addressinfo == null ? "" 
                      : 
                      (
                          this.state.table.DeliveryRequire == '2' 
                      ?
                        
                         this.state.table.OrderStatus == 0 ?
                        <div>
                            <div className="title">自提地址</div>
                              
                                <div className="goodsPlaceContent">
                                    {
                                         
                                        this.state.addressInfo.length 
                                        ?   
                                        this.state.addressInfo.map((item, index) => (
                                            <div key={index} className={`goodsPlace ${item.IsDefault ? "defaullt" : ""}`}onClick={()=>{
                                                this.setState({
                                                placeId:item.Id
                                                },()=>{
                                                    console.log(this.state.placeId)
                                                })
                                            }}
                                                className={this.state.placeId == item.Id ? 'goodsPlace defaullt' : 'goodsPlace'}
                                            >
                                                <p className="isDefault"><span>{item.Consignee}</span><span>{item.IsDefault ? '默认地址' : ''}</span></p>
                                                <p>{item.Mobile}</p>
                                                <p>{item.Province}{item.City}{item.County}</p>
                                                <p>{item.Address}</p>
                                               
                                            </div>
                                        )) 
                                        : 
                                        ''
                                    }
                                </div>
                                <div className="placeAdministration">
                                    <span onClick={this.increasePlace.bind(this, 0)}>新增地址</span>
                                    <span onClick={this.addressdelivery}>地址管理</span>
                                </div>
                            </div>
                                :
                                
                                <div className="goodsPlaceContainer">
                                <p className="title">自提地址</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>收货人</th>
                                            <th>收货人手机号</th>
                                            <th>所在地区</th>
                                            <th>详细地址</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.addressinfo.Consignee}</td>
                                            <td>{this.state.addressinfo.Mobile }</td>
                                            <td>{this.state.addressinfo.Province }-{this.state.addressinfo.City  }-{this.state.addressinfo.County  }</td>
                                            <td className="detail">{this.state.addressinfo.Address }</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                      
                      : 
                        <div className="goodsPlaceContainer">
                            <p className="title">收货地址</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={()=>{
                                            console.log(this.state.addressinfo)
                                        }}>收货人</th>
                                        <th>收货人手机号</th>
                                        <th>所在地区</th>
                                        <th>详细地址</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.addressinfo.Consignee}</td>
                                        <td>{this.state.addressinfo.Mobile }</td>
                                        <td>{this.state.addressinfo.Province }-{this.state.addressinfo.City  }-{this.state.addressinfo.County  }</td>
                                        <td className="detail">{this.state.addressinfo.Address }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        )
                    }   
                        
                        
                       
                        {/* 发票信息 */}
                        {
                            this.state.information == null ? '' :
                            (this.state.information.InvoiceType=="1" 
                            ?
                            <div className="invoiceInfoContainer">
                            <p className="title">发票信息</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>发票类型</th>
                                        <th>发票抬头</th>
                                        <th>纳税人识别号</th>
                                        <th>发票内容</th>
                                        <th>发票人手机号</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {this.state.information.InvoiceType=='1' ? '普通发票' : ''}
                                           
                                        </td>
                                        <td>{this.state.information.CompanyName}</td>
                                        <td>{this.state.information.IdentificationNo}</td>
                                        <td>{this.state.information.InvoiceContent}</td>
                                        <td>{this.state.information.Mobile}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            '')
                        }
                        
                        {
                            this.state.information == null ? '' :
                            (this.state.information.InvoiceType=="2" 
                            ?
                            <div className="invoiceInfoContainer">
                            <p className="title">发票信息</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>发票类型</th>
                                        <th>单位名称</th>
                                        <th>纳税人识别号</th>
                                        <th>注册地址</th>
                                        <th>注册电话</th>
                                        <th>开户银行</th>
                                        <th>银行账号</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.information.InvoiceType=='2' ? '增值税专用发票发票' : ''}</td>
                                        <td>{this.state.information.CompanyName }</td>
                                        <td>{this.state.information.IdentificationNo}</td>
                                        <td>{this.state.information.CompanyAddress}</td>
                                        <td>{this.state.information.CompanyPhone }</td>
                                        <td>{this.state.information.CompanyBankName }</td>
                                        <td>{this.state.information.CompanyBankAccount }</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            '')
                        }
                        {
                            this.state.information == null ?
                            (this.state.table.OrderInvoiceInfoId==  0
                            ?
                            <div className="invoiceInfoContainer">
                            <p className="title">发票信息</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>发票类型</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>无票</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            '')
                            :
                            ''
                        }
                        {/* 购物清单 */}
                        <div className="shoppingListContainer">
                            <p className="title">购物清单</p>
                           
                            <div className="buyerOrderList">
                                <Table
                                    width="100%"
                                    rowKey={(record, index) => index}
                                    columns={this.state.columns}
                                    dataSource={this.state.details}
                                    bordered={true}
                                    pagination={false}
                                    className="buyerOrderListTable"
                                />
                            </div>
                            
                        </div>
                        <div className="totalContainer2">
                            <Row>
                                <Col span={16}><span>共{this.state.Quantity}件商品,总计金额:</span></Col>
                                <Col span={5}><span>￥{(this.state.TotalMoney).toFixed(2)}</span></Col>
                            </Row>
                            <Row>
                                <Col span={16}><span>运费(卖家接单后确认):</span></Col>
                                <Col span={5}><span>￥{this.state.table.OrderStatus == 0 ? Number(this.state.Freight).toFixed(2) :Number(this.state.table.Freight).toFixed(2)}</span></Col>
                            </Row>
                            <Row>
                                <Col span={16}><span>优惠(卖家接单后确认):</span></Col>
                                <Col span={5}><span>￥{this.state.table.OrderStatus == 0 ? Number(this.state.FavorablePrice).toFixed(2) : Number(this.state.table.FavorablePrice).toFixed(2)}</span></Col>
                            </Row>
                            <Row>
                                <Col span={16}>应付金额：</Col>
                                <Col span={5}>￥{this.state.table.OrderStatus == 4 ? '' :(this.state.table.OrderStatus == 0 ? (((this.state.TotalMoney*1000) + (this.state.Freight*1000) - (this.state.FavorablePrice*1000))/1000).toFixed(2) : (((this.state.TotalMoney*1000) + (this.state.table.Freight*1000) - (this.state.table.FavorablePrice*1000))/1000).toFixed(2))}</Col>
                            </Row>
                               
                            </div>
                            <div className="totalButton">
                                {this.state.table.OrderStatus==0 ? <span
                                className="btn-OK"
                                onClick={()=>{
                                    if(this.refs.favorable.value == ''){
                                        if(this.refs.favorable.value >=0){
                                            message.info('请填写运费')
                                        }
                                       
                                    }else if(this.refs.favorablePrice.value =='' ) {
                                        if(this.refs.favorablePrice.value >=0){
                                            message.info('请填写优惠金额')
                                        }
                                       
                                    }else if(this.state.table.DeliveryRequire == 2 && this.state.placeId == 0){
                                        message.info('此订单为自提订单，请填写自提地址！')
                                    }else if(this.state.TotalMoney <= 0){
                                       
                                        message.info('应付金额不能为负数')
                                    }else if( (((this.state.TotalMoney*1000) + (this.state.Freight*1000) - (this.state.FavorablePrice*1000))/1000).toFixed(2) <=0){
                                        message.info('应付金额不能小于等于0')
                                        
                                    }else{
                                        this.Notarize()
                                    }
                                    
                                }}>确认订单</span> : ''}
                                {this.state.table.OrderStatus==2 ? <span
                                    className="btn-OK"
                                    onClick={()=>{
                                        if(this.state.table.DeliveryRequire=='2') {
                                            this.tubeHandletooltip();
                                            this.getShopCartDeliver()
                                        }else{
                                            if(this.refs.logisticsCompany.value === ''){
                                                message.info('请填写物流公司！')
                                            }else if(this.refs.logisticsNumber.value === '') {
                                                message.info('请填写物流单号！')
                                            }else{
                                                this.tubeHandletooltip();
                                                this.getShopCartDeliver()
                                            }
                                        }
                                       
                                    }}
                                >发货</span> : ''}
                                {/* {this.state.table.OrderStatus==3 ? <span
                                className="btn-OK"
                                    onClick={()=>{
                                        this.Cancels()
                                    }}
                                >确认全部订单</span> : ''} */}
                            </div>
                           
                    </div>
                   
                </div>
                <Modal
                    title="提示"
                    closeIcon="关闭"
                    visible={this.state.tooltip}
                    onCancel={this.tubeHandleTooltip}
                    footer={null}
                    centered
                    style={{ margin: 'auto' }}
                    wrapClassName="placeTubeModel"
                    width={400}
                    
                    maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                >
                    <div className="DeliveryRequire">
                        {
                            this.state.table.DeliveryRequire=='2' ?  <h4>此订单为自提订单，已向收货人发送收货提醒</h4> : <h4>订单已发货</h4>
                        }
                    </div>
                    <Button className="btn" onClick={()=>{
                            if(this.state.isCode==1){
                                message.info('系统错误请重新发货')
                            }else{

                                this.props.history.push('/SellerOrder')
                            }
                           
                      
                   
                        // this.tubeHandleTooltip()
                    }}>确定</Button>
                </Modal>
                <Modal
                    title="完成订单"
                    closeIcon="关闭"
                    visible={this.state.too}
                    onCancel={this.Cancel}
                    footer={null}
                    centered
                    style={{ margin: 'auto' }}
                    wrapClassName="placeTubeModel"
                    width={400}
                    hight={265}
                    maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                >
                    <div className="ConfirmReceipt">
                        <p className="Confirmreceipt-p">确认全部收货</p>
                        <Button onClick={()=>{
                            this.getShopCartOK()
                            this.props.history.push('/SellerOrder')
                        }} className="Confirmreceipt-btn">确认</Button>
                    </div>  
                </Modal>
                <Modal

                    title="确认订单"
                    closeIcon="关闭"
                    visible={this.state.notarize}
                    onCancel={this.notarize}
                    footer={null}
                    centered
                    style={{ margin: 'auto' }}
                    wrapClassName="placeTubeModel"
                    width={400}
                    hight={265}
                    maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                >
                    <div className="Conf">
                        <p className="Confirmreceip">请确认订单</p>
                        <Button onClick={()=>{
                            // this.getShopCartnotarize()
                            
                            this.getShopCartOK()
                            this.props.history.push('/SellerOrder')
                        }} className="Conf-btn">确认</Button>
                    </div>  
                </Modal>
                <Modal
                            title="编辑自提地址"
                            closeIcon="关闭"
                            visible={this.state.delivery}
                            onCancel={this.tubeHandledelivery}
                            footer={null}
                            centered
                            style={{ margin: 'auto' }}
                            wrapClassName="placeTubeModel"
                            width={970}
                            maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                            zIndex={10}
                            >
                                <div className="tubePlaceContainer">
                            <Table
                            width='100%'
                            rowKey={(record, index) => index}
                            columns={this.state.columoos}
                            dataSource={this.state.addressInfo}
                            bordered={true}
                            pagination={false}
                            locale={this.state.locale}
                            className='placeTable'
                            />
                                <p className="increasePlace"><span onClick={this.increasePlace.bind(this, 0)}>新增</span></p>
                            </div>
                        </Modal>
                        <Modal
                        width={"width", "350px"}
                        title="自提地址"
                        closeIcon="关闭"
                        visible={this.state.visible}
                        onCancel={this.handleCancel}
                        onOk={this.handleOk}
                        centered={true}
                        wrapClassName="Model"
                        okText="确认"
                        cancelText="取消"
                        zIndex={100}
                        >
                            <div className="increasePlaceContainer">
                            <div className="increasePlaceContent">
                            <p><span>收货人</span><input type="text" ref="Consignee" /></p>
                            <p><span>手机号</span><input type="text" ref="phonePlace" /></p>
                            <div className="areaContainer"><span>地区</span>
                        <Select
                        // showSearch
                        style={{ width: 70, marginRight: 2, marginLeft: 10, textAlign: 'center' }}
                        placeholder="省"
                        optionFilterProp="children"
                        value={this.state.provinceValue}
                        onChange={this.selectProvince}
                        showArrow={false}
                        allowClear={true}
                        filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                        {
                            Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                        }
                        </Select>
                        <Select
                        // showSearch
                            style={{ width: 70, marginRight: 2, textAlign: 'center' }}
                            placeholder="市"
                            optionFilterProp="children"
                            value={this.state.cityValue}
                            onChange={this.selectCity}
                            allowClear={true}
                            showArrow={false}
                            allowClear={true}
                            disabled={this.state.cityDisabled}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >{
                            areaData.ProvinceCity[this.state.provinceValue] ?
                            Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                                areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                                    <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                                ))
                            )) : []
                        }
                        </Select>
                        <Select
                            // showSearch
                            style={{ width: 85, textAlign: 'center' }}
                            placeholder="区"
                            optionFilterProp="children"
                            value={this.state.regionValue}
                            onChange={this.selectRegion}
                            showArrow={false}
                            allowClear={true}
                            disabled={this.state.regionDisabled}
                            filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >{

                            areaData.ProvinceCity[this.state.provinceValue] ?
                            Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                                areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                                    item.Name == this.state.cityValue ?
                                        item.CityArea.map(items => (
                                            <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                                        )) : ''
                                ))
                            )) : []
                        }
                        </Select>
                        </div>
                            <p><span className="detailPlace">详细地址</span><textarea rows="3" cols="30" style={{ resize: 'none', overflow: 'hidden' }} ref="detailPlace"></textarea></p>
                            <p className="defaultPlaceContainer"><input type="checkbox" id="default" checked={this.state.increaseList.isDefault} onChange={this.isDefaultPlace} />
                            <label htmlFor="default">默认地址</label></p>
                        </div>
                        </div>
                        </Modal>
            </div>
        )
    }
}
export default  withRouter(ToBeSalesslip)