import React, { Component } from 'react'
import Header from '../../../../components/Header'


import { Tabs, message, Modal, Button, Card } from 'antd';
import './Notopened.scss'
// import Shipments from './Shipments'

const { TabPane } = Tabs;



export default class Notopened extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        return (
            <div className="sellerOrderPage">
                <div className="sellerOrderContent">
                    <div className="headerTitle">
                        <div className="headerTitle-card">
                            <div className="card-title">
                                <div className="card-title1" >
                                    <h3 className="card-span" >提示</h3>
                                    <span className="card-guan" >关闭</span>
                                </div>
                                <div className="card-content">
                                    <p>1.目前仅支持车企典ERP付费会员成为卖家</p>
                                    <p>2.未开通扫码付的卖家，商品暂不支持下单</p>
                                    <span className="pone">客服电话：400-679-0900(9:00-18:00)</span>
                                    {/* <span className="content-span">会员开通扫码</span>
                                                <span className="content-span1">在线咨询</span> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}
