import React, { Component } from 'react'
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import { Modal } from 'antd'
import BackEnd from '../../../../services/api'
import './ContactUs.scss'
class ContactUs extends Component {
  constructor() {
    super()
    this.state = {
      detailInfo: {},
      PeopleService: [],
      visible: false,
      showContent: '', // 弹框要展示的内容
      storePic: '' // 门店图片
    }
  }
  componentWillMount() { }
  componentDidMount() {
    this.contact()
  }
  contact() {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const id = searchParams.get('id')
    BackEnd
      .companySquare('/api/EnterpriseSquareInfo/GetQueryStoreSquareListdetails', {
        authCode: id
      })
      .then(res => {
        console.log('数据 ', res)
        if (res.data.ResponseObj) {
          this.setState({
            detailInfo: res.data.ResponseObj,
            PeopleService: res.data.ResponseObj.storeLoginUserModel,
            storeTitle: res.data.ResponseObj.Store,
            storePic: res.data.ResponseObj.SmallImgUrl
          }, () => {
            // console.log('标题', this.state.storePic[0])
          })
        }
      })
  }
  // 查看二维码
  magnify(type, img) {
    if (type == 'WeChat') {
      this.setState({
        showContent: img,
        visible: true
      })
    }
  }
  // 弹框关闭
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  }
  render() {
    return (
      <div className="contactUsPage">
        <Header text={this.state.detailInfo} />
        <div className="contactContainer">
          <div className="storePic">
            {this.state.storePic != null ? <img src={this.state.storePic[0]} alt="门店图片" /> : <img src={require('../../images/SquareDefaultImg_small.png')} alt="门店图片" />}
          </div>
          <div className="contactMode">
            <div className="contactPhone">
              <p><span></span><span>联系电话</span></p>
              <div><span>{this.state.detailInfo.Telephone}</span></div>
            </div>
            <div className="contactBusiness">
              <p><span></span><span>业务咨询</span></p>
              <ul>
                {
                  this.state.PeopleService ?
                    this.state.PeopleService.map((item, index) => (
                      <li key={index}>
                        <span className="head">{item.UserName.substr(item.UserName.length - 1, 1)}</span>
                        <span>{item.UserName}</span>
                        {item.Mobile ? 
                          <span><img src={require("../../images/dsv.png")} alt="手机号" />{item.Mobile}</span>
                          : 
                          ''
                        }
                        {
                          item.WeChatQRUrl ?
                          <span className="seeCode" onClick={this.magnify.bind(this, "WeChat", item.WeChatQRUrl)}>
                            <img src={require("../../images/vsd.png")} alt="微信" />查看二维码</span> 
                            :
                          <span></span>
                        }
                        
                      </li>
                    )) : ""}
              </ul>
            </div>
          </div>
        </div>
        <div className="botton">
          <Footer />

        </div>
        {/* 弹框 */}
        <Modal
          title="查看图片"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          centered={true}
        >
          <img src={this.state.showContent} alt="大图查看内容" style={style.banner} />
        </Modal>
      </div >
    )
  }
}
export default ContactUs
const style = {
  banner: {
    width: '100%',
    height: '300px',
    display: 'inline-block',
    marginBottom: '35px',
    textAlign: 'center',
    marginTop: '15px'
  }
}