import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { setCookie, getCookie } from '../../utils/env'
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { message } from 'antd';
import cookie from 'react-cookies'
import './Login.scss'
import {
    encryptByDES,
    encryptPassword,
    encryptEDSPwds
} from '../../utils/encrypt';
import BackEnd from '../../services/api';
const FormItem = Form.Item;
class Login extends Component {
    constructor() {
        super()
        this.state = {
            loginPhoneChange: '', //登录手机号
            loginPasswordChange: '', // 登录的手机密码 
        }
    }
    // 返回
    goBack = () => {
        // window.history.back();
        this.props.history.push('/')
    }
    // 监听登录手机号
    loginPhone = (e) => {
        this.setState({
            loginPhoneChange: e.target.value
        })
    }
    // 监听登录密码
    loginPassword = (e) => {
        this.setState({
            loginPasswordChange: e.target.value
        })
    }
    // 忘记密码
    forgetPassword = () => {
        this.props.history.push('/forgetPassword')
    }
    // 免费注册
    freeRegister = () => {
        this.props.history.push('/register')
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                let initData = {
                    Code: 21001, // 21101 21001
                    IsTokenLogin: "FALSE",
                    Mobile: this.state.loginPhoneChange
                };
                let newInitData =
                    this.state.loginPhoneChange.substring(this.state.loginPhoneChange.length - 4) +
                    "00kt";
                let newLoginPwd = encryptPassword(this.state.loginPasswordChange);
                initData = Object.assign({}, initData, {
                    LoginPwd: encryptEDSPwds(newLoginPwd, newInitData)
                });
                BackEnd.getData(initData).then(res => {
                    this.setState({
                        userID: res.data.Message.Id
                    })
                    console.log(res)

                    const {
                        ErrorMessage,
                    } = res.data.Message;
                    if (ErrorMessage) {
                        message.error(ErrorMessage.ErrMsg);
                        return;
                    }
                    window.localStorage.setItem('userInfo', JSON.stringify(res.data.Message))
                    window.localStorage.setItem('sessionId', res.data.Message.Tokens)
                    window.localStorage.setItem('userPhone', res.data.Message.Mobile)
                    window.localStorage.setItem('EnterpriseCategory', res.data.Message.EnterpriseCategory)
                    window.localStorage.setItem('IMId', res.data.Message.IMId)
                    window.localStorage.setItem('IMKey', res.data.Message.ImKey)
                    window.localStorage.setItem('IMTokens', res.data.Message.Tokens)
                    window.localStorage.setItem('CompanyName', res.data.Message.CompanyName)

                    if (res.data.Message.Id) {
                        window.localStorage.setItem('userId', res.data.Message.Id)

                    } else {
                        window.localStorage.setItem('userId', 0)
                    }
                    window.localStorage.setItem('AuthCode', res.data.Message.AuthCode)
                    window.localStorage.setItem('Tokens', res.data.Message.Tokens)
                    window.sessionStorage.setItem('Tokens', res.data.Message.Tokens)
                    window.sessionStorage.setItem('LoginCacheKey', res.data.Message.OWebLoginToken)
                    window.sessionStorage.setItem('JHPayRegistStatus', res.data.Message.JHPayRegistStatus)
                    window.localStorage.setItem('JHPayRegistStatus', res.data.Message.JHPayRegistStatus)

                    setCookie('LoginCacheKey', res.data.Message.OWebLoginToken, 30)
                   

                    // 建立WebSocket链接
                    // var ws = new WebSocket("ws://192.168.1.47:5100/api/ws/accept?loginkey="+res.data.Message.OWebLoginToken); //本地
                    // var ws = new WebSocket("ws://192.168.1.47:5101/ws/accept?loginkey="+res.data.Message.OWebLoginToken); //测试
                    // var ws = new WebSocket("wss://prewsapi.chexd.com/ws/accept?loginkey="+res.data.Message.OWebLoginToken); //准成产
                    var ws = new WebSocket("wss://wsapi.chexd.com/ws/accept?loginkey="+res.data.Message.OWebLoginToken); //成产


                    ws.onopen = function(evt) { 
                        console.log("已经连接"); 
                       
                    };
                    ws.onmessage = function(evt) {
                        console.log(evt)
                        const str = evt.data
                        const mp3Url = JSON.parse(str).data
                        
                        let mp3 = mp3Url
                        let audio = new Audio(mp3)
                        audio.play()
                
                    // ws.close();
                    };
                    ws.onclose = function(evt) {
                        // alert("Connection closed.");
                        console.log(evt)
                    }; 
                    // return false
                      
                 
                    ///////////////////////
                    message.success('登录成功');
                    console.log(Number(window.localStorage.getItem('userId')))
                    // this.props.history.replace('/')
                    window.history.go(-1)  

                    // cookie.save('userId', 'hahaha', {
                    //     path: '/',
                    //     domain: 'http://*.chexd.com'
                    //   })

                    // document.cookie="name=value;path=/;domain=chexd.com";
                    window.jq.cookie('userInfo', JSON.stringify(res.data.Message), {
                        path: '/',
                        domain: 'chexd.com'
                    })
                    window.jq.cookie('sessionId', res.data.Message.Tokens, {
                        path: '/',
                        domain: 'chexd.com'
                    })
                    window.jq.cookie('userPhone', res.data.Message.Mobile, {
                        path: '/',
                        domain: 'chexd.com'
                    })
                    // alert("1111")
                    window.jq.cookie('LoginCacheKeys', res.data.Message.OWebLoginToken, {
                        path: '/',
                        domain: 'chexd.com'
                    })
                    // window.jq.cookie('LoginCacheKey', res.data.Message.OWebLoginToken, {
                    //     path: '/',
                    //     domain: 'chexd.com'
                    // })
                    // alert("2222")
                    window.jq.cookie('wangBaDan', '1111111', {
                        path: '/',
                        domain: 'chexd.com'
                    })

                    // window.jq.cookie('EnterpriseCategory', res.data.Message.EnterpriseCategory, {
                    //     path: '/',
                    //     domain: 'chexd.com'
                    // })
                    if (res.data.Message.Id) {
                        window.jq.cookie('userId', res.data.Message.Id, {
                            path: '/',
                            domain: 'chexd.com'
                        })

                    } else {
                        window.jq.cookie('userId', 0, {
                            path: '/',
                            domain: 'chexd.com'
                        })
                    }
                    window.jq.cookie('AuthCode', res.data.Message.AuthCode, {
                        path: '/',
                        domain: 'chexd.com'
                    })
                    window.jq.cookie('Tokens', res.data.Message.Tokens, {
                        path: '/',
                        domain: 'chexd.com'
                    })
                    // window.jq.cookie('LoginCacheKey', res.data.Message.OWebLoginToken, {
                    //     path: '/',
                    //     domain: 'chexd.com'
                    // })



                }).catch(err => {
                    message.error('服务异常,请稍后重试')
                })
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        return (
            <div className='LoginPage'>
                <div className='LoginContainer'>
                   
                    <p className='LoginContainerTitle'>用户登录  <img src="images/close.png" alt="关闭按钮" onClick={this.goBack} /></p>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            <span className='inpuntSpan'>手机号</span>
                            {getFieldDecorator('phone', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入手机号'
                                    }, {
                                        pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/,
                                        message: '手机号输入不正确'
                                    }],
                            })(
                                <Input
                                    placeholder="请输入手机号"
                                    onChange={this.loginPhone}
                                    maxLength={11}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <span className='inpuntSpan'>登录密码</span>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入密码'
                                    }],
                            })(
                                <Input.Password
                                    type="password"
                                    placeholder="请输入密码"
                                    onChange={this.loginPassword}
                                    maxLength={20}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登录</Button>
                        </Form.Item>
                        <Form.Item>
                            <div className='orderOptions'><span onClick={this.forgetPassword}>忘记密码?</span><span onClick={this.freeRegister}>免费注册</span></div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}
export default Form.create()(Login)
