import React, { PureComponent } from 'react'
import {Icon,Upload,message} from 'antd'
class UploadImg extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            imageUrl:"",
            loading: false, // 上传图片的loading图
        }
    }
    
    handleChange = (info, fileList, event)=>{
       
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
            }
            if (info.file.status === 'done') {
            // Get this url from response in real world.
                console.log(info,fileList, event)
                const baseUrl = info.file.response.datas.baseUrl
                const filePath = info.file.response.datas.filePath
                this.setState({
                    imageUrl:baseUrl + filePath
                })
            }
        
    }
  
    beforeUpload=(file)=>{
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('只能上传类型是JPG/PNG文件');
        }
        const isLt2M = file.size / 1024 / 1024 < 6;
        if (!isLt2M) {
          message.error('上传图片不能超过6MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    kong =()=>{
        this.setState({
            imageUrl:"",
            loading:false
        })
    }
    setImg=(e)=>{
        this.setState({
            imageUrl:e
        })
    }
    render() {
        const uploadButton = (
            <div className='ant-upload_int'>
              <Icon type={this.state.loading ? 'loading' : 'plus'} />
              <div className="ant-upload-text">{this.props.name}</div>
            </div>
          );
        return (
            <div className="leftImg">
                 <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    // 上传接口不能变如需改动 后台培训也得改
                    action="https://shop.chexd.com/apis/upload/uploadFile"
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    >
                    {this.state.imageUrl ? <img className="photos_img" src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                    
            </div>
        )
    }
}

export default UploadImg