import React, { Component } from 'react'
import { Layout, Icon } from 'antd'
import './Footer.scss'
const { Footer } = Layout

class CbFooter extends Component {
  render() {
    return (
      <Footer className="Cbfooter">
        <div className="official-container">
          <div className="official-title">
            <h1>联系我们</h1>
          </div>
          <div className="official-main">
            <div className="officialUrl">
              <img src={require('./images/officialAccounts.png')} alt="公众号" />
              <p>车企典之家公众号</p>
            </div>
            <div className="officialUrl officialUrlLocation">
              <Icon type="environment" />
              <span>&nbsp;&nbsp;</span>
              <div className='companyLocation'>
                <span>研发中心</span>
                <span className='locationGap'>北京市通州区新华西街60号院通州万达A座19层1920室</span>
                <span>市场营销部</span>
                <span className='locationGap'>北京市通州区新华西街60号院通州万达A座19层1921室</span>
                <span>北京市场售后部</span>
                <span className='locationGap'>北京市通州区新华西街60号院通州万达A座19层1922室</span>
                <span>沈阳市场售后部</span>
                <span className='locationGap'>沈阳市浑南区国际软件园F7-403室</span>
              </div>
            </div>
            <div>
				<div className="officialUrl officialUrlPhone">
					<Icon type="phone" />
					<span>&nbsp;&nbsp;</span>
					<div className='companyPhone'>
						<span>400-679-0900 (9:00-18:00)</span>
						<span>销售咨询按1 售后服务按2</span>
					</div>
				</div>
				<div className="officialUrl officialUrlPhone">
					<Icon type="phone" />
					<span>&nbsp;&nbsp;</span>
					<div className='companyPhone'>
						<span>400-679-0901 (9:00-18:00)</span>
						<span>报废车辆回收咨询</span>
					</div>
				</div>
				
            </div>
            
          </div>
        </div>
        <div className="footer-bottom-in">Copyright © 2018 北京车兄弟科技有限公司 - <a href="http://beian.miit.gov.cn">京ICP备18061963号-1</a></div>
      </Footer>
    )
  }
}

export default CbFooter
