import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty, Input, Select, ConfigProvider, DatePicker, Button, Tooltip, Row, Col, Pagination } from 'antd'
import './Buyersorder.scss'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import BackEnd from '../../../services/api'
import areaData from '../../../data.json'
import { formatDate } from '../../../utils/filter'
// const {  SearchOutlined  } = icons;
const { TabPane } = Tabs
const { confirm } = Modal
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <span>上一页</span>;
    } if (type === 'next') {
        return <span>下一页</span>;
    }
    return originalElement;
}
class Buyersorder extends Component {
    constructor() {
        super()
        this.state = {
            invoiceArr: '',
            loading: false, // 上传图片的loading图
            imageUrl: '', // 上传图片的url
            buyerOrderList: [], // 查询结果列表
            provinceValue: undefined,// 省级查询值
            cityValue: undefined, // 市级查询值
            regionValue: undefined, // 区级查询值
            cityDisabled: true, // 区域市级按钮
            regionDisabled: true, // 区域区级按钮
            orderStatus: ' ',
            CountPerPage: 1,// 每页数量
            page: 0,
            totalCount: 0, // 数据总条数
            currentPage: 1,
            pageSize: 10,
            current: 1,
            startValue: null,
            endValue: null,
            Id: 0,
            tooltip: false, //提示提交
            BeginTime: '',
            EndTime: '',
            OrderCode: 0,
            OrderAmount: 0,
            columns: [{
                title: '订单号',
                dataIndex: 'OrderCode',
                key: 'OrderCode',
                width: 140,

            }, {
                title: '下单时间',
                dataIndex: 'OrderTime',
                key: 'OrderTime',
                width: 130,
                className: 'resultColumns',
                render: (text, record) => (
                    <span>{moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</span>
                )
            }, {
                title: '卖家名称',
                dataIndex: 'AuthCodeName',
                key: 'AuthCodeName',
                width: 130,
                ellipsis: true,
            }, {
                title: '卖家地区',
                dataIndex: 'ProvinceCityCounty',
                key: 'ProvinceCityCounty',
                width: 100,
                ellipsis: true,
                className: 'resultColumns',

            }, {
                title: '订单金额',
                dataIndex: 'OrderAmount',
                key: 'OrderAmount',
                width: 80,
                className: 'resultColumns',
                render: (text, record) => (
                    <div>{Number(text).toFixed(2)}</div>
                )
            }, {
                title: '状态',
                dataIndex: 'OrderStatusName',
                key: 'OrderStatusName',
                width: 80,
                ellipsis: true,
                className: 'resultColumns',

            }, {
                title: '操作',
                dataIndex: 'Origin',
                key: 'Origin',
                id: 'Id',
                width: 120,
                ellipsis: true,
                className: 'resultColumns',
                render: (text, record) => (
                    <span>
                        <Link
                            className="link"
                            to={
                                {
                                    pathname: "/ToBeConfirmed",
                                    search: `?id=${record.Id}`
                                }
                            }
                        >详情 </Link>

                    </span>
                )
            }]
        }
    }

    componentWillMount() {
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        this.getShopCartInquire()
    }
    getShopCartInquire() {
        BackEnd.orderinquire('/api/ShoppingCart/QueryBuyer', {
            "userId": Number(window.localStorage.getItem('userId')),
            "authCodeName": this.refs.businessPhone.value,
            "orderCode": this.refs.businessName.value,
            "orderStatus": this.state.orderStatus,
            "province": this.state.provinceValue,
            "city": this.state.cityValue,
            "county": this.state.regionValue,
            "orderTimeStart": this.state.BeginTime,
            "orderTimeEnd": this.state.EndTime,
            "currentPage": this.state.currentPage,
            "pageSize": this.state.pageSize

        }).then(res => {
            console.log(res.data)
            this.setState({
                buyerOrderList: res.data.ResponseObj.Items,
                totalCount: res.data.ResponseObj.CustomTotalNum
            })

        })
    }
    tubeHandleTooltip = () => {
        this.setState({
            tooltip: false
        })
    }

    //打开
    tubeHandletooltip = () => {
        this.setState({
            tooltip: true
        })
    }
    userTypeChange(value) {
        console.log(`${value}`);
    }
    // 省级下拉选项
    selectProvince = (value) => {
        let newProvinceValue = Object.assign({}, this.state.searchFirm,
            { Province: value },
            { City: '' },
            { County: '' }
        )
        this.setState({
            searchFirm: newProvinceValue,
            provinceValue: value,
            cityValue: undefined,
            regionValue: undefined
        })
        if (value) {
            this.setState({
                cityDisabled: false,
                regionDisabled: true // 禁用区级选项
            })
        } else {
            // 清空按钮触发时
            let newProvinceValue = Object.assign({}, this.state.searchFirm,
                { Province: '' },
                { City: '' },
                { County: '' }
            )
            this.setState({
                searchFirm: newProvinceValue,
                cityDisabled: true,
                regionDisabled: true,
                provinceValue: value,
            })
        }
    }
    // 市级下拉选项
    selectCity = (value) => {
        let newCityValue = Object.assign({}, this.state.searchFirm,
            { City: value },
            { County: '' }
        )
        this.setState({
            searchFirm: newCityValue,
            cityValue: value,
            regionValue: undefined
        })
        if (value) {
            this.setState({
                regionDisabled: false
            })
        } else {
            // 清空按钮触发时
            let newCityValue = Object.assign({}, this.state.searchFirm,
                { City: '' },
                { County: '' }
            )
            this.setState({
                searchFirm: newCityValue,
                regionDisabled: true,
                regionValue: value
            })
        }
    }
    // 区级下拉选项
    selectRegion = (value) => {
        let newRegionValue = Object.assign({}, this.state.searchFirm,
            { County: value }
        )
        if (value) {
            this.setState({
                searchFirm: newRegionValue,
                regionValue: value,
            })
        } else {
            // 清空按钮触发时
            let newRegionValue = Object.assign({}, this.state.searchFirm,
                { County: '' }
            )
            this.setState({
                searchFirm: newRegionValue,
                regionValue: value,
            })
        }
    }

    // 分页改变
    onChange = page => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getShopCartInquire()
            console.log(page, this.state.currentPage);
        });
    };

    //获得时间
    onStartChange = (date, dateString) => {
        this.setState({
            BeginTime: formatDate(dateString, 'yyyymmdd') + '000000'
        })
        console.log(formatDate(dateString, 'yyyymmdd' + '000000'));
    }
    onEndChange = (date, dateString) => {
        console.log(formatDate(dateString, 'yyyymmdd') + '000000');
        this.setState({
            EndTime: formatDate(dateString, 'yyyymmdd') + '235959'
        })
    }
    render() {
        const { startValue, endValue, endOpen } = this.state;
        return (
            <div>
                <Header></Header>
                <Usercenter></Usercenter>
                <div className="Buyersorderbox">
                    <p className='title'>买家订单</p>
                    <div className="Buyersorder">
                        <div className="buyerOrderSearchContainer">
                            <Row className="row-p">
                                <Col className="col" span={6}>
                                    <span className="col-span">订单号:&nbsp;&nbsp;&nbsp;</span><Input className="col-input" type="text" ref='businessName' />
                                </Col>
                                <Col className="col" span={6}>
                                    <span className="col-span">卖家名称：</span><Input className="col-input" type="text" ref='businessPhone' />
                                </Col>
                                <Col className="col" span={6}>

                                    <span className="col-span">订单状态：</span>
                                    <Select defaultValue="全部" style={{ width: '70%' }} className="Select-input" onChange={e => {
                                        this.setState({
                                            orderStatus: e
                                        })
                                    }}>
                                        <Option value="">全部</Option>
                                        <Option value="0">待确认</Option>
                                        <Option value="1">待支付</Option>
                                        <Option value="2">待发货</Option>
                                        <Option value="3">已发货</Option>
                                        <Option value="4">已取消</Option>
                                        <Option value="5">已收货</Option>
                                    </Select>

                                </Col>
                            </Row>
                            <Row className="row-p">
                                <Col className="col-1" span={8}>
                                    <span className="col-span">卖家地区:&nbsp;&nbsp;</span>
                                    <Select
                                        // showSearch
                                        style={{ width: "20%", marginTop: 10, marginRight: 10, textAlign: 'center' }}
                                        placeholder="省"
                                        optionFilterProp="children"
                                        value={this.state.provinceValue}
                                        onChange={this.selectProvince}
                                        showArrow={false}
                                        allowClear={true}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            Object.keys(areaData.ProvinceCity).map(item => <Option value={item} title={item} key={item}>{item}</Option>)
                                        }
                                    </Select>
                                    <Select
                                        // showSearch
                                        style={{ width: "20%", marginTop: 10, marginRight: 10, textAlign: 'center' }}
                                        placeholder="市"
                                        optionFilterProp="children"
                                        value={this.state.cityValue}
                                        onChange={this.selectCity}
                                        allowClear={true}
                                        showArrow={false}
                                        allowClear={true}
                                        disabled={this.state.cityDisabled}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >{
                                            areaData.ProvinceCity[this.state.provinceValue] ?
                                                Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                                                    areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                                                        <Option value={item.Name} title={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))
                                                )) : []
                                        }
                                    </Select>
                                    <Select
                                        // showSearch
                                        style={{ width: "20%", marginTop: 10, textAlign: 'center' }}
                                        placeholder="区"
                                        optionFilterProp="children"
                                        value={this.state.regionValue}
                                        onChange={this.selectRegion}
                                        showArrow={false}
                                        allowClear={true}
                                        disabled={this.state.regionDisabled}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >{

                                            areaData.ProvinceCity[this.state.provinceValue] ?
                                                Object.keys(areaData.ProvinceCity[this.state.provinceValue]).map(key => (
                                                    areaData.ProvinceCity[this.state.provinceValue][key].map((item) => (
                                                        item.Name == this.state.cityValue ?
                                                            item.CityArea.map(items => (
                                                                <Option value={items.Name} title={items.Name} key={items.Name}>{items.Name}</Option>
                                                            )) : ''
                                                    ))
                                                )) : []
                                        }
                                    </Select>

                                </Col>
                                <Col className="col-1 col-2" span={10} style={{marginTop: 10}}>

                                    <span className="col-span">下单时间:&nbsp;&nbsp;</span>
                                    <ConfigProvider locale={zh_CN}>
                                        <DatePicker
                                            className="date"
                                            // defaultValue={moment(new Date(), dateFormat)}
                                            onChange={this.onStartChange}
                                        /></ConfigProvider>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <b className='separator'>至</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <ConfigProvider locale={zh_CN}  className="date">
                                        <DatePicker
                                             className="date"
                                            onChange={this.onEndChange}
                                        />
                                    </ConfigProvider>
                                </Col>
                                <div className="searchButton" style={{marginTop: 10,}}>
                                <Button type="primary" onClick={() => {

                                    this.getShopCartInquire()
                                }} >查询</Button>
                                {/* <Button>重置</Button> */}
                            </div>
                            </Row>
                           
                        </div>
                        {/* 买家订单管理 */}
                        <div className="buyerOrderList">
                            <span className="bu-p">数据列表</span>
                            <Table
                                width="100%"
                                rowKey={(record, index) => index}
                                columns={this.state.columns}
                                dataSource={this.state.buyerOrderList}
                                bordered={true}
                                pagination={false}
                                className="buyerOrderListTable"
                            />
                        </div>
                        <div className="totleP"> <Pagination
                            total={this.state.totalCount}
                            defaultCurrent={3}
                            onChange={this.onChange}
                            current={this.state.currentPage}
                        /> </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Buyersorder)
