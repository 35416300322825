import React, { Component } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Tabs, message, Modal } from 'antd'
import copy from 'copy-to-clipboard'
import BackEnd from '../../../../services/api'
import CompanyInfo from '../../../Square/PersonalStore/ProductDetail/component/CompanyInfo'
// import './CheapDetail.scss'
const { TabPane } = Tabs;
class CheapDetail extends Component {
  constructor(props) {
    super()
    const { search } = props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const prevAddr = searchParams.get('prevAddr') || ''
    this.state = {
      Authentication: '', // 认证
      storePic: [], // 门店图片
      goodsList: [], // 商品信息
      componentList: [], // 配件基本信息
      standardCar: [], // 标准车型
      swapGroup: [], // 互换组
      companyInfo: [], // 供应商信息
      ImageUrlList: [], // 配件图片
      companyBigPic: [], // 公司信息大图展示
      componentBigImg: '', // 默认展示大图
      activeIndex: 0,// 默认放大展示的图片索引
      defeated: false,
      Code: 0,
      imagesUrl: {},
      imagesArr: [],
      nowImage: '',
      onlyPrice: '',
      prevAddr: prevAddr // 上一个地址入口
      // nowimages:''
    }
  }
  componentWillMount() { }
  componentDidMount() {
    this.getProductDetail()
  }
  callback = (key) => {
    console.log(key);
  }
  // 获取详情页数据
  getProductDetail() {
    const { search } = this.props.location
    const paramsString = search.substring(1)
    const searchParams = new URLSearchParams(paramsString)
    const id = searchParams.get('id')
    BackEnd
      .companySquare('/api/ShoppingCart/GetComponentDetails', {
        componentId: id,
        userId: Number(window.localStorage.getItem('userId'))
      })
      .then(res => {
        console.log('商品详情', res.data)

        if (res.data.Message == "Success") {
          if (res.data.ResponseObj) {
            this.setState({
              imagesUrl: res.data.ResponseObj.component
            }, () => {
              this.arrs()
            })
            if (res.data.ResponseObj.component.ImageUrl.length) {
              this.setState({
                ImageUrlList: res.data.ResponseObj.component.ImageUrl,
                componentBigImg: res.data.ResponseObj.component.ImageUrl[0].ImageUrl
              })
            }
            if (res.data.ResponseObj.SmallStoreImgUrl) {
              console.log('执行了')
              this.setState({
                companyBigPic: res.data.ResponseObj.SmallStoreImgUrl[0]
              })
            }
            this.setState({
              Authentication: res.data.ResponseObj.Legalize, // 是否认证
              storePic: res.data.ResponseObj.SmallStoreImgUrl, // 门店图片
              goodsList: res.data.ResponseObj.component, // 商品信息 基本信息
              standardCar: res.data.ResponseObj.vehicleModes || [], // 标准车型
              swapGroup: res.data.ResponseObj.exchange, // 互换组
              companyInfo: res.data.ResponseObj.authorization, // 供应商
            }, () => {
              // if(this.state.Authentication) {

              // }
              if (this.state.goodsList.EnterpriseCategoryPrice == '面议') {
                this.setState({
                  onlyPrice: this.state.goodsList.EnterpriseCategoryPrice
                })
              } else {
                this.setState({

                  onlyPrice: Number(this.state.goodsList.EnterpriseCategoryPrice).toFixed(2)

                })
              }
              console.log('认证状态', this.state.goodsList)
            })
          }
        }
      })
  }
  // 放大的图片
  enlarge(ImageUrl, index) {
    this.setState({
      componentBigImg: ImageUrl,
      activeIndex: index
    })
  }
  // 复制配件编码
  copyText = (text) => {
    console.log('复制', text)
    copy(text);
    message.info('复制成功', 1)
  }
  // 减少购买数量
  removeQuantity = (index) => {
    if (this.state.goodsList.ShoppingQuantity <= 1) {
      return
    }
    this.state.goodsList.ShoppingQuantity--
    this.setState({
      goodsList: this.state.goodsList

    }, () => {

    })
  }
  // 增加数量
  addQuantity = (index) => {
    console.log(this.state.goodsList.ShowQuantity)
    let shoppingQuantity = this.state.goodsList.ShoppingQuantity;
    if (this.state.goodsList.ShowQuantity == ">10") {
      if (shoppingQuantity + 1 > 10) {
        return message.success('单次下单不能超过10个', 1)
      }

    } else {

      if (shoppingQuantity + 1 > this.state.goodsList.ShowQuantity) {
        return message.success('库存不足', 1)
      }
    }

    console.log('shoppingQuantity', shoppingQuantity)
    this.state.goodsList.ShoppingQuantity++;

    this.setState({
      goodsList: this.state.goodsList
    }, () => {
      console.log('更改', this.state.goodsList)
    })
  }
  // 加入购物车
  addShopCart = () => {
    let singleShopcart = {
      userId: Number(window.localStorage.getItem('userId')),
      shoppingCartItem: [
        {
          authCode: this.state.goodsList.AuthCode,
          id: this.state.goodsList.ShareId,
          componentCode: this.state.goodsList.Code,
          componentName: this.state.goodsList.Name,
          componentId: this.state.goodsList.Id,
          brand: this.state.goodsList.Brand,
          vehicleMode: this.state.goodsList.VehicleMode,
          origin: this.state.goodsList.Origin,
          companyName: this.state.companyInfo.Name,
          region: this.state.goodsList.Region,
          unitPrice: Number(this.state.goodsList.EnterpriseCategoryPrice).toFixed(2),
          unit: this.state.goodsList.Unit,
          quantity: this.state.goodsList.ShoppingQuantity,
          showQuantity: this.state.goodsList.ShowQuantity
        }
      ]
    }
    BackEnd
      .companySquare('/api/ShoppingCart/InSetShoppingCartCache', singleShopcart).then(res => {
        console.log("加入购物车", res.data)
        // this.setState({
        //   Code:res.data.Code
        // })
        if (res.data.Message == "Success") {

          message.success('已加入购物车', 1)
        } else if (res.data.Code == 1) {
          message.info('不可超出库存数量且不能为0')
        }
      })
  }

  defeated = () => {

    this.setState({
      defeated: false
    })
  }
  Defeated = () => {

    this.setState({
      defeated: true
    })
  }
  arrs = () => {

    this.setState({
      imagesArr: this.state.imagesUrl.PicturePath == null ? '' : this.state.imagesUrl.PicturePath.split('|'),
      nowImage: this.state.imagesUrl.PicturePath == null ? '' : this.state.imagesUrl.PicturePath.split('|')[0]
    })


  }

  goLogin = () => {
    this.props.history.push('/login')
  }
  render() {
    return (
      <div className="productDetailPage">
        <Header text={this.state.companyInfo} prevAddr={this.state.prevAddr} />
        <div className="productInfoContainer">
          {/* <div className="catalogNav"><span className="catalogNav-span" onClick={()=>{
            this.props.history.push(`/productList?id=${this.state.companyInfo.AuthCode}`)
          }}>产品列表</span><span>></span><span className="none" onClick={()=>{
            console.log(this.arrs())
          }}>配件详情</span></div> */}
          <div className="productContent">
            <div className="productInfoContent">
              {/* 商品图片 */}
              <div className="productPic">
                <div className="bigPic">
                  {
                    this.state.imagesUrl.PicturePath ? <img src={this.state.nowImage} alt="配件图片" /> : <img src={require('../../imgage/prouctImg.png')} alt="默认配件图片" />
                  }
                </div>
                <div>
                  <ul>
                    {
                      this.state.imagesArr ?
                        this.state.imagesArr.map((item, index) => <li className="image-li" key={index}>

                          <img className="image-arr" onClick={() => {
                            this.setState({
                              nowImage: item,

                            })

                          }} src={item} alt="" />
                        </li>)
                        :
                        ''
                    }
                  </ul>

                </div>
                {/* <div className="picList">
                  {this.state.ImageUrlList.length ? this.state.ImageUrlList.map((item, index) => (
                    <span className={`${this.state.activeIndex == index ? 'active' : ''}`} onClick={this.enlarge.bind(this, item.ImageUrl, index)} key={index}><img src={item.ImageUrl} alt="缩略图" /></span>
                  )) : ''}
                </div> */}
              </div>
              {/* 商品信息 */}
              <div className="productInfo">
                <p><span style={{ fontSize: "18px", fontWeigh: "800" }}>{this.state.goodsList.Code}</span><span className="copyText" onClick={this.copyText.bind(this, this.state.goodsList.Code)}>复制</span></p>
                <p style={{ fontSize: "20px", fontWeigh: "800" }}>{this.state.goodsList.Name}</p>
                <ul>
                  <li><span>品牌</span><span>{this.state.goodsList.Brand}</span></li>
                  <li><span>产地</span><span>{this.state.goodsList.Origin}</span></li>
                  <li><span>车型</span><span>{this.state.goodsList.VehicleMode}</span></li>
                  <li><span>库存</span><span>{this.state.goodsList.ShowQuantity}</span></li>
                  <li><span>单位</span><span>{this.state.goodsList.Unit}</span></li>
                  {/* {
                    this.state.goodsList.PromotionStatus == 1 ?   <li><span>起购数量</span><span>{this.state.goodsList.PromotionalQuantityOfPurchase}</span></li> : ''
                  }
                  {
                    this.state.goodsList.PromotionStatus == 1 ?   <li> <span>备货周期(天)</span><span>{this.state.goodsList.PromotionalStockingCycle}</span></li> : ''
                  }
                   */}

                  <li><span>单价</span><span>{
                    window.localStorage.getItem('Tokens') == null ? <span className="addshopCart1" onClick={() => { this.goLogin() }}>登陆查看单价</span> : '￥' + this.state.onlyPrice
                  }</span></li>
                  <li><span>介绍</span><span>{this.state.goodsList.PromotionStatus == 1 ? this.state.goodsList.PromotionalDescribe : this.state.goodsList.Memo}</span></li>
                  {
                    this.state.Authentication == false
                      ?
                      <li>
                        <a href={this.state.companyInfo.DomainName + "&prevType=1"} className="addShopCart" style={{ marginLeft: "20px" }}>进入店铺</a>
                        {/* <span className="addShopCart" onClick={() => {
                          this.props.history.push({ pathname: '/personalStore', search: `?id=${this.state.goodsList.AuthCode}` })
                        }}>进入店铺</span> */}
                      </li>
                      :
                      (
                        window.localStorage.getItem('Tokens') == null ? '' :
                          <li>
                            <div className="chooseNumContainer">
                              <span onClick={this.removeQuantity}>-</span>
                              <input disabled={this.state.goodsList.ShowQuantity} type="text" value={this.state.goodsList.ShoppingQuantity | Number()} ref="quantity" readOnly />
                              <span onClick={this.addQuantity}>+</span></div>
                            <span className="addShopCart" onClick={() => {
                              if (Number(this.state.goodsList.EnterpriseCategoryPrice).toFixed(2) == NaN) {
                                message.success('加入购物车失败', 1)
                              } else {
                                this.addShopCart()
                              }
                              // this.Defeated()
                            }}>加入购物车</span>
                            {/* http://bjjchyqpxszx.chexd.com/#/personalStore?id=2003&prevType=1 */}
                            <a href={this.state.companyInfo.DomainName + "&prevType=1"} className="addShopCart" style={{ marginLeft: "20px" }}>进入店铺</a>

                            {/* <span className="addShopCart" style={{ marginLeft: '20px' }} onClick={() => {
                              this.props.history.push({ pathname: '/personalStore', search: `?id=${this.state.goodsList.AuthCode}` })
                            }}>进入店铺</span> */}

                          </li>
                      )
                  }

                </ul>
              </div>
            </div>
            <div className="accuracyInfoContent">
              <Tabs onChange={this.callback} type="card">
                <TabPane tab="基本信息" key="1" >
                  <table className="basicContainer">
                    <tbody>
                      <tr>
                        <td>配件名称</td>
                        <td>{this.state.goodsList.Name}</td>
                        <td>单位</td>
                        <td>{this.state.goodsList.Unit}</td>
                      </tr>
                      <tr>
                        <td>配件编码</td>
                        <td>{this.state.goodsList.Code}</td>
                        <td>规格</td>
                        <td>{this.state.goodsList.Specification}</td>
                      </tr>
                      <tr>
                        <td>标准配件分类</td>
                        <td>{this.state.goodsList.Category}</td>
                        <td>单车用量</td>
                        <td>{this.state.goodsList.Quantity}</td>
                      </tr>
                      <tr>
                        <td>配件品牌</td>
                        <td>{this.state.goodsList.Brand}</td>
                        <td>车身位置</td>
                        <td>{this.state.goodsList.Position}</td>
                      </tr>
                      <tr>
                        <td>产地</td>
                        <td>{this.state.goodsList.Origin}</td>
                        <td>适用品牌</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>备注</td>
                        <td>{this.state.goodsList.Memo}</td>
                        <td>部位码</td>
                        <td>{this.state.goodsList.PartCode}</td>
                      </tr>
                    </tbody>
                  </table>
                </TabPane>
                <TabPane tab="标准车型" key="2">
                  <table className="standardCarContainer">
                    <thead>
                      <tr>
                        <th>序号</th>
                        <th>品牌</th>
                        <th>厂牌</th>
                        <th>车型</th>
                        <th>型号</th>
                        <th>年款</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.standardCar.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.FullName}</td>
                          <td>{item.MakeFactory}</td>
                          <td>{item.Mode}</td>
                          <td>{item.Model}</td>
                          <td>{item.MakeYear}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TabPane>
                <TabPane tab="互换组" key="3">
                  <table className="swapGroupContainer">
                    <thead>
                      <tr>
                        <th>序号</th>
                        <th>配件编码</th>
                        <th>品牌</th>
                        <th>备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.swapGroup.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ComponentCode}</td>
                          <td>{item.Brand}</td>
                          <td>{item.Memo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TabPane>
                <TabPane tab="供应商信息" key="4">
                  <CompanyInfo companyInfo={this.state.companyInfo}
                    Authentication={this.state.Authentication + ''}
                    storePic={this.state.storePic}
                    companyBigPic={this.state.companyBigPic}>

                  </CompanyInfo>
                </TabPane>
              </Tabs>
            </div>
          </div >
        </div >
        <Footer />
        <Modal
          title="提示"
          visible={this.state.defeated}
          onCancel={this.defeated}
          footer={null}
          maskClosable={false}
          wrapClassName='isLoginModel'
        >
          <p className="goLogin-p">下单失败</p>

        </Modal>
      </div >
    )
  }
}
export default CheapDetail