import React, { Component } from 'react'
import {
    Form,
    Input,
    Tooltip,
    Icon,
    Cascader,
    Upload,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    message,
    AutoComplete,
} from 'antd';
import './ForgetPassword.scss'
import {
    encryptByDES,
    encryptPassword,
    encryptEDSPwd
} from "../../utils/encrypt";
import BackEnd from '../../services/api';
class ForgetPassword extends Component {
    constructor() {
        super()
        this.state = {
            isClick: false, // 验证码按钮禁用
            codeContent: '获取验证码', // 验证码按钮文案
            forgetPhoneChange: '', //找回手机号
            forgetPasswordChange: '', // 找回手机密码 
            forgetCaptchaChange: '' // 监听验证码
        }
    }
    // 返回
    goBack = () => {
        window.history.back();
    }
    // 监听找回手机号
    forgetPhoneChange = (e) => {
        this.setState({
            forgetPhoneChange: e.target.value
        })
    }
    // 监听找回登录密码
    forgetPasswordChange = (e) => {
        this.setState({
            forgetPasswordChange: e.target.value
        })
    }
    // 监听验证码
    forgetCaptchaChange = (e) => {
        this.setState({
            forgetCaptchaChange: e.target.value
        })
    }
    // 获取验证码
    getCode = () => {
        // 重新发送动画
        if (this.state.forgetPhoneChange === "") {
            message.error('手机号不能为空')
            return;
        }
        let time = 60;
        this.state.isClick = !this.state.isClick;
        var timer = setInterval(() => {
            time--;
            this.setState({
                codeContent: `重新发送(${time})`
            })
            if (time <= 0) {
                clearInterval(timer);
                time = 5;
                this.setState({
                    codeContent: `获取验证码`,
                    isClick: !this.state.isClick
                })
            }
        }, 1000);
        let encrypt = {
            Code: "00021003",
            Mobile: this.state.forgetPhoneChange,
            TimeSptams: String(Date.parse(new Date())),
            SendMsgType: "4",
            Tokens: ""
        };
        let msg = `${encrypt.Mobile}${encrypt.SendMsgType}${encrypt.TimeSptams}`;

        encrypt.Tokens = encryptByDES(msg, 'jinmusen7931BEIJING');

        BackEnd.getData(encrypt)
            .then(res => {
                if (res.data.Message.ErrorMessage) {
                    message.error(res.data.Message.ErrorMessage.ErrMsg)
                    return;
                }
                window.sessionStorage.setItem('forgetTimeSptams', encrypt.TimeSptams)
                message.success('短信发送成功!');
            })
            .catch(err => {
                message.error('短信发送失败!');
            });
    };
    // 格式数据
    initData() {
        let initData = {
            Code: 21005,
            Mobile: this.state.forgetPhoneChange,
            MsgCodes: this.state.forgetCaptchaChange,
            TimeSptams: window.sessionStorage.getItem('forgetTimeSptams'),
            SendMsgType: '4'
        }

        let key = `${initData.Mobile}${initData.MsgCodes}${initData.TimeSptams}`;

        initData = Object.assign(
            {},
            initData,
            {
                Tokens: encryptByDES(key, 'jinmusen7931BEIJING')
            }
        )
        return initData
    }
    handleforgetSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                BackEnd.getData(this.initData()).then(res => {
                    if (res.data.Message.ErrorMessage) {
                        message.error('验证码不正确!')
                        return;
                    }
                    BackEnd.getData({
                        Code: "21045",
                        Mobile: this.state.forgetPhoneChange,
                        LoginPwd: this.state.forgetPasswordChange
                    }).then(res => {
                        if (res.data.ErrorMessage) {
                            message.error('密码找回失败!')
                            return;
                        }
                        message.success('密码找回成功!');
                        this.props.history.replace('/login')
                    })
                })
            }
        });
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };
        return (
            <div className='forgetPasswordPage'>
                <div className='forgetContainer'>
                    <p className='forgetContainerTitle'>找回密码<img src="images/close.png" alt="关闭按钮" onClick={this.goBack} /></p>
                    <div className='forgetContent'>
                        <Form {...formItemLayout} onSubmit={this.handleforgetSubmit}>
                            <Form.Item label="手机号">
                                {getFieldDecorator('forgetPhone', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入手机号',
                                        }, {
                                            pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/,
                                            message: '手机号输入不正确'
                                        }
                                    ],
                                })(<Input autoComplete='new-password' maxLength={11} onChange={this.forgetPhoneChange} placeholder="请输入手机号" />)}
                            </Form.Item>
                            <Form.Item label="验证码">
                                <Row gutter={8}>
                                    <Col span={12}>
                                        {getFieldDecorator('forgetCaptcha', {
                                            rules: [{ required: true, message: '请输入验证码' }],
                                        })(<Input maxLength={6} onChange={this.forgetCaptchaChange} placeholder='请输入验证码' />)}
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={this.getCode} disabled={this.state.isClick}>{this.state.codeContent}</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label="登录密码">
                                {getFieldDecorator('forgetPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入密码',
                                        },
                                    ],
                                })(<Input.Password maxLength={20} onChange={this.forgetPasswordChange} placeholder="请输入密码" autoComplete='new-password' />)}
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout} className='finishArea'>
                                <Button type="primary" htmlType="submit" className='finishButton'>完成</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
export default Form.create({})(ForgetPassword)
