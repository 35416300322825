import React, { Component } from 'react'
import './Footer.scss'

class StoreFooter extends Component {
  constructor() {
    super()
    this.state = {
      
    }
  }
  componentDidMount (){
    
  }
  render() {
    return (
      <div className="storeFooters">
          <img src={require('../../imgage/footerImg.png')} alt="" className="storeFooterImg"/>
      </div>
    )
  }
}

export default StoreFooter
