import React, { PureComponent } from 'react'
import Banner from '../../components/Banner'
import Download from '../../components/Download'
import Pack from '../../components/Pack'
import './Home.scss'
class Home extends PureComponent {
  constructor() {
    super()
    this.state = {}
  }
  render() {
    return (
      <div className='container-line'>
        <div className="cb-home">
          <Banner />
          <Download></Download>
          <Pack></Pack>
          <p className='backEnd'>
            <a href="https://admin.chexd.com/backend" target='_blank'>管理平台</a>
          </p>
        </div>
      </div>
    )
  }
}
export default Home
