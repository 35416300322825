import React, { PureComponent } from 'react'
import Header from '../../components/Header'
import './Disassembly.scss'

import { Button, Row , Col, Modal,Form, Input, message} from 'antd';
import axios from 'axios'
import BackEnd from '../../services/api'
class index extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            visible: false, // 未登录提示框
            username:'',
            phone:'',
            NickName:""
        }
    }
    loginVisible =()=>{
        let data = {
            company:this.state.NickName,
            contacts:this.state.username,
            phone:this.state.phone,
          }
          BackEnd.onTrial(data) .then(res => {
              console.log(res)
              message.success(res.data.msg);
              if(res.data.code == 0){
                   this.setState({
                    visible: false
                },()=>{
                    message.success(res.data.msg,3);
                })

              }
          })
    }
    handleOk=()=>{
        this.setState({
            visible: false,
            username:'',
            phone:'',
            NickName:""
          },()=>{
            console.log('取消')

        })
    }
    name=(e)=>{
        this.setState({
            username: e.target.value
          })
    }
    Phone=(e)=>{
        this.setState({
            phone: e.target.value
          })
    }
    nickName=(e)=>{
        this.setState({
            NickName: e.target.value
          })
    }

    render() {
        return (
            <div>
                <Header></Header>
               
                <div  className="warpper-chai">
                    <div className="warper_Disassembly">
                        <div className="Disassembly_con">
                            <p className="title_Disassembly">车兄弟机动车拆解管理系统简介：</p>
                            <div className="Disassembly_con_top">
                                <div className="Disassembly_con_top_cld">
                                    <div className="Disassembly_con_img">
                                        <img className="img_" src={require('./images/qiche.png')} alt="" />
                                    </div>
                                    <p className="Disassembly_con_p">
                                        <p>
                                            北京车兄弟科技有限公司（以下简称车兄弟）成立于2018年，是一家专业面向汽车后市场提供信息技术服务、咨询培训服务、系统集成服务、工业4.0智能工业设备研发服务的国家高新企业。
                                        </p>
                                        <p>
                                            车兄弟的主要服务内容有：
                                        </p>
                                        <p>
                                            1、机动车报废企业及汽车零部件再制造企业专业咨询及系统集成服务
                                        </p>
                                        <p className='tx4'>
                                            包括：立项申报咨询、项目建设咨询（图设、土建、监理）、生产工艺设计、设备销售、经营辅导（配件+废料资源、人员培训、固废危废转移对接）、中汽研资质认定辅导、SaaS管理软件系统、工业4.0升级等。
                                        </p>
                                        <p>
                                            2、汽车后市场循环经济软件系统信息技术服务
                                        </p>
                                        <p className='tx4'>
                                            运用自主研发的【机动车拆解厂SaaS系统】、【汽配供应链经销SaaS系统】、【车兄弟回收】等多项软件产品形成信息技术服务生态核心，并通过APP、公众号、小程序、人工智能、行业大数据等服务加以升华，形成以新旧汽车配件为中心的汽车后市场循环经济信息技术服务体系。
                                        </p>
                                    </p>
                                </div>
                                <div className='Disassembly_con_btn'>
                                    <p>
                                    3、行业资源对接服务
                                    </p>
                                    <p className='tx4'>
                                        运营自主研发的信息技术服务体系，为汽车后市场循环经济主体（包括：汽车配件经销商、汽车维修企业、机动车拆解企业、汽车零部件再制造企业）提供企业经营上下游行业资源对接导流，帮助相关企业提升业绩和收入。
                                    </p>
                                    <p className='tx4'>
                                        车兄弟旗下拥有车企典汽配ERP、车兄弟回用件ERP、车企典汽修ERP、车企典移动端、件儿APP等多款软件产品服务于全国汽配商及汽修厂，并具备原厂EPC大数据、车牌+VIN码解析大数据、汽车配件贸易大数据、汽车配件库存大数据等立体化大数据服务能力，主要服务于各大汽配贸易商、4S店、汽修厂等汽车后市场企业，满足汽车后市场参与者对平台服务的不同需求。
                                    </p>
                                    <p className='tx4'>
                                        车兄弟于2020年年初进军报废机动车拆解行业，经过对全国80多家拆解厂的实地走访调研，采集需求，研发了机动车拆解厂管理系统，并在首特钢报废机动车综合利用有限公司、北京市大石河报废汽车解体厂、北京华宏再生资源利用有限公司、北京博瑞联通汽车循环利用科技有限公司、北京天交报废汽车回收处理有限责任公司等5家拆解厂投入使用，截止2023年8月公司已在北京发展线下合作收车网点超过480家，服务区域基本覆盖北京市主要区域，对报废机动车采购、商务部机动车报废牌证数据录入、机动车拆解管理及拆解物销售分销管理均发挥了很大作用，工作效率得到了大幅提升，拆解厂上下游业务也得到显著发展。
                                    </p>
                                    <p className='tx4'>
                                        车兄弟拆解厂SaaS系统目前已占有北京市机动车报废拆解厂应用软件服务市场约72%的市场份额，并与天津、河北、江苏、浙江、河南、山东、辽宁、吉林等省份诸多拆解厂签署合作协议、部署系统、开展业务合作，预计到2023年底，将拓展100家以上拆解厂进行软件业务合作，并逐步布局自有拆解厂和代运营拆解厂，同时发展线下合作门店，形成报废车辆“前店后厂”的完善回收体系，依托强大的SaaS系统和大数据体系深度参与汽车报废拆解实体业务。
                                    </p>
                                    <p className='tx4'>
                                        为了配合拆解厂的业务开展，车兄弟还组织大型回用件经销商成立了车兄弟回用件联盟，进行“全国拍车、就近拆解、定价公平、拆后包销”的上下游配套服务，让拆解厂的上下游业务得到充分的发展。
                                    </p>
                                    <p className='tx4'>
                                        为规范行业管理，促进从业人员对行业法律法规、环保要求、规范化操作内容的理解掌握，车兄弟承办了由商务部国际贸易经济合作研究院主办的报废机动车回收拆解行业培训服务，培训考试合规后，由商务部研究院颁发培训证书。
                                    </p>
                                    <p className='tx4'>
                                        2022年3月29日北京车兄弟科技有限公司与生态环境部固体废物与化学品管理技术中心就共同开发创建报废拆解领域产业大数据平台达成了长期战略合作关系，双方将充分发挥各自优势在报废机动车、废料回收利用等相关领域开展合作。 
                                    </p>
                                    {/* <p className='tx4'>为了配合拆解厂的业务开展，车兄弟还组织大型回用件经销商成立了车兄弟回用件联盟，进行“全国拍车、就近拆解、定价公平、拆后包销”的上下游配套服务，让拆解厂的上下游业务得到充分的发展。</p>
                                    <p className='tx4'>为规范行业管理，促进从业人员对行业法律法规、环保要求、规范化操作内容的理解掌握，车兄弟承办了由商务部国际贸易经济合作研究院主办的报废机动车回收拆解行业培训服务，培训考试合规后，由商务部研究院颁发培训证书。</p>
                                    <p className='tx4'>此外车兄弟还向拆解厂提供拆解厂政务公关、拆解厂经营咨询、拆解厂管理外包等企业咨询类服务，帮助拆解厂解决经营管理中的难题。</p>
                                    <p className='tx4'>2022年3月29日北京车兄弟科技有限公司与生态环境部固体废物与化学品管理技术中心就共同开发创建报废拆解领域产业大数据平台达成了长期战略合作关系，双方将充分发挥各自优势在报废机动车、报废船舶、报废飞行器等相关领域开展合作。</p>
                                    <p className='tx4'>未来，车兄弟将以汽车后市场循环经济为基础，逐步开展船舶报废、飞行器报废等相关循环经济业务，立志奉献社会，为实现碳达峰碳中和目标贡献力量！</p> */}
                                    
                                    <Button type="primary" className="Disassembly_con_btn"  size={'large'} 
                                        onClick={() => {
                                        this.setState({
                                            visible: true,
                                            username:'',
                                            phone:'',
                                            NickName:""
                                        })
                                    }} >马上申请试用</Button>
                                </div>
                            </div> 
                        </div>
                    <div>
                            <p className="title_Disassembly">
                                七大功能模块：
                            </p>
                            <div className="upstream">
                                <Row className="upstream_row" gutter={20} >
                                    <Col span={6}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u8.png')} alt="" />
                                                <p className="upstream_img_p">采购管理</p>
                                            </div>
                                            <p className="info_p">支持线上收车报价、预采购管理 车辆采购实时估价</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u11.png')} alt="" />
                                                <p className="upstream_img_p">运输调度</p>
                                            </div>
                                        <p className="info_p">支持车辆管理、司机管理，快捷导航，运输在途图像管理，车辆预检管理</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u9.png')} alt="" />
                                                <p className="upstream_img_p">资料登记</p>
                                            </div>
                                            <p className="info_p">支持Excel导入，商务部报废资料一键录入，支持打印报废回收证明</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u10.png')} alt="" />
                                                <p className="upstream_img_p">拆解管理</p>
                                            </div>
                                            <p className="info_p">
                                            支持预处理、精拆、粗拆任务分配，支持移动端拆解进度及拆解物录入
                                            </p>
                                        </div> 
                                    </Col>
                                </Row>
                                <Row className="upstream_row mt-20" gutter={20} >
                                    <Col span={8}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u12.png')} alt="" />
                                                <p className="upstream_img_p">仓储管理</p>
                                            </div>
                                            <p className="info_p">支持分仓库仓位货架管理、支持回用件转大宗</p>
                                        </div> 
                                    </Col>
                                    <Col span={8}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u13.png')} alt="" />
                                                <p className="upstream_img_p">销售管理</p>
                                            </div>
                                        <p className="info_p">支持传统销售、支持在线平台分销、支持预定及销售</p>
                                        </div> 
                                    </Col>
                                    <Col span={8}>
                                    <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u14.png')} alt="" />
                                                <p className="upstream_img_p">财务管理</p>
                                            </div>
                                            <p className="info_p">支持金蝶、用友等财务软件对接</p>
                                        </div> 
                                    </Col>
                                   
                                </Row>
                            </div>
                        </div>
                        <div>
                            <p className="title_Disassembly">
                                上游收车业务赋能：
                            </p>
                            <div className="upstream">
                                <Row className="upstream_row" gutter={20} >
                                    <Col span={6}>
                                       <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u31.png')} alt="" />
                                                <p className="upstream_img_p">线上便捷收车</p>
                                            </div>
                                            <p className="info_p">支持使用官网，app，小程序等进行收车业务，支持对接第三方收车平台</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                       <div className="upstream_li">
                                             <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u29.png')} alt="" />
                                                <p className="upstream_img_p">精准推广收车</p>
                                            </div>
                                           <p className="info_p">支持通过用户画像进行收车广告精准投放、短信点对点推送等</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                       <div className="upstream_li">
                                              <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u30.png')} alt="" />
                                                <p className="upstream_img_p">拍卖就近收车</p>
                                            </div>
                                            <p className="info_p">我司旗下回用件经销商通过拍卖购得的车辆会为系统内拆解厂就近送车</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                       <div className="upstream_li">
                                              <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u33.png')} alt="" />
                                                <p className="upstream_img_p">收车极速估价</p>
                                            </div>
                                            <p className="info_p">
                                                支持通过车架号对车辆进行估价，方便拆解厂以合理的价位进行收车
                                            </p>
                                        </div> 
                                    </Col>
                                </Row>
                               
                            </div>
                        </div>
                        <div>
                            <p className="title_Disassembly">
                                下游回用件/拆解物分销：
                            </p>
                            <div className="upstream">
                            <Row className="upstream_row" gutter={20} >
                                    <Col span={6}>
                                       <div className="upstream_li">
                                            <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u44.png')} alt="" />
                                                <p className="upstream_img_p">第三方分销平台接入</p>
                                            </div>
                                            <p className="info_p">支持对接各大第三方拆解物分销平台，实时同步库存及订单</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                       <div className="upstream_li">
                                             <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u47.png')} alt="" />
                                                <p className="upstream_img_p">支持预售</p>
                                            </div>
                                           <p className="info_p">支持回用件及拆解物预定、预售</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                       <div className="upstream_li">
                                              <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u46.png')} alt="" />
                                                <p className="upstream_img_p">支持线上竞价</p>
                                            </div>
                                            <p className="info_p">支持回用件及拆解物线上竞价，利润最大化</p>
                                        </div> 
                                    </Col>
                                    <Col span={6}>
                                       <div className="upstream_li">
                                              <div className="upstream_img">
                                                <img className="upstream_img_" src={require('./images/u45.png')} alt="" />
                                                <p className="upstream_img_p">订单实时同步</p>
                                            </div>
                                            <p className="info_p">
                                            线上订单可实时进行双向同步，买卖双方第一时间获知订单状态
                                            </p>
                                        </div> 
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal 
                    title="申请试用" 
                    visible={this.state.visible} 
                    onOk={this.loginVisible}
                    onCancel={this.handleOk} 
                    okText="确认"
                    cancelText="取消"
                >
                    <p>姓名：
                        <Input  placeholder="姓名" ref="names" value={this.state.username}  onChange={this.name}></Input>
                    </p>
                    <p>电话：
                        <Input  placeholder="电话"  ref="Phones" value={this.state.phone}  onChange={this.Phone}></Input>
                    </p>
                    <p>公司名称：
                        <Input  placeholder="公司名称"  ref="nicknames" value={this.state.NickName}  onChange={this.nickName}></Input>
                    </p>
                </Modal>
               
            </div>
        )
    }
}

export default index