import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import { Link, withRouter } from 'react-router-dom'
import { Tabs, Table, Modal, message, Checkbox, Empty } from 'antd'
import Download from '../../../components/Download'

import './Shopadministration.scss'
import BackEnd from '../../../services/api'
const { TabPane } = Tabs
const { confirm } = Modal




class Shopadministration extends Component {
  constructor() {
    super()
    this.state = {
      activeIndex: '0',
      resultList: [{
        key: 1,
        PackName: 'Visual C++ 2013 Steup.exe',
        Renewal: '2019-12-10',
        Description: 'VC2013软件关闭报错下载',
        Size: '6.2M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/VC2013软件关闭报错下载.exe'
      }, {
        key: 2,
        PackName: 'AccessDatabaseEngine.exe',
        Renewal: '2019-12-10',
        Description: '导入报错下载',
        Size: '25.4M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/导入报错下载.exe'
      }, {
        key: 3,
        PackName: 'bangwoba.exe',
        Renewal: '2019-12-10',
        Description: 'win10远程工具服务人员下载',
        Size: '56.6M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/win10远程工具服务人员下载.exe'
      }, {
        key: 4,
        PackName: 'Visual C++ 2017 Steup.exe',
        Renewal: '2019-12-10',
        Description: 'VC2017智能报价报错下载',
        Size: '13.9M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/VC2017智能报价报错下载.exe'
      }, {
        key: 5,
        PackName: 'WPS2019.exe',
        Renewal: '2019-12-10',
        Description: 'office办公软件下载',
        Size: '138M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/office办公软件下载.exe'
      },
      {
        key: 6,
        PackName: 'dotNetFx40_Full_x86_x64.exe',
        Renewal: '2019-12-10',
        Description: '.Net 4.0 完整版(32位&64位)',
        Size: '48.1 M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/dotNetFx40_Full_x86_x64.exe'
      },
      {
        key: 7,
        PackName: '软件字体.rar',
        Renewal: '2019-12-10',
        Description: 'XP字体报错下载',
        Size: '24.9M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/XP字体报错下载.rar'
      }, {
        key: 8,
        PackName: 'bangwoba.exe',
        Renewal: '2019-12-10',
        Description: 'win10远程工具客户下载',
        Size: '45.1M',
        Operation: '下载',
        OperationUrl: 'http://down.chexd.com/工具包/win10远程工具客户下载.exe'
      }],
      columns: [{
        title: '补丁包名称',
        dataIndex: 'PackName',
        key: 'PackName',
        align: 'center',
        width: 105,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '更新时间',
        dataIndex: 'Renewal',
        key: 'Renewal',
        align: 'center',
        width: 10,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '说明',
        dataIndex: 'Description',
        key: 'Description',
        width: 105,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '大小',
        dataIndex: 'Size',
        key: 'Size',
        align: 'center',
        width: 50,
        render: (text, record) => (
          <span>{text}</span>
        )
      }, {
        title: '操作',
        dataIndex: 'Operation',
        key: 'Operation',
        align: 'center',
        width: 105,
        render: (text, record) => (
          <a href={record.OperationUrl}>{text}</a>
        )
      }]
    }
  }
  clickRow(num) {
    this.setState({
      activeIndex: (num.key - 1)//获取点击行的索引
    })
  }
  setClassName = (record, index) => {//record代表表格行的内容，index代表行索引
    //判断索引相等时添加行的高亮样式
    return index === this.state.activeIndex ? 'l-table-row-active' : '';
  }

  render() {
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>

        <div className="Qualityassurancecommitmentbox">
          <p className='title'>商机管理</p>
          <div className="Qualityassurancecommitment">
            请下载车企典ERP进行店铺管理
            <Download></Download>
            <h1 style={{
              'font-size': '18px',
              'text-align': 'center',
              'margin-top': '10px'
            }}>补丁包下载</h1>

            <Table bordered pagination={{ position: ["bottomLeft"] }, { pageSize: 5 }} dataSource={this.state.resultList} columns={this.state.columns} onRow={(record) => {//表格行点击事件
              return {
                onMouseEnter: this.clickRow.bind(this, record)
              };
            }} />;
          </div>

        </div>

      </div>
    )
  }
}
export default withRouter(Shopadministration)
