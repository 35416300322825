import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import BackEnd from '../../../services/api'
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import moment from 'moment'; 

import { Pagination, message, Select ,Modal,Spin} from 'antd'
import './information.scss'


function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <span>上一页</span>;
    } if (type === 'next') {
      return <span>下一页</span>;
    }
    return originalElement;
  }
  class information extends Component {
    constructor(){
        super()
        this.state = {
            searchList:[],
            arr:[],
            CountPerPage: 0, // 每页数量
            TotalCount: 0, // 查询结果总数量
            Page: 1, 
            informationList:{
                currentPage: 1,
                pageSize: 10,
                Title:'',
                Brand:''
            },
            balance:'0.00',
            ModalText: 'Content of the modal',
            visible: false,
            confirmLoading: false,  
            price:'0.00',
            title:"",
            time:"",
            detailData:[],
            loading:true,
            loadings:true,
            carSeries: [],// 车系
            reminder: '请输入企业名称、品牌、简介', // 搜索框提示文字
            currentIndex: -1, // 一级标题
            currentIndexs: -1, // 二级标题
            showMold: false, // 显示2级
        }
      }


      componentDidMount() {
        this.getInformation() // 获取资讯列表
        this.getAccountBalance() //获取余额
        this.trademark()//获取品牌信息
      }
      //获取余额
      getAccountBalance(){
          // BackEnd.companySquare('/api/IndustryInformation/GetAccountBalance').then(res =>{
          //     console.log(res)
          //     if(res.data.Message == "Success"){
          //         this.setState({
          //           loadings:false,
          //           balance:Number(res.data.ResponseObj).toFixed(2)
          //         })
          //     }else{
          //       this.setState({
          //         loadings:false,
          //       })
          //       message.error("登陆后查看余额"); 
          //       // this.props.history.push({ pathname: '/Login' })
          //     }   
          // })
          BackEnd.companySquare("/api/PayCenter/GetAccountBalance", {
          }).then(res => {
            if (res.data.message != "Success") {
            this.setState({
                  loadings:false,
                })
                message.error("登陆后查看余额");
            }
            else {
              this.setState({
                    loadings:false,
                    balance:res.data.responseObj.balance + res.data.responseObj.givenBalance
                  })
            }
          })
          
      }
      findData() {
        BackEnd.companySquare('/api/EnterpriseSquareInfo/GetQueryStoreSquareList', this.state.searchFirm).then(res => {
          console.log('新接口', res.data);
          if (res.data.Message == "Success") {
            if (res.data.ResponseObj) {
              this.setState({
                // TotalCount: res.data.ResponseObj.TotalCount,
                searchList: res.data.ResponseObj.StoreSquareListGroup
              }, () => {
              
              })
            }
          }
        })
      }
      //跳转充值页面、
      goRecharge = (item) => {
        if( window.localStorage.getItem('Tokens')== null){
          message.error("登陆后进行充值"); 

        }else{
        this.props.history.push({ pathname: '/Recharge' })

        }
        // this.props.history.push('/productDetail')
      }
      // 跳转资讯详情页面
      goDetail = (item) => {
        if( window.localStorage.getItem('Tokens')== null){
          message.error("请先登录"); 

        }else{
          BackEnd.companySquare('/api/IndustryInformation/QueryIndustryInformationModel',{industryInformationId:item.Id}).then(res =>{
            console.log(res)
            // return false
            if(res.data.Code == "1"){
                // this.handleCancel()
                message.error("请先登录");
            }else if(res.data.Code == "0"){
                this.props.history.push({ pathname: '/InformationDetail', search: `?id=${item.Id}` })
            }else if(res.data.Code == "2"){
              console.log(item)
              this.setState({
                  price:item.Price,
                  title:item.Title,
                  time:moment(item.AddTime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
                  detailData:item
      
              })
                this.showModal();
                }
            })
        }

             
  
        
          
      

      }
      showModal = () => {
        this.setState({
          visible: true,
        });
      };
      //点击确定
      handleOk = () => {
          console.log(this.state.detailData)
          BackEnd.companySquare('/api/IndustryInformation/BuyIndustryInformationForBalance',{industryInformationId:this.state.detailData.Id}).then(res =>{
            console.log(res)
            if(res.data.Code == "1"){
                this.handleCancel()
                message.error(res.data.Message);
            }else{
                this.props.history.push({ pathname: '/InformationDetail', search: `?id=${this.state.detailData.Id}` })
            }
        })

      };
    
      handleCancel = () => {
      console.log('取消')
      this.setState({
        visible: false,
      });
      };
      //获取列表
      getInformation (){
            BackEnd.companySquare('/api/IndustryInformation/QueryIndustryInformationList',this.state.informationList).then(res =>{
                if(res.data.Message == "Success" ){
                  this.setState({
                    loading:false
                  })
                    if(res.data.ResponseObj){
                        this.setState({
                            TotalCount: res.data.ResponseObj.CustomTotalNum,
                            arr: res.data.ResponseObj.Items
                        })
                    }
                }
                   
            console.log(res)
        })
      }
//         // 切换分页
          conversion = (page) => {
            let changePage = Object.assign({}, this.state.informationList, { currentPage: page })
            console.log(changePage)
            // window.scrollTo(0, 0);
            this.setState({
              informationList: changePage
            }, () => { this.getInformation() })
          }
          firstPage = () => {
            let changePage = Object.assign({}, this.state.informationList, { currentPage: 1 })
            // window.scrollTo(0, 0);
            this.setState({
              informationList: changePage
            }, () => { this.getInformation() })
          }
          endPage = () => {
            let changePage = Object.assign({}, this.state.informationList, { currentPage: Math.ceil(this.state.TotalCount / 10) || 1 })
            // window.scrollTo(0, 0);
            this.setState({
              informationList: changePage
            }, () => { this.getInformation() })
          }

      // 一级标题
      setCurrentIndex(index, e) {
        console.log(index)
        if (index !== '-1') {
          this.setState({
            currentIndex: index,
            currentIndexs: -1,
            showMold: true,
          })
        } else {
          let informationLists = {
            currentPage: 1,
            pageSize: 10,
            Title:'',
            Brand:''
          }
          this.setState({
            currentIndex: -1,
            currentIndexs: -1,
            showMold: false,
            informationList: informationLists,
                
          }, () => {
                  this.getInformation()
          })
    
        }
      }
      // 二级标题
      setCurrentIndexs(indexs, e) {
       console.log(e.target.getAttribute('value')) 

        e.stopPropagation()// 阻止事件冒泡
        let informationLists = {
          currentPage: 1,
          pageSize: 10,
          Title:'',
          Brand:e.target.getAttribute('value')
        }
        this.setState({
          currentIndexs: indexs,
          informationList: informationLists,
          
          }, () => {
                  this.getInformation()
          })
       
      }
      // 获取品牌信息
      trademark() {
        BackEnd
          .getData({
            Code: '27005'
          })
          .then(res => {
            if (res.data.Message.CarSeriesListGroup && res.data.Message.CarSeriesListGroup.length > 0) {
              this.setState({
                carSeries: res.data.Message.CarSeriesListGroup
              })
            }
          })
      }
      searchData(content) {
        console.log(1111)
        let informationLists = {
          currentPage: 1,
          pageSize: 10,
          Title:content,
          Brand:''
        }
        this.setState({
              informationList: informationLists,
              
        }, () => {
                this.getInformation()
        })
      }

    render() {
        const { visible, confirmLoading, ModalText } = this.state;
        return (
            <div>
               <Header searchInformation={this.searchData.bind(this)}/>
               <div className="brand_lists">
                  <div className="brand_title">
                    <span className="brand_listName">品牌:</span>
                    <span onClick={this.setCurrentIndex.bind(this, "-1")} className={this.state.currentIndex == "-1" ? "active_01" : ""}>不限</span>
                    {this.state.carSeries.map((item, index) => (
                      <span
                        key={index}
                        value={item.Content}
                        className={this.state.currentIndex === index ? "actives" : ""}
                        onClick={this.setCurrentIndex.bind(this, index)}
                      >{item.Content}</span>
                    ))}
                  </div>
                  <div className="list_detail" style={this.state.showMold ? { display: "block" } : { display: "none" }}>
                    {this.state.carSeries[this.state.currentIndex] ? this.state.carSeries[this.state.currentIndex].Brand.split(",").map((items, indexs) => (
                      <span
                        key={indexs}
                        value={items}
                        className={this.state.currentIndexs === indexs ? "active" : ""}
                        onClick={this.setCurrentIndexs.bind(this, indexs)}
                      >{items}</span>
                    )) : ""}
                  </div>
                  <div className="vacancy"></div>
                </div>
               <Spin spinning={this.state.loadings}>
                  <div style={{float:"right",marginRight:'332px',marginTop: '10px'}}>
                    
                      <span>账户余额 <i style={{color:"#ff6b37"}}>{this.state.balance}</i>元</span>
                      <span className="Recharges" onClick={this.goRecharge.bind()}>充值</span>
                  </div>
               </Spin>
                   <div className="information_main">
                        <div className="banner">
                            <img src={require('../imgage/informaBanner.png')} alt=""/>
                        </div>
                        <div className="information_list">
                        <Spin tip="Loading..." spinning={this.state.loading}>
                            {
                                this.state.arr.map(
                                    (item,index) =>  <div onClick={this.goDetail.bind(this,item)} className="information_list_title" key={index}>{item.Title}</div>)
                            }
                            {/* <div className="information_list_title">  
                                文章标题文章标题文章标题文章标题文章标题
                            </div> */}
                         </Spin>
                        </div>
                        <Modal
                            title="提示"
                            visible={visible}
                            onOk={this.handleOk}
                            confirmLoading={confirmLoading}
                            onCancel={this.handleCancel}
                            okText={"确认"}
                            cancelText={"取消"}
                            >
                            <p>这篇文章需要您支付 <i style={{color:"#ff6b37"}}>{this.state.price}</i>元 才能访问,当前余额 <i style={{color:"#ff6b37"}}> {this.state.balance}</i>元</p>
                            <p>文章标题：{this.state.title}</p>
                            <p>发布时间：{this.state.time}</p>
                            <p>是否支付<i style={{color:"#ff6b37"}}>{this.state.price}</i>元进行访问？</p>
                            </Modal>
                   </div>
                   <div className="list_info_pagination">
                <span className="first_button" onClick={this.firstPage}>首页</span>
                <Pagination
                  total={this.state.TotalCount || 10}
                //   current={this.state.searchFirm.currentPage}
                  itemRender={itemRender}
                  pageSize={10}
                  onChange={this.conversion}
                />
                <span className="last_button" onClick={this.endPage}>末页</span>
              </div>
                <Footer/>
            </div>
        );
    }
}

export default information;
