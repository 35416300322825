import React, { Component } from 'react'
import Broadside from '../../../../components/Particulars/Broadside'


import QRCode from 'qrcode.react';
import Header from '../../../../components/Header'
import { Link, withRouter } from 'react-router-dom'
import './ToBeConfirmed.scss'

import moment from 'moment'

import BackEnd from '../../../../services/api'
import { Table, Radio, Modal, message, Card, Button, Row, Col } from 'antd'
class ToBeConfirmed extends Component {
    constructor() {
        super()
        this.state = {
            information: {},
            addressInfo: {},
            details: [],
            table: {},
            UnitPrice: 0,
            Quantity: 0,
            now: 0,
            OrderStatus: 0,
            tooltip: false,
            too: false,
            visibleTube: false,
            QRcode: '',
            qrUrl: '',
            TotalMoney: 0,
            columns: [{
                title: '卖家名称',
                dataIndex: 'CompanyName',
                key: 'CompanyName ',
                width: 100,
                ellipsis: true,
                
            }, {
                title: '配件编码',
                dataIndex: 'ComponentCode',
                key: 'ComponentCode',
                width: 100,
            }, {
                title: '配件名称',
                dataIndex: 'ComponentName',
                key: 'ComponentName ',
                width: 100,
            }, {
                title: '产地',
                dataIndex: 'Origin',
                key: 'Origin',
                width: 80,
            }, {
                title: '单位',
                dataIndex: 'Unit',
                key: 'Unit',
                width: 80,
            }, {
                title: '单价',
                dataIndex: 'UnitPrice',
                key: 'UnitPrice',
                width: 100,
                render: (text, record) => (
                    <div>{Number(text).toFixed(2)}</div>
                  )
            }, {
                title: '订货数量',
                dataIndex: 'Quantity',
                key: 'Quantity',
                width: 100,
            }, , {
                title: '小计',
                dataIndex: 'OrderStatus',
                key: 'OrderStatus',
                width: 100,
                render: (text, record) => (
                    <span>
                        {(record.Quantity * record.UnitPrice).toFixed(2)}
                    </span>
                )
            },],
            Code: 0,
            invoiceArr:''

        }
    }

    componentWillMount() {
        window.scrollTo(0, 0);


    }
    componentDidMount() {
        
        this.getShopCartDetails()

    }

    getShopCartDetails() {
       
        const { search } = this.props.location
        console.log(search)
        const paramsString = search.substring(4)
        console.log(paramsString)
        const id = paramsString
        console.log(id)
        
        BackEnd.orderDetails('/api/ShoppingCart/GetOrderDetails', {
            "id": Number(id)
            // "id":21
        }).then(res => {
            console.log(res)

            this.setState({
                information: res.data.ResponseObj.orderInvoiceInfo,
                addressInfo: res.data.ResponseObj.order_AddressInfo,
                details: res.data.ResponseObj.order_Details,
                table: res.data.ResponseObj.order_Table,
                UnitPrice: res.data.ResponseObj.order_Details[0].UnitPrice,
                Quantity: res.data.ResponseObj.order_Details[0].Quantity,
            }, () => {
                this.totalMoney()
            })
        })
    }
    //取消订单
    getShopCarOrder() {
        const { search } = this.props.location
        const paramsString = search.substring(4)
        
        const id = paramsString
        BackEnd.CancelOrder('/api/ShoppingCart/CancelOrder', {
            "status": 4,
            "id": Number(id),
            "reason": this.state.invoiceArr
        }).then(res => {
            
            console.log(res)
        })

    }
    //支付订单
    getShopCartPayment() {
        const { search } = this.props.location
        const paramsString = search.substring(4)
        
        const id = paramsString
        BackEnd.getData({
            "Code": "31607",
            "OrderId": id,
            "payTypeId": 1004
        }).then(res => {
            console.log("支付", res)
           
            this.setState({
                qrUrl: res.data.Message.Content
            })
            // console.log(this.props.location.state.name, this.state.qrUrl)
        })
    }

    //关闭
    tubeHandleTooLtip = () => {
        this.setState({
            tooltip: false
        })
    }
    tubeHandleCancel = () => {

        this.setState({
            visibleTube: false
        })
    }
    tubeHandleCancels = () => {
        this.setState({
            visibleTube: true
        }, () => {
            // this.props.history.push('/buyerOrder')
        })
    }
    Cancels = () => {

        this.setState({
            too: true
        })
    }
    Cancel = () => {

        this.setState({
            too: false
        })
    }
    //打开
    tubeHandleTooltip = () => {
        this.setState({
            tooltip: true
        })
    }
    //收货订单
    getShopCartGoodsOrder() {
        const { search } = this.props.location
        const paramsString = search.substring(4)
        
        const id = paramsString
        BackEnd.GoodsOrder('/api/ShoppingCart/ReceivingGoodsOrder', {
            "status": 5,
            "id": Number(id)
        }).then(res => {
            console.log(res)
            this.setState({
                Code: res.data.Code
            })
        })
    }
    totalMoney = () => {
        let TotalPice = 0;
        let TotalPices = 0;
        for (var i = 0; i < this.state.details.length; i++) {
            TotalPice = TotalPice + Number(this.state.details[i].UnitPrice) * Number(this.state.details[i].Quantity)
            TotalPices = TotalPices + Number(this.state.details[i].Quantity)
        }
        this.setState({
            TotalMoney: TotalPice,
            Quantity: TotalPices
        }, () => {
            console.log('结果', this.state.TotalMoney, this.state.Quantity)
        })
    }
    render() {
        return (
            <div className="toBeConfirmedPage">
                <Header></Header>
                <div className="toBeConfirmedContainer">
                    <div className="Broadsides">
                        <div className="title">
                            <span>订单管理</span>
                        </div>
                        <p className="title-p">
                            <span className="title-span" >订单管理(买家)</span>
                        </p>
                        <p onClick={() => {
                            this.props.history.push('/SellerOrder')
                        }} >
                            <span >订单管理(卖家)</span>
                        </p>
                    </div>
                    <div className="toBeConfirmedContent">
                        <p className="headerTitle"><span>账户资料</span></p>
                        <p className="navigation"><span className="spann" onClick={()=>{
                                this.props.history.push('/buyerOrder')
                        }}>订单管理</span><span>></span><span>订单详情</span></p>
                        {/* 订单详情 */}
                        {
                            this.state.table.OrderStatus == 0 ?
                                <div className="orderDetail">
                                    <p className="title list-title">订单详情</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>订单编号</th>
                                                <th>下单时间</th>
                                                <th>订单状态</th>
                                                <th>确认时间</th>
                                                <th>发货时间</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.OrderCode}</td>
                                                <td>{moment(this.state.table.OrderTime  ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                {this.state.table.OrderStatus == 0 ? <td className="TD">待确认</td> : ''}
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ""
                        }
                        {
                            this.state.table.OrderStatus == 1 ?
                                <div className="orderDetail">
                                    <p className="title">订单详情</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>订单编号</th>
                                                <th>下单时间</th>
                                                <th>订单状态</th>
                                                <th>确认时间</th>
                                                <th>发货时间</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.OrderCode}</td>
                                                <td>{moment(this.state.table.OrderTime  ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                {this.state.table.OrderStatus == 1 ? <td className="TD">待支付</td> : ''}
                                                <td>{moment().format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ""
                        }
                        {
                            this.state.table.OrderStatus == 2 ?
                                <div className="orderDetail">
                                    <p className="title">订单详情</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>订单编号</th>
                                                <th>下单时间</th>
                                                <th>订单状态</th>
                                                <th>确认时间</th>
                                                <th>发货时间</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.OrderCode}</td>
                                                <td>{moment(this.state.table.OrderTime  ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                {this.state.table.OrderStatus == 2 ? <td className="TD">待发货</td> : ''}
                                                <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ""
                        }
                        {
                            this.state.table.OrderStatus == 3 ?
                                <div className="orderDetail">
                                    <p className="title">订单详情</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>订单编号</th>
                                                <th>下单时间</th>
                                                <th>订单状态</th>
                                                <th>确认时间</th>
                                                <th>发货时间</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.OrderCode}</td>
                                                <td>{moment(this.state.table.OrderTime  ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                {this.state.table.OrderStatus == 3 ? <td className="TD">已发货</td> : ''}
                                                <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{moment(this.state.table.DeliveryTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ""
                        }
                        {
                            this.state.table.OrderStatus == 4 ?
                                <div className="orderDetail">
                                    <p className="title">订单详情</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>订单编号</th>
                                                <th>下单时间</th>
                                                <th>订单状态</th>
                                                <th>取消原因</th>
                                                <th>确认时间</th>
                                                <th>发货时间</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.OrderCode}</td>
                                                <td>{moment(this.state.table.OrderTime  ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                {this.state.table.OrderStatus == 4 ? <td className="TD">已取消</td> : <td></td>}
                                                <td>{this.state.table.Reason}</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ""
                        }
                        {
                            this.state.table.OrderStatus == 5 ?
                                <div className="orderDetail">
                                    <p className="title">订单详情</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>订单编号</th>
                                                <th>下单时间</th>
                                                <th>订单状态</th>
                                                <th>确认时间</th>
                                                <th>发货时间</th>
                                                <th>收货时间</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.OrderCode}</td>
                                                <td>{moment(this.state.table.OrderTime  ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                {this.state.table.OrderStatus == 5 ? <td className="TD">已收货</td> : ''}
                                                <td>{moment(this.state.table.ConfirmTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{moment(this.state.table.DeliveryTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td>{moment(this.state.table.UpdateTime ,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}</td>



                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ""
                        }
                        {/* 物流与结算 */}
                        {
                            this.state.table.OrderStatus == 0
                                ?
                                <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>送货要求</th>
                                                <th>交易备注</th>
                                                <th>运费（元）</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.DeliveryRequire == '1' ? '隔天' : ""}
                                                    {this.state.table.DeliveryRequire == '2' ? '自提' : ""}
                                                    {this.state.table.DeliveryRequire == '3' ? '急件' : ""}
                                                </td>
                                                <td>{this.state.table.TradingRemarks}</td>
                                                <td>卖家接单后确认</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ''
                        }
                        {
                            this.state.table.OrderStatus == 1
                                ?
                                <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>送货要求</th>
                                                <th>交易备注</th>
                                                <th>运费（元）</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.table.DeliveryRequire == '1' ? '隔天' : ""}
                                                    {this.state.table.DeliveryRequire == '2' ? '自提' : ""}
                                                    {this.state.table.DeliveryRequire == '3' ? '急件' : ""}
                                                </td>
                                                <td>{this.state.table.TradingRemarks}</td>
                                                <td>{this.state.table.Freight}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                ''
                            }
                        {
                            this.state.table.OrderStatus == 2
                            ?
                            <div>
                                    <div className="logisticszContainer">
                                        <p className="title">物流与结算</p>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>送货要求</th>
                                                    <th>交易备注</th>
                                                    <th>运费（元）</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.table.DeliveryRequire == '1' ? '隔天' : ""}
                                                        {this.state.table.DeliveryRequire == '2' ? '自提' : ""}
                                                        {this.state.table.DeliveryRequire == '3' ? '急件' : ""}
                                                    </td>
                                                    <td>{this.state.table.TradingRemarks}</td>
                                                    <td>{this.state.table.Freight}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        {
                            this.state.table.OrderStatus == 3
                            ?
                            <div>
                                    <div className="logisticszContainer">
                                        <p className="title">物流与结算</p>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>送货要求</th>
                                                    <th>交易备注</th>
                                                    <th>运费（元）</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.table.DeliveryRequire == '1' ? '隔天' : ""}
                                                        {this.state.table.DeliveryRequire == '2' ? '自提' : ""}
                                                        {this.state.table.DeliveryRequire == '3' ? '急件' : ""}
                                                    </td>
                                                    <td>{this.state.table.TradingRemarks}</td>
                                                    <td>{this.state.table.Freight}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th>物流公司</th>
                                                    <th>物流单号</th>
                                                    <th>发货备注</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.table.LogisticsCompany}</td>
                                                    <td>{this.state.table.LogisticsNumber}</td>
                                                    <td>{this.state.table.DeliveryRemarks}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        {
                            this.state.table.OrderStatus == 4
                            ?
                            <div>
                                    <div className="logisticszContainer">
                            <p className="title">物流与结算</p>
                                        <table>

                                            <thead>
                                                <tr>
                                                    <th>送货要求</th>
                                                    <th>交易备注</th>
                                                    <th>运费（元）</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.table.DeliveryRequire == '1' ? '隔天' : ""}
                                                        {this.state.table.DeliveryRequire == '2' ? '自提' : ""}
                                                        {this.state.table.DeliveryRequire == '3' ? '急件' : ""}
                                                    </td>
                                                    <td>{this.state.table.TradingRemarks}</td>
                                                    <td>卖家接单后确认</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        {
                            this.state.table.OrderStatus == 5
                            ?
                            <div>
                                    <div className="logisticszContainer">
                                    <p className="title">物流与结算</p>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>送货要求</th>
                                                    <th>交易备注</th>
                                                    <th>运费（元）</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.table.DeliveryRequire == '1' ? '隔天' : ""}
                                                        {this.state.table.DeliveryRequire == '2' ? '自提' : ""}
                                                        {this.state.table.DeliveryRequire == '3' ? '急件' : ""}
                                                    </td>
                                                    <td>{this.state.table.TradingRemarks}</td>
                                                    <td>{this.state.table.Freight}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th>物流公司</th>
                                                    <th>物流单号</th>
                                                    <th>发货备注</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.table.LogisticsCompany}</td>
                                                    <td>{this.state.table.LogisticsNumber}</td>
                                                    <td>{this.state.table.DeliveryRemarks}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                ''
                        }
                        {/* 收货地址 */}
                        {this.state.addressInfo == null ? "" :

                            (this.state.table.DeliveryRequire == '2'
                                ?
                                
                               ( 
                                   this.state.table.OrderStatus == 0  ? '' :
                                    <div>
                                        <div className="goodsPlaceContainer">
                                            <p className="title">收货地址</p>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>自提点联系人</th>
                                                        <th>联系手机号</th>
                                                        <th>所在地区</th>
                                                        <th>详细地址</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.addressInfo.Consignee}</td>
                                                        <td>{this.state.addressInfo.Mobile}</td>
                                                        <td>{this.state.addressInfo.Province}-{this.state.addressInfo.City}-{this.state.addressInfo.County}</td>
                                                        <td>{this.state.addressInfo.Address}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                               )
                                :
                                <div className="goodsPlaceContainer">
                                    <p className="title">收货地址</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>收货人</th>
                                                <th>收货人手机号</th>
                                                <th>所在地区</th>
                                                <th>详细地址</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.addressInfo.Consignee}</td>
                                                <td>{this.state.addressInfo.Mobile}</td>
                                                <td>{this.state.addressInfo.Province}-{this.state.addressInfo.City}-{this.state.addressInfo.County}</td>
                                                <td className="detail">{this.state.addressInfo.Address}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}


                        {/* 发票信息 */}
                        {
                             this.state.information == null ? '' : 
                            (this.state.information.InvoiceType == "1"
                                ?
                                <div className="invoiceInfoContainer">
                                    <p className="title">发票信息</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>发票类型</th>
                                                <th>发票抬头</th>
                                                <th>纳税人识别号</th>
                                                <th>发票内容</th>
                                                <th>发票人手机号</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.information.InvoiceType == '1' ? "普通发票" : ''}</td>
                                                <td>{this.state.information.CompanyName}</td>
                                                <td>{this.state.information.IdentificationNo}</td>
                                                <td>{this.state.information.InvoiceContent}</td>
                                                <td>{this.state.information.Mobile}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                '')
                        }

                        {
                             this.state.information == null ? '' : 
                            (this.state.information.InvoiceType == "2"
                                ?
                                <div className="invoiceInfoContainer">
                                    <p className="title">发票信息</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>发票类型</th>
                                                <th>单位名称</th>
                                                <th>纳税人识别号</th>
                                                <th>注册地址</th>
                                                <th>注册电话</th>
                                                <th>开户银行</th>
                                                <th>银行账号</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td">{this.state.information.InvoiceType == '2' ? "增值税专用发票发票" : ''}</td>
                                                <td className="td">{this.state.information.CompanyName}</td>
                                                <td className="td">{this.state.information.IdentificationNo}</td>
                                                <td>{this.state.information.CompanyAddress}</td>
                                                <td>{this.state.information.CompanyPhone }</td>
                                                <td>{this.state.information.CompanyBankName }</td>
                                                <td>{this.state.information.CompanyBankAccount }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                '')
                        }
                        {
                            this.state.information == null ?
                            (this.state.table.OrderInvoiceInfoId==  0
                            ?
                            <div className="invoiceInfoContainer">
                            <p className="title">发票信息</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>发票类型</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>无票</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            '')
                            :
                            ''
                        }
                        {/* 购物清单 */}
                        <div className="shoppingListContainer">
                            <p className="title">购物清单</p>

                            <div className="buyerOrderList">
                                <Table
                                    width="100%"
                                    rowKey={(record, index) => index}
                                    columns={this.state.columns}
                                    dataSource={this.state.details}
                                    bordered={true}
                                    pagination={false}
                                    className="table-heig"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="totalContainer">
                                <div className="totalContent-p">
                                    <Row>
                                        <Col span={16}> <span>共{this.state.Quantity}件商品,总计金额:</span></Col>
                                        <Col span={5}><span>￥{(this.state.TotalMoney).toFixed(2)}</span> </Col>
                                    </Row>
                                    <Row>
                                        <Col span={16}> <span>{ this.state.table.OrderStatus== 1?' 运费（卖家接单确认）：':'运费：'  } </span></Col>
                                        <Col span={5}><span>￥{Number(this.state.table.Freight).toFixed(2)}</span></Col>
                                    </Row>
                                    <Row>
                                        <Col span={16}> <span>{this.state.table.OrderStatus== 1? '优惠（卖家接单后确认）:':'优惠：'}</span></Col>
                                        <Col span={5}>￥{Number(this.state.table.FavorablePrice).toFixed(2)}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={16}> <span> {this.state.table.OrderStatus == 1 ? '应付金额：' : '实付金额：'} </span></Col>
                                        <Col span={5}>￥{this.state.table.OrderStatus == 4 ? 0 :(((this.state.TotalMoney * 1000) + (this.state.table.Freight * 1000) - (this.state.table.FavorablePrice * 1000)) / 1000).toFixed(2)}</Col>
                                    </Row>
                                </div>

                            </div>
                           
                <div className="totalButton1">
                                {this.state.table.OrderStatus == 0 ? <span
                                    className="btn-OK"
                                    onClick={() => {
                                        this.tubeHandleTooltip()
                                    }}
                                >取消订单</span> : ''}
                                {this.state.table.OrderStatus == 1 ? <span
                                    className="btn-OK"
                                    onClick={() => {
                                        this.tubeHandleCancels();this.getShopCartPayment()
                                        // this.tubeHandleCancels()
                                        // this.getShopCartPayment()
                                    }}

                                >支付订单</span> : ''}
                                {this.state.table.OrderStatus == 3 ? <span
                                    className="btn-OK"
                                    onClick={() => {
                                        this.Cancels()
                                    }}>确认全部收货</span> : ''}
                            </div>
                <div>
                    <Modal
                        title="取消订单"
                        closeIcon="关闭"
                        visible={this.state.tooltip}
                        onCancel={this.tubeHandleTooLtip}
                        footer={null}
                        centered
                        style={{ margin: 'auto' }}
                        wrapClassName="placeTubeModel"
                        width={400}
                        hight={600}
                        maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                    >
                        <div className="Cancel-box">
                            <p className="Cancel-p">请选择取消理由，取消后不能恢复</p>
                            <div className="Cancel">
                                <Radio.Group value={this.state.invoiceArr} onChange={(e) => {
                                    this.setState({
                                        invoiceArr: e.target.value
                                    }, () => {
                                        console.log(this.state.invoiceArr)
                                    })

                                }}>
                                    <Radio value='不想买了'>不想买了</Radio>
                                    <Radio value='信息错误，重新拍'>信息错误，重新拍</Radio>
                                    <Radio value='卖家缺货'>卖家缺货</Radio>
                                    <Radio value='其他原因'>其他原因</Radio>
                                </Radio.Group>
                            </div>
                            <Button className="tooltipSpan" onClick={() => {
                                if( this.state.invoiceArr == ''){
                                    message.info('请选择取消原因')
                                }else{
                                    this.getShopCarOrder()
                                    this.tubeHandleTooLtip()
                                    this.props.history.push('/buyerOrder')
                                }
                                
                                
                            }}  >确定</Button>
                        </div>
                    </Modal>
                    <Modal
                        title="支付订单"
                        closeIcon="关闭"
                        visible={this.state.visibleTube}
                        onCancel={this.tubeHandleCancel}
                        footer={null}
                        centered
                        style={{ margin: 'auto' }}
                        wrapClassName="placeTubeModel"
                        width={400}
                        hight={265}
                        maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                    >
                        <div className="QRCode" >
                            <h4>请使用支付宝支付</h4>
                            <QRCode className="QRCode-qrCode" value={this.state.qrUrl} size={100} />
                        <p className="QRCode-p">支付成功请返回订单详情页</p>
                            <Button className="QRCode-BTN" onClick={() => {
                                this.props.history.push('/buyerOrder')
                            }}>返回详情页</Button>
                        </div>
                    </Modal>
                    <Modal
                        title="确认订单"
                        closeIcon="关闭"
                        visible={this.state.too}
                        onCancel={this.Cancel}
                        footer={null}
                        centered
                        style={{ margin: 'auto' }}
                        wrapClassName="placeTubeModel"
                        width={400}
                        hight={265}
                        maskStyle={{ background: 'rgba(129,148,148,.5)' }}
                    >
                        <div className="ConfirmReceipt">
                            <p className="Confirmreceipt-p">确认全部收货</p>
                            <Button onClick={() => {
                                if (this.state.Code == 1) {
                                    message.info('确认失败，请重新确认')
                                } else {
                                    this.getShopCartGoodsOrder(); this.props.history.push('/buyerOrder')
                                }

                            }} className="Confirmreceipt-btn">确认</Button>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}
export default withRouter(ToBeConfirmed)
