import React, { Component } from 'react'
import Header from '../../../components/Header'
import Usercenter from '../Usercenter'
import './VocationalTraining.scss'
import { Table, Button, Modal, Pagination, message } from 'antd'
import ContentTheReport from './components/ContentTheReport'
import InvoiceToApplyFor from './components/InvoiceToApplyFor'
import ViewTheResults from './components/ViewTheResults'
import ProofOfPayment from "./components/ProofOfPayment";
import BackEnd from '../../../services/api'


function itemRender(current, type, originalElement) {
	if (type === 'prev') {
	  return <span>上一页</span>;
	} if (type === 'next') {
	  return <span>下一页</span>;
	}
	return originalElement;
  }


export default class index extends Component {
	constructor(props){
		super(props)
		this.state = {
			columns:[
				{
					title: "培训期数",
					dataIndex: 'term',
					key: 'term',
					width: 90,
				},
				{
					title: "培训计划",
					dataIndex: 'plan_name',
					key: 'plan_name',
					width: 110,
				},
				{
					title: "培训计划方向",
					dataIndex: 'plan_remark',
					key: 'plan_remark',
					width: 110,
				},
				{
					title: "报名截止",
					dataIndex: 'end_time',
					key: 'end_time',
					width: 135,
				},
				
				{
					title: "培训时间",
					dataIndex: 'start_time',
					key: 'start_time',
					width: 135,
				},
				{
					title: "状态",
					dataIndex: 'TimeStatus',
					key: 'TimeStatus',
					width: 90,
					render: (text, record) => (
						<div className="operation">
							{
								record.TimeStatus == 1 ? '进行中' : record.TimeStatus == 2 ? '已结束' :''  
							}
						</div>
					)
				},
				{
					title: "操作",
					width: 250,
					key: 'id',
					render: (text, record) => (
						<div className="operation">
							{
								record.TimeStatus == 1 ?  <Button type="link" onClick={()=> this.openReport(record)}>培训报名</Button> : ''
							}
							
							<Button type="link" onClick={()=>this.openFormance(record)}>查看学员编号/成绩</Button>
							{/* <Button type="link" onClick={()=>this.openPayment(record)}>付款凭证</Button> */}
							{/* {
								record.is_invoice == 0 ? <Button type="link" onClick={()=> this.openInvoice(record)}>发票申请</Button> :  (record.image ?  <Button type="link" onClick={()=> this.LookInvoice(record)}>查看发票</Button> : '')  
							} */}
							
						</div>
					)
				},
			],//列表表头
			carList:[],//列表data
			visible:false,//培训报告弹窗
			invoice:false,//成绩弹窗
			performance:false,//发票弹窗
			TotalCount:1,//分页
			pageSize:10,//分页大小
			Total:1,//分页大小
			invoiceId:null,//培训Id
			userId:window.localStorage.getItem('userId') || '',
			CompanyName:window.localStorage.getItem('CompanyName') ||'',
			LookInvoice:false,//查看发票
			LookProof:false,//
			listArr:[],//数组
			classList:[],
		}
		this.TheReport = React.createRef()
		this.ApplyFor = React.createRef()
		this.Results = React.createRef()
		this.ProoRef = React.createRef()
	}
	//组件渲染后调用
	componentDidMount(){
		this.lodData(this.state.pageSize,this.state.TotalCount)
		BackEnd.orderDetails("/api/PayCenter/GetAccountBalance", {
		}).then(res => {
		  if (res.data.message != "Success") {
			message.error("请登录")
			this.props.history.push("/PseudoLogIn")
			return
		  }
		})
	}
	lodData = (pageSize,page)=>{
		BackEnd.trainList({pageSize,page}).then(res=>{
			this.setState({
				carList:res.data.data.data,
				Total:res.data.data.total,
			})
		})
	}
	//关闭培训报告
	handleReport =(e)=>{
		this.setState(({
			visible:false
		}))
	}
	//打开培训报告弹窗
	openReport = (record)=>{
		this.setState({
			visible:true
		},()=>{
			setTimeout(()=>{
				this.TheReport.current.state.SId = record.id
				this.TheReport.current.kong()
				this.TheReport.current.setStudent()
			},300)
		})
	}
	//发票申请
	openInvoice = ()=>{
		BackEnd.paymentList({userId:window.localStorage.getItem('userId')}).then(res=>{
            if(res.data.code == 0 ){
                this.setState({
                    classList:res.data.data
                },()=>{
					if(this.state.classList.length === 0) {
						this.setState({
							invoice:false
						})
						message.error('请上传付款凭证')
					}else{
						this.setState({
							invoice:true,
						},()=>{
							setTimeout(() => {
								this.ApplyFor.current.kong()
								// this.ApplyFor.current.set()
								
							}, 300);
						})
					}
				})
            }
        })
		
	}
	//关闭成绩弹窗
	handleinvoice = ()=>{
		this.setState({
			invoice:false
		})
	}
	//发票弹窗
	openFormance = (e)=>{
		this.setState({
			performance:true
		},()=>{
			setTimeout(()=>{
				this.Results.current.getData(e)
			},300)
		})
	}
	//关闭弹窗
	handleformance = ()=>{
		this.setState({
			performance:false
		})
	}
	//申请发票
	handleinvoiceOk = ()=>{
	
		let obj = this.ApplyFor.current.state.form
		// obj.train_id = this.state.listArr
		obj.userId = this.state.userId
		obj.CompanyName = this.state.CompanyName
	
		BackEnd.AddInvoice(obj).then(res=>{
			if(res.data.code == 0) {
				this.setState({
					invoice:false,
				},()=>{
					this.lodData(this.state.pageSize,this.state.TotalCount)
					message.success(res.data.Message);
				})
			}else{
				message.error(res.data.Message)
				}
				
			})
	}
	//分页
	conversion=(e)=>{
		this.setState({
			TotalCount:e
		},()=>{
			this.lodData(this.state.pageSize,this.state.TotalCount)
		})
	}
	LookInvoice = (e) =>{
		
		this.setState({
			img:e.image,
			LookInvoice:true
		})
	
	}
	invoiceOk=()=>{
		this.setState({
		
			LookInvoice:false
		})
	}
	//付款凭证
	openPayment=()=>{
		if(this.state.listArr.length!=0){
			this.setState({
				LookProof:true,
			},()=>{
				setTimeout(() => {
				
					this.ProoRef.current.kongImg()
				}, 300);
			})
		}else{
			message.error('请选择课程类型！')
		}
	}
	//付款凭证提交
	ProofOk=()=>{
		 this.ProoRef.current.onSubimt(this.state.listArr)
	}
	onSelectChange=(e,x)=>{
		let arr =[]
		x.forEach(i => {
			arr.push(i.id)
		});
		this.setState({
			listArr:arr
		})
    }
  render() {
	const rowSelection = {
		onChange: this.onSelectChange.bind(this)
	};
    return (
      <div>
        <Header></Header>
        <Usercenter></Usercenter>
        <div className='train'>
            <p className='title'>行业培训</p>
			<br></br>
			<div>
				<Button  type="primary" onClick={this.openPayment}>付款凭证</Button>
				<Button className='ml-10' type="primary" onClick={this.openInvoice}>发票申请</Button>
			</div>
			<br></br>
			<div className='contentPage'>
				<Table rowSelection={rowSelection} scroll={{ x: "100%" }} bordered dataSource={this.state.carList} columns={this.state.columns} pagination={false}   />
				<Pagination
                  total={this.state.Total}
                  itemRender={itemRender}
                  pageSize={this.state.pageSize}
                  onChange={this.conversion}
                />
			</div>
        </div>
		<Modal
          title="培训报名"
          visible={this.state.visible}
		  maskClosable={false}
		  onCancel={this.handleReport}
		  cancelText="关闭窗口"
		  footer
		  width='60%'
        >
			<ContentTheReport onCls={()=>{this.setState({visible:false})}} ref={this.TheReport} ></ContentTheReport>
        </Modal>
		<Modal
          title="查看成绩"
          visible={this.state.performance}
		  maskClosable={false}
		  onOk={this.handleformance}
		  onCancel={this.handleformance}
		  cancelText="关闭窗口"
		  okText="确认"
		  width='70%'
        >
			<ViewTheResults ref={this.Results}></ViewTheResults>
        </Modal>
		<Modal
          title="发票申请"
          visible={this.state.invoice}
		  maskClosable={false}
		  onOk={this.handleinvoiceOk}
		  onCancel={this.handleinvoice}
		  cancelText="关闭窗口"
		  okText="申请发票"
		  width='50%'
        >
			<InvoiceToApplyFor ref={this.ApplyFor}  isdata={this.state.classList}  ></InvoiceToApplyFor>
        </Modal>
		{/* <Modal
          title="发票申请"
          visible={this.state.LookInvoice}
		  maskClosable={false}
		  onOk={this.invoiceOk}
		  onCancel={this.invoiceOk}
		  cancelText="关闭窗口"
		  okText="确定"
		  width='50%'
        >
			<img style={{width:'100%'}} src={this.state.img} alt="" />
        </Modal> */}
		<Modal
          title="付款凭证"
          visible={this.state.LookProof}
		  maskClosable={false}
		  width='30%'
		  onCancel={()=>{this.setState({LookProof:false})}}
		  onOk={this.ProofOk}
		  cancelText="关闭窗口"
		  okText="提交"
        >
			<ProofOfPayment ref={this.ProoRef}   Payclose={()=>{this.setState({LookProof:false})}}></ProofOfPayment>
        </Modal>
	
      </div>
    )
  }
}
