import React, { Component } from 'react'
import { Carousel } from 'antd'
class Banner extends Component {
  render() {
    return (
      <Carousel autoplay speed={1000} style={style.shape}>
        <div>
          <img style={style.banner} src={require('./images/banner-1.png')} alt="北京车兄弟有限公司" />
        </div>
        <div>
          <img style={style.banner} src={require('./images/banner-2.png')} alt="北京车兄弟有限公司" />
        </div>
      </Carousel>
    )
  }
}
export default Banner

const style = {
  banner: {
    display: 'block',
    width: '100%',
    height: 408
  },
  shape: {
    width: 1000,
    height: 408,
    boxSizing: 'border-box'
  }
}
