import React, { Component } from 'react'
import './CompanyInfo.scss'
import { NavLink, withRouter } from 'react-router-dom'
class CompanyInfo extends Component {
    constructor(props) {
        super()
        this.state = {
            activeIndex: 0,// 默认放大展示的图片索引
            componentBigImg: '', // 默认展示大图
            nowimage:'',
        }
        console.log(111111,props.Authentication)
    }
    // 放大的图片
    enlarge(ImageUrl, index) {
        this.setState({
            componentBigImg: ImageUrl,
            activeIndex: index
        })
    }
    componentDidMount() {
        // console.log(this.props.storePic)
        // return false
        if(this.props.storePic == null){
            this.setState({
                nowimage:''
            })
        }else{
            this.setState({
                nowimage:this.props.storePic[0]
            })
        }
        
      }
    render() {
        return (
            <div className="companyInfoContainer">
                {/* 公司门店图片 */}
                <div className="storePic">
                    <div className="bigPic">
                        <img src={this.state.nowimage ? this.state.nowimage :  require("../../../images/noCommodity.png")} alt="门店大图展示" />
                    </div>
                    <div className="picList">
                        <ul>
                            {
                                this.props.storePic ? this.props.storePic.map((item, index) => <li className="li" key={index}>
                                <img className="li-image" onClick={()=>{
                                    this.setState({
                                        nowimage:item
                                    })
                                }} src={item} alt="门店小图"/>
                            </li>
                                
                            )
                            :''
                            
                            }
                        </ul>
                       
                    </div>
                </div>
                <div className="companyInfoContent">
                    <table className="companyInfoTable">
                        <tbody>
                            <tr>
                                <td>供应商名称</td>
                                <td
                                className="toPersonalStore"
                                onClick={() => {
                                    this.props.history.push({ pathname: '/personalStore', search: `?id=${this.props.companyInfo.AuthCode || ''}` })
                                }}>{this.props.companyInfo.Name}</td>
                            </tr>
                            <tr>
                                <td>经营品牌</td>
                                <td>{this.props.companyInfo.AdminMainBrand}</td>
                            </tr>
                            <tr>
                                <td>联系电话</td>
                                <td>{this.props.companyInfo.Mobile}</td>
                            </tr>
                            <tr>
                                <td>所在地址</td>
                                <td>{this.props.companyInfo.Address}</td>
                            </tr>
                            <tr>
                                <td>简介</td>
                                <td>{this.props.companyInfo.Memo}</td>
                            </tr>
                            <tr>
                                <td>认证情况</td>
                                <td>{this.props.Authentication == "true" ? "已认证" : "未认证"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default withRouter(CompanyInfo)
