import React, { PureComponent } from 'react'
import { getCookie, deleteCookie } from '../../utils/env'
import {
	Table,
	message,
	Modal
} from 'antd'
import BackEnd from '../../services/api'
class NoInquiry extends PureComponent {
	constructor() {
		super()
		this.state = {
			visible: true, // 未登录提示框
			activeIndex: '',
			visibleProvider: false, // 供应商名片弹框
			visibleFitting: false, // 配件查询弹框
			ModelList: [], // 弹框数据列表
			CompanyDetail: [], // 供应商弹框信息
			noInquiryList: [], // 未询价单列表
			pitchOnList: [],// 选中的的询价列表
			batchEnquiryList: [], // 批量询价列表
			locale: {
				emptyText: <img src={(require('./images/noData.png'))}></img>
			},
			columns: [{
				title: '',
				dataIndex: 'QQ',
				key: 'QQ',
				width: 40,
				render: (text, record) => (
					text ?
						<a href={'tencent://message/?uin=' + text}><img border="0" src={require('./images/QQ.png')} alt="添加好友" title={text} /></a> :
						<img border="0" src={require('./images/QQ.png')} alt="添加好友" title={text} onClick={this.RemindUser} />
				)
			}, {
				title: '供应商',
				dataIndex: 'CompanyName',
				key: 'CompanyName',
				width: 240,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text} className='storer' onClick={this.providerModel.bind(this, record)}>{text}</span>
				)
			}, {
				title: '区域',
				dataIndex: 'Region',
				key: 'Region',
				width: 150,
				ellipsis: true,
				render: (text, record) => (
					<div title={text} >
						{text}
					</div>
				)
			}, {
				title: '配件编码',
				dataIndex: 'ComponentCode',
				key: 'ComponentCode',
				// width: 120,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text} className='storer' onClick={this.FittingsModel.bind(this, record)}>
						{text}
					</span>
				)
			}, {
				title: '配件名称',
				dataIndex: 'ComponentName',
				key: 'ComponentName',
				width: 140,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<div title={text} className='omit' style={{ width: '110px' }}>
						{text}
					</div>
				)
			}, {
				title: '品牌',
				dataIndex: 'Brand',
				key: 'Brand',
				width: 80,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '产地',
				dataIndex: 'Origin',
				key: 'Origin',
				width: 65,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '车型',
				dataIndex: 'VehicleMode',
				key: 'VehicleMode',
				width: 150,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '数量',
				dataIndex: 'Quantity',
				key: 'Quantity',
				width: 150,
				ellipsis: true,
				render: (text, record, index) => (
					<div className="quantityContainer">
						<span onClick={this.removeEnquiryQuantity.bind(this, record, index)}>-</span>
						<input type="text" value={text} className="enquiryQuantity" maxLength="5" ref="enquiryQuantity" readOnly/>
						<span onClick={this.addEnquiryQuantity.bind(this, record, index)}>+</span>
					</div>
				)
			}, {
				title: '单位',
				dataIndex: 'unit',
				key: 'unit',
				width: 60,
				ellipsis: true,
				className: 'resultColumns',
				render: (text, record) => (
					<span title={text}>
						{text}
					</span>
				)
			}, {
				title: '单价',
				dataIndex: 'EnterpriseCategoryPrice',
				key: 'EnterpriseCategoryPrice',
				width: 100,
				ellipsis: true,
				render: (text, record) => (
					getCookie('LoginCacheKey') ?
						Number(text) > 0 ? <span title={text}>
							{text}
						</span> : <span>面议</span> : <span onClick={this.goLogin} className='storer'>登录可查看</span>
				)
			}, {
				title: '操作',
				key: 'operation',
				width: 100,
				ellipsis: true,
				render: (text, record, index) => (
					<span onClick={this.deleteEnquiry.bind(this, record, index)} className='storer'>删除</span>
				)
			}]
		}
	}
	componentWillMount() {
		if (window.localStorage.getItem('EnterpriseCategory')) {
			this.setState({
				visible: false
			})
		}
	}
	componentDidMount() {
		this.getData()
	}
	// 登录状态弹框
	loginVisible = () => {
		this.setState({
			visible: false
		}, () => {
			this.props.history.push('/')
		})
	}
	// 去注册
	goRegister = () => {
		this.props.history.push('/register')
	}
	// 去登录
	goLogin = () => {
		this.props.history.push('/login')
	}
	// 获取询价单数据
	getData() {
		this.setState({
			disableButton: true,
			activeIndex: ''
		}, () => {
			BackEnd
				.getInquiry()
				.then(res => {
					console.log('未询价单信息', res.data)
					if (res.data.Message === 'Success') {
						this.setState({
							noInquiryList: res.data.ResponseObj.InquiryVoucherItem
						}, () => {
							console.log('数据', this.state.noInquiryList)
						})
					}
				}).catch(error => {
					message.error('获取询价单异常!');
				})
		})
	}
	// 更新数据
	updateData() {
		BackEnd
			.setInquiry({ userId:Number(Number(window.localStorage.getItem('userId'))), inquiryVoucherItem: this.state.noInquiryList })
			.then(res => {
				if (res.data.Message === 'Success') {
					this.getData()
				}
			}).catch(error => {
				message.error('操作异常,请稍后重试!');
			})
	}
	// 减少询价配件数量
	removeEnquiryQuantity(record, index) {
		let removeEnquiryQuantityList = [...this.state.noInquiryList]
		if (removeEnquiryQuantityList[index].Quantity <= 0) {
			return
		}
		removeEnquiryQuantityList[index].Quantity--;
		this.setState({
			noInquiryList: removeEnquiryQuantityList
		}, () => {
			this.updateData()
		})
	}
	// 增加询价配件数量
	addEnquiryQuantity = (record, index) => {
		let addEnquiryQuantityList = [...this.state.noInquiryList]
		addEnquiryQuantityList[index].Quantity++;
		this.setState({
			noInquiryList: addEnquiryQuantityList
		}, () => {
			this.updateData()
		})
	}
	// 批量询价 or 批量删除
	onSelectChange = (selectedRowKeys, selectedRows) => {
		console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		this.setState({
			pitchOnList: selectedRows
		}, () => {
			console.log('选中的列表', this.state.pitchOnList)
		})
	}

	// 供应商名片弹框
	providerModel(record) {
		this.setState({
			ModelList: record,
			visibleProvider: true,
			CompanyDetail: record.CompanyItem,
			activeIndex: ''
		})

	}
	// 配件编码弹框
	FittingsModel(record) {
		this.setState({
			ModelList: record,
			visibleFitting: true,
			activeIndex: ''
		})
	}
	// 批量询价
	batchEnquiry = () => {
		if (this.state.pitchOnList.length <= 0) {
			message.info('请选择配件')
			return
		}
		console.log('批量询价')
		let newPitchOnList = []
		for (let i = 0; i < this.state.pitchOnList.length; i++) {
			var obj = {};
			obj.ComponentId = this.state.pitchOnList[i].ComponentId
			obj.ShowQuantity = this.state.pitchOnList[i].Quantity
			obj.Memo = this.state.pitchOnList[i].Memo
			newPitchOnList.push(obj)
		}
		console.log('组合', newPitchOnList)
		this.setState({
			batchEnquiryList: newPitchOnList
		}, () => {
			BackEnd
				.getData({
					Code: 22026,
					OwebInquiryCompListGroup: this.state.batchEnquiryList
				}).then(res => {
					if (res.data.Message.Success === 'True') {
						message.info('已提交询价单，请留意报价短信提醒！')
					} else {
						message.info('报价失败，请您稍后重试！')
					}
				}).catch(err => {
					message.info('报价失败，请您稍后重试！')
				})
		})

	}
	// 删除询价单
	deleteEnquiry(record, index) {
		this.state.noInquiryList.splice(index, 1);
		this.updateData()
	}
	// 批量删除询价单
	batchDeleteEnquiry = () => {
		if (this.state.pitchOnList.length <= 0) {
			message.info('请选择配件')
			return
		}
		let set = this.state.pitchOnList.map(item => item.ComponentId)
		let resArr = this.state.noInquiryList.filter(item => !set.includes(item.ComponentId))
		BackEnd
			.setInquiry({ userId:Number(Number(window.localStorage.getItem('userId'))), inquiryVoucherItem: resArr })
			.then(res => {
				if (res.data.Message === 'Success') {
					message.info('已删除')
					this.getData()
				}
			}).catch(err => {
				message.error('删除询价单异常!');
			})
	}
	// 取消弹框
	handleCancel = () => {
		this.setState({
			visibleFitting: false,
			visibleProvider: false,
			visibleEnquiry: false,
			activeIndex: ''
		});
	}
	RemindUser = () => {
		message.error('暂无QQ信息!')
	}
	// 获取点击行的索引
	clickRow(num) {
		this.setState({
			activeIndex: (num.key)// 获取点击行的索引
		})
	}
	setClassName = (record, index) => {// record代表表格行的内容，index代表行索引
		// 判断索引相等时添加行的高亮样式
		return index === this.state.activeIndex ? 'l-table-row-show' : '';
	}
	render() {
		const rowSelection = {
			onChange: this.onSelectChange.bind(this)
		};
		return (
			<div className="noInquiry">
				<div className='accessories_result'>
					<Table
						width='100%'
						rowSelection={rowSelection}
						rowKey={(record, index) => index}
						columns={this.state.columns}
						dataSource={this.state.noInquiryList}
						bordered={true}
						pagination={false}
						locale={this.state.locale}
						className='accessoriesTable'
						rowClassName={this.setClassName}
						onRow={(record) => {//表格行点击事件
							return {
								onClick: this.clickRow.bind(this, record)
							};
						}}
					/>
					<div className='list_info_pagination'>
						<div className="enquiryContainer">
							<span onClick={this.batchEnquiry}>批量询价</span>
							<span onClick={this.batchDeleteEnquiry}>批量删除</span>
						</div>
						<div className="accessoriesPagination"></div>
						<div className="reserved"></div>
					</div>
				</div>
				<Modal
					title="供应商名片"
					visible={this.state.visibleProvider}
					onCancel={this.handleCancel}
					footer={null}
					centered
					width={350}
					maskStyle={{ background: 'rgba(129,148,148,.5)' }}
				>
					<div className='adjust'><span>供应商名称:</span><span className='conceal' title={this.state.ModelList.CompanyName}>{this.state.ModelList.CompanyName}</span></div>
					<div className='adjust'><span>区域:</span><span className='conceal'>{this.state.ModelList.Region}</span></div>
					<div className='adjust'><span>地址:</span><span className='conceal' title={this.state.CompanyDetail.Address}>{this.state.CompanyDetail.Address}</span></div>
					<div className='adjust'><span>联系人:</span><span className='conceal'>{this.state.CompanyDetail.Contractor}</span></div>
					<div className='adjust'><span>手机号:</span><span className='conceal' title={this.state.CompanyDetail.Mobile}>{this.state.CompanyDetail.Mobile}</span></div>
					<div className='adjust'><span>联系电话:</span><span className='conceal' title={this.state.CompanyDetail.Telephone}>{this.state.CompanyDetail.Telephone}</span></div>
					<div className='adjust'><span>QQ:</span><span className='conceal' title={this.state.CompanyDetail.QQ}>{this.state.CompanyDetail.QQ}</span></div>
					<div className='consult'>
						<img src={require('./images/QQ.png')} />
						{
							this.state.CompanyDetail.QQ ?
								<a href={'tencent://message/?uin=' + this.state.CompanyDetail.QQ} className='interview'>立即咨询</a> :
								<a href='javascript:;' className='interview' onClick={this.RemindUser}>立即咨询</a>
						}
					</div>
				</Modal>
				<Modal
					title="配件查询"
					visible={this.state.visibleFitting}
					onCancel={this.handleCancel}
					footer={null}
					centered
					style={{ margin: 'auto' }}
					width={300}
					maskStyle={{ background: 'rgba(129,148,148,.5)' }}
				>
					<div className='adjust'><span>配件编码:</span><span className='conceal' title={this.state.ModelList.ComponentCode}>{this.state.ModelList.ComponentCode}</span></div>
					<div className='adjust'><span>配件名称:</span><span className='conceal' title={this.state.ModelList.ComponentName}>{this.state.ModelList.ComponentName}</span></div>
					<div className='adjust'><span>品牌:</span><span className='conceal' title={this.state.ModelList.Brand}>{this.state.ModelList.Brand}</span></div>
					<div className='adjust'><span>产地:</span><span className='conceal' title={this.state.ModelList.Origin}>{this.state.ModelList.Origin}</span></div>
					<div className='adjust'><span>数量:</span><span className='conceal'>{this.state.ModelList.Quantity}</span></div>
					<div className='adjust'><span>单位:</span><span className='conceal'>{this.state.ModelList.unit}</span></div>
					<div className='adjust'><span>单价:</span><span className='conceal'>{Number(this.state.ModelList.EnterpriseCategoryPrice) > 0 ? this.state.ModelList.EnterpriseCategoryPrice : '面议'}</span></div>
					<div className='adjust'><span>介绍:</span><span className='conceal' title={this.state.ModelList.Memo}>{this.state.ModelList.Memo ? this.state.ModelList.Memo : '暂无信息'}</span></div>
					<div className='consult'>
						<img src={require('./images/QQ.png')} />
						{
							this.state.CompanyDetail.QQ ?
								<a href={'tencent://message/?uin=' + this.state.CompanyDetail.QQ} className='interview'>立即咨询</a> :
								<a href='javascript:;' className='interview' onClick={this.RemindUser}>立即咨询</a>
						}
					</div>
				</Modal>
				{/* <Modal
					title="提示"
					visible={this.state.visible}
					onCancel={this.loginVisible}
					footer={null}
					maskClosable={false}
					wrapClassName='isLoginModel'
				>
					<p>请您登录后再访问</p>
					<p className='loginFunction'><span onClick={this.goRegister}>企业注册</span><span onClick={this.goLogin}>登录</span></p>
				</Modal> */}
				{/* <Modal
					title="提示"
					visible={this.state.visible}
					onCancel={this.loginVisible}
					footer={null}
					maskClosable={false}
					wrapClassName='isLoginModel'
				>
					<p>请您登录后再访问</p>
					<p className='loginFunction'><span onClick={this.goRegister}>企业注册</span><span onClick={this.goLogin}>登录</span></p>
				</Modal> */}
			</div>
		)
	}
}
export default NoInquiry
